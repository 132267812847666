import { Box, SxProps, Theme, Typography } from '@mui/material'
import { ChartOptions, mapColors } from 'components/analytics/Chart'
import { map } from 'lodash'

enum Shape {
  SQAURE = 'Square',
  LINE = 'Line',
  CIRCLE = 'Circle',
  SOLID_LINE = 'Solid Line',
}

export interface IconProps {
  color?: string
  label: string
  shape?: Shape
  removeLegendMarginY?: boolean
}

type Gradient = {
  low: string
  high: string
}

export interface Props {
  icons?: IconProps[]
  chartOptions?: ChartOptions
  gradient?: Gradient
  removeLegendMarginY?: boolean
}

const LegendIcon = (props: IconProps): JSX.Element => {
  if (props.shape === Shape.LINE) {
    return <Line color={props.color ?? 'info.light'} />
  } else if (props.shape === Shape.CIRCLE) {
    return <Circle color={props.color ?? 'info.light'} />
  } else if (props.shape === Shape.SOLID_LINE) {
    return <SolidLine color={props.color ?? 'info.light'} />
  }
  return (
    <Square
      removeLegendMarginY={props.removeLegendMarginY}
      color={props.color ?? 'info.light'}
    />
  )
}

const Legend = (props: Props): JSX.Element => {
  const defaultChartOptions: ChartOptions = {
    colorPalette: ['#6ad098', '#25c5f8', '#ee7d5c', '#8a66a6'],
  }

  const chartOptions = { ...defaultChartOptions, ...props.chartOptions }

  const mappedColors = mapColors(
    map(props.icons, (icon) => icon.label),
    chartOptions?.colorPalette,
    props?.chartOptions?.colorMap
  )

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Typography variant="body2" mb={1}>
        Legend
      </Typography>
      <Box height="fill-available" sx={{ overflowY: 'auto' }} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          {props?.icons?.map(
            (icon) =>
              icon.label && (
                <Box
                  key={icon.label}
                  display="flex"
                  alignItems="center"
                  id="legend-container"
                  mb={props.removeLegendMarginY ? 0 : 1}
                >
                  <LegendIcon
                    label={icon.label}
                    shape={icon.shape}
                    color={icon.color ? icon.color : mappedColors[icon.label]}
                    removeLegendMarginY={props.removeLegendMarginY}
                  />
                  <Typography variant="caption" id="legend-title">
                    {icon.label}
                  </Typography>
                </Box>
              )
          )}

          {props.gradient && <GradientView {...props.gradient} />}
        </Box>
      </Box>
    </Box>
  )
}

const Square = ({
  color,
  removeLegendMarginY,
}: {
  color: string
  removeLegendMarginY?: boolean
}) => (
  <Box
    minHeight={removeLegendMarginY ? 13 : 15}
    minWidth={removeLegendMarginY ? 13 : 15}
    bgcolor={color}
    sx={legendStyle}
  />
)

const Circle = ({ color }: { color: string }) => (
  <Typography
    sx={legendStyle}
    bgcolor={color}
    color="info.light"
    borderRadius="50%"
    fontSize="12px"
  >
    #
  </Typography>
)
const Line = ({ color }: { color: string }) => (
  <Typography sx={legendStyle} color={color} fontWeight="bold" fontSize="19px">
    --
  </Typography>
)
const SolidLine = (props: { color: string }) => (
  <Box bgcolor={props.color} height="4px" width="15px" mr={2} />
)

const GradientView = ({ low, high }: Gradient) => (
  <Box display="flex" flexDirection="column" id="actions-legend">
    <Typography pb={1} variant="caption">
      Number of Actions
    </Typography>

    <Box display="flex">
      <Box
        height={84}
        width={26}
        mr={2}
        sx={
          [
            {
              background: `linear-gradient(to top, ${low}, ${high})`,
            },
          ] as SxProps<Theme>
        }
        id="gradient-rectangle"
      />
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Typography variant="caption">HIGH</Typography>
        <Typography variant="caption">LOW</Typography>
      </Box>
    </Box>
  </Box>
)

const legendStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mr: 2,
  height: '15px',
  width: '15px',
}

export default Legend
