import { ErrorMessage } from '@hookform/error-message'
import { Typography } from '@mui/material'
import { Props as SelectionLabelProps } from 'components/form/SelectionBox/SelectionLabel'
import { DeepMap, FieldError, FieldValues } from 'react-hook-form'

export interface Props extends SelectionLabelProps {
  errors?: DeepMap<FieldValues, FieldError>
}

const SelectionErrors = ({ errors, label }: Props): JSX.Element => {
  return (
    <ErrorMessage
      errors={errors}
      name={label}
      render={({ message }) => (
        <Typography
          variant="body1"
          sx={{ color: 'error.main', fontSize: '14px', fontWeight: 600 }}
        >
          *{message}
        </Typography>
      )}
    />
  )
}

export default SelectionErrors
