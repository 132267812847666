import { ActivityFeed } from 'components/ActivityFeed'
import { EvaluationTask, useGetHomePageDataQuery } from 'generated/graphql'
import {
  defaultUser,
  User,
  UserContext,
  UserRoles,
} from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'

export interface HomePageState {
  tasks: EvaluationTask[] | undefined
  user: User
  activityFeed: ActivityFeed[]
  loading: boolean
}

export function useHomePageState(): HomePageState {
  const userContext = useContext(UserContext)
  const [tasks, setTask] = useState<EvaluationTask[]>()
  const [user, setUser] = useState<User>(defaultUser)
  const [activityFeed, setActivityFeed] = useState<ActivityFeed[]>([])

  const userId = userContext.user.userId
  const tenantId = userContext.user.tenant.id

  const [query] = useGetHomePageDataQuery({
    variables: { userId, tenantId },
    pause: userId === '' || tenantId === '',
  })

  useEffect(() => {
    setUser(userContext?.user)

    if (query?.data) {
      const data = query.data.listEvaluationTasksByUser as EvaluationTask[]
      setTask(data ?? [])
      setActivityFeed(query.data.listActivities as ActivityFeed[])
    } else if (
      !userContext.hasRole(UserRoles.EVALUATOR) &&
      !userContext.hasRole(UserRoles.CONTRACT_SPECIALIST)
    ) {
      setTask([])
    }
  }, [query, setUser, setTask, user, userContext])

  return {
    tasks,
    user,
    activityFeed,
    loading: query.fetching,
  }
}
