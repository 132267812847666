import { Box, Typography } from '@mui/material'
import { InfoButton } from 'components/InfoButton'
import { PageHeader } from 'components/PageHeader'
import { PageSection } from 'components/PageSection'
import { format, parseISO } from 'date-fns'
import { useState } from 'react'

import { ContractsOverviewTable } from '../ContractsOverviewTable'
import { ContractsOverviewPageState } from '../state/useContractsOverviewState'

export const Page = ({
  contracts,
}: ContractsOverviewPageState): JSX.Element => {
  const [infoOpen, setInfoOpen] = useState('')
  return (
    <Box
      display="flex"
      flex={1}
      ml={0.75}
      mt={3.5}
      flexDirection="column"
      mr={4}
    >
      <PageHeader header="Contracts Overview" />
      <PageSection
        header={'DHMS Details'}
        inlineLeftChildren={
          <>
            <InfoButton
              label={'contracts-subheading-info'}
              openInfoLabel={infoOpen}
              setOpenInfoLabel={setInfoOpen}
              sx={{ marginLeft: -2 }}
            >
              <Typography
                variant="caption"
                sx={{ fontWeight: 500, color: '#0E0E0E' }}
              >
                The information shown below is comprised of data from the PMAT
                Contracts Module, the CPARS report, and the Acquisition Tracker,
                with supplementary data provided by CD-DHMS members.
              </Typography>
            </InfoButton>
            {contracts[0]?.dateUpdatedAt && (
              <Typography variant="caption" color="text.secondary" ml={1}>
                {`Last Updated ${format(
                  parseISO(contracts[0].dateUpdatedAt),
                  'yyyy-MM-dd'
                )}`}
              </Typography>
            )}
          </>
        }
      >
        <ContractsOverviewTable contracts={contracts} />
      </PageSection>
    </Box>
  )
}
