import '@fontsource/roboto'
import '@fontsource/roboto-slab'
import '@fontsource/inter'

import { createTheme, ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    calendar: {
      light: string
      mid: string
      main: string
      dark: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    calendar?: {
      light?: string
      mid?: string
      main?: string
      dark?: string
    }
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: '#E0F3FF',
      main: '#A8D7F5',
      dark: '#638194',
    },
    secondary: {
      light: '#FFEEC1',
      main: '#FFDD85',
      dark: '#AC955A',
    },
    info: {
      light: '#EBD6FE',
      main: '#D1A8F6',
      dark: '#7F6597',
    },
    success: {
      light: '#EBFECB',
      main: '#C1E38A',
      dark: '#7F965B',
    },
    warning: {
      light: '#FFDED0',
      main: '#FFB596',
      dark: '#B47F69',
    },
    error: {
      light: '#FFD8E5',
      main: '#FBACC7',
      dark: '#A26F80',
    },
    text: {
      primary: '#424244',
      secondary: '#838383',
    },
    background: {
      default: '#FCFEFF',
      paper: '#FFFFFF',
    },
  },
  typography: {
    h1: {
      fontSize: '95px',
      fontFamily: 'Roboto',
      color: '#1B7791',
      fontWeight: 700,
    },
    h2: {
      fontSize: '24px',
      fontFamily: 'Roboto Slab',
      fontWeight: 700,
      color: '#5F7173',
    },
    h3: {
      fontSize: '60px',
      fontFamily: 'Roboto',
      color: '#1B7791',
      fontWeight: 700,
    },
    h4: {
      fontSize: '18px',
      fontFamily: 'Roboto',
      color: '#1B7791',
      fontWeight: 700,
    },
    h5: {
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#838383',
    },
    h6: {
      fontSize: '14px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#424244',
    },
    body1: {
      fontSize: '14px',
      fontFamily: 'Roboto',
      color: '#424244',
      lineHeight: '16px',
    },
    body2: {
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: 500,
      color: '#424244',
    },
    subtitle1: {
      fontSize: '12px',
      fontFamily: 'Roboto',
      color: '#424244',
    },
    subtitle2: {
      fontSize: '14px',
      fontFamily: 'Inter',
      color: '#838383',
      fontStyle: 'italic',
    },
    button: {
      fontSize: '14px',
      fontFamily: 'Roboto',
      color: '#424244',
      fontWeight: 700,
      textTransform: 'none',
    },
    caption: {},
    overline: {},
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '28px',
        },
        sizeSmall: {
          height: '22px',
        },
        sizeLarge: {
          height: '28px',
        },
        contained: {
          webkitBoxShadow: 'none',
          mozBoxShadow: 'none',
          boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '32px',
          paddingRight: '32px',
        },
      },
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: '0px',
          margin: '0px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: '0px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          border: 'solid 2px #838383',
          color: '#424244',
          fontSize: '14px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#FFFFFF',
          '&:focus': {
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },
  },
  calendar: {
    light: '#FAF2DC',
    mid: '#F6E7BF',
    main: '#EBC76B',
    dark: '#E0A715',
  },
}

export const theme = createTheme(themeOptions)
