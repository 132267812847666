import FlagIcon from '@mui/icons-material/Flag'
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag'
import RateReviewIcon from '@mui/icons-material/RateReview'
import { Box, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { DiscoveryFeedback, EvaluationTaskType } from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { theme } from 'theme'

interface Props {
  taskType: EvaluationTaskType
  active: boolean
  flaggedForReview?: Date
  acknowledged?: boolean
  feedback?: DiscoveryFeedback
  handleFlagForReview: () => void
}

const FlagAndFeedback = ({
  taskType,
  active,
  flaggedForReview,
  acknowledged,
  feedback,
  handleFlagForReview,
}: Props): JSX.Element => {
  const [show, setShow] = useState(false)
  const changeRequestedAndUnacknowledged = !acknowledged && !!feedback
  const handleShow = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation()
    setShow(!show)
  }
  const handleFlagClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation()

    !changeRequestedAndUnacknowledged && handleFlagForReview()
  }
  useEffect(() => {
    if (!active) {
      setShow(false)
    }
  }, [active])
  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Box display="flex" flex={1}>
        {taskType === EvaluationTaskType.Consensus && (
          <>
            <Box
              display="flex"
              alignItems={'center'}
              height="20px"
              sx={{
                color:
                  flaggedForReview || changeRequestedAndUnacknowledged
                    ? theme.palette.secondary.dark
                    : theme.palette.text.secondary,
                ':hover': {
                  color: !changeRequestedAndUnacknowledged
                    ? theme.palette.text.primary
                    : '',
                  cursor: changeRequestedAndUnacknowledged ? 'default' : '',
                },
              }}
              onClick={handleFlagClick}
            >
              <Box mr={1.25} mt={0.5}>
                {flaggedForReview || changeRequestedAndUnacknowledged ? (
                  <FlagIcon />
                ) : (
                  <OutlinedFlagIcon />
                )}
              </Box>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: theme.typography.fontWeightMedium,
                  my: 'auto',
                  color: 'inherit',
                }}
              >
                For review
              </Typography>
            </Box>
          </>
        )}
        {!acknowledged &&
          taskType === EvaluationTaskType.Consensus &&
          feedback && (
            <Box
              sx={{ borderLeft: `1px solid ${theme.palette.info.main}` }}
              mx={1.5}
              height="10px"
              my="auto"
            />
          )}
        {!acknowledged && feedback && (
          <Box
            display="flex"
            alignItems={'center'}
            height="20px"
            onClick={(e) => e.stopPropagation()}
            sx={{ ':hover': { cursor: 'default' } }}
          >
            <Box
              sx={{ color: theme.palette.text.primary }}
              height="20px"
              width="20px"
              mr={1.25}
            >
              <RateReviewIcon />
            </Box>
            <Typography
              variant="caption"
              sx={{ fontWeight: theme.typography.fontWeightMedium }}
            >
              Feedback requested
            </Typography>
          </Box>
        )}
        {acknowledged && (
          <Typography
            variant="body2"
            fontSize="14px"
            fontWeight={600}
            ml="auto"
          >{`Acknowledged: ${format(
            parseISO(feedback?.updatedAt),
            'yyyy-MM-dd, HH:mm'
          )}`}</Typography>
        )}
      </Box>

      {active && feedback && (
        <>
          <Box
            borderBottom={`1px solid ${theme.palette.info.main}`}
            width="100%"
            my={1.5}
          />
          <Box display="flex">
            <Typography
              variant="body2"
              fontSize="14px"
              onClick={(e) => e.stopPropagation()}
              sx={{ ':hover': { cursor: 'default' } }}
            >{`${feedback?.sentBy?.firstName} ${
              feedback?.sentBy?.lastName
            } - Update requested on ${format(
              parseISO(feedback?.createdAt),
              'yyyy-MM-dd, HH:mm'
            )}`}</Typography>
            <Typography
              variant="caption"
              lineHeight={'28px'}
              fontWeight={theme.typography.fontWeightBold}
              onClick={(e) => handleShow(e)}
              ml={1}
              sx={{
                ':hover': {
                  color: 'primary.main',
                  textDecoration: 'underline',
                },
              }}
            >
              {show ? 'HIDE' : 'SHOW'}
            </Typography>
          </Box>
        </>
      )}

      {show && (
        <Typography variant="body2" mt={1} onClick={(e) => handleShow(e)}>
          {feedback?.comment}
        </Typography>
      )}
    </Box>
  )
}

export default FlagAndFeedback
