import { Solicitation, SolicitationRole } from 'generated/graphql'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import React from 'react'

interface OverflowY {
  isEvaluator: boolean
  isConsensus: boolean
}

export const useSolicitationRole = (solicitation?: Solicitation): OverflowY => {
  const userContext = React.useContext(UserContext)

  const isEvaluator =
    solicitation?.members
      ?.filter((member) => member.user.id === userContext.user.userId)
      .some((member) =>
        member.roles.some((role) => role === SolicitationRole.Evaluator)
      ) || false

  const isConsensus =
    solicitation?.members
      ?.filter((member) => member.user.id === userContext.user.userId)
      .some((member) =>
        member.roles.some(
          (role) => role === SolicitationRole.ContractSpecialist
        )
      ) || false

  return { isConsensus, isEvaluator }
}
