import { ChartData } from 'components/analytics/Chart'
import {
  differenceInBusinessDays,
  format,
  getDay,
  isAfter,
  isBefore,
  subBusinessDays,
} from 'date-fns'
import {
  EvaluationTask,
  EvaluationTaskHistory,
  EvaluationTaskStatus,
} from 'generated/graphql'
import {
  findLastIndex,
  groupBy,
  isEqual,
  lowerCase,
  map,
  partition,
  reduce,
  uniqBy,
  upperFirst,
  words,
} from 'lodash'

import {
  StatusTableRow,
  TaskDetails,
  TaskStatusCountsObject,
  WorkflowDashboardTaskStatus,
} from './useWorkflowState'

const dayNumberToDayString = {
  0: 'Su',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'Th',
  5: 'F',
  6: 'S',
}

export const isAtRisk = (task: EvaluationTask): boolean => {
  const now = new Date()
  const dueAt = new Date(task.dueAt)
  return (
    isAfter(now, dueAt) &&
    task.status !== EvaluationTaskStatus.Completed &&
    task.status !== EvaluationTaskStatus.RequiresReviewNotStarted &&
    task.status !== EvaluationTaskStatus.RequiresReviewStarted
  )
}

export const getDaysRemaining = (tasks: EvaluationTask[]): number => {
  const today = new Date()
  const latest = new Date(
    reduce(tasks, (latestTask, currentTask) => {
      const latest = new Date(latestTask.dueAt)
      const current = new Date(currentTask.dueAt)
      return isAfter(latest, current) ? latestTask : currentTask
    })?.dueAt
  )
  return Math.max(differenceInBusinessDays(latest, today), 0)
}

export const getTaskStatusCounts = (
  tasks: EvaluationTask[]
): TaskStatusCountsObject => {
  const [completedTasks, notCompleteTasks] = partition(
    tasks,
    (task) =>
      task.status === EvaluationTaskStatus.Completed ||
      task.status === EvaluationTaskStatus.RequiresReviewNotStarted ||
      task.status === EvaluationTaskStatus.RequiresReviewStarted
  )
  const [atRiskTasks, notCompletedNotAtRiskTasks] = partition(
    notCompleteTasks,
    (task) => isAtRisk(task)
  )
  const [startedTasks, notStartedTasks] = partition(
    notCompletedNotAtRiskTasks,
    ['status', EvaluationTaskStatus.Started]
  )
  return {
    [WorkflowDashboardTaskStatus.Completed]: completedTasks.length,
    [WorkflowDashboardTaskStatus.NotStarted]: notStartedTasks.length,
    [WorkflowDashboardTaskStatus.Active]: startedTasks.length,
    [WorkflowDashboardTaskStatus.AtRisk]: atRiskTasks.length,
  }
}

export const getProjectedCountByDate = (
  tasks: EvaluationTask[],
  date: Date
): number => {
  const [notDueOrAtRiskTasks, _completedOrPastDueTasks] = partition(
    tasks,
    (task) =>
      isBefore(date, new Date(task.dueAt)) &&
      !isAtRisk(task) &&
      getTaskStatus(task) !== WorkflowDashboardTaskStatus.Completed
  )
  return notDueOrAtRiskTasks.length
}

export const getTasksNotDueCountByDate = (
  tasks: EvaluationTask[],
  date: Date
): number => {
  const [notDueTasks, _dueTasks] = partition(tasks, (task) =>
    isBefore(date, new Date(task.dueAt))
  )
  return notDueTasks.length
}

export const getTaskSetStatus = (
  tasks: EvaluationTask[]
): WorkflowDashboardTaskStatus => {
  const statusCounts = getTaskStatusCounts(tasks)
  if (statusCounts[WorkflowDashboardTaskStatus.AtRisk] > 0) {
    return WorkflowDashboardTaskStatus.AtRisk
  } else if (
    statusCounts[WorkflowDashboardTaskStatus.Active] > 0 ||
    (statusCounts[WorkflowDashboardTaskStatus.NotStarted] > 0 &&
      statusCounts[WorkflowDashboardTaskStatus.Completed] > 0)
  ) {
    return WorkflowDashboardTaskStatus.Active
  } else if (statusCounts[WorkflowDashboardTaskStatus.NotStarted] > 0) {
    return WorkflowDashboardTaskStatus.NotStarted
  }
  return WorkflowDashboardTaskStatus.Completed
}

export const adjustTaskStatusByDay = (
  tasks: EvaluationTask[],
  date: Date
): EvaluationTask[] => {
  const adjustedTasks = map(tasks, (task) => {
    const currentStatus = {
      updatedAt: task.updatedAt,
      status: task.status,
    } as EvaluationTaskHistory
    const histories = [...task.history, currentStatus]
    const lastStatusIndex = findLastIndex(
      histories,
      (history) =>
        isBefore(new Date(history.updatedAt), date) ||
        isEqual(new Date(history.updatedAt), date)
    )
    return {
      ...task,
      status:
        histories[lastStatusIndex]?.status || EvaluationTaskStatus.NotReleased,
    }
  })
  return adjustedTasks
}

export const tasksNotCompleted = (
  tasks: EvaluationTask[],
  date: Date
): number => {
  const tasksPerDay = getTaskStatusCounts(adjustTaskStatusByDay(tasks, date))
  const totalTasks = Object.values(tasksPerDay).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  )
  return totalTasks - tasksPerDay[WorkflowDashboardTaskStatus.Completed]
}

export const getBarChartData = (tasks: EvaluationTask[]): ChartData[] => {
  const dates = map(Array(11), (_x, index) =>
    subBusinessDays(new Date(), 5 - index)
  )
  return map(dates, (date, index) =>
    index <= 5
      ? {
          date: format(date, 'M-dd'),
          day: dayNumberToDayString[getDay(date)],
          ...getTaskStatusCounts(adjustTaskStatusByDay(tasks, date)),
          Projected: 0,
          remainingTasks: tasksNotCompleted(tasks, date),
        }
      : {
          date: format(date, 'M-dd'),
          day: dayNumberToDayString[getDay(date)],
          ...getTaskStatusCounts(adjustTaskStatusByDay(tasks, date)),
          Projected: getProjectedCountByDate(tasks, date),
          [WorkflowDashboardTaskStatus.Completed]: 0,
          [WorkflowDashboardTaskStatus.Active]: 0,
          [WorkflowDashboardTaskStatus.NotStarted]: 0,
          [WorkflowDashboardTaskStatus.AtRisk]: 0,
        }
  )
}

export const getLineChartData = (tasks: EvaluationTask[]): ChartData[] => {
  const dates = map(Array(11), (_x, index) =>
    subBusinessDays(new Date(), 5 - index)
  )

  return map(dates, (date) => ({
    date: format(date, 'M-dd'),
    'SS Timeline': getTasksNotDueCountByDate(tasks, date),
  }))
}

export const getTaskStatus = (
  task: EvaluationTask
): WorkflowDashboardTaskStatus => {
  if (isAtRisk(task)) {
    return WorkflowDashboardTaskStatus.AtRisk
  } else if (
    task.status === EvaluationTaskStatus.Completed ||
    task.status === EvaluationTaskStatus.RequiresReviewNotStarted ||
    task.status === EvaluationTaskStatus.RequiresReviewStarted
  ) {
    return WorkflowDashboardTaskStatus.Completed
  } else if (task.status === EvaluationTaskStatus.Started) {
    return WorkflowDashboardTaskStatus.Active
  }
  return WorkflowDashboardTaskStatus.NotStarted
}

export function detailedTaskRow(task: EvaluationTask): TaskDetails {
  return {
    taskName: task.name,
    factorName: task.factor.name,
    offerer: task.proposal.organization,
    status: getTaskStatus(task),
    dueDate: format(new Date(task.dueAt), 'yyyy-MM-dd'),
    countDiscoveries: task.discoveries.length,
  }
}

export function getRoleFromTasks(tasks: EvaluationTask[]): string {
  const uniqueRoleTasks = uniqBy(tasks, (task) => task.factor.members[0].roles)
  const roles = map(uniqueRoleTasks, (task) => task.factor.members[0].roles[0])

  const roleString = roles
    .map((role) =>
      words(role)
        .map((word) => upperFirst(lowerCase(word)))
        .join(' ')
    )
    .sort()
    .join(', ')

  return roleString
}

export const generateStatusTableRows = (
  tasks: EvaluationTask[]
): StatusTableRow[] => {
  const tasksByMember = groupBy(
    tasks,
    (task: EvaluationTask) => task.factor.members[0].user.id
  )

  const structuredRows = map(Object.keys(tasksByMember), (memberId) => ({
    id: memberId + getRoleFromTasks(tasksByMember[memberId]),
    name: `${tasksByMember[memberId][0].factor.members[0].user.firstName} ${tasksByMember[memberId][0].factor.members[0].user.lastName}`,
    role: getRoleFromTasks(tasksByMember[memberId]),
    status: getTaskSetStatus(tasksByMember[memberId]),
    tasksComplete: getTaskStatusCounts(tasksByMember[memberId])[
      WorkflowDashboardTaskStatus.Completed
    ],
    totalTasks: tasksByMember[memberId].length,
    details: tasksByMember[memberId].map(detailedTaskRow),
  }))
  return structuredRows
}
