import { ActionMenuKebab } from 'components/ActionMenuKebab'
import DataTable, { Props as DataTableProps } from 'components/DataTable'
import { TextRenderer } from 'components/DataTable/GenericRenderers'
import { RatingSet } from 'generated/graphql'

interface Props {
  ratings: RatingSet[]
}

export const RatingsTable = ({ ratings }: Props): JSX.Element => {
  const isCollapsible = (data: RatingSet) => {
    return !!data.options?.length
  }

  const actionMenuRenderer = (): JSX.Element => {
    return (
      <ActionMenuKebab
        items={[{ itemName: 'Edit', onClick: () => alert('edit') }]}
      />
    )
  }

  const collapseRenderer = (data: RatingSet): JSX.Element => {
    return <>{JSON.stringify(data.options)}</>
  }

  const tableConfig: DataTableProps<RatingSet> = {
    columns: [
      {
        name: 'Name',
        renderer: TextRenderer,
        sort: (item) => item.name.toLowerCase(),
        key: 'name',
      },

      {
        name: 'Actions',
        renderer: actionMenuRenderer,
      },
    ],
    data: ratings,
    rowsPerPage: 10,
    isCollapsible: isCollapsible,
    collapsible: collapseRenderer,
    noResultFound: 'No ratings at this time',
  }
  return <DataTable {...tableConfig} />
}
