import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  SxProps,
  Theme,
} from '@mui/material'
import React, { ReactNode, useEffect } from 'react'

export interface TooltipPopperProps {
  open: boolean
  anchorEl: HTMLElement | null
  children: ReactNode
  popperSx?: SxProps<Theme>
  setAnchorEl: (anchorEl: HTMLElement | null) => void
  handleTooltip: (
    e: React.MouseEvent<HTMLElement> | MouseEvent | TouchEvent
  ) => void
}

const TooltipPopper = ({
  open,
  anchorEl,
  children,
  popperSx,
  setAnchorEl,
  handleTooltip,
}: TooltipPopperProps): JSX.Element => {
  useEffect(() => {
    if (!open) {
      setAnchorEl(null)
    }
  }, [open, setAnchorEl])

  return (
    <Popper
      id={'tooltip-popper'}
      open={open}
      anchorEl={anchorEl}
      placement="top"
      sx={{ zIndex: 1000, ...popperSx }}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 11],
          },
        },
      ]}
    >
      <ClickAwayListener onClickAway={(e) => handleTooltip(e)}>
        <Paper
          sx={{
            minWidth: '200px',

            minHeight: '10px',
            backgroundColor: 'background.paper',
            boxShadow:
              '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
            borderRadius: '4px',
            marginLeft: 1,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box p={2.5} pr={0}>
              {children}
            </Box>
            <Box
              onClick={(e) => handleTooltip(e)}
              pt={0.5}
              pr={0.5}
              sx={{ ':hover': { cursor: 'pointer' } }}
            >
              <CloseOutlinedIcon />
            </Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}

export default TooltipPopper
