/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
//https://github.com/apollographql/apollo-feature-requests/issues/224
import * as graphqlPrinter from 'graphql/language/printer'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { v4 as uuid4 } from 'uuid'

export class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  generateOperationId() {
    return uuid4()
  }
}

export const createAppSyncAuthorizedWebSocket = () => {
  return class extends WebSocket {
    // SubscriptionClient takes a fixed websocket url so we append query string parameters every time the websocket
    // is created, in case the authorization information has changed.
    constructor(url, protocols = undefined) {
      super(url, protocols)
    }

    // AppSync acknowledges GraphQL subscriptions with "start_ack" messages but SubscriptionClient cannot handle them
    set onmessage(handler) {
      super.onmessage = (event) => {
        if (event.data) {
          const data = this._tryParseJsonString(event.data)

          if (data && data.type === 'start_ack') {
            return
          }
        }

        return handler(event)
      }
    }

    _tryParseJsonString(jsonString) {
      try {
        return JSON.parse(jsonString)
      } catch (e) {
        return undefined
      }
    }
  }
}

export const createAppSyncGraphQLOperationAdapter = (
  getAppSyncAuthorizationInfo
) => ({
  applyMiddleware: async (options, next) => {
    // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
    options.data = JSON.stringify({
      query:
        typeof options.query === 'string'
          ? options.query
          : graphqlPrinter.print(options.query),
      variables: options.variables,
    })

    // AppSync only permits authorized operations
    options.extensions = { authorization: getAppSyncAuthorizationInfo }

    // AppSync does not care about these properties
    delete options.operationName
    delete options.variables
    // Not deleting "query" property as SubscriptionClient validation requires it

    next()
  },
})
