import { alpha, SxProps, Theme } from '@mui/material'
import {
  add,
  differenceInDays,
  isAfter,
  isBefore,
  parseISO,
  sub,
} from 'date-fns'
import {
  ActiveInstrument,
  Contract,
  ContractOption,
  Scalars,
  useGetContractsOverviewPageStateQuery,
} from 'generated/graphql'
import { filter, find, map } from 'lodash'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'
import { theme } from 'theme'

import { DateRange, DollarRange } from '../constants'

export interface ContractsOverviewPageState {
  contracts: MutatedContract[]
  isFetching: boolean
}

export type MutatedContract = Omit<Contract, 'activeInstruments'> & {
  activeInstruments: MutatedActiveInstrument[]
  displayData: {
    closeoutDate: Date
    optionsRemaining: number
    ceilingValue: {
      value: string
      range: string
    }
    finalCompletion: {
      value: Scalars['Date']
      range: string
    }
    currentOptionEnd: {
      value: Scalars['Date'] | undefined
      range: string | undefined
    }
  }
}

export type MutatedActiveInstrument = ActiveInstrument & {
  displayData: {
    cparsDue?: Date | Scalars['Date'] | string
    disbursedTarget: number
    liquidCoefficient: number
    unfundedAmount: number
    percentFunded: string
  }
}

export const getDollarValueRange = (
  value: number | string | Scalars['Float']
): DollarRange => {
  const floatValue = value as number

  switch (true) {
    case floatValue < 10000:
      return DollarRange.LESS_THAN_10K
    case floatValue >= 10000 && floatValue < 250000:
      return DollarRange.BETWEEN_10K_250K
    case floatValue >= 250000 && floatValue < 2000000:
      return DollarRange.BETWEEN_250K_2M
    case floatValue >= 2000000 && floatValue < 7500000:
      return DollarRange.BETWEEN_2M_7_5M
    case floatValue >= 7500000 && floatValue < 25000000:
      return DollarRange.BETWEEN_7_5M_25M
    case floatValue >= 25000000 && floatValue < 100000000:
      return DollarRange.BETWEEN_25M_100M
    case floatValue >= 100000000 && floatValue < 500000000:
      return DollarRange.BETWEEN_100M_500M
    case floatValue >= 500000000:
      return DollarRange.MORE_THAN_500M
  }

  return DollarRange.LESS_THAN_10K
}

export const getCurrentOptionEndDateRange = (
  contractOptions: ContractOption[]
): {
  value: Scalars['Date'] | undefined
  range: string | undefined
} => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const targetOption = find(contractOptions, (option) =>
    isBefore(yesterday, parseISO(option.endDate))
  )

  const now = new Date()
  const compareDate = parseISO(targetOption?.endDate)
  const f60days = add(now, { days: 60 })
  const f90days = add(now, { days: 90 })
  const f6months = add(now, { months: 6 })

  let range = DateRange.LESS_THAN_60_DAYS
  switch (true) {
    case isBefore(compareDate, f60days):
      range = DateRange.LESS_THAN_60_DAYS
      break
    case isAfter(compareDate, f60days) && isBefore(compareDate, f90days):
      range = DateRange.BETWEEN_60_TO_90_DAYS
      break
    case isAfter(compareDate, f90days) && isBefore(compareDate, f6months):
      range = DateRange.BETWEEN_3_TO_6_MONTHS
      break
    case isAfter(compareDate, f6months):
      range = DateRange.MORE_THAN_6_MONTHS
      break
  }

  return {
    value: targetOption?.endDate,
    range: targetOption?.endDate ? range : undefined,
  }
}

export const getFinalCompletionDateRange = (
  date: string | Scalars['Date'] | Date
): DateRange => {
  const now = new Date()
  const compareDate = parseISO(date)
  const f12months = add(now, { months: 12 })
  const f18months = add(now, { months: 18 })
  const f24months = add(now, { months: 24 })

  switch (true) {
    case isBefore(compareDate, f12months):
      return DateRange.LESS_THAN_12_MONTHS
    case isAfter(compareDate, f12months) && isBefore(compareDate, f18months):
      return DateRange.BETWEEN_12_TO_18_MONTHS
    case isAfter(compareDate, f18months) && isBefore(compareDate, f24months):
      return DateRange.BETWEEN_18_TO_24_MONTHS
    case isAfter(compareDate, f24months):
      return DateRange.MORE_THAN_24_MONTHS
  }

  return DateRange.LESS_THAN_12_MONTHS
}

const getRemainingOptionCount = (contractOptions: ContractOption[]) => {
  const compareDate = new Date()
  compareDate.setDate(compareDate.getDate() - 1)
  const remainingContractOptions = filter(contractOptions, (option) =>
    isAfter(parseISO(option.endDate), compareDate)
  )
  return remainingContractOptions.length
}

const calculateDisbursedTarget = (
  activeInstrument: ActiveInstrument
): number => {
  const { dispersedAmount, ceilingValue, startDate, endDate } = activeInstrument
  const today = new Date()
  const amountFraction = dispersedAmount / ceilingValue
  const remainingDaysFraction =
    differenceInDays(today, parseISO(startDate)) /
    differenceInDays(parseISO(endDate), parseISO(startDate))
  return amountFraction / remainingDaysFraction
}

const calculateLiquidCoefficient = (
  activeInstrument: ActiveInstrument
): number => {
  const { dispersedAmount, fundedAmount, startDate, endDate } = activeInstrument
  const today = new Date()
  const amountFraction = dispersedAmount / fundedAmount
  const remainingDaysFraction =
    differenceInDays(today, parseISO(startDate)) /
    differenceInDays(parseISO(endDate), parseISO(startDate))
  return amountFraction / remainingDaysFraction
}

const getMutateActiveInstruments = (
  activeInstruments: ActiveInstrument[]
): MutatedActiveInstrument[] => {
  return map(activeInstruments, (activeInstrument) => {
    return {
      ...activeInstrument,
      displayData: {
        cparsDue: activeInstrument.cparsDueDate
          ? sub(parseISO(activeInstrument.cparsDueDate), { days: 90 })
          : undefined,
        disbursedTarget: calculateDisbursedTarget(activeInstrument),
        liquidCoefficient: calculateLiquidCoefficient(activeInstrument),
        unfundedAmount:
          activeInstrument.ceilingValue - activeInstrument.fundedAmount,
        percentFunded: `${Math.round(
          (activeInstrument.fundedAmount / activeInstrument.ceilingValue) * 100
        )}%`,
      },
    }
  })
}

const getCloseoutDate = (contract: Contract): Date => {
  const { ceilingValue, endDate, contractType } = contract

  if (ceilingValue < 250000) {
    return parseISO(endDate)
  } else if (ceilingValue >= 250000 && contractType === 'FFP') {
    return add(parseISO(endDate), { months: 6 })
  } else if (
    contractType &&
    ['CPIF', 'FPI', 'CPFF', 'CPAF', 'COST'].includes(contractType) &&
    ceilingValue >= 250000
  ) {
    return add(parseISO(endDate), { months: 36 })
  } else {
    return add(parseISO(endDate), { months: 20 })
  }
}

export const transformContracts = (
  contracts: Contract[]
): MutatedContract[] => {
  return map(contracts, (contract) => {
    return {
      ...contract,
      displayData: {
        closeoutDate: getCloseoutDate(contract),
        optionsRemaining: getRemainingOptionCount(contract.contractOptions),
        ceilingValue: {
          value: `$${contract.ceilingValue.toLocaleString('en-US')}`,
          range: getDollarValueRange(contract.ceilingValue),
        },
        finalCompletion: {
          value: contract.endDate,
          range: getFinalCompletionDateRange(contract.endDate),
        },
        currentOptionEnd: getCurrentOptionEndDateRange(
          contract.contractOptions
        ),
      },
      activeInstruments: getMutateActiveInstruments(contract.activeInstruments),
    }
  })
}

export const getDollarRangeColors = (range: DollarRange): SxProps<Theme> => {
  switch (range) {
    case DollarRange.BETWEEN_10K_250K:
      return {
        backgroundColor: alpha(theme.chips.dollar.default, 0.25),
        color: theme.chips.dollar.text,
      }
    case DollarRange.BETWEEN_250K_2M:
      return {
        backgroundColor: alpha(theme.chips.dollar.default, 0.5),
        color: theme.palette.info.light,
      }
    case DollarRange.BETWEEN_2M_7_5M:
      return {
        backgroundColor: alpha(theme.chips.dollar.default, 0.75),
        color: theme.palette.info.light,
      }
    case DollarRange.BETWEEN_7_5M_25M:
      return {
        backgroundColor: theme.chips.dollar.default,
        color: theme.palette.info.light,
      }
    case DollarRange.BETWEEN_25M_100M:
      return {
        backgroundColor: theme.chips.dollar.main,
        color: theme.palette.info.light,
      }
    case DollarRange.BETWEEN_100M_500M:
      return {
        backgroundColor: theme.chips.dollar.mid,
        color: theme.palette.info.light,
      }
    case DollarRange.MORE_THAN_500M:
      return {
        backgroundColor: theme.chips.dollar.dark,
        color: theme.palette.info.light,
      }
    default:
      return {
        backgroundColor: alpha(theme.chips.dollar.default, 0.1),
        color: theme.chips.dollar.text,
      }
  }
}

const useContractsOverviewState = (): ContractsOverviewPageState => {
  const userContext = useContext(UserContext)
  const tenantId = userContext.user.tenant.id
  const [contracts, setContracts] = useState<MutatedContract[]>([])

  const [query] = useGetContractsOverviewPageStateQuery({
    variables: {
      tenantId: tenantId,
    },
    pause: tenantId === '',
  })

  useEffect(() => {
    if (query?.data) {
      const contracts = transformContracts(
        query?.data.listContracts as Contract[]
      )
      setContracts(contracts)
    }
  }, [query])

  return {
    contracts,
    isFetching: query.fetching,
  }
}

export { useContractsOverviewState }
