import UploadDocumentProvider from 'pages/DocumentManager/providers/UploadDocumentProvider'
import { useParams } from 'react-router-dom'

import DocumentManagerPageSkeleton from './DocumentManagerPageSkeleton'
import { Page } from './Page'
import {
  useDocumentManagerState,
  useDocumentManagerTransform,
} from './state/useDocumentManagerState'

const DocumentManager = (): JSX.Element => {
  const { solicitationId } = useParams<{ solicitationId: string }>()

  const {
    solicitation,
    generateReport,
    deleteFile,
    editFile,
    downloadFile,
    upsertFile,
  } = useDocumentManagerState(decodeURIComponent(solicitationId || ''))

  const transformedProps = useDocumentManagerTransform(solicitation)

  return solicitation ? (
    <UploadDocumentProvider upsertFile={upsertFile}>
      <Page
        {...transformedProps}
        generateReport={generateReport}
        deleteFile={deleteFile}
        editFile={editFile}
        downloadFile={downloadFile}
      />
    </UploadDocumentProvider>
  ) : (
    <DocumentManagerPageSkeleton />
  )
}

export { DocumentManager }
