import { alpha, SxProps, Theme } from '@mui/material'
import { WorkflowDashboardTaskStatus } from 'pages/WorkflowDashboard/state/useWorkflowState'
import { theme } from 'theme'

import { Chip } from './Chip'

export type Variant = WorkflowDashboardTaskStatus | 'doc'

interface Props {
  variant?: Variant
  label?: string
  sx?: SxProps<Theme>
  onClick?: () => void
}

export const Tag = ({ sx, variant = 'doc', ...props }: Props): JSX.Element =>
  variant !== 'doc' ? (
    <Chip
      label={variant}
      sx={Object.assign(
        {
          color: 'text.secondary',
        },
        styles[variant],
        ...(Array.isArray(sx) ? sx : [sx])
      )}
    />
  ) : (
    <Chip
      {...props}
      sx={Object.assign({}, ...(Array.isArray(sx) ? sx : [sx]))}
    />
  )

const styles: Record<string, SxProps<Theme>> = {
  [WorkflowDashboardTaskStatus.Active]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    color: `${theme.palette.success.main}!important`,
  },
  [WorkflowDashboardTaskStatus.AtRisk]: {
    backgroundColor: 'error.light',
    color: `${theme.palette.error.dark}!important`,
  },
  [WorkflowDashboardTaskStatus.Completed]: {
    backgroundColor: 'success.light',
    color: `${theme.palette.text.primary}!important`,
  },
}
