import {
  Box,
  ClickAwayListener,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { ActionMenuKebab } from 'components/ActionMenuKebab'
import { ActionMenuItem } from 'components/ActionMenuPopper'
import RatingSelection from 'components/form/RatingSelection'
import { format } from 'date-fns'
import {
  Criteria,
  RatingInput,
  RatingOption,
  Scalars,
  User,
} from 'generated/graphql'
import React, { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { ViewAndEditComment } from '../components/ViewAndEditComment'
import { GenericCommentBox } from './GenericCommentBox'

const NO_COMMENTS = 'No Comments'

export interface Props {
  user: User
  criteria: Criteria
  ratings: Array<RatingOption>
  forceActive?: boolean
  upsertRating: (inputs: {
    rating: RatingInput
    criteriaId?: Scalars['ID']
  }) => void
  sx?: SxProps<Theme>
}

const EvaluationCriteriaComment = ({
  user,
  criteria,
  ratings,
  forceActive = false,
  upsertRating,
  sx,
}: Props): JSX.Element => {
  const formHook = useForm({
    defaultValues: {
      Rating: criteria.rating?.option,
      Comment: criteria.rating?.comment,
    } as FieldValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [active, setActive] = useState(forceActive || false)
  const [editing, setEditing] = useState(false)
  const hasData = !!criteria.rating

  const actionMenuItems: ActionMenuItem[] = [
    {
      itemName: 'Edit',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!editing) {
          setEditing(true)
          setActive(true)
        }
      },
    },
  ]

  const handleSave = async (data: Record<string, string>) => {
    await upsertRating({
      rating: {
        option: data['Rating'],
        comment: data['Comment'],
      },
      criteriaId: criteria.id,
    })
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        active && setActive(false)
      }}
    >
      <Box
        sx={[...(Array.isArray(sx) ? sx : [sx])] as SxProps<Theme>}
        id="evaluation-criteria-comment"
        onClick={() => setActive(true)}
      >
        <GenericCommentBox
          name={hasData ? `${user.firstName} ${user.lastName}` : ''}
          date={
            criteria.rating?.createdAt
              ? format(new Date(criteria.rating?.createdAt), 'yyyy-MM-dd')
              : ''
          }
          hasLeftBar={true}
          inlineChildren={
            <Box display="flex" alignItems="center">
              <Box pr={2}>
                <RatingSelection
                  defaultOption={'Select Rating'}
                  useFormHook={formHook}
                  label="Rating"
                  hideLabel={true}
                  items={ratings}
                  itemColor={'backgroundColor'}
                  hideErrorMessage={true}
                  rules={{ required: 'This field is required' }}
                  readonly={!editing}
                />
              </Box>
              <ActionMenuKebab items={actionMenuItems} />
            </Box>
          }
        >
          <Box ml={8} pb={2} id="criteria-comment">
            {hasData || editing ? (
              <ViewAndEditComment
                active={active}
                editing={editing}
                setEditing={setEditing}
                formHook={formHook}
                onSave={formHook.handleSubmit(handleSave)}
              />
            ) : (
              <Typography variant="body2" pt={2}>
                {NO_COMMENTS}
              </Typography>
            )}
          </Box>
        </GenericCommentBox>
      </Box>
    </ClickAwayListener>
  )
}

export { EvaluationCriteriaComment as CriteriaComment }
