import { Annotation as DiscoveryAnnotation } from 'generated/graphql'
import { map, partition, range, reduce, round, sortBy, uniqBy } from 'lodash'
import { TextItem } from 'react-pdf'

export interface LineMetadata {
  item: TextItem
  lineNumber: number
  itemIndex: number
  pageNumber: number
  absoluteTop: number
  absoluteLeft: number
}

export interface SelectedTextItem {
  item: TextItem
  itemIndex: number
  startIndex: number
  stopIndex: number
}

const nullAnnotation: DiscoveryAnnotation = {
  id: 'nullAnnotation',
  text: 'Null',
  startPage: -1,
  startLine: -1,
  startIndex: -1,
  endPage: -1,
  endLine: -1,
  endIndex: -1,
}

interface ItemPosition {
  absoluteTop: number
  normalizedTop: number
  absoluteLeft: number
  normalizedLeft: number
}

function normalizeFloat(value: number) {
  return Math.floor(round(value, 4))
}

function calculateTop(
  viewport: {
    viewbox: number[]
    defaultSideways: boolean
  },
  transform: number[],
  scale: number
) {
  const [_fontHeightPx, _fontWidthPx, offsetX, offsetY, x, y] = transform
  const [_xMin, yMin, _xMax, yMax] = viewport.viewbox

  return viewport.defaultSideways
    ? (x + offsetX + yMin) * scale
    : (yMax - (y + offsetY)) * scale
}

function calculateLeft(
  viewport: {
    viewbox: number[]
    defaultSideways: boolean
  },
  transform: number[],
  scale: number
) {
  const [_fontHeightPx, _fontWidthPx, _offsetX, _offsetY, x, y] = transform
  const [xMin, _yMin, _xMax, _yMax] = viewport.viewbox

  return viewport.defaultSideways ? (y - xMin) * scale : (x - xMin) * scale
}

/**
 * Create indexes that can be used to lookup line meta data based on Y Height or line numbers
 *
 *
 * @param textItems
 * @param maxViewportY
 * @param pageNumber
 * @returns
 */
function indexTextItems(
  textItems: TextItem[],
  viewport: {
    viewbox: number[]
    defaultSideways: boolean
  },
  pageNumber: number,
  scale: number
): {
  yLookup: Map<number, LineMetadata[]>
  lineNumberLookup: Map<number, LineMetadata[]>
  isValid: (itemIndex: number) => boolean
} {
  function toLookups(
    acc: {
      currentY: number
      currentLineNumber: number

      yLookup: Map<number, LineMetadata[]>
      lineNumberLookup: Map<number, LineMetadata[]>
    },
    textItems: [TextItem, number, ItemPosition][]
  ): {
    currentY: number
    currentLineNumber: number

    yLookup: Map<number, LineMetadata[]>
    lineNumberLookup: Map<number, LineMetadata[]>
  } {
    const { currentLineNumber, yLookup, lineNumberLookup } = acc
    const metadata: LineMetadata[] = map(
      textItems,
      ([item, index, position]) => ({
        item: item,
        lineNumber: currentLineNumber + 1,
        itemIndex: index,
        pageNumber: pageNumber,
        absoluteTop: position.absoluteTop,
        absoluteLeft: position.absoluteLeft,
      })
    )

    lineNumberLookup.set(currentLineNumber + 1, metadata)

    textItems.forEach((item) =>
      yLookup.set(item[2].normalizedTop, [
        ...(yLookup.get(item[2].normalizedTop) || []),
        ...metadata,
      ])
    )

    return {
      currentY: 0,
      currentLineNumber: currentLineNumber + 1,
      yLookup: yLookup,
      lineNumberLookup: lineNumberLookup,
    }
  }

  function stripWhiteSpaceAtTopReducer(
    items: [TextItem, number, ItemPosition][][]
  ) {
    const [remove, keep] = partition(
      items,
      (group) =>
        map(group, (item) => item[0].str)
          .join('')
          .trim().length === 0
    )
    return { items: keep, removedItems: remove }
  }
  function sortByTop([item]: [TextItem, number, ItemPosition]) {
    return calculateTop(viewport, item.transform as number[], scale) / scale
  }

  function sortByLeft([item]: [TextItem, number, ItemPosition]) {
    return normalizeFloat(
      calculateLeft(viewport, item.transform as number[], scale) / scale
    )
  }

  const tupleTextItems: [TextItem, number, ItemPosition][] = map(
    textItems,
    (item, index) => {
      const absoluteTop = calculateTop(
        viewport,
        item.transform as number[],
        scale
      )
      const absoluteLeft = calculateLeft(
        viewport,
        item.transform as number[],
        scale
      )
      return [
        item,
        index,
        {
          absoluteTop: absoluteTop,
          normalizedTop: normalizeFloat(absoluteTop),
          absoluteLeft: absoluteLeft,
          normalizedLeft: normalizeFloat(absoluteLeft),
        } as ItemPosition,
      ]
    }
  )

  function textToLinesReducer(
    {
      lineNumber,
      nextPosition,
      currentTop,
      currentLeft,
      itemGroups,
    }: {
      lineNumber: number
      nextPosition: number
      currentTop: number
      currentLeft: number
      itemGroups: Map<string, [TextItem, number, ItemPosition][]>
    },
    item: [TextItem, number, ItemPosition]
  ): {
    lineNumber: number
    nextPosition: number
    currentTop: number
    currentLeft: number
    itemGroups: Map<string, [TextItem, number, ItemPosition][]>
  } {
    const [textItem, _itemIndex, itemPosition] = item

    const unscaledLeft = itemPosition.absoluteLeft / scale
    const isOverlap = unscaledLeft < nextPosition
    const isClose = Math.abs(unscaledLeft - nextPosition) / unscaledLeft < 0.03
    const isOnSameLine =
      Math.abs(itemPosition.absoluteTop / scale - currentTop) /
        textItem.height <
      1.0
    const isTextLeftToRight = currentLeft < unscaledLeft

    if (isOnSameLine && isTextLeftToRight && (isOverlap || isClose)) {
      return {
        lineNumber: lineNumber,
        nextPosition: unscaledLeft + textItem.width,
        currentTop,
        currentLeft: currentLeft,
        itemGroups: itemGroups.set(lineNumber.toString(), [
          ...(itemGroups.get(lineNumber.toString()) || []),
          item,
        ]),
      }
    } else {
      return {
        lineNumber: lineNumber + 1,
        nextPosition: unscaledLeft + textItem.width,
        currentTop: itemPosition.absoluteTop / scale,
        currentLeft: itemPosition.absoluteLeft / scale,
        itemGroups: itemGroups.set((lineNumber + 1).toString(), [item]),
      }
    }
  }

  function groupLines() {
    const [head, ...tail] = sortBy(tupleTextItems, [sortByTop, sortByLeft])
    if (!head) {
      return {
        itemGroups: new Map<string, [TextItem, number, ItemPosition][]>(),
      }
    }

    const [headItem, _headIndex, headPosition] = head

    return reduce(tail, textToLinesReducer, {
      lineNumber: 1,
      nextPosition: headItem.width + headPosition.absoluteLeft / scale,
      currentTop: headPosition.absoluteTop / scale,
      currentLeft: headPosition.absoluteLeft / scale,
      itemGroups: new Map<string, [TextItem, number, ItemPosition][]>().set(
        '1',
        [head]
      ),
    })
  }

  function mergeLinesCloseTogether(
    lines: [TextItem, number, ItemPosition][][],
    output: [TextItem, number, ItemPosition][][]
  ): [TextItem, number, ItemPosition][][] {
    if (lines.length === 0) {
      return output
    }

    const windowSize = 10
    const line = lines[0]
    const head = line[0]
    const tail = line[line.length - 1]
    const _text = head[0].str
    const window = [
      ...(windowSize > lines.length
        ? lines.slice(1, lines.length)
        : lines.slice(1, windowSize + 1)),
    ]
    const isSmallLine =
      map(line, (l) => l[0].str)
        .join('')
        .trim().length < 3
    const fuzzyWidthPercentage = isSmallLine ? 0.2 : 0.03

    const intervals = range(0, 105, 5)

    const heightIntervals = map(
      map(intervals, (value) => value / 100),
      (interval) => head[2].absoluteTop / scale + interval * head[0].height
    )

    const widthIntervals = map(
      map(range(0, 105, 5), (value) => value / 100),
      (interval) =>
        head[2].absoluteLeft / scale +
        interval *
          (tail[2].absoluteLeft / scale -
            head[2].absoluteLeft / scale +
            tail[0].width)
    )
    const foundMergeCandidates = window.filter((windowLines) => {
      const [headWindowItem, _headWindowIndex, headWindowPosition] =
        windowLines[0]
      const [tailWindowItem, _tailWindowIndex, tailWindowPosition] =
        windowLines[windowLines.length - 1]

      const isWithinHeight = heightIntervals.some(
        (interval) =>
          headWindowPosition.absoluteTop / scale < interval &&
          headWindowPosition.absoluteTop / scale + headWindowItem.height >
            interval
      )

      const isWithinWidth = widthIntervals.some(
        (interval) =>
          headWindowPosition.absoluteLeft / scale -
            (headWindowPosition.absoluteLeft / scale) * fuzzyWidthPercentage <
            interval &&
          tailWindowPosition.absoluteLeft / scale +
            tailWindowItem.width +
            (tailWindowPosition.absoluteLeft / scale + tailWindowItem.width) *
              fuzzyWidthPercentage >
            interval
      )

      return isWithinHeight && isWithinWidth
    })

    if (foundMergeCandidates.length > 0) {
      const findClosestCandidate = sortBy(
        foundMergeCandidates,
        (items: [TextItem, number, ItemPosition][]) =>
          Math.abs(
            items[0][2].absoluteLeft / scale - line[0][2].absoluteLeft / scale
          )
      )

      const updateLines = sortBy(
        [...findClosestCandidate[0], ...line],
        (items: [TextItem, number, ItemPosition]) =>
          items[2].absoluteLeft / scale
      )
      const indexOfMerge = lines.findIndex(
        (l) => l[0][1] === findClosestCandidate[0][0][1]
      )
      const newInput = [
        ...lines.slice(1, indexOfMerge),
        updateLines,
        ...lines.slice(indexOfMerge + 1),
      ]
      return mergeLinesCloseTogether(newInput, output)
    }
    return mergeLinesCloseTogether(lines.slice(1), [...output, line])
  }

  const cleanup = stripWhiteSpaceAtTopReducer(
    Array.from(groupLines().itemGroups.values())
  )
  const textLinesGrouped = mergeLinesCloseTogether(
    sortBy(cleanup.items, [
      (items: [TextItem, number, ItemPosition][]) =>
        items[0][2].absoluteTop / scale,

      (items: [TextItem, number, ItemPosition][]) =>
        items[0][2].absoluteLeft / scale,
    ]),
    []
  )
  const bottomUpFixed = mergeLinesCloseTogether(
    textLinesGrouped.reverse(),
    []
  ).reverse()

  // Find the columns going top left to bottom.  Lines must be some what close to each other to be a column
  function reduceTextLinesIntoColumns(
    input: [TextItem, number, ItemPosition][][],
    output: [TextItem, number, ItemPosition][][],
    column: [number, number, [TextItem, number, ItemPosition][][]]
  ): [TextItem, number, ItemPosition][][] {
    const [minColumn, maxColumn, items] = column

    const lastLineInColumn = items[items.length - 1]
    const [minColumn2, maxColumn2] = [
      lastLineInColumn[0][2].absoluteLeft / scale,
      lastLineInColumn[lastLineInColumn.length - 1][2].absoluteLeft / scale +
        lastLineInColumn[lastLineInColumn.length - 1][0].width,
    ]
    const [minHeight, maxHeight] = [
      lastLineInColumn[0][2].absoluteTop / scale,
      lastLineInColumn[0][2].absoluteTop / scale +
        lastLineInColumn[0][0].height * 3,
    ]
    if (input.length === 0) {
      return [...output, ...items]
    }

    /*
    Find all lines that fall within a column.  This effectively tests that any part of the line is within the column.
    */
    function filterLinesInColumn() {
      // To a best guess at the columns.  Only takes into account the x-direction
      const lineIndex = input.findIndex((groupedList) => {
        const [headItem, _headIndex, headPosition] = groupedList[0]
        const [tailItem, _tailIndex, tailPosition] =
          groupedList[groupedList.length - 1]

        const left = headPosition.absoluteLeft / scale
        const right = tailPosition.absoluteLeft / scale + tailItem.width

        const widthIntervals = map(
          map(range(0, 105, 5), (value) => value / 100),
          (interval) => left + interval * (right - left)
        )

        const heightIntervals = map(
          map(range(0, 105, 5), (value) => value / 100),
          (interval) =>
            headPosition.absoluteTop / scale + interval * headItem.height
        )

        const isWithinWidth = widthIntervals.some(
          (interval) => minColumn2 < interval && maxColumn2 > interval
        )

        const isWithinHeight = heightIntervals.some(
          (interval) => minHeight < interval && maxHeight > interval
        )

        return isWithinWidth && isWithinHeight
      })
      return lineIndex
    }
    const inColumnIndex = filterLinesInColumn()
    if (inColumnIndex < 0) {
      const nextColumn = input[0]

      return reduceTextLinesIntoColumns(
        input.slice(1),
        [...output, ...items],
        [
          nextColumn[0][2].absoluteLeft / scale,
          nextColumn[nextColumn.length - 1][2].absoluteLeft / scale +
            nextColumn[nextColumn.length - 1][0].width,
          [nextColumn],
        ]
      )
    }

    const nextInput = [
      ...input.slice(0, inColumnIndex),
      ...input.slice(inColumnIndex + 1),
    ]
    return reduceTextLinesIntoColumns(nextInput, output, [
      minColumn,
      maxColumn,
      [...items, input[inColumnIndex]],
    ])
  }

  const [sortedByTopHead, ...sortedByTopTail] = sortBy(bottomUpFixed, [
    (items: [TextItem, number, ItemPosition][]) =>
      items[0][2].absoluteTop / scale,

    (items: [TextItem, number, ItemPosition][]) =>
      items[0][2].absoluteLeft / scale,
  ])

  const linesGroupedIntoColumns = sortedByTopHead
    ? reduceTextLinesIntoColumns(
        sortedByTopTail,
        [],
        [
          sortedByTopHead?.[0]?.[2]?.absoluteLeft / scale,
          sortedByTopHead?.[sortedByTopHead.length - 1]?.[2]?.absoluteLeft /
            scale +
            sortedByTopHead?.[sortedByTopHead.length - 1]?.[0]?.width,
          [sortedByTopHead],
        ]
      )
    : []

  const isValid = (itemIndex: number) =>
    !cleanup.removedItems
      .flat()
      .some(([_item, index, _position]) => index === itemIndex)

  return {
    ...reduce(
      linesGroupedIntoColumns.filter((items) => items.length !== 0),
      toLookups,
      {
        lineNumberLookup: new Map<number, LineMetadata[]>(),
        yLookup: new Map<number, LineMetadata[]>(),
        currentLineNumber: 0,
        currentY: -1,
      }
    ),
    isValid: isValid,
  }
}

const siblingText = (
  node: Node | null | undefined,
  top: number,
  text: string[]
): string => {
  // Find all the siblings as long as we are on the same line
  if (
    node &&
    ((node as HTMLElement).offsetTop === top ||
      (node?.parentElement as HTMLElement).offsetTop === top)
  ) {
    return siblingText(node.previousSibling, top, [
      node.textContent || '',
      ...text,
    ])
  } else {
    return text.join('')
  }
}

const firstSpan = (node: Node): Node | null => {
  if (node && node?.nodeName === 'SPAN') {
    return node
  } else if (node.parentNode) {
    return firstSpan(node.parentNode)
  } else {
    return null
  }
}

/**
 * Extract annotation information based on the current selection.
 *
 * 1. Get the selected text from the window
 * 2. Find all HTML elements that are part of the selection
 * 3. Map each HTML element into a (y position, text) object
 * 4. Use the Y position to find the text items that match
 * 5. Grab the entire line of the matched data
 * 6. Calculate the indices on each matched data
 *
 * @param selection
 * @param yLookup
 * @param scale
 * @returns
 */
function makeAnnotation(
  selection: Selection,
  yLookup: Map<number, LineMetadata[]>
): DiscoveryAnnotation {
  const selectedText = selection.toString()

  if (selectedText === '' || selection.rangeCount === 0) {
    return nullAnnotation
  }
  const selectedRange = selection.getRangeAt(0)
  const startSpan = firstSpan(selectedRange.startContainer)
  const stopSpan = firstSpan(selectedRange.endContainer)

  const baseStartIndex = siblingText(
    selectedRange.startContainer.previousSibling ||
      selectedRange.startContainer.parentNode?.previousSibling,
    (startSpan as HTMLElement).offsetTop,
    []
  ).length

  const baseStopIndex = siblingText(
    selectedRange.endContainer.previousSibling ||
      selectedRange.endContainer.parentNode?.previousSibling,
    (stopSpan as HTMLElement).offsetTop,
    []
  ).length

  const startNormalizedTop = parseFloat(
    (startSpan as HTMLElement).style.top.replace('px', '') || '0'
  )
  const startNormalizedLeft = parseFloat(
    (startSpan as HTMLElement).style.left.replace('px', '') || '0'
  )

  const stopNormalizedTop = parseFloat(
    (stopSpan as HTMLElement).style.top.replace('px', '') || '0'
  )
  const stopNormalizedLeft = parseFloat(
    (stopSpan as HTMLElement).style.left.replace('px', '') || '0'
  )

  const [startLine, ..._rest] = (
    yLookup.get(normalizeFloat(startNormalizedTop)) || []
  ).filter(
    ({ absoluteLeft }) =>
      normalizeFloat(startNormalizedLeft) === normalizeFloat(absoluteLeft)
  )
  const [endLine, ..._rest2] = (
    yLookup.get(normalizeFloat(stopNormalizedTop)) || []
  ).filter(
    ({ absoluteLeft }) =>
      normalizeFloat(stopNormalizedLeft) === normalizeFloat(absoluteLeft)
  )

  return {
    id: '',
    text: selectedText,
    startPage: startLine.pageNumber,
    endPage: endLine.pageNumber,
    startLine: startLine.lineNumber,
    startIndex: baseStartIndex + selectedRange.startOffset,
    endLine: endLine.lineNumber,
    endIndex: baseStopIndex + selectedRange.endOffset,
  }
}

/**
 * Find the selected text items based on the annotation data.
 *
 * 1. Find all the metadata lines associated with a line number
 * 2. Walk the line from the startIndex to the endIndex.  If there are more
 *    than one line in the annotation, then the entire text item is selected
 *
 * See test cases for examples
 *
 * @param annotation
 * @param lineNumberLookup
 * @returns
 */
function selectedItems(
  annotation: DiscoveryAnnotation,
  lineNumberLookup: Map<number, LineMetadata[]>
): SelectedTextItem[] {
  const lineNumbers = range(annotation.startLine, annotation.endLine + 1)

  const linesMetadata = map(
    lineNumbers,
    (lineNumber: number) => lineNumberLookup.get(lineNumber) || []
  )

  const startLines = linesMetadata.flatMap(
    (lines) =>
      lines
        .filter((line) => line.lineNumber === annotation.startLine)
        .reduce(
          (
            acc: { upto: number; lines: SelectedTextItem[] },
            line: LineMetadata
          ) => {
            if (acc.upto + line.item.str.length <= annotation.startIndex) {
              return { upto: acc.upto + line.item.str.length, lines: acc.lines }
            } else if (
              annotation.startIndex >= acc.upto &&
              annotation.startIndex <= acc.upto + line.item.str.length
            ) {
              const stopIndex = (stopLine: number, line: LineMetadata) => {
                if (stopLine !== line.lineNumber) {
                  return line.item.str.length
                } else if (
                  annotation.endIndex - acc.upto >
                  line.item.str.length
                ) {
                  return line.item.str.length
                } else {
                  return annotation.endIndex - acc.upto
                }
              }
              return {
                lines: [
                  ...acc.lines,
                  {
                    item: line.item,
                    itemIndex: line.itemIndex,
                    startIndex: annotation.startIndex - acc.upto,
                    stopIndex: stopIndex(annotation.endLine, line),
                  } as SelectedTextItem,
                ],
                upto: acc.upto + stopIndex(annotation.endLine, line),
              }
            } else if (annotation.endLine !== line.lineNumber) {
              return {
                upto: acc.upto + line.item.str.length,
                lines: [
                  ...acc.lines,
                  {
                    item: line.item,
                    itemIndex: line.itemIndex,
                    startIndex: 0,
                    stopIndex: line.item.str.length,
                  } as SelectedTextItem,
                ],
              }
            } // string can be within annotation
            return acc
          },
          { upto: 0, lines: [] as SelectedTextItem[] }
        ).lines
  )
  const middleLines = linesMetadata
    .flatMap((lines) =>
      lines.filter(
        (line) =>
          line.lineNumber !== annotation.startLine &&
          line.lineNumber !== annotation.endLine
      )
    )
    .map((line) => ({
      item: line.item,
      itemIndex: line.itemIndex,
      startIndex: 0,
      stopIndex: line.item.str.length,
    }))
  const endLines = linesMetadata.flatMap(
    (lines) =>
      lines
        .filter((line) => line.lineNumber === annotation.endLine)
        .reduce(
          (
            acc: { upto: number; lines: SelectedTextItem[] },
            line: LineMetadata
          ) => {
            if (
              annotation.startLine === annotation.endLine &&
              acc.upto + line.item.str.length <= annotation.startIndex
            ) {
              return {
                upto: acc.upto + line.item.str.length,
                lines: acc.lines,
              }
            } else if (acc.upto + line.item.str.length <= annotation.endIndex) {
              return {
                upto: acc.upto + line.item.str.length,
                lines: [
                  ...acc.lines,
                  {
                    item: line.item,
                    itemIndex: line.itemIndex,
                    startIndex: 0,
                    stopIndex: line.item.str.length,
                  } as SelectedTextItem,
                ],
              }
            } else if (
              annotation.endIndex > acc.upto &&
              annotation.endIndex <= acc.upto + line.item.str.length
            ) {
              return {
                lines: [
                  ...acc.lines,
                  {
                    item: line.item,
                    itemIndex: line.itemIndex,
                    startIndex: 0,
                    stopIndex: annotation.endIndex - acc.upto,
                  } as SelectedTextItem,
                ],
                upto: acc.upto + line.item.str.length,
              }
            } // string can be within annotation
            return { lines: acc.lines, upto: acc.upto + line.item.str.length }
          },
          { upto: 0, lines: [] as SelectedTextItem[] }
        ).lines
  )

  return uniqBy(
    [...startLines, ...middleLines, ...endLines],
    (line) => line.itemIndex
  )
}

export { indexTextItems, makeAnnotation, nullAnnotation, selectedItems }
