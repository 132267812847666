import { useElementSize } from 'hooks/useElementSize'
import React, { RefObject, useLayoutEffect, useRef } from 'react'

import { HighlightData, WrappedSelectedTextItem } from './WrappedPage'

interface Props {
  item: {
    parent: WrappedSelectedTextItem
    children: WrappedSelectedTextItem[]
  }
  setHighlightsData: React.Dispatch<React.SetStateAction<HighlightData[]>>

  pageRef: RefObject<HTMLDivElement> | null
  annotationIds: string[]
  discoveryIds: string[]
  id: string
}

const HighlightBase = ({
  item,
  setHighlightsData,

  pageRef,
  annotationIds,
  discoveryIds,
  id,
}: Props): JSX.Element => {
  const markRef = useRef(null)
  const markSize = useElementSize(markRef.current)
  const pagePosition = useElementSize(pageRef ? pageRef?.current : null)

  useLayoutEffect(() => {
    if (markSize && markSize.x !== 0 && pagePosition && pagePosition.x !== 0) {
      const highlightData = {
        top: markSize.top - pagePosition.top,
        left: markSize.left - pagePosition.left,
        width: markSize.width,
        height: markSize.height,
        annotationIds: annotationIds,
        discoveryIds: discoveryIds,
      }
      setHighlightsData((prev) => [...prev, highlightData])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markSize])

  return (
    <div
      id={id}
      ref={markRef}
      style={{
        backgroundColor: 'transparent',
        whiteSpace: 'pre',
        color: 'transparent',
        display: 'inline-block',
      }}
    >
      {item.parent.item.item.str.substring(
        item.parent.item.startIndex,
        item.parent.item.stopIndex
      )}
    </div>
  )
}

export default HighlightBase
