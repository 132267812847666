export enum UserTable {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  TITLE = 'Title',
  EMAIL = 'Email',
  ROLES = 'Role(s)',
  STATUS = 'Status',
  ACTIONS = 'Actions',
}

export enum AdminTabs {
  TENANT_MANAGEMENT = 'Tenant Management',
  USER_MANAGEMENT = 'User Management',
  RATINGS = 'Ratings',
  SOLICITATIONS = 'Solicitations',
}

export enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
