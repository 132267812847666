import CheckIcon from '@mui/icons-material/Check'
import { alpha, Box, Checkbox, CheckboxProps, Typography } from '@mui/material'
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import { theme } from 'theme'

export interface Props extends CheckboxProps {
  name: string
  control: Control<FieldValues>
  title?: string
  message?: string
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  errors?: DeepMap<FieldValues, FieldError>
  isChecked?: boolean
  isUpdate?: boolean
  parentCallback?: () => void
}

const Acknowledgement = (props: Props): JSX.Element => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field }) => (
        <Box
          id="acknowledgement"
          sx={{
            display: 'flex',
            bgcolor: alpha(theme.palette.secondary.light, 0.5),
            alignItems: 'center',
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '4px',
            '&:hover': {
              bgcolor: alpha(theme.palette.secondary.light, 0.75),
              '& #acknowledgement-box': {
                border: `2px solid ${theme.palette.secondary.dark}`,
              },
            },
          }}
        >
          <Box px={4} py={3} display="flex" alignItems="center">
            <Box
              id="acknowledgement-box"
              onClick={props.parentCallback}
              sx={{
                bgcolor: theme.palette.info.light,
                border: `2px solid ${theme.palette.secondary.main}`,
                borderRadius: '2px',
                height: theme.spacing(3),
                width: theme.spacing(3),
              }}
            >
              <Checkbox
                id="acknowledgement-checkbox"
                color="default"
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                checked={!props.isUpdate ? field.value : props.isChecked}
                onChange={(e) => field.onChange(e.target.checked)}
                checkedIcon={<CheckIcon />}
                {...props}
                sx={{
                  p: 0,
                  borderRadius: 0,
                  color: 'transparent',
                  '&.Mui-checked': {
                    color: theme.palette.secondary.main,
                  },
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              />
            </Box>
            <Box ml={2}>
              <Typography variant="h6">{props.title}</Typography>
              <Typography variant="subtitle1">{props.message}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    />
  )
}
export default Acknowledgement
