import { Typography } from '@mui/material'
import { ChildTable, ChildTableProps } from 'components/DataTable/ChildTable'
import { TextRenderer } from 'components/DataTable/GenericRenderers'
import { Chip } from 'components/Tag/Chip'
import { ContractOption } from 'generated/graphql'
import {
  getDollarRangeColors,
  getDollarValueRange,
  MutatedActiveInstrument,
} from 'pages/ContractsOverview/state/useContractsOverviewState'
import React from 'react'
import { theme } from 'theme'

import { ActiveInstTable } from '../../constants'

export interface ContractPanelInterface {
  activeInstrument: MutatedActiveInstrument
  closeOutDate: Date
  contractOptions: ContractOption[]
}

interface Props {
  activeInstruments: MutatedActiveInstrument[]
  closeOutDate: Date
  contractOptions: ContractOption[]
  handlePanelEvent: (data: ContractPanelInterface) => void
}

export const ActiveInstrumentTable = ({
  activeInstruments,
  closeOutDate,
  contractOptions,
  handlePanelEvent,
}: Props): JSX.Element => {
  const ValueRenderer = (data: MutatedActiveInstrument) => {
    const range = getDollarValueRange(data.ceilingValue)
    return <Chip label={range} sx={getDollarRangeColors(range)} />
  }

  const DetailsRenderer = (data: MutatedActiveInstrument) => {
    return (
      <Typography
        variant="body2"
        onClick={() =>
          handlePanelEvent({
            activeInstrument: data,
            closeOutDate,
            contractOptions,
          })
        }
        color={`${theme.palette.text.primary} !important`}
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        View Details
      </Typography>
    )
  }
  const tableConfig: ChildTableProps<MutatedActiveInstrument> = {
    columns: [
      {
        name: ActiveInstTable.ACTIVE_INSTRUMENT_NUMBER,
        renderer: TextRenderer,
        key: 'externalId',
      },
      {
        name: ActiveInstTable.ACTIVE_INSTRUMENT_NAME,
        renderer: TextRenderer,
        key: 'name',
      },
      {
        name: ActiveInstTable.VALUE,
        renderer: ValueRenderer,
        key: 'ceilingValue',
      },
      {
        name: ActiveInstTable.BUSINESS_UNIT,
        renderer: TextRenderer,
        key: 'businessUnit',
      },
      {
        name: ActiveInstTable.CONTRACTOR,
        renderer: TextRenderer,
        key: 'contractor',
      },
      {
        name: '',
        renderer: DetailsRenderer,
      },
    ],
    data: activeInstruments,
  }

  return <ChildTable {...tableConfig} />
}
