import { alpha, Box, SxProps, Theme, Typography } from '@mui/material'
import DataTable, {
  ColumnConfiguration,
  Props as DataTableProps,
} from 'components/DataTable'
import { TextRenderer } from 'components/DataTable/GenericRenderers'
import { InfoButton } from 'components/InfoButton'
import { Chip } from 'components/Tag/Chip'
import { Priority, Scalars } from 'generated/graphql'
import ContractPanel from 'pages/ContractsOverview/ContractPanel'
import React, { useEffect, useState } from 'react'
import { theme } from 'theme'

import {
  ContractsTable,
  DateRange,
  DollarRange,
  PriorityOrder,
} from '../constants'
import {
  getDollarRangeColors,
  MutatedContract,
} from '../state/useContractsOverviewState'
import {
  ActiveInstrumentTable,
  ContractPanelInterface,
} from './ActiveInstrumentTable'

export interface Props {
  contracts: MutatedContract[]
}

const isCollapsible = (data: MutatedContract) => {
  return data.activeInstruments.length > 0
}

export const ContractsOverviewTable = ({ contracts }: Props): JSX.Element => {
  const [panelData, setPanelData] = useState<ContractPanelInterface>()
  const [showPanel, setShowPanel] = useState(false)
  const [infoOpen, setInfoOpen] = useState('')
  useEffect(() => {
    if (panelData) {
      setShowPanel(true)
    }
  }, [panelData])

  useEffect(() => {
    if (!showPanel) {
      setPanelData(undefined)
    }
  }, [showPanel])

  const ContractNameRenderer = (
    data: MutatedContract,
    column: ColumnConfiguration<MutatedContract>
  ) => {
    return (
      <Box display="flex" alignItems="center">
        {TextRenderer(data, column)}
        <Box>
          <InfoButton
            label={data.id + data.name}
            openInfoLabel={infoOpen}
            setOpenInfoLabel={setInfoOpen}
          >
            <Typography variant="caption" noWrap={true}>
              {`${ContractsTable.CONTRACT_NUMBER}: ${data.externalId ?? '--'}`}
            </Typography>
          </InfoButton>
        </Box>
      </Box>
    )
  }

  const OptionsRemainingRenderer = (data: MutatedContract) => {
    return <Typography>{data.displayData.optionsRemaining}</Typography>
  }

  const CeilingValueRenderer = (contract: MutatedContract) => {
    const { range, value } = contract.displayData.ceilingValue

    return (
      <ClickableChipRenderer
        value={value}
        range={range}
        sx={getDollarRangeColors(range as DollarRange)}
      />
    )
  }

  const FinalCompletionRenderer = (data: MutatedContract): JSX.Element => {
    const { range, value } = data.displayData.finalCompletion
    const getColors = () => {
      if (value) {
        if (range === DateRange.MORE_THAN_24_MONTHS) {
          return styles.green
        } else if (range === DateRange.LESS_THAN_12_MONTHS) {
          return styles.red
        } else if (range === DateRange.BETWEEN_12_TO_18_MONTHS) {
          return styles.yellow
        }
      }
      return styles.gray
    }

    return (
      <ClickableChipRenderer value={value} range={range} sx={getColors()} />
    )
  }

  const CurrentOptionEndRenderer = (data: MutatedContract): JSX.Element => {
    const { range, value } = data.displayData.currentOptionEnd
    const getColors = () => {
      if (range === DateRange.MORE_THAN_6_MONTHS) {
        return styles.green
      } else if (range === DateRange.LESS_THAN_60_DAYS) {
        return styles.red
      } else if (range === DateRange.BETWEEN_60_TO_90_DAYS) {
        return styles.yellow
      }
      return styles.gray
    }
    return (
      <>
        {value && range ? (
          <ClickableChipRenderer value={value} range={range} sx={getColors()} />
        ) : (
          <ClickableChipRenderer
            value={'Unknown'}
            range={'Unknown'}
            sx={getColors()}
          />
        )}
      </>
    )
  }

  const PriorityRenderer = ({ priority }: MutatedContract): JSX.Element => {
    const getColors = () => {
      if (priority === Priority.Low) {
        return styles.green
      } else if (priority === Priority.High) {
        return styles.red
      }
      return styles.gray
    }

    return (
      <Box display="flex">
        <Chip
          label={(priority?.toLowerCase() as string) ?? 'Unknown'}
          sx={getColors()}
        />
      </Box>
    )
  }

  const collapseRenderer = (data: MutatedContract): JSX.Element => {
    return (
      <ActiveInstrumentTable
        activeInstruments={data.activeInstruments}
        closeOutDate={data.displayData.closeoutDate}
        contractOptions={data.contractOptions}
        handlePanelEvent={setPanelData}
      />
    )
  }

  const tableConfig: DataTableProps<MutatedContract> = {
    columns: [
      {
        name: ContractsTable.CONTRACT_NAME,
        renderer: ContractNameRenderer,
        key: 'name',
        sort: (item) => item.name,
      },
      {
        name: ContractsTable.BUSINESS_UNIT,
        renderer: TextRenderer,
        key: 'businessUnit',
        sort: (item) => item.businessUnit?.toLowerCase() || '',
      },
      {
        name: ContractsTable.VALUE,
        renderer: CeilingValueRenderer,
        key: 'ceilingValue',
        sort: (item) => item.ceilingValue,
      },
      {
        name: ContractsTable.CONTR_OFFICE,
        renderer: TextRenderer,
        key: 'contractingOffice',
        sort: (item) => item.contractingOffice?.toLowerCase() || '',
      },
      {
        name: ContractsTable.CURRENT_OPT_END,
        renderer: CurrentOptionEndRenderer,
        sort: (item) => item.displayData.currentOptionEnd.value,
      },
      {
        name: ContractsTable.OPTS_REMAINING,
        renderer: OptionsRemainingRenderer,
        key: 'contractOptions',
        sort: (item) => item.displayData.optionsRemaining,
      },
      {
        name: ContractsTable.FINAL_COMPLETION,
        renderer: FinalCompletionRenderer,
        key: 'endDate',
        sort: (item) => item.endDate,
      },
      {
        name: ContractsTable.PRIORITY,
        renderer: PriorityRenderer,
        key: 'priority',
        sort: (item) => (item.priority ? PriorityOrder[item.priority] : ''),
      },
    ],
    data: contracts,
    rowsPerPage: 10,
    collapsible: collapseRenderer,
    isCollapsible: isCollapsible,
  }

  return (
    <>
      {panelData && (
        <ContractPanel
          data={panelData}
          open={showPanel}
          handleOnClose={() => setShowPanel(false)}
        />
      )}
      <DataTable {...tableConfig} />
    </>
  )
}

const ClickableChipRenderer = ({
  value,
  range,
  sx,
}: {
  value: Scalars['Date'] | string
  range: string
  sx: SxProps<Theme>
}): JSX.Element => {
  const [showLabel, setShowLabel] = React.useState(false)

  return (
    <Box display="flex">
      <Chip
        label={showLabel ? value : range}
        sx={sx}
        onClick={() => setShowLabel(!showLabel)}
      />
    </Box>
  )
}

const styles: Record<string, SxProps<Theme>> = {
  green: {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
    color: `${theme.palette.success.main}!important`,
  },
  red: {
    backgroundColor: 'error.light',
    color: `${theme.palette.error.dark}!important`,
  },
  yellow: {
    backgroundColor: 'secondary.light',
    color: '#705000',
  },
  gray: {
    backgroundColor: 'info.main',
    color: `${theme.palette.text.secondary}!important`,
  },
}
