import {
  alpha,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ColumnConfiguration } from 'components/DataTable/index'
import { theme } from 'theme'

export interface ChildTableProps<T> {
  columns: ColumnConfiguration<T>[]
  data: T[]
  disableHeaderShadow?: boolean
}

export const ChildTable = <T,>({
  columns,
  data,
  disableHeaderShadow,
}: ChildTableProps<T>): JSX.Element => {
  const childTable = 'child-table'
  const childTableId = (id: string | number) => `${'child-table'}-${id}`

  return (
    <TableContainer>
      <Table id={childTable}>
        <TableHead
          id={childTableId('header')}
          sx={{
            bgcolor: alpha(theme.palette.info.main, 0.25),
            boxShadow: !disableHeaderShadow
              ? 'inset 0px 4px 6px 0px rgb(0,0,0,.15)'
              : '',
          }}
        >
          <TableRow>
            {columns &&
              columns.map((column, index) => (
                <TableCell
                  key={childTableId(index)}
                  sx={{ pl: index ? 2 : 7, py: 1, width: column?.customWidth }}
                >
                  <Typography variant="caption" sx={column.headerSx}>
                    {column.name}
                  </Typography>
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody
          id={childTableId('body')}
          sx={{ '& .MuiTableBody-root': theme.typography.body2 }}
        >
          {data?.map((item, index) => (
            <TableRow
              id="data-table-body"
              key={'data-table-body' + index}
              sx={{
                '& .MuiTypography-root': theme.typography.body2,
                bgcolor: alpha(theme.palette.info.main, 0.5),
              }}
            >
              {columns?.map((column, columnsIndex) => (
                <TableCell
                  key={columnsIndex}
                  sx={{ pl: columnsIndex ? 2 : 7, py: 1.7 }}
                >
                  {column.renderer(item, column)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
