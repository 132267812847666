export enum TaskStatusElementIDType {
  NOT_RELEASED = 'StatusElement2',
  RELEASED = 'StatusElement3',
  STARTED = 'StatusElement4',
  COMPLETE = 'StatusElement5',
  REQUIRES_REVIEW_NOT_STARTED = 'StatusElement6',
  REQUIRES_REVIEW_STARTED = 'StatusElement7',
}

export enum DiscoveryTag {
  MARKED_FOR_REVIEW = 'MarkedForReview',
  ADDED_TO_SSEB = 'AddedToSSEB',
}

export enum DiscoveryStatusElementIDType {
  LOGGED = 'DiscoveryStatusElement1',
  FLAGGED = 'DiscoveryStatusElement2',
  ACKNOWLEDGED = 'DiscoveryStatusElement3',
}

export enum FileStatusElementIDType {
  UPLOADING = 'FileStatusElement1',
  NORMAL = 'FileStatusElement2',
  UPDATING = 'FileStatusElement3',
  DELETED = 'FileStatusElement4',
}

//Needs to be kept in sync with InitiateFileUpload
export enum FileTypes {
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt = 'text/plain',
}
