import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Typography } from '@mui/material'
import BidscaleLogo from 'assets/Bidscale_Logomark_WhiteTealBackground_2022_V1.png'
import { ExecuteButton } from 'components/Buttons/BidscaleButton'
import GenericDialog from 'components/GenericDialog'
import { EvaluationTask, RatingInput } from 'generated/graphql'
import { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { theme } from 'theme'

import { RecommendationDrawerContent } from '../RecommendationDrawer/RecommendationDrawerContent'

export interface SummaryDrawerHeaderProps {
  closeDrawer: () => void
  task: EvaluationTask
  upsertRating: (inputs: { rating: RatingInput }) => void
  completeTask: () => void
}

export const SummaryDrawerHeader = ({
  task,
  closeDrawer,

  upsertRating,
  completeTask,
}: SummaryDrawerHeaderProps): JSX.Element => {
  const [submitReviewIsOpen, setSubmitReviewIsOpen] = useState(false)
  const handleOpenSubmitReviewDialog = () => {
    setSubmitReviewIsOpen(true)
  }
  const formHook = useForm({
    defaultValues: {
      'Overall Recommendation': '',
      'Rate This Proposal': '',
      checkbox: false,
    } as FieldValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const onSubmit = (data: { [x: string]: string }) => {
    upsertRating({
      rating: {
        comment: data['Overall Recommendation'],
        option: data['Rate This Proposal'],
      },
    })

    completeTask()
  }
  const onCancel = () => {
    setSubmitReviewIsOpen(false)
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      id="summary-top-bar"
      mr={2}
    >
      <Box display="flex" alignItems="center">
        <Box width={48} height={48}>
          <img
            src={BidscaleLogo}
            alt="Bidscale Logo"
            style={{ marginRight: '12px', width: '100%' }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            onClick={closeDrawer}
            sx={{
              pl: '12px',
              color: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              '&:hover': { color: 'primary.dark', cursor: 'pointer' },
            }}
          >
            <ArrowBackIcon color="inherit" fontSize="small" />
            <Typography color="inherit" variant="body1" pl={1}>
              Evaluation
            </Typography>
          </Box>
          <Typography color="primary.main" variant="body1" pl={1}>
            /
          </Typography>
          <Typography color="text.secondary" variant="body1" pl={1}>
            Summary
          </Typography>
        </Box>
      </Box>

      <Box>
        <ExecuteButton
          sx={{
            width: 1,
            height: theme.spacing(6),
            px: 3,
          }}
          onClick={handleOpenSubmitReviewDialog}
        >
          SUBMIT REVIEW
        </ExecuteButton>
      </Box>
      <GenericDialog
        open={submitReviewIsOpen}
        handleClose={() => setSubmitReviewIsOpen(false)}
        title="Overall Recommendation"
      >
        <RecommendationDrawerContent
          formHook={formHook}
          onSave={formHook.handleSubmit(onSubmit)}
          onCancel={() => onCancel()}
          withRating={true}
          ratingOptions={task.factor.overallRatingOptions}
          proposalName={
            <Typography fontSize="16px">{task.proposal.name}</Typography>
          }
        />
      </GenericDialog>
    </Box>
  )
}
