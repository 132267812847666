import { Box, Typography } from '@mui/material'
import { RatingOption } from 'generated/graphql'

const SingleDefinition = ({
  id,
  name,
  description,
  foregroundColor,
  backgroundColor,
}: RatingOption): JSX.Element => {
  return (
    <Box id={`rating-${id}`}>
      <Typography
        variant="caption"
        color={foregroundColor}
        bgcolor={backgroundColor}
        py={0.5}
        display="flex"
        justifyContent="center"
        id="rating-name"
      >
        {name}
      </Typography>
      <Typography variant="body2" my={2} id="rating-definition">
        {description}
      </Typography>
    </Box>
  )
}
export default SingleDefinition
