import { Box, Typography } from '@mui/material'
import { Tabs } from 'components/Tabs'
import { useSolicitationRole } from 'hooks/useSolicitationRole'
import { DocumentManager } from 'pages/DocumentManager'
import { WorkflowDashboard } from 'pages/WorkflowDashboard'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import SolicitationDashboard from '../SolicitationDashboard'
import { SolicitationPageState } from '../state/useSolicitationPageState'

const Page = ({
  solicitation,
  tasks,
  activityFeed,
}: SolicitationPageState): JSX.Element => {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('dashboard')
  const location = useLocation()
  useEffect(() => {
    const pathList = location.pathname.split('/')
    setSelectedTab(pathList[pathList.length - 1].toLowerCase())
  }, [location])

  const handleTabChange = (tabName: string) => {
    const pathList = location.pathname.split('/')
    pathList.splice(pathList.length - 1, 1, tabName)
    navigate(pathList.join('/'))
  }

  const { isConsensus } = useSolicitationRole(solicitation)

  const tabs = [
    {
      tabTitle: 'Dashboard',
      tabPanel: (
        <SolicitationDashboard tasks={tasks} activityFeed={activityFeed} />
      ),
      display: true,
      link: 'dashboard',
    },
    {
      tabTitle: 'Documents',
      tabPanel: <DocumentManager />,
      display: true,
      link: 'documents',
    },
    {
      tabTitle: 'Progress',
      tabPanel: <WorkflowDashboard />,
      display: isConsensus,
      link: 'progress',
    },
    {
      tabTitle: 'Compliance Engine',
      tabPanel: <>TODO: Compliance Engine</>,
      display: false,
      link: 'compliance',
    },
  ]

  return (
    <Box
      id="page-view"
      width="100%"
      display="flex"
      flexDirection="column"
      ml={-3.5}
      flex="auto"
    >
      <Box marginLeft={5} mt={3.5} mb={2.5}>
        <Typography variant="h5">{solicitation?.name}</Typography>
      </Box>
      <Box flex="auto" display="flex" flexDirection="column">
        <Tabs
          selectedTab={selectedTab}
          tabs={tabs}
          handleOnChange={handleTabChange}
        />
      </Box>
    </Box>
  )
}

export { Page }
