import { Box, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'

export interface FilterProps {
  title: string
  isSelected: boolean
  setIsSelected: (data: boolean) => void
  sx?: SxProps<Theme>
}

export const Filter = ({
  title,
  isSelected,
  setIsSelected,
  sx,
}: FilterProps): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={'fit-content'}
      px={1.5}
      height={32}
      bgcolor={isSelected ? 'primary.main' : 'info.light'}
      border={1}
      borderColor={isSelected ? 'primary.dark' : 'info.dark'}
      borderRadius={16}
      onClick={() => {
        setIsSelected(!isSelected)
      }}
      sx={Object.assign(
        {
          '&:hover': {
            backgroundColor: isSelected ? 'primary.main' : 'info.main',
            cursor: 'pointer',
          },
        },
        noSelect,
        ...(Array.isArray(sx) ? sx : [sx])
      )}
    >
      <Typography
        variant="body2"
        color={isSelected ? 'info.light' : 'body2.color'}
      >
        {title}
      </Typography>
    </Box>
  )
}

const noSelect: SxProps<Theme> = {
  userSelect: 'none',
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
}
