import React from 'react'

interface OverflowY {
  ref: React.RefObject<HTMLDivElement>
  isOverflowY: boolean
}

export const useDetectOverflowY = (
  callback?: (hasOverflow: boolean) => void
): OverflowY => {
  const [isOverflowY, setIsOverflowY] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    const { current } = ref

    const detectOverflow = () => {
      if (current) {
        const hasOverflowY = current.scrollHeight > window.innerHeight

        setIsOverflowY(hasOverflowY)
        callback?.(hasOverflowY)
      }
    }

    detectOverflow()
    window.addEventListener('resize', detectOverflow)

    return () => window.removeEventListener('resize', detectOverflow)
  }, [callback])

  return { ref, isOverflowY }
}
