import { Box, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import { User, UserStatus } from 'generated/graphql'
import { filter } from 'lodash'

import { UserManagementTable } from './UserTable'

interface Props {
  users: User[]
}

export const UserManagement = ({ users }: Props): JSX.Element => {
  const activeUsers = filter(users, (user) => user.status === UserStatus.Active)
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        mt={1}
        mb={4}
        mr={5}
      >
        <Box>
          <Typography variant="h5" fontSize="24px" lineHeight="32px">
            User Management
          </Typography>
          <Typography mt={1.5} variant="subtitle2" color="text.secondary">
            Total Active User(s): {activeUsers.length}
          </Typography>
        </Box>
        <BidscaleButton
          buttonVariant="execute"
          children="ADD USER"
          onClick={() => alert('User Added')}
        />
      </Box>
      <UserManagementTable users={users} />
    </Box>
  )
}
