import { Box, Skeleton } from '@mui/material'
import React from 'react'

const DocumentManagerPageSkeleton = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      ml={1}
      width="inherit"
      height="100%"
      pr={5}
    >
      <Box width="100%" height="80px">
        <Skeleton variant="rectangular" animation="wave" height="inherit" />
      </Box>
      <Box width="100%" height="290px" mt={5}>
        <Skeleton variant="rectangular" animation="wave" height="inherit" />
      </Box>
      <Box width="100%" height="100%" flex="auto" mt={5}>
        <Skeleton variant="rectangular" animation="wave" height="inherit" />
      </Box>
    </Box>
  )
}

export default DocumentManagerPageSkeleton
