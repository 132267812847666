import { alpha, Box, SxProps, Theme, Typography } from '@mui/material'
import { ChildTable, ChildTableProps } from 'components/DataTable/ChildTable'
import {
  DateRenderer,
  TextRenderer,
} from 'components/DataTable/GenericRenderers'
import { Chip } from 'components/Tag/Chip'
import {
  ContractingAction,
  ExpirationStatus,
  Initiative,
} from 'generated/graphql'
import React from 'react'
import { theme } from 'theme'

export const ContractingActionsTable = (data: Initiative): JSX.Element => {
  const tableConfig: ChildTableProps<ContractingAction> = {
    columns: [
      {
        name: 'Action',
        key: 'name',
        renderer: TextRenderer,
        customWidth: '40%',
        headerSx: styles.columnHeader,
      },
      {
        name: 'Due Date',
        key: 'dueDate',
        renderer: DateRenderer,
        headerSx: styles.columnHeader,
      },
      {
        name: 'Action Owner',
        key: 'owner',
        renderer: TextRenderer,
        headerSx: styles.columnHeader,
      },
      {
        name: 'Date Completed',
        key: 'completedDate',
        renderer: DateRenderer,
        headerSx: styles.columnHeader,
      },
    ],
    data: data.contractingActions,
    disableHeaderShadow: true,
  }

  const InnerTableHeader = ({
    score,
    nextAction,
  }: {
    score: number
    nextAction: string
  }) => {
    const createExpirationLabel = (
      expirationStatus: ExpirationStatus | null
    ): string => {
      switch (true) {
        case expirationStatus === ExpirationStatus.Expiring:
          return 'Expiring'
        case expirationStatus === ExpirationStatus.Yeartwo:
          return '2 Years'
        case expirationStatus === ExpirationStatus.Yearone:
          return '1 Year'
      }
      return 'Unknown'
    }
    const expirationStatusLabel = createExpirationLabel(
      data.expirationStatus || null
    )

    return (
      <Box
        sx={{
          bgcolor: alpha(theme.palette.info.main, 0.25),
          boxShadow: 'inset 0px 4px 6px -4px rgb(0,0,0,.15)',
          borderBottom: `1px solid #E0E0E0`,
        }}
      >
        <Box
          display="flex"
          flex={1}
          height={'80px'}
          justifyContent="flex-start"
          alignItems="center"
          ml={7.5}
        >
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography variant="body1" pr={1} sx={{ fontWeight: 600 }}>
              Score:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: theme.typography.fontWeightRegular }}
            >
              {score}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml={6}
            mr={6}
          >
            <Typography variant="body1" pr={1} sx={{ fontWeight: 600 }}>
              Next Action:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: theme.typography.fontWeightRegular }}
            >
              {nextAction}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            pr={3.125}
          >
            <Typography variant="body1" pr={1} sx={{ fontWeight: 600 }}>
              Expiration:
            </Typography>
            {data.expirationStatus && (
              <>
                <Box>
                  <Chip
                    label={expirationStatusLabel}
                    sx={{
                      ...(
                        expirationColors as Record<
                          string,
                          Record<string, string>
                        >
                      )[expirationStatusLabel],
                      fontWeight: 500,
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  const nextAction = data.contractingActions.filter(
    (action) =>
      (action.completedDate && action.completedDate.length <= 0) ||
      !action.completedDate
  )

  return data.contractingActions &&
    data.contractingActions.length > 0 &&
    data?.score !== undefined ? (
    <>
      <InnerTableHeader
        score={data?.score || 0}
        nextAction={nextAction[0]?.name || '--'}
      />
      <ChildTable {...tableConfig} />
    </>
  ) : (
    <Typography>Missing contract Actions data</Typography>
  )
}
const expirationColors = {
  Expiring: {
    bgcolor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  '1 Year': {
    bgcolor: theme.palette.secondary.light,
    color: '#705000',
  },
  '2 Years': {
    bgcolor: alpha(theme.palette.primary.main, 0.35),
    color: theme.palette.success.main,
  },
  Unknown: {
    bgcolor: theme.palette.info.main,
    color: theme.palette.text.secondary,
  },
}

const styles: Record<string, SxProps<Theme>> = {
  columnHeader: {
    fontWeight: 500,
  },
}
