import { Dispatch, SetStateAction, useState } from 'react'

interface DocumentState {
  numPages: number
  pageWidth: number
  pageHeight: number
}

interface PDFNavigation {
  documentState: DocumentState
  setDocumentState: Dispatch<SetStateAction<DocumentState>>
  pageInView: number
  setPageInView: Dispatch<SetStateAction<number>>
  nextPage: () => void
  prevPage: () => void
  jumpToPage: (jumpTo?: number) => void
}

const BASE_ID = 'page_'

export const pageNumberId = (pageNumber: string | number): string =>
  BASE_ID + `${pageNumber}`

export const usePdfNavigation = (props: DocumentState): PDFNavigation => {
  const [documentState, setDocumentState] = useState<DocumentState>({
    numPages: props.numPages,
    pageWidth: props.pageWidth,
    pageHeight: props.pageHeight,
  })

  const [pageInView, setPageInView] = useState<number>(1)

  const nextPage = () => {
    if (pageInView < documentState.numPages) {
      window.location.href = '#' + BASE_ID + `${pageInView + 1}`
    }
  }

  const prevPage = () => {
    if (pageInView > 1) {
      window.location.href = '#' + BASE_ID + `${pageInView - 1}`
    }
  }

  const jumpToPage = (jumpTo?: number) => {
    if (jumpTo) {
      if (jumpTo > documentState.numPages) {
        window.location.href = '#' + BASE_ID + `${documentState.numPages}`
      } else if (jumpTo < 1) {
        window.location.href = '#' + BASE_ID + `1`
      } else {
        window.location.href = '#' + BASE_ID + `${jumpTo}`
      }
    }
  }

  return {
    documentState,
    setDocumentState,
    pageInView,
    setPageInView,
    nextPage,
    prevPage,
    jumpToPage,
  }
}
