import { Box } from '@mui/material'
import { Auth } from 'aws-amplify'
import React, { createContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext({} as AuthContextType)

interface AuthContextType {
  logout: () => void
}

interface Props {
  children: React.ReactNode
}

const AuthProvider = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate()
  const listener = () => {
    const key = 'CognitoIdentityServiceProvider'
    const storage = Object.keys(window.localStorage)
    const loginItem = localStorage.getItem('login')
    const hasItem = loginItem ? loginItem === 'true' : 'false'
    if (!storage.filter((data) => data.includes(key)).length || !hasItem) {
      Auth.signOut().then(() => {
        localStorage.clear()
      })
    }
  }

  const setLoginAndRunListener = async () => {
    try {
      await Auth.currentAuthenticatedUser()
      window.localStorage.setItem('login', 'true')
    } catch (_err) {
      window.localStorage.setItem('login', 'false')
    } finally {
      window.onstorage = () => listener()
    }
  }

  useEffect(() => {
    setLoginAndRunListener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = () => {
    Auth.signOut().then(() => {
      localStorage.clear()
      window.localStorage.setItem('login', 'false')
    })
    navigate('/login')
  }

  return (
    <AuthContext.Provider value={{ logout }}>
      <Box
        style={{
          overflow: 'hidden',
          height: 0,
          width: 0,
          visibility: 'hidden',
        }}
      >
        <iframe
          src={window.location.href}
          title="AuthenticationLogout"
          frameBorder="0"
        />
      </Box>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider }
