import { Box, Skeleton } from '@mui/material'
import React from 'react'

const SolicitationPageSkeleton = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      ml={1}
      width="inherit"
      height="100vh"
      mr={4}
    >
      <Skeleton
        variant="rectangular"
        height="80px"
        width="50%"
        animation="wave"
        sx={{ marginTop: 3.5 }}
      />

      <Skeleton
        variant="rectangular"
        height="50px"
        width="100%"
        animation="wave"
        sx={{ marginTop: 8 }}
      />

      <Box display="flex" width="100%" height="50%">
        <Box width="50%" display="flex" flexDirection="column">
          <Skeleton
            variant="rectangular"
            height="50px"
            width="100%"
            animation="wave"
            sx={{ marginTop: 3.5 }}
          />
          <Skeleton
            variant="rectangular"
            height="-webkit-fill-available"
            width="100%"
            animation="wave"
            sx={{ marginTop: 3.5 }}
          />
        </Box>
        <Box width="50%" pl={2}>
          <Skeleton
            variant="rectangular"
            height="80%"
            width="100%"
            animation="wave"
            sx={{ marginTop: 3.5 }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default SolicitationPageSkeleton
