export const dateToString = (date: Date): string | 'Unknown' => {
  if (
    typeof date === 'undefined' ||
    date.toString() === 'Invalid Date' ||
    (date as unknown as string) === 'NaT'
  ) {
    return 'Unknown'
  }
  return new Date(date).toISOString()
}

export const future = (date: Date, days: number): Date => {
  const futureDate = new Date(date)
  futureDate.setDate(futureDate.getDate() + days)
  return futureDate
}

export const past = (date: Date, days: number): Date => {
  const pastDate = new Date(date)
  pastDate.setDate(pastDate.getDate() - days)
  return pastDate
}

export const toAmericanDate = (inDate: Date): string => {
  if (dateToString(inDate) === 'Unknown') {
    return 'Unknown'
  }
  const date = new Date(inDate)
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  )
}

export const getDateOnly = (dateTime: Date): string => {
  return dateTime.toISOString().split('T')[0]
}

export const month_names = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
