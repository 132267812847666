import {
  alpha,
  FormControl,
  InputLabel,
  outlinedInputClasses,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { InputBaseProps } from '@mui/material/InputBase'
import { merge } from 'lodash'
import { ChangeEvent, useMemo } from 'react'
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form'

export interface Props {
  useFormHook: UseFormReturn
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  inputType?: string
  inputProps?: InputBaseProps['inputProps']
  placeholder?: string
  label: string
  name?: string
  hideLabel?: boolean
  sx?: Record<string, SxProps<Theme>>
  disabled?: boolean
  handleOnChange?: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
}

const SingleLineInput = ({
  inputType = 'text',
  label,
  name,
  placeholder = '',
  rules,
  useFormHook,
  hideLabel = false,
  inputProps = {},
  sx = {},
  disabled,
  handleOnChange,
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormHook
  const fieldName = useMemo(() => {
    const value = name ?? label
    return value.toLowerCase().trim().replace(/\s+/g, '-')
  }, [label, name])

  const classes = styles({ useFormHook, label: fieldName, hideLabel, sx })

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue=""
      rules={rules}
      render={({ field: { ref, onChange, ...field } }) => (
        <FormControl fullWidth variant="outlined">
          {!hideLabel && (
            <InputLabel
              htmlFor={fieldName}
              shrink={false}
              sx={{
                transform: 'translate(3px, 0px) scale(1)',
              }}
            >
              <Typography variant="body1">{label}</Typography>
            </InputLabel>
          )}
          <TextField
            {...field}
            name={fieldName}
            type={inputType}
            variant="outlined"
            error={!!Object.keys(errors).length}
            onChange={(e) => {
              onChange(e)
              if (handleOnChange) {
                handleOnChange(e)
              }
            }}
            placeholder={placeholder}
            inputRef={ref}
            inputProps={{
              style: { padding: '12px 14px' },
              ...inputProps,
            }}
            InputProps={{
              sx: { height: '44px' },
            }}
            sx={classes.input}
            disabled={disabled}
          />
        </FormControl>
      )}
    />
  )
}

const styles = (props: Partial<Props>): Record<string, SxProps<Theme>> => {
  const formHook = props.useFormHook
  const { label = '', hideLabel, sx } = props
  const defaultStyles: Record<string, SxProps<Theme>> = {
    input: {
      marginTop: (theme) => (hideLabel ? 0 : theme.spacing(4)),
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        backgroundColor: formHook?.formState?.errors?.[label]
          ? (theme) => alpha(theme.palette.error.light, 0.25)
          : '',
        borderColor: formHook?.formState?.errors?.[label]
          ? (theme) => `${theme.palette.error.light}!important`
          : 'info.dark',
        borderStyle: 'solid',
        borderWidth: formHook?.formState?.errors?.[label] ? '3px' : '1px',
      },
      [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: 'primary.main',
          borderWidth: '1px',
        },
    },
  }

  return merge(defaultStyles, sx)
}

export default SingleLineInput
