import { Criteria, EvaluationTask } from 'generated/graphql'
import { filter, orderBy } from 'lodash'
import { TotalDiscovery } from 'pages/Evaluation/state/useEvaluationPageState'
import { useMemo } from 'react'

export type DiscoveryPositions = {
  order: string
  id: string
}

export type CriteriaPositions = {
  order: string
  id: string
}
export interface SortedCriteria extends Criteria {
  discoveries?: Array<TotalDiscovery & { order?: string }>
}

export const useSortedCriteria = (
  evaluationTask?: EvaluationTask,
  totalDiscoveries?: TotalDiscovery[]
): {
  sortedCriteria: SortedCriteria[]
  discoveryPositions: DiscoveryPositions[]
  criteriaPositions: CriteriaPositions[]
} => {
  const discoveryPositions: DiscoveryPositions[] = []
  const criteriaPositions: CriteriaPositions[] = []
  const sortedCriteria: SortedCriteria[] = useMemo(() => {
    return orderBy(
      evaluationTask?.factor.criteria ?? [],
      (item) => item.name,
      'asc'
    )
  }, [evaluationTask?.factor.criteria])

  sortedCriteria.forEach((criteria, criteriaIndex) => {
    criteriaPositions.push({
      order: `${criteriaIndex + 1}.0: ${criteria.name}`,
      id: criteria.id,
    })
    const discoveries = filter(
      totalDiscoveries ?? [],
      (discovery) => discovery.criteria.id === criteria.id
    )

    criteria.discoveries = orderBy(
      [...discoveries],
      (item) => item.createdAt,
      'asc'
    )

    criteria.discoveries.forEach((discovery, discoveryIndex) => {
      discovery.order = `${criteriaIndex + 1}.${discoveryIndex + 1}`
      discoveryPositions.push({ order: discovery.order, id: discovery.id })
    })
  })
  return { sortedCriteria, discoveryPositions, criteriaPositions }
}
