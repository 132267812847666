import { Box, Typography } from '@mui/material'
import { BasicAccordion } from 'components/Accordian'
import Acknowledgement from 'components/Acknowledgement'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import RatingSelection from 'components/form/RatingSelection'
import { ItemCard } from 'components/ItemCard'
import { MessageAlert } from 'components/MessageAlert'
import MultiLineTextField from 'components/MultiLineTextField'
import RatingDefinitions from 'components/RatingDefinitions'
import { Discovery } from 'generated/graphql'
import { useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

import { Recommendation } from '..'

export interface Props extends Recommendation {
  formHook: UseFormReturn<FieldValues>
  onSave?: () => void
}

export const RecommendationDrawerContent = ({
  withRating,
  formHook,
  ratingOptions,
  proposalName,
  onSave,
  onCancel,
  openCriteriaDrawer,
  missingCriteriaRatings,
  criteriaPositions,
  openViewDiscoveriesDrawer,
  discoveryPositions,
  reviewDiscoveries,
}: Props): JSX.Element => {
  const { watch } = formHook
  const isChecked = watch('checkbox')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSave = async () => {
    setIsSubmitting(true)
    await onSave?.()

    setIsSubmitting(false)
  }

  const hasErrors = !(
    Object.keys(formHook.formState.errors).length === 0 &&
    formHook.formState.errors.constructor === Object
  )
  const discoveryCriteriaOrder = (discovery: Discovery) => {
    return `${
      discoveryPositions?.find((item) => item.id === discovery.id)?.order
    }: ${discovery.criteria.name}`
  }
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box id="recommendation-top-portion">
        <Typography mt={3} variant="body1">
          {proposalName}
        </Typography>

        {(missingCriteriaRatings?.length || reviewDiscoveries?.length) && (
          <>
            <Box mt={5}>
              <MessageAlert variant="warning" />
            </Box>

            {missingCriteriaRatings?.map((rating, index) => (
              <Box mt={5} key={'missing-criteria-' + index}>
                <ItemCard
                  header="This evaluation criteria needs to be rated:"
                  title={
                    criteriaPositions?.find((item) => item.id === rating.id)
                      ?.order ?? rating.name
                  }
                  linkText="Rate this Evaluation Criteria"
                  customClick={() => openCriteriaDrawer?.(rating.id)}
                />
              </Box>
            ))}

            {reviewDiscoveries?.map((discovery, index) => {
              const { firstName, lastName } = discovery.author
              return (
                <Box mt={5} key={'missing-review-' + index}>
                  <ItemCard
                    header="This discovery needs to be acknowledged by an evaluator:"
                    title={discoveryCriteriaOrder(discovery)}
                    subtitle={`${firstName} ${lastName} received this request - ${String(
                      discovery.createdAt
                    ).substring(0, 10)}`}
                    body={discovery.annotations[0].text}
                    linkText="View this Discovery"
                    customClick={() =>
                      openViewDiscoveriesDrawer?.([discovery.id])
                    }
                  />
                </Box>
              )
            })}

            {reviewDiscoveries?.map((discovery, index) => {
              const { firstName, lastName } = discovery.feedback[0].sentBy
              return (
                <Box mt={5} key={'check-review-' + index}>
                  <ItemCard
                    header="This discovery was marked for your review:"
                    title={discoveryCriteriaOrder(discovery)}
                    subtitle={`Marked for review by ${firstName} ${lastName} - ${String(
                      discovery.createdAt
                    ).substring(0, 10)}`}
                    body={discovery.annotations[0].text}
                    linkText="Review this Discovery"
                    customClick={() =>
                      openViewDiscoveriesDrawer?.([discovery.id])
                    }
                  />
                </Box>
              )
            })}
          </>
        )}

        {withRating && (
          <Box id={'recommendation-definitions'} mt={4}>
            <BasicAccordion title={'Definitions'}>
              <Box px={2} pt={1}>
                <RatingDefinitions data={ratingOptions} />
              </Box>
            </BasicAccordion>
          </Box>
        )}

        {hasErrors && (
          <Box pt={5} pb={2}>
            <MessageAlert variant="error" />
          </Box>
        )}

        {withRating && (
          <Box id={'recommendation-rate-discovery'} mt={1}>
            <RatingSelection
              defaultOption={'Select Rating'}
              useFormHook={formHook}
              label="Rate This Proposal"
              items={ratingOptions}
              itemColor={'backgroundColor'}
              itemDefinition="description"
              rules={{ required: true }}
              hideErrorMessage={true}
            />
          </Box>
        )}
        <Box id={'overall-recommendation-comment'} mt={4}>
          <MultiLineTextField
            rows={5}
            variant={'outlined'}
            title={'Overall Recommendation'}
            name={'Overall Recommendation'}
            placeholder={'Write a comment'}
            useFormHook={formHook}
            rules={{ required: true }}
          />
        </Box>
        <Box id={'recommendation-acknowledgement'} mt={5}>
          <Acknowledgement
            control={formHook.control}
            title="Acknowledgement"
            message="Submitting this evaluation will finalize the evaluation and return you to the homepage"
            name="checkbox"
            rules={{ required: true }}
          />
        </Box>
      </Box>

      <Box
        id={'recommendation-bottom-portion'}
        position="sticky"
        bottom={0}
        bgcolor="background.paper"
        py={1}
        zIndex={33}
      >
        <Box
          id={'recommendation-action-buttons'}
          display="flex"
          justifyContent="flex-end"
          my={3}
        >
          <BidscaleButton
            id="recommendation-cancel-button"
            buttonVariant="cancel"
            onClick={onCancel}
            sx={{ width: theme.spacing(29), mr: 3 }}
          >
            Cancel
          </BidscaleButton>
          <BidscaleButton
            id={'recommendation-save-button'}
            buttonVariant="execute"
            sx={{ width: theme.spacing(29) }}
            onClick={handleSave}
            disabled={!isChecked}
            activityIndicator={isSubmitting}
          >
            Confirm
          </BidscaleButton>
        </Box>
      </Box>
    </Box>
  )
}
