import { Box, Divider, SxProps, Theme } from '@mui/material'
import cuid from 'cuid'
import React from 'react'

interface Props {
  children: React.ReactNode
  sx?: SxProps<Theme>
  hr?: SxProps<Theme>
}

const componentId = 'vertical-divider-list'

export const VerticalDividerUtility = ({
  sx,
  ...props
}: Props): JSX.Element => (
  <Box
    display="flex"
    flex={1}
    justifyContent="space-evenly"
    alignItems="center"
    sx={[...(Array.isArray(sx) ? sx : [sx])] as SxProps<Theme>}
    id={componentId}
  >
    {props.children instanceof Array
      ? props.children?.map((node, index) => (
          <Box display="flex" flex={3} key={`${componentId}-${cuid()}`}>
            <Box display="flex" flex={2} justifyContent="center">
              {node}
            </Box>
            {/* Had to check if Array here again, despite same body */}
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="center"
              sx={props.hr}
            >
              {props.children instanceof Array &&
                index !== props?.children?.length - 1 && (
                  <Divider variant="middle" orientation="vertical" flexItem />
                )}
            </Box>
          </Box>
        ))
      : props.children}
  </Box>
)
