import { Box, Typography } from '@mui/material'
import { ActionMenuKebab } from 'components/ActionMenuKebab'
import DataTable, { Props as DataTableProps } from 'components/DataTable'
import {
  TagRenderer,
  TextRenderer,
} from 'components/DataTable/GenericRenderers'
import { multilineEllipsis } from 'constants/styles'
import { User } from 'generated/graphql'
import { UserTable } from 'pages/Admin/constants'
import { capitalizeString } from 'utils/normalizeString/normalizeString'

interface Props {
  users: User[]
}

export const UserManagementTable = ({ users }: Props): JSX.Element => {
  const RolesRenderer = (user: User): JSX.Element => {
    return (
      <Box display="flex">
        <Typography variant="body2" sx={multilineEllipsis(1)}>
          {capitalizeString(
            Object.values(user.roles)
              .map((word) => word.replace('_', ' '))
              .join(', ')
          )}
        </Typography>
      </Box>
    )
  }

  const actionMenuRenderer = (): JSX.Element => {
    return (
      <ActionMenuKebab
        items={[{ itemName: 'Edit', onClick: () => alert('edit') }]}
      />
    )
  }

  const tableConfig: DataTableProps<User> = {
    columns: [
      {
        name: UserTable.FIRST_NAME,
        renderer: TextRenderer,
        sort: (item) => item.firstName.toLowerCase(),
        key: 'firstName',
      },
      {
        name: UserTable.LAST_NAME,
        renderer: TextRenderer,
        sort: (item) => item.lastName.toLowerCase(),
        key: 'lastName',
      },
      {
        name: UserTable.TITLE,
        renderer: TextRenderer,
        sort: (item) => item.title.toLowerCase(),
        key: 'title',
      },
      {
        name: UserTable.EMAIL,
        renderer: TextRenderer,
        sort: (item) => item.email.toLowerCase(),
        key: 'email',
      },
      {
        name: UserTable.ROLES,
        renderer: RolesRenderer,
        sort: (item) => item.roles.toString().toLowerCase(),
        key: 'roles',
      },
      {
        name: UserTable.STATUS,
        renderer: TagRenderer,
        key: 'status',
      },
      {
        name: UserTable.ACTIONS,
        renderer: actionMenuRenderer,
      },
    ],
    data: users,
    rowsPerPage: 10,
    noResultFound: 'No users at this time',
  }
  return <DataTable {...tableConfig} />
}
