import { Box, SxProps, Theme, Typography } from '@mui/material'
import defaultBackground from 'assets/defaultTabBackground.svg'
import hoverBackground from 'assets/hoverTabBackground.svg'

export interface Props {
  itemCount: number
  onClick?: () => void
  sx?: SxProps<Theme>
}

export const AnnotationTab = ({
  itemCount,
  onClick,
  sx,
}: Props): JSX.Element => {
  return (
    <Box
      sx={{
        width: '36px',
        height: '36px',
        backgroundImage: `url(${defaultBackground})`,
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
          backgroundImage: `url(${hoverBackground})`,
          cursor: 'pointer',
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <Typography variant="button" pl={'6px'}>
        {itemCount > 9 ? '9+' : itemCount}
      </Typography>
    </Box>
  )
}
