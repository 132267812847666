import { Box, SxProps, Theme, Typography } from '@mui/material'
import { ReactComponent as ItemCardIcon } from 'assets/itemCardIcon.svg'
import { box_shadow, multilineEllipsis } from 'constants/styles'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'theme'

export interface Props {
  header: string
  title: string
  subtitle?: string
  body?: string
  linkText: string
  linkPath?: string
  customClick?: () => void
  icon?: ReactElement
  sx?: SxProps<Theme>
}

export const ItemCard = ({
  header,
  title,
  subtitle,
  body,
  linkPath,
  linkText,
  customClick,
  icon,
  sx,
}: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      pt={2}
      pb={3}
      px={3}
      sx={
        [
          {
            border: `1px solid ${theme.palette.info.main}`,
            borderRadius: '4px',
            ...box_shadow,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ] as SxProps<Theme>
      }
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          lineHeight: '28px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        mb={1}
      >
        {header}
      </Typography>

      <Typography
        variant="subtitle2"
        mb={1}
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {title}
      </Typography>

      <Box display="flex" flexDirection="column">
        <Typography variant="body2" mb={1}>
          {subtitle}
        </Typography>
        <Typography pl={5} mb={1} variant="subtitle1" sx={multilineEllipsis(3)}>
          {body}
        </Typography>
      </Box>

      <Box display="flex" id="link">
        <Box id="item-card-icon">
          {icon ?? <ItemCardIcon height={'24px'} width={'24px'} />}
        </Box>
        <Link
          to={linkPath ?? ''}
          style={{
            display: 'flex',
          }}
          onClick={customClick}
        >
          <Typography
            variant="body2"
            alignSelf={'flex-end'}
            ml={2}
            color="text.primary"
            sx={{
              textDecoration: 'underline',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {linkText}
          </Typography>
        </Link>
      </Box>
    </Box>
  )
}
