import { Box, Divider, Typography } from '@mui/material'
import RatingDefinitions from 'components/RatingDefinitions'
import { EvaluationTask, Scalars } from 'generated/graphql'
import { SortedCriteria, useSortedCriteria } from 'hooks/useSortedCriteria'
import { filter, indexOf, map } from 'lodash'
import {
  TotalDiscovery,
  UpdateDiscovery,
} from 'pages/Evaluation/state/useEvaluationPageState'
import { ViewAndEditDiscovery } from 'pages/Evaluation/ViewAndEditDiscovery'
import { GenericDiscoveryBox } from 'pages/Evaluation/ViewAndEditDiscovery/GenericDiscoveryBox'
import { Fragment, useState } from 'react'

export interface Props extends UpdateDiscovery {
  task: EvaluationTask
  discoveries: TotalDiscovery[]
}

export const SummaryDrawerContent = ({
  discoveries,
  task,
  editDiscovery,
  deleteDiscovery,
  flagDiscovery,
}: Props): JSX.Element => {
  const [removedDiscoveries, setRemovedDiscoveries] =
    useState<Scalars['ID'][]>()
  const { sortedCriteria } = useSortedCriteria(task, discoveries)

  const handleRemoveDiscovery = async (id: Scalars['ID']) => {
    const removedSuccessfully = await deleteDiscovery(id)
    if (removedSuccessfully) {
      if (!removedDiscoveries) {
        setRemovedDiscoveries([id])
      } else if (indexOf(removedDiscoveries, id) < 0) {
        setRemovedDiscoveries([...removedDiscoveries, id])
      }
    }
    return removedSuccessfully
  }

  const getDiscoveries = (criteria: SortedCriteria) => {
    const withoutDeletedDiscoveries = removedDiscoveries
      ? filter(
          criteria.discoveries,
          (discovery) => indexOf(removedDiscoveries, discovery.id) < 0
        )
      : criteria.discoveries
    return withoutDeletedDiscoveries
  }

  return (
    <Box mx={32} pb={4} mt={12}>
      <Typography variant="overline" color="info.dark">
        Summary
      </Typography>
      <Typography variant="h2">{task.factor.name}</Typography>
      {/* TODO: This below filter row should be used after the demo */}
      {/* <Box
        mt={7}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <Typography pr={1} variant="subtitle2" color="text.secondary">
          Filter:{' '}
        </Typography>
        <IconButton aria-label="filter">
          <FilterListIcon />
        </IconButton>
      </Box> */}
      <Box mt={6}>
        <Typography variant="button">Factor Definition</Typography>
      </Box>
      <Typography variant="body1" fontWeight="fontWeightRegular" mt={1}>
        {task.factor.description}
      </Typography>
      <Box mt={4}>
        <RatingDefinitions data={task.factor.overallRatingOptions} />
      </Box>
      <Box pb={4}>
        {map(sortedCriteria, (criteria, criteriaIndex) => (
          <Fragment key={criteriaIndex}>
            <Box py={5}>
              <Divider />
            </Box>
            <Typography variant="h4" py={3}>{`${criteriaIndex + 1}.0 ${
              criteria.name
            }`}</Typography>
            <Typography variant="body1" fontWeight="fontWeightRegular">
              {criteria.description}
            </Typography>
            <Box mt={4}>
              <RatingDefinitions data={task.factor.discoveryRatingOptions} />
            </Box>
            <Typography variant="h5" mt={3}>
              Discoveries
            </Typography>
            {getDiscoveries(criteria)?.length ? (
              map(getDiscoveries(criteria), (discovery) => (
                <Box mt={3} key={discovery.order}>
                  <ViewAndEditDiscovery
                    titleNumbering={discovery.order || ''}
                    discovery={discovery}
                    ratings={task.factor.discoveryRatingOptions}
                    onEditDiscovery={editDiscovery}
                    onDeleteDiscovery={handleRemoveDiscovery}
                    onFlagDiscovery={flagDiscovery}
                    forceActive={true}
                    taskType={task.taskType}
                  />
                </Box>
              ))
            ) : (
              <Box pb={3}>
                <GenericDiscoveryBox disabled editingOrDeleting={false} />
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  )
}
