import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import {
  Box,
  IconButton,
  MenuItem,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ExecuteButton } from 'components/Buttons/BidscaleButton'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

interface CallbackProps {
  arrowUpwardCallback?: () => void
  arrowDownwardCallback?: () => void
  zoomInCallback?: () => void
  zoomOutCallback?: () => void
  setParentZoom?: (value: number) => void
  jumpToPage?: (jumpTo?: number) => void
  openRecommendationDrawer?: () => void
}

export interface PageNavigationProps extends CallbackProps {
  current: number
  max: number
  zoomOptions?: number[]
  zoom?: number
  sx?: SxProps<Theme>
}

export const PdfPageNavigation = (props: PageNavigationProps): JSX.Element => {
  const BASE_ID = 'evaluation-top-bar-navigation'
  const [iconMarginRight, margin_right] = [1, 1.5]

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      sx={Object.assign(
        {},
        ...(Array.isArray(props.sx) ? props.sx : [props.sx])
      )}
      id={BASE_ID}
    >
      <Box
        display="flex"
        flexDirection="row"
        color="text.primary"
        fontSize={18}
      >
        <Box
          mr={iconMarginRight}
          onClick={props.arrowUpwardCallback}
          id={`${BASE_ID}-up-icon`}
        >
          <IconButton color="inherit" size="small">
            <ArrowUpwardIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          mr={iconMarginRight}
          onClick={props.arrowDownwardCallback}
          id={`${BASE_ID}-down-icon`}
        >
          <IconButton color="inherit" size="small">
            <ArrowDownwardIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          mr={iconMarginRight}
          onClick={props.zoomInCallback}
          id={`${BASE_ID}-zoom-in-icon`}
        >
          <IconButton color="inherit" size="small">
            <ZoomInIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          mr={iconMarginRight}
          onClick={props.zoomOutCallback}
          id={`${BASE_ID}-zoom-out-icon`}
        >
          <IconButton color="inherit" size="small">
            <ZoomOutIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>

      <Box mr={margin_right} display="flex" justifyContent="center">
        <PageIteration
          current={props.current}
          max={props.max}
          callback={props?.jumpToPage}
        />
      </Box>

      <Box mr={margin_right}>
        {props.setParentZoom && props.zoomOptions && props.zoom && (
          <Dropdown
            setParentZoom={props.setParentZoom}
            zoomOptions={props.zoomOptions}
            zoom={props.zoom}
          />
        )}
      </Box>

      <Box mr={margin_right}>
        <ExecuteButton sx={{ height: '40px' }}>
          <Typography
            variant="button"
            color="info.light"
            onClick={props.openRecommendationDrawer}
          >
            SUBMIT REVIEW
          </Typography>
        </ExecuteButton>
      </Box>
    </Box>
  )
}

const PageIteration = (props: {
  current: number
  max: number
  callback?: (jumpTo?: number) => void
}): JSX.Element => {
  const [value, setValue] = useState<number | string>(props.current)

  useEffect(() => {
    setValue(props.current)
  }, [props])

  const debounceMemo = useMemo(
    () =>
      debounce((input) => {
        props.callback?.(Number(input))
      }, 1200),
    [props]
  )

  const debouncedEventHandler = (input: string) => {
    setValue(input)
    debounceMemo(input)
  }

  const handleOnChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedEventHandler(e.target.value)
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <TextField
        onChange={(e) => handleOnChange(e)}
        value={value}
        sx={{
          minWidth: 20,
          maxWidth: 48,
          height: '40px',
          margin: 'auto',
          '& .MuiInputBase-root': {
            margin: 'auto',
            height: '40px',
          },
          '& .MuiInputBase-input': {
            paddingX: 1,
            paddingY: 0.25,
            textAlign: 'center',
            height: '20px',
            margin: 'auto',
          },
        }}
      />
      <Typography variant="body1" ml={0.5}>{`/ ${props.max}`}</Typography>
    </Box>
  )
}

const Dropdown = (props: {
  setParentZoom: (value: number) => void
  zoomOptions: number[]
  zoom: number
}): JSX.Element => {
  const handleChange = (event: SelectChangeEvent) => {
    props.setParentZoom?.(Number(event.target.value))
  }

  const toPercent = (input: number) => Math.round(input * 100).toString() + '%'

  return (
    <Box id="evaluation-top-bar-zoom-percentage-select">
      <FormControl focused={false}>
        <Select
          value={props.zoom.toString()}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Zoom' }}
          sx={{
            minWidth: 96,
            maxHeight: '40px',
          }}
        >
          {props.zoomOptions?.map((option) => (
            <MenuItem key={option} value={option.toString()}>
              {toPercent(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
