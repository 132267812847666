import { Box, Typography } from '@mui/material'
import { theme } from 'theme'

export interface Props {
  value: number | string
  subtitle: string
  barColor: string
}

export const KpiMedium = ({
  value,
  subtitle,
  barColor,
}: Props): JSX.Element => {
  return (
    <Box display="flex" alignItems="flex-end">
      <Box
        sx={{
          bgcolor: barColor,
          height: '101px',
          width: theme.spacing(1),
          mr: 3,
        }}
      />
      <Box display="flex" flexDirection="column">
        <Typography variant="h2">{value}</Typography>

        <Typography textTransform="capitalize" variant="subtitle1">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  )
}
