import { useState } from 'react'

interface Props {
  zoom: number
  ratio: number
  zoomOptions?: number[]
}

interface ZoomState {
  zoom: number
  scale: number
  setZoom: (input: number) => void
  zoomIn: () => void
  zoomOut: () => void
  zoomOptions: number[]
}

const zoomOptionDefault = [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5]

export function useZoomState(props: Props): ZoomState {
  const [zoom, setZoom] = useState<number>(props.ratio)
  const [scale, setScale] = useState<number>(props.ratio * props.zoom)
  const [zoomOptions, _setZoomOptions] = useState<number[]>(
    props.zoomOptions ?? zoomOptionDefault
  )

  const setZoomFun = (input: number) => {
    if (zoomOptions.find((option) => option === input)) {
      setZoom(input)
      setScale(input * props.ratio)
    }
  }

  const zoomIn = () => {
    const index = zoomOptions.findIndex((option) => option === zoom)
    if (index >= 0 && index < zoomOptions.length - 1) {
      setZoom(zoomOptions[index + 1])
      setScale(zoomOptions[index + 1] * props.ratio)
    }
  }

  const zoomOut = () => {
    const index = zoomOptions.findIndex((option) => option === zoom)
    if (index > 0) {
      setZoom(zoomOptions[index - 1])
      setScale(zoomOptions[index - 1] * props.ratio)
    }
  }

  return {
    zoom: zoom,
    scale: scale,
    setZoom: setZoomFun,
    zoomIn: zoomIn,
    zoomOut: zoomOut,
    zoomOptions: zoomOptions,
  }
}
