import { alpha, Box, Typography } from '@mui/material'
import Acknowledgement from 'components/Acknowledgement'
import { ExecuteButton } from 'components/Buttons/BidscaleButton'
import { format } from 'date-fns'
import { detect } from 'detect-browser'
import { useDetectOverflowY } from 'hooks/useOverflow'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { publicIpv4 } from 'public-ip'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { theme } from 'theme'

interface DataProps {
  ip: string
  date: string
  browser: string
  username: string
  name: string
}

interface ViewProps extends DataProps {
  title: string
  onConfirmClick?: (confirmationDetails: DataProps) => void
}

interface Props {
  title: string
  onConfirmClick?: (confirmationDetails: DataProps) => void
}

const UserAgreement = (props: Props): JSX.Element => {
  const user = useContext(UserContext)
  const browserDetectionResult = detect()
  const currentDate = new Date().toISOString()
  const browser = browserDetectionResult
    ? `${browserDetectionResult.name
        .trim()
        .replace(/^\w/, (c) => c.toUpperCase())} ${
        browserDetectionResult.version
      }`
    : 'N/A'
  const [publicIPAddr, setPublicIPAddr] = useState('')
  useEffect(() => {
    const setIP = async () => {
      try {
        setPublicIPAddr(await publicIpv4())
      } catch {
        setPublicIPAddr('Error fetching IP')
      }
    }
    setIP()
  }, [setPublicIPAddr])

  return (
    <UserAgreementView
      {...props}
      browser={browser}
      date={currentDate}
      username={user.user.email}
      name={user.user.firstName + ' ' + user.user.familyName}
      ip={publicIPAddr}
    />
  )
}

const UserAgreementView = ({
  title,
  onConfirmClick,
  ...props
}: ViewProps): JSX.Element => {
  const { ref, isOverflowY } = useDetectOverflowY()

  const { control, watch } = useForm<FieldValues>({
    defaultValues: {
      checkbox: false,
    },
  })
  const isChecked = watch('checkbox')

  return (
    <Box
      ref={ref}
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Typography variant="subtitle2" textTransform="uppercase" my={3}>
          {title}
        </Typography>
        <Typography variant="body2" mb={4.5}>
          By acknowledging, you confirm that the details displayed on this
          window are accurate and agree to the following terms. This is an
          Information System (IS) that is provided for U.S. Government (USG)
          authorized use only. By using this IS (which includes any device
          attached to this IS), you consent to the following conditions: the IS
          monitors electronic activity while utilizing the IS, unauthorized
          activities or suspicious behavior may be reported to USG officials,
          data contained within the IS is subject to the policies and procedures
          governing the supported source selection, and storage of user-created
          data may be used for purposes of developing government-requested
          artifacts and product enhancement efforts.
        </Typography>

        <Box mb={4}>
          <Data {...props} />
        </Box>

        <Box mb={4.5} id="acknowledgement">
          <Acknowledgement
            control={control}
            name="checkbox"
            title="Acknowledgement"
            message="I agree to the above policy and will respect said rules of engagement"
          />
        </Box>
      </Box>

      <Box position="sticky" bottom={0} bgcolor="info.light">
        {isOverflowY && (
          <Box
            id="line-across-page"
            height="1px"
            width="103%"
            ml={-3}
            mt={0.25}
            boxShadow={`0px -4px 2px ${alpha(theme.palette.info.dark, 0.5)}`}
          />
        )}

        <Box
          display="flex"
          justifyContent="flex-end"
          px={4}
          py={5}
          id="confirm-button"
        >
          <ExecuteButton
            disabled={!isChecked}
            onClick={() => onConfirmClick?.({ ...props })}
            sx={{ width: theme.spacing(33) }}
          >
            Confirm
          </ExecuteButton>
        </Box>
      </Box>
    </Box>
  )
}

const Data = (props: DataProps): JSX.Element => (
  <Box display="flex" bgcolor="info.main" px={4} py={2} id="user-data">
    <Box flex={1}>
      <Box>
        <Typography variant="subtitle2" textTransform="capitalize">
          IP
        </Typography>
        <Typography variant="body2">{props.ip}</Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="subtitle2">Date</Typography>
        <Typography variant="body2">
          {format(new Date(props.date), 'PPP')}
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="subtitle2">Time</Typography>
        <Typography variant="body2">
          {format(new Date(props.date), 'p')}
        </Typography>
      </Box>
    </Box>

    <Box flex={1}>
      <Box>
        <Typography variant="subtitle2" textTransform="capitalize">
          Browser
        </Typography>
        <Typography variant="body2">{props.browser}</Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="subtitle2">Username</Typography>
        <Typography variant="body2">{props.username}</Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="subtitle2">Name</Typography>
        <Typography variant="body2" textTransform="capitalize">
          {props.name}
        </Typography>
      </Box>
    </Box>
  </Box>
)

export { UserAgreement, UserAgreementView }
