import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { ReactNode } from 'react'
import { theme } from 'theme'

export interface IconProps {
  value: string
  avatarIcon: ReactNode
}
export interface Props {
  select: string | null
  arrIcons: IconProps[]
  exclusive?: boolean
  handleClick: (event: React.MouseEvent<HTMLElement>, value: string) => void
}
export const IconButton = ({
  select,
  arrIcons: children,
  handleClick,
  exclusive = true,
}: Props): JSX.Element => {
  return (
    <ToggleButtonGroup
      value={select}
      exclusive={exclusive}
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .MuiToggleButtonGroup-grouped': {
          mx: 1.5,
          border: 0,
          '&.Mui-disabled': {
            border: 0,
          },
          '&:not(:first-of-type)': {
            borderRadius: '2px',
          },
          '&:first-of-type': {
            borderRadius: '2px',
          },
        },
      }}
    >
      {children?.map((icon, i) => (
        <ToggleButton
          key={i}
          value={icon.value}
          sx={{
            height: theme.spacing(5),
            ml: 1.5,
            color: theme.palette.text.primary,
            borderRadius: '2px',
            '&:hover, &.Mui-selected:hover': {
              bgcolor: 'info.dark',
            },
            '&.Mui-selected': {
              bgcolor: 'info.main',
            },
          }}
          onClick={handleClick}
        >
          {icon.avatarIcon}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
