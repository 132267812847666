import axios from 'axios'
import { FileTypes } from 'constants/enumerations'
import {
  Exact,
  MutationUpsertFileArgs,
  UpsertFileInput,
  UpsertFileMutation,
} from 'generated/graphql'
import { useSnackbar } from 'notistack'
import React, { createContext, useEffect, useState } from 'react'
import { OperationResult } from 'urql'
import { FileContent } from 'use-file-picker/dist/interfaces'

interface Props {
  children: React.ReactNode
  upsertFile: (
    input: UpsertFileInput
  ) => Promise<
    OperationResult<UpsertFileMutation, Exact<MutationUpsertFileArgs>>
  >
}

interface UploadDocumentContextProps {
  uploadFile: (fileContent: FileContent[]) => void
  uploadProgress: UploadProgress | null
  fileDetails: UploadFile | null
  uploadCompleted: number
  showUploadError: boolean
  resetUploadError: () => void
}

export interface UploadFile {
  files: FileContent[]
  size: string
  totalSize: number
}

export interface UploadProgress {
  progress: number
  uploaded: number[]
}

export const UploadDocumentContext = createContext(
  {} as UploadDocumentContextProps
)

const UploadDocumentProvider = ({
  children,
  upsertFile,
}: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()
  const [uploadProgress, setUploadProgress] = useState<null | UploadProgress>(
    null
  )
  const [uploadFileDetails, setUploadFileDetails] = useState<null | UploadFile>(
    null
  )
  const [uploadCompleted, setUploadCompleted] = useState(1)
  const [showUploadError, setUploadError] = useState(false)

  const resetState = () => {
    setUploadFileDetails(null)
    setUploadCompleted(1)
    setUploadProgress(null)
  }

  const calculateFileSize = (size: number) => {
    const value = size.toString()

    if (value.length > 6) {
      return `${Math.round(size / 1000000)} MB`
    }

    if (value.length > 3) {
      return `${Math.round(size / 1000)} KB`
    }

    return `${size} B`
  }

  const getFilContentType = (file: FileContent) => {
    const contentType = file.name.split('.').pop() as FileTypes
    return Object.values(FileTypes)[Object.keys(FileTypes).indexOf(contentType)]
  }

  const getUploadPreSignedURL = async (fileName: string) => {
    try {
      const { data } = await upsertFile({ name: fileName })
      return data?.upsertFile.transfer.presignedUrl
    } catch (e) {
      resetState()
      setUploadError(true)
    }
  }

  const handleUploadProgress = async (
    progressEvent: ProgressEvent,
    index: number
  ) => {
    if (uploadFileDetails && uploadProgress) {
      const { files, totalSize } = uploadFileDetails
      const { uploaded } = uploadProgress
      const tempUploaded = uploaded

      tempUploaded[index] = progressEvent.loaded

      if (
        tempUploaded[index] / progressEvent.total === 1 &&
        uploadCompleted < files.length
      ) {
        setUploadCompleted(uploadCompleted + 1)
      }

      setUploadProgress({
        uploaded: tempUploaded,
        progress:
          tempUploaded.reduce((res, current) => res + current, 0) / totalSize,
      })
    }
  }

  const uploadFile = async (fileContent: FileContent[]) => {
    const size = fileContent.reduce(
      (res, cur) => res + (cur.content as unknown as ArrayBuffer).byteLength,
      0
    )

    setUploadFileDetails({
      size: calculateFileSize(size),
      totalSize: size,
      files: fileContent,
    })

    setUploadProgress({
      progress: 0,
      uploaded: [],
    })
  }

  const runUpload = () => {
    try {
      if (uploadFileDetails?.files) {
        uploadFileDetails.files.forEach(async (file, index) => {
          const presignedURL = await getUploadPreSignedURL(file.name)

          if (presignedURL) {
            const contentTypeValue = getFilContentType(file)
            await axios.put(presignedURL, file.content, {
              headers: { 'Content-Type': contentTypeValue },
              onUploadProgress: (progressEvent) =>
                handleUploadProgress(progressEvent, index),
            })
          }
        })
      }
    } catch (e) {
      resetState()
      setUploadError(true)
    }
  }

  const resetUploadError = () => setUploadError(false)

  useEffect(() => {
    if (uploadFileDetails) {
      runUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileDetails])

  useEffect(() => {
    let timeout: null | ReturnType<typeof setTimeout> = null

    if (uploadFileDetails && uploadProgress) {
      const { progress } = uploadProgress

      if (progress === 1) {
        timeout = setTimeout(() => {
          enqueueSnackbar(
            `New file${
              uploadFileDetails.files.length > 1 ? 's' : ''
            } successfully uploaded`
          )
          resetState()
        }, 500)
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgress, uploadFileDetails, uploadCompleted])

  return (
    <UploadDocumentContext.Provider
      value={{
        uploadFile,
        uploadProgress,
        fileDetails: uploadFileDetails,
        uploadCompleted,
        resetUploadError,
        showUploadError,
      }}
    >
      {children}
    </UploadDocumentContext.Provider>
  )
}

export default UploadDocumentProvider
