import { useReducer } from 'react'

export enum DrawerType {
  DISCOVERY_DRAWER = 'Discovery Editor',
  CRITERIA_DRAWER = 'CriteriaDrawer',
  USER_AGREEMENT = 'User Agreement',
  VIEW_DISCOVERIES_DRAWER = 'ViewDiscoveriesDrawer',
  RECOMMENDATION_DRAWER = 'Overall Recommendation',
  SUMMARY_DRAWER = 'SummaryDrawer',
}

interface DrawerReturn {
  drawerState: DrawerState
  openDrawer: (drawerId: DrawerType) => void
  closeDrawer: (drawerId: DrawerType) => void
}

export enum ActionType {
  OPEN,
  CLOSE,
}

interface DrawerState {
  currentOpenDrawer: DrawerType | undefined
}

const defaultState: DrawerState = {
  currentOpenDrawer: DrawerType.USER_AGREEMENT,
}

export type Action =
  | { type: ActionType.OPEN; payload: DrawerType }
  | { type: ActionType.CLOSE; payload: DrawerType }

function drawerReducer(state: DrawerState, action: Action): DrawerState {
  const { type, payload } = action

  switch (type) {
    case ActionType.OPEN:
      return { ...state, currentOpenDrawer: payload }
    case ActionType.CLOSE:
      return payload === state.currentOpenDrawer
        ? { ...state, currentOpenDrawer: undefined }
        : state
  }
}

export function useDrawerState(): DrawerReturn {
  const [state, dispatch] = useReducer(drawerReducer, defaultState)

  const openDrawer = (drawerId: DrawerType) => {
    if (state.currentOpenDrawer !== undefined) {
      dispatch({ type: ActionType.CLOSE, payload: state.currentOpenDrawer })
      setTimeout(() => {
        dispatch({ type: ActionType.OPEN, payload: drawerId })
      }, 150)
    } else {
      dispatch({ type: ActionType.OPEN, payload: drawerId })
    }
  }

  const closeDrawer = (drawerId: DrawerType) => {
    dispatch({ type: ActionType.CLOSE, payload: drawerId })
  }

  return {
    drawerState: state,
    openDrawer: openDrawer,
    closeDrawer: closeDrawer,
  }
}
