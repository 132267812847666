import { Box, Typography } from '@mui/material'
import { Tabs } from 'components/Tabs'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AdminTabs } from '../constants'
import { Ratings } from '../Ratings'
import { Solicitations } from '../Solicitations'
import { AdminPanelPageState } from '../state/useAdminPanelPageState'
import { TenantManagement } from '../TenantManagement'
import { UserManagement } from '../UserManagement'

export interface Props {
  state: AdminPanelPageState
}

export const Page = ({ state }: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState('usermanagement')

  const navigate = useNavigate()
  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName)
    navigate(tabName)
  }

  const tabs = [
    {
      tabTitle: AdminTabs.TENANT_MANAGEMENT,
      tabPanel: <TenantManagement />,
      display: true,
      link: 'tenantmanagement',
    },
    {
      tabTitle: AdminTabs.USER_MANAGEMENT,
      tabPanel: <UserManagement users={state.users} />,
      display: true,
      link: 'usermanagement',
    },
    {
      tabTitle: AdminTabs.RATINGS,
      tabPanel: <Ratings ratings={state.ratings} />,
      display: true,
      link: 'ratings',
    },
    {
      tabTitle: AdminTabs.SOLICITATIONS,
      tabPanel: <Solicitations />,
      display: true,
      link: 'solicitations',
    },
  ]

  return (
    <Box
      id="admin-page-view"
      width="100%"
      display="flex"
      flexDirection="column"
      ml={-3.5}
      flex="auto"
    >
      <Box ml={4} mt={3.4} mb={2.5}>
        <Typography variant="h5" fontSize="30px" lineHeight="32px">
          Admin Settings
        </Typography>
      </Box>
      <Box flex="auto" display="flex" flexDirection="column">
        <Tabs
          selectedTab={selectedTab}
          sxTabSpacer={{ width: '32px' }}
          tabs={tabs}
          handleOnChange={handleTabChange}
        />
      </Box>
    </Box>
  )
}
