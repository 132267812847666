import FilterListIcon from '@mui/icons-material/FilterList'
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import { find, forEach, map } from 'lodash'
import { useEffect, useState } from 'react'
import { theme } from 'theme'

import { FilterClickInput, FilterData, Filters } from '../Filters'

export interface Props {
  filtersData: FilterData[]
  setFiltersData: (data: FilterData[]) => void
  actionButtonsPlacement: 'right' | 'bottom'
  subtitle?: string
}

export const ControlledFilters = ({
  filtersData,
  setFiltersData,
  actionButtonsPlacement,
  subtitle,
}: Props): JSX.Element => {
  const [filters, setFilters] = useState<FilterData[]>(filtersData)
  const [numberOfOptionsSelected, setNumberOfOptionsSelected] = useState(0)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let count = 0
    forEach(filters, (filter) => {
      forEach(filter.options, (option) => {
        if (option.selected) {
          count++
        }
      })
    })
    setNumberOfOptionsSelected(count)
  }, [filters])

  const resetFilters = () => {
    const filtersWithNoSelections = map(filtersData, (filterData) => {
      return {
        category: filterData.category,
        options: map(filterData.options, (option) => {
          return {
            title: option.title,
            selected: false,
          }
        }),
      }
    })
    setFilters(filtersWithNoSelections)
    setFiltersData(filtersWithNoSelections)
  }

  const handleFilterClick = (data: FilterClickInput) => {
    const filterBeingUpdated = find(
      filters,
      (filter) => filter.category === data.category
    )
    if (filterBeingUpdated) {
      const updatedOptions = map(filterBeingUpdated.options, (option) => {
        if (option.title === data.option.title) {
          return {
            title: option.title,
            selected: data.option.selected,
          }
        }
        return option
      })

      const updatedFilters = map(filters, (filters) => {
        if (filters.category === data.category) {
          return {
            category: data.category,
            options: updatedOptions,
          }
        }
        return filters
      })
      setFilters(updatedFilters)
    }
  }

  const handleOnCancel = () => {
    setOpen(false)
    setFilters(filtersData)
  }

  const handleOnApply = () => {
    setFiltersData(filters)
    setOpen(false)
  }

  return (
    <Box
      width={1}
      zIndex={200}
      position={'absolute'}
      bgcolor={theme.palette.background.paper}
      border={`1px solid ${theme.palette.background.paper}`}
      sx={{
        boxShadow: open ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
      }}
    >
      <Collapse in={open} collapsedSize={56}>
        <Box pt={1} pb={2} px={2}>
          <Box id="filters-header" display={'flex'} alignItems={'center'}>
            <IconButton
              aria-label="filter"
              onClick={() => setOpen((prev) => !prev)}
              sx={{
                color: open ? 'font.dark' : 'info.dark',
                '&:hover': {
                  color: 'text.primary',
                },
              }}
            >
              <FilterListIcon
                color={numberOfOptionsSelected > 0 ? 'success' : 'inherit'}
              />
            </IconButton>
            <Typography pr={1} variant="subtitle2" color="text.secondary">
              {`Filters: (${numberOfOptionsSelected})`}
            </Typography>
            <Button
              disabled={numberOfOptionsSelected === 0}
              onClick={resetFilters}
              sx={{
                font: '14px',
                fontWeight: theme.typography.fontWeightMedium,
                color: 'text.primary',
                textDecoration: 'underline',
                '&:disabled': { textDecoration: 'none' },
                '&:hover': {
                  background: 'none',
                  textDecoration: 'underline',
                  color: 'primary.main',
                },
              }}
            >
              Reset
            </Button>
          </Box>
          {subtitle && (
            <Box pt={1} pl={1} mb={2}>
              <Typography variant={'caption'}>{subtitle}</Typography>
            </Box>
          )}
          <Box
            id="filters-body"
            display={'flex'}
            flexDirection={
              actionButtonsPlacement === 'right' ? 'row' : 'column'
            }
            justifyContent={'space-between'}
          >
            <Box id="left-column">
              <Filters
                filtersData={filters}
                onFilterClick={handleFilterClick}
              />
            </Box>
            <Box
              id="right-column"
              alignSelf={'flex-end'}
              display={'flex'}
              pt={2}
              mb={1}
              mr={1}
            >
              <BidscaleButton
                id={'filters-cancel-button'}
                buttonVariant="cancel"
                sx={{ mr: 2, height: '34px', width: theme.spacing(16) }}
                onClick={handleOnCancel}
              >
                CANCEL
              </BidscaleButton>
              <BidscaleButton
                id={'filters-apply-button'}
                buttonVariant="execute"
                sx={{ height: '34px', width: theme.spacing(16) }}
                onClick={handleOnApply}
              >
                APPLY
              </BidscaleButton>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}
