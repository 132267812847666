import { Box, Skeleton } from '@mui/material'
import React from 'react'

const AcquisitionTrackerPageSkeleton = (): JSX.Element => {
  return (
    <Box display="flex" width="100%" height="100vh" flexDirection="column">
      <Box width="45%" height="80px" my={3} mx={2}>
        <Skeleton
          variant="rectangular"
          height="100%"
          width="100%"
          animation="wave"
        />
      </Box>

      <Box
        flex={1}
        mt={3}
        mb={5}
        ml={2}
        mr={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rectangular"
          height="35%"
          width="100%"
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          height="25%"
          width="100%"
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          height="35%"
          width="100%"
          animation="wave"
        />
      </Box>
    </Box>
  )
}

export default AcquisitionTrackerPageSkeleton
