import { Box, SxProps, Theme, Typography } from '@mui/material'
import { ReactComponent as AreaChartIcon } from 'assets/areaChartIcon.svg'
import { ReactComponent as CalendarIcon } from 'assets/calendarIcon.svg'
import { IconButton } from 'components/Buttons/IconButton'
import { InfoButton } from 'components/InfoButton'
import { KpiLarge } from 'components/Kpi/LargeKpi'
import { PageHeader } from 'components/PageHeader'
import { PageSection } from 'components/PageSection'
import { ControlledFilters } from 'components/RadioGroup/ControlledFilters/'
import { VerticalDividerUtility } from 'components/VerticalDividerUtility'
import { format } from 'date-fns'
import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { theme } from 'theme'

import { ActiveInitiativesTable } from '../ActiveInitiativesTable'
import ExecutiveAreaChart from '../ExecutiveAreaChart'
import ExecutiveCalendarContainer from '../ExecutiveCalendarContainer'
import { useAcquisitionTrackerTransformationReturn } from '../state/useAcquisitionTrackerTransformation'

enum IconVariant {
  AREA_CHART = 'Area Chart',
  CALENDAR = 'Calendar',
}

const PageSubtitleAndIndexMapping: Record<number, string> = {
  0: 'Acquisition Score Over Time',
  1: 'Contracting Actions Calendar',
}

interface Props {
  state: useAcquisitionTrackerTransformationReturn
  lastUpdated?: Date
}

const Page = ({ state, lastUpdated }: Props): JSX.Element => {
  const [index, setIndex] = React.useState(0)
  const handleClick = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setIndex(value === IconVariant.AREA_CHART ? 0 : 1)
  }
  const updatedDate = (lastUpdated: Date) => {
    return (
      <Box display="flex" id="last-updated">
        <Typography variant="caption" color="text.secondary">
          {`Last Updated ${format(new Date(lastUpdated), 'yyyy-MM-dd')}`}
        </Typography>
      </Box>
    )
  }

  const [infoOpen, setInfoOpen] = useState('')

  return (
    <Box display="flex" flex={1} ml={1} flexDirection="column" mr={4}>
      <Box mt={3.5}>
        <PageHeader header="Portfolio Overview" />
      </Box>
      {state.filtersData.some((item) => item.options.length) && (
        <Box position={'relative'} mb={8} mx={-3}>
          <ControlledFilters
            filtersData={state.filtersData}
            setFiltersData={state.setFiltersData}
            actionButtonsPlacement={'right'}
            subtitle="*Selected filters apply to entire page"
          />
        </Box>
      )}
      <PageSection
        header={PageSubtitleAndIndexMapping[index]}
        inlineLeftChildren={
          <>
            <InfoButton
              openInfoLabel={infoOpen}
              setOpenInfoLabel={setInfoOpen}
              label="acquisition-score-chart-info"
              sx={{ marginRight: 1.5, marginLeft: -1.5 }}
              popperSx={{ width: 'max-content' }}
              disablePortal={true}
            >
              <Typography
                variant="caption"
                sx={{
                  display: 'block',
                  textAlign: 'left',
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              >
                The composite score shown below is based on a combination of the
                following input metrics: Value, Type, Competition, Novelty, Risk
                to Mission, Urgency, Priority Visibility, Value to PEO. The
                values for these metrics were created and collected in
                coordination with CD-DHMS members.
              </Typography>
            </InfoButton>
            {lastUpdated && updatedDate(lastUpdated)}
          </>
        }
        inlineRightChildren={
          <TrackerIconButton handleClick={handleClick} index={index} />
        }
      >
        <Box width="100%" mb={1} id="acquisition-caraousel-container">
          <Carousel
            animation="slide"
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysInvisible={true}
            index={index}
            swipe={false}
          >
            <Box width="100%" height="338px" id="executive-area-chart">
              <ExecutiveAreaChart areaChartProps={state.areaChartProps} />
            </Box>
            <Box width="100%" height="338px" id="executive-calendar">
              <ExecutiveCalendarContainer
                date={new Date()}
                calendarDates={state.calendarDates}
                maxActions={50}
              />
            </Box>
          </Carousel>
        </Box>

        <Box display="flex" justifyContent="center" width="100%">
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            pt={3}
            pb={5}
            id="kpi-indicators"
          >
            <VerticalDividerUtility>
              {state.keyPerformanceIndicatorsData.map((kpiData, index) => (
                <Box display="flex" key={'kpi-large-' + index}>
                  <KpiLarge
                    {...kpiData}
                    labelSx={styles.kpiLabel}
                    subtitleSx={styles.kpiSubtitle}
                  />
                </Box>
              ))}
            </VerticalDividerUtility>
          </Box>
        </Box>
      </PageSection>

      <PageSection header="Initiative Details">
        <ActiveInitiativesTable initiatives={state.getFilteredInitiatives()} />
      </PageSection>
    </Box>
  )
}

type TrackerIconButtonProps = {
  index: number
  handleClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => void
}

const TrackerIconButton = (props: TrackerIconButtonProps): JSX.Element => (
  <Box display="flex" justifyContent="flex-end" alignItems="center">
    <Typography variant="body2">View</Typography>
    <IconButton
      arrIcons={[
        { value: IconVariant.AREA_CHART, avatarIcon: <AreaChartIcon /> },
        {
          value: IconVariant.CALENDAR,
          avatarIcon: <CalendarIcon />,
        },
      ]}
      select={props.index === 0 ? IconVariant.AREA_CHART : IconVariant.CALENDAR}
      handleClick={props.handleClick}
    />
  </Box>
)

const styles: Record<string, SxProps<Theme>> = {
  kpiLabel: {
    fontSize: 72,
    fontWeight: 700,
    letterSpacing: '0.05em',
  },
  kpiSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    width: 144,
  },
}

export default Page
