import { IconButton } from '@mui/material'
import { ReactComponent as SortButtonIcon } from 'assets/sortButtonIcon.svg'
import { theme } from 'theme'

export type SortDirection = 'asc' | 'desc'
export interface SortButtonProps {
  disabled: boolean
  order?: SortDirection
}

export const SortButton = ({
  disabled,
  order,
}: SortButtonProps): JSX.Element => {
  return (
    <IconButton
      id="sort-button"
      sx={{
        height: '36px',
        width: '36px',
        '& .topArrow': {
          fill:
            order === 'asc' && !disabled
              ? theme.palette.text.primary
              : theme.palette.info.dark,
        },
        '& .bottomArrow': {
          fill:
            order === 'desc' && !disabled
              ? theme.palette.text.primary
              : theme.palette.info.dark,
        },
      }}
      disabled={disabled}
    >
      <SortButtonIcon />
    </IconButton>
  )
}
