import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import { Alert, alpha, Box, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { theme } from 'theme'

export type MessageAlertVariants = 'error' | 'warning'

interface IMessageAlertDetail {
  icon: JSX.Element
  backgroundColor: string
  border: string
  defaultMessage: string
}

type IMessageAlertDetails = Record<MessageAlertVariants, IMessageAlertDetail>

const MessageAlertDetails: IMessageAlertDetails = {
  error: {
    icon: <WarningIcon fontSize="inherit" sx={{ color: 'error.dark' }} />,
    backgroundColor: alpha(theme.palette.error.light, 0.15),
    border: `3px solid ${theme.palette.error.light}`,
    defaultMessage: 'There are items that require your attention',
  },
  warning: {
    icon: <ErrorIcon fontSize="inherit" sx={{ color: 'warning.dark' }} />,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    border: `3px solid ${theme.palette.warning.dark}`,
    defaultMessage:
      'Before you can submit this review, you have tasks that need attention',
  },
}

export interface Props {
  variant: MessageAlertVariants
  message?: string
  sx?: SxProps<Theme>
  handleOnClose?: () => void
}

const MessageAlert = ({
  variant,
  message,
  sx,
  handleOnClose,
}: Props): JSX.Element => {
  return (
    <Alert
      id={`${variant}-alert`}
      sx={
        [
          {
            backgroundColor: MessageAlertDetails[variant].backgroundColor,
            border: MessageAlertDetails[variant].border,
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
            px: 3,
            py: 2,
            '& > div': {
              p: 0,
              m: 0,
            },
            '& .MuiAlert-icon': {
              pr: 2,
            },
            '& .MuiAlert-message': {
              flex: 1,
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ] as SxProps<Theme>
      }
      severity={variant}
      icon={MessageAlertDetails[variant].icon}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography color="text.primary" variant="body2">
          {message ?? MessageAlertDetails[variant].defaultMessage}
        </Typography>
        {handleOnClose && (
          <CloseIcon
            onClick={handleOnClose}
            sx={{
              color: '#1F1F1F',
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
    </Alert>
  )
}

export { MessageAlert }
