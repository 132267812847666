import { Box, SxProps, Theme, Typography } from '@mui/material'
import { ReactComponent as PersonIcon } from 'assets/personIcon.svg'
import { box_shadow } from 'constants/styles'
import React from 'react'
import { theme } from 'theme'

export interface GenericCommentBoxProps {
  title?: string
  name?: string
  date?: string
  hasLeftBar?: boolean
  sx?: SxProps<Theme>
}

export interface Props {
  inlineChildren?: React.ReactNode
  children?: React.ReactNode
}

export const GenericCommentBox = ({
  title = 'Criteria Comment',
  name,
  date,
  hasLeftBar = true,
  sx,
  inlineChildren,
  children,
}: Props & GenericCommentBoxProps): JSX.Element => {
  return (
    <Box
      p={2}
      borderLeft={3}
      borderColor={hasLeftBar ? 'primary.main' : 'info.light'}
      sx={
        [
          {
            backgroundColor: 'background.paper',
            '&:hover': box_shadow,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ] as SxProps<Theme>
      }
      id="generic-comment-box"
    >
      <Box display="flex" alignItems="center">
        <Box mr={2} id="avatar">
          <PersonIcon />
        </Box>

        <Box display="flex" flex={1} justifyContent="space-between">
          <Box id="criteria-comment-profile">
            <Typography variant="body1" textTransform="capitalize">
              {title}
            </Typography>
            <Box display="flex">
              {name && (
                <Typography variant="body2">
                  {name} {date && '-'}
                </Typography>
              )}
              {date && (
                <Typography
                  variant="body2"
                  fontWeight={theme.typography.fontWeightLight}
                  pl={1}
                >
                  {date}
                </Typography>
              )}
            </Box>
          </Box>

          {inlineChildren}
        </Box>
      </Box>

      {children}
    </Box>
  )
}
