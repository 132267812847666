import ContractsOverviewSkeleton from './ContractsOverviewSkeleton'
import { Page } from './Page'
import { useContractsOverviewState } from './state/useContractsOverviewState'

const ContractsOverview = (): JSX.Element => {
  const { contracts, isFetching } = useContractsOverviewState()

  if (isFetching) {
    return <ContractsOverviewSkeleton />
  } else {
    return <Page contracts={contracts} isFetching={isFetching} />
  }
}

export { ContractsOverview }
