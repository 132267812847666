import {
  alpha,
  Box,
  ClickAwayListener,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { ReactComponent as CommentIcon } from 'assets/discoveryCommentIcon.svg'
import { ReactComponent as CommentIconActive } from 'assets/discoveryCommentIconActive.svg'
import { ReactComponent as CommentIconDisabled } from 'assets/discoveryCommentIconDisabled.svg'
import React, { useState } from 'react'
import { theme } from 'theme'

export interface Props {
  forceActive?: boolean
  keepActive?: boolean
  disabled?: boolean
  children?: React.ReactNode
  secondaryChildren?: React.ReactNode
  flagged?: boolean
  setActive?: (input: boolean) => void
  editingOrDeleting: boolean
  progressBar?: boolean
}

export const GenericDiscoveryBox = ({
  forceActive,
  keepActive,
  disabled,
  children,
  secondaryChildren,
  flagged,
  setActive: setContentActive,
  editingOrDeleting,
  progressBar,
}: Props): JSX.Element => {
  const [active, setActive] = useState(forceActive || false)

  const handleSetActive = (input: boolean) => {
    if (!forceActive && !keepActive) {
      setActive(input)
      setContentActive?.(input)
    }
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        active && !keepActive && handleSetActive(false)
      }}
    >
      <Box
        role="presentation"
        id={'generic-discovery-box'}
        sx={
          [
            {
              p: 3,
              border: flagged
                ? `2px solid ${theme.palette.secondary.main}`
                : `1px solid ${theme.palette.info.main}`,
              background: `${
                flagged ? alpha(theme.palette.secondary.light, 0.1) : '1px'
              } `,
            },
            !disabled &&
              !keepActive && {
                '&:hover': {
                  cursor: 'pointer',
                  border: `${flagged ? '2px' : '1px'}  solid ${
                    flagged
                      ? theme.palette.secondary.main
                      : alpha(theme.palette.info.dark, 0.6)
                  }`,
                  boxShadow: `0px 3px 7px ${
                    flagged
                      ? alpha(theme.palette.secondary.main, 0.5)
                      : alpha(theme.palette.info.dark, 0.4)
                  }`,
                },
              },
          ] as SxProps<Theme>
        }
        onClick={() => {
          handleSetActive(!active)
        }}
      >
        <Box id={'first-row'} display={'flex'}>
          <Box
            id={'icon-column'}
            mr={2}
            sx={{
              '& .background': {
                fill: flagged ? theme.palette.secondary.main : '',
              },
              '& .comment': {
                fill: flagged ? '#FFFFFF' : '',
              },
            }}
          >
            {disabled ? (
              <CommentIconDisabled />
            ) : active ? (
              <CommentIconActive />
            ) : (
              <CommentIcon />
            )}
          </Box>
          <Box id={'main-column'} width={1}>
            {disabled ? (
              <Typography variant="h6" color="info.dark">
                No Discoveries
              </Typography>
            ) : (
              children
            )}
          </Box>
        </Box>
        {(!disabled && active && secondaryChildren) ||
          (editingOrDeleting && (
            <Box id={'second-row'} mt={3}>
              {secondaryChildren}
            </Box>
          ))}
        {progressBar && (
          <Box mx={-3} mb={-3} mt={3}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  )
}
