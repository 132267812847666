import { Box } from '@mui/material'
import AreaChart, {
  Props as AreaChartProps,
} from 'components/analytics/AreaChart'

export interface Props {
  areaChartProps?: AreaChartProps
}

//TODO: Update colors from theme
const ExecutiveAreaChart = ({ areaChartProps }: Props): JSX.Element => {
  const colorPalette = ['#95D4E8', '#838383', '#1B7791', '#003141', '#f7BD26']

  const chartProps = areaChartProps
    ? {
        ...areaChartProps,
        chartOptions: {
          colorPalette: colorPalette,
          colorMap: {},
          xAxis: {
            xAxis1: 'month',
          },
          showLegend: true,
        },
      }
    : undefined

  return (
    <>
      {chartProps && (
        <Box
          id="executive-dashboard-area-chart"
          width={'100%'}
          height={'100%'}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Box flexGrow={1}>
            <AreaChart {...chartProps} />
          </Box>
        </Box>
      )}
    </>
  )
}

export default ExecutiveAreaChart
