import { Box } from '@mui/material'
import { ActivityFeed, ActivityFeedView } from 'components/ActivityFeed'
import TaskAccordion from 'components/TaskAccordion'
import { TaskStatus } from 'components/TaskCard'
import { EvaluationTask, EvaluationTaskStatus } from 'generated/graphql'

import ActionsMessage from './ActionsMessage'

interface Props {
  tasks?: EvaluationTask[]
  activityFeed?: ActivityFeed[]
}

export const MapGqlEnumToTaskStatusEnum = (
  task: EvaluationTaskStatus
): TaskStatus => {
  if (task === EvaluationTaskStatus.NotStarted) {
    return TaskStatus.NOT_STARTED
  } else if (task === EvaluationTaskStatus.Started) {
    return TaskStatus.ACTIVE
  } else if (task === EvaluationTaskStatus.NotReleased) {
    return TaskStatus.AT_RISK
  } else if (task === EvaluationTaskStatus.Completed) {
    return TaskStatus.COMPLETED
  }
  return TaskStatus.SOLICITATION
}

function SolicitationDashboard({ tasks, activityFeed }: Props): JSX.Element {
  const tasksDue = tasks?.filter((task) => task.status !== 'COMPLETED')
  const completedTasks = tasks?.filter((task) => task.status === 'COMPLETED')
  return (
    <Box display="flex" pt={1} width="100%">
      <Box display="flex" flexDirection="column" width="60%" pr={4}>
        <ActionsMessage tasks={tasksDue} />
        <TaskAccordion
          accordions={[
            {
              accordionTitle: 'Tasks Due',
              tasks: tasksDue?.map((task) => ({
                id: task.id,
                variant: MapGqlEnumToTaskStatusEnum(task.status),
                title: task.name,
                subtitle: task.description,
                vendor: task.proposal.organization,
                dueDate: task.dueAt,
                taskTypes: task.taskType,
                linkTo: `/solicitations/${encodeURIComponent(
                  task.proposal.solicitationId
                )}/evaluations/${encodeURIComponent(task.id)}`,
                newTab: true,
              })),
              emptyMessage: 'You have no tasks due at this time',
            },
            {
              accordionTitle: 'Completed Tasks',
              tasks: completedTasks?.map((task) => ({
                id: task.id,
                variant: MapGqlEnumToTaskStatusEnum(task.status),
                title: task.name,
                subtitle: task.description,
                vendor: task.proposal.organization,
                dueDate: task.dueAt,
                taskTypes: task.taskType,
              })),
              emptyMessage: 'You have no completed tasks at this time',
            },
          ]}
        />
      </Box>
      <Box width="40%" pl={4}>
        <ActivityFeedView items={activityFeed} />
      </Box>
    </Box>
  )
}

export default SolicitationDashboard
