import { Box, Chip, Stack, Typography } from '@mui/material'
import { FilePickerButton } from 'components/Buttons/FilePickerButton'
import { PageSection } from 'components/PageSection'
import { FileTag, Maybe } from 'generated/graphql'
import { useSolicitationRole } from 'hooks/useSolicitationRole'
import { filter } from 'lodash'
import { UploadDocumentContext } from 'pages/DocumentManager/providers/UploadDocumentProvider'
import UploadDocument from 'pages/DocumentManager/UploadDocument'
import { useContext, useState } from 'react'
import { FileContent } from 'use-file-picker/dist/interfaces'

import { DocumentsTable } from '../DocumentsTable'
import { ReportsTable } from '../ReportsTable'
import { DocumentManagerReturn } from '../state/useDocumentManagerState'

export interface Props extends DocumentManagerReturn {
  generateReport: (id: string) => Promise<unknown>
  deleteFile: (fileId: string, proposalId?: string) => Promise<boolean>
  downloadFile: (fileId: string) => void
  editFile: (id: string, name: string, tag?: Maybe<FileTag>) => Promise<boolean>
}

export const Page = ({
  reportsTableData,
  documentsTableData,
  generateReport,
  deleteFile,
  editFile,
  downloadFile,
  solicitation,
}: Props): JSX.Element => {
  const [filterState, setFilterState] = useState<FileTag | undefined>()

  const { isConsensus } = useSolicitationRole(solicitation)
  const {
    uploadFile,
    uploadProgress,
    fileDetails,
    uploadCompleted,
    resetUploadError,
    showUploadError,
  } = useContext(UploadDocumentContext)

  const inlineLeftChildren = () => (
    <Stack direction="row" spacing={1} display="flex">
      {filterState === undefined ? (
        <SelectedChip label="All Files" />
      ) : (
        <UnselectedChip
          label="All Files"
          onClick={() => setFilterState(undefined)}
        />
      )}
      {filterState === FileTag.Rfp ? (
        <SelectedChip label="RFP" />
      ) : (
        <UnselectedChip
          label="RFP"
          onClick={() => setFilterState(FileTag.Rfp)}
        />
      )}
      {filterState === FileTag.Admin ? (
        <SelectedChip label="Admin" />
      ) : (
        <UnselectedChip
          label="Admin"
          onClick={() => setFilterState(FileTag.Admin)}
        />
      )}
      {filterState === FileTag.Training ? (
        <SelectedChip label="Training" />
      ) : (
        <UnselectedChip
          label="Training"
          onClick={() => setFilterState(FileTag.Training)}
        />
      )}
    </Stack>
  )
  //TODO: Replace with IconButtons when ready
  const inlineRightChildren = () => (
    <Box display="flex" justifyContent="flex-end">
      <FilePickerButton
        disabled={!!(uploadProgress && fileDetails)}
        onFilesSelected={(fileContent: FileContent[]) =>
          uploadFile?.(fileContent)
        }
      />
    </Box>
  )

  return (
    <>
      <PageSection
        header="Documents"
        inlineLeftChildren={inlineLeftChildren()}
        inlineRightChildren={inlineRightChildren()}
      >
        <UploadDocument
          uploadProgress={uploadProgress}
          fileDetails={fileDetails}
          uploadCompleted={uploadCompleted}
          showUploadError={showUploadError}
          resetUploadError={resetUploadError}
        />
        <DocumentsTable
          documentsTableData={
            filterState
              ? filter(documentsTableData, (row) => row.tag === filterState)
              : documentsTableData
          }
          deleteFile={deleteFile}
          editFile={editFile}
          downloadFile={downloadFile}
        />
      </PageSection>

      {isConsensus && (
        <PageSection header="Reports">
          <ReportsTable
            reportsTableData={reportsTableData}
            generateReport={generateReport}
          />
        </PageSection>
      )}
    </>
  )
}

const SelectedChip = ({ label }: { label: string }): JSX.Element => (
  <Chip
    label={
      <Typography variant="body2" color="info.light">
        {label}
      </Typography>
    }
    color="primary"
  />
)

const UnselectedChip = ({
  label,
  onClick,
}: {
  label: string
  onClick: () => void
}): JSX.Element => (
  <Chip
    label={<Typography variant="body2">{label}</Typography>}
    variant="outlined"
    onClick={onClick}
  />
)
