import { Box, CircularProgress } from '@mui/material'
import React from 'react'

interface BoxSize {
  height: number
  width: number
}

const LoadingPage: React.FC<{
  boxSize: BoxSize
  className?: string
}> = ({ boxSize, className }): JSX.Element => {
  return (
    <Box
      className={className}
      sx={{
        width: boxSize.width,
        height: boxSize.height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  )
}

export { LoadingPage }
