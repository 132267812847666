import {
  Button,
  ButtonProps,
  CircularProgress,
  SxProps,
  Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useState } from 'react'
import { theme } from 'theme'

type ButtonVariant = 'execute' | 'cancel' | 'delete' | 'secondary'

export interface Props extends ButtonProps {
  buttonVariant: ButtonVariant
  activityIndicator?: boolean
  indicatorSize?: string | number
}

const BidscaleButton = ({
  buttonVariant,
  sx,
  children,
  activityIndicator,
  ...props
}: Props): JSX.Element => {
  const [inverted, setInverted] = useState(false)

  const clicked = buttonVariant + 'Inversion'
  const font = buttonVariant + 'Font'

  const invertedStyle = inverted ? styles[clicked] : styles[buttonVariant]

  return (
    <Button
      id="button"
      sx={
        [
          {
            '& .MuiTouchRipple-rippleVisible span': {
              backgroundColor: 'info.dark',
            },
          },
          styles.button,
          invertedStyle,
          ...(Array.isArray(sx) ? sx : [sx]),
        ] as SxProps<Theme>
      }
      //rest of props must be passed before custom prop usage for overwrite
      {...props}
      onMouseDown={() => setInverted(true)}
      onMouseUp={() => setInverted(false)}
      onMouseLeave={() => setInverted(false)}
      disabled={props.disabled || activityIndicator}
    >
      <Typography
        variant="button"
        display="flex"
        textTransform={'capitalize'}
        sx={styles[font]}
      >
        {activityIndicator ? (
          <CircularProgress
            thickness={7.2}
            size={props.indicatorSize ?? theme.spacing(4)}
            color="inherit"
          />
        ) : (
          children
        )}
      </Typography>
    </Button>
  )
}

type ButtonPropsWithoutVariant = Omit<Props, 'buttonVariant'>
const ExecuteButton = (props: ButtonPropsWithoutVariant): JSX.Element => (
  <BidscaleButton buttonVariant="execute" {...props} />
)
const SecondaryButton = (props: ButtonPropsWithoutVariant): JSX.Element => (
  <BidscaleButton buttonVariant="secondary" {...props} />
)
const CancelButton = (props: ButtonPropsWithoutVariant): JSX.Element => (
  <BidscaleButton buttonVariant="cancel" {...props} />
)
const DeleteButton = (props: ButtonPropsWithoutVariant): JSX.Element => (
  <BidscaleButton buttonVariant="delete" {...props} />
)

export {
  BidscaleButton,
  ExecuteButton,
  SecondaryButton,
  CancelButton,
  DeleteButton,
}

const styles: Record<string, SxProps<Theme>> = {
  button: {
    width: '148.19px',
    height: '48px',
    border: '4px solid',
    borderRadius: '2px',
    whiteSpace: 'nowrap',
    backgroundClip: 'padding-box',
  },
  execute: {
    backgroundColor: 'primary.main',
    border: `4px solid`,
    '&:disabled': {
      backgroundColor: 'primary.main',
      borderColor: 'primary.main',
      opacity: 0.35,
    },
    '&:hover': {
      backgroundColor: 'primary.dark',
      borderColor: 'primary.dark',
    },
  },
  executeFont: {
    color: 'info.light',
  },
  executeInversion: {
    '&:hover': {
      backgroundColor: 'primary.main',
      fontColor: 'info.light',
      border: `4px solid`,
      borderColor: 'rgba(38,170,148,0.3)',
    },
  },
  secondary: {
    backgroundColor: 'secondary.main',
    border: `4px solid`,
    borderColor: 'secondary.main',
    '&:disabled': {
      backgroundColor: 'secondary.light',
      borderColor: 'secondary.light',
      opacity: 0.35,
    },
    '&:hover': {
      backgroundColor: 'secondary.dark',
      borderColor: 'secondary.dark',
    },
  },
  secondaryInversion: {
    '&:hover': {
      backgroundColor: 'secondary.main',
      fontColor: 'info.light',
      border: `4px solid`,
      borderColor: 'rgba(248, 189, 37, 0.35)',
    },
  },
  cancel: {
    backgroundColor: 'info.light',
    border: `1px solid`,
    borderColor: 'info.dark',
    '&:disabled': {
      backgroundColor: 'info.light',
      opacity: 0.35,
    },
    '&:hover': {
      backgroundColor: 'info.main',
      borderColor: 'info.dark',
    },
  },
  cancelInversion: {
    '&:hover': {
      fontColor: 'text.primary',
      border: '4px solid',
      backgroundColor: 'info.light',
      borderColor: 'info.dark',
    },
  },
  delete: {
    backgroundColor: 'error.main',
    borderColor: 'error.main',
    '&:disabled': {
      backgroundColor: 'error.main',
      opacity: 0.35,
    },
    '&:hover': {
      backgroundColor: 'error.dark',
      borderColor: 'error.dark',
    },
  },
  deleteFont: {
    color: (theme) => `${theme.palette.info.light}!important`,
  },
  deleteInversion: {
    '&:hover': {
      fontColor: 'info.light',
      border: '4px solid',
      backgroundColor: 'error.main',
      borderColor: 'rgba(217, 45, 32, 0.3)',
    },
  },
}
