import { Auth } from 'aws-amplify'
import ForgotPasswordForm from 'pages/Login/Authentication/ForgotPassword/ForgotPasswordForm'
import TwoStepAuthentication from 'pages/Login/Authentication/TwoStepAuthentication'
import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ResetPasswordForm } from './ResetPasswordForm'

type ForgotPasswordError = {
  code: string
  message: string
  name: string
}

export type ForgotPasswordSuccess = {
  CodeDelivery: {
    AttributeName: string
    DeliveryMedium: 'SMS' | 'Email'
    Destination: string
  }
}

enum Form {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  MFA_CODE,
}

const ForgotPassword = (): JSX.Element => {
  const forgotPasswordFormHook = useForm<FieldValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const resetPasswordFormHook = useForm<FieldValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [isCodeResent, setIsCodeResent] = useState(false)
  const [showForm, setShowForm] = useState(Form.FORGOT_PASSWORD)
  const navigate = useNavigate()

  useEffect(() => {
    resetPasswordFormHook.register('mfa', {
      required: true,
      pattern: /^\d{6}$/,
    })
  }, [resetPasswordFormHook])

  const setMfaErrorsAndClearFields = () => {
    resetPasswordFormHook.setError('mfa', {})
    setShowForm(Form.MFA_CODE)
  }

  const setResetPasswordErrors = () => {
    resetPasswordFormHook.setError('newpassword', {})
    resetPasswordFormHook.setError('verifypassword', {})
  }

  const onForgotPasswordSubmit = (data: FieldValues) => {
    Auth.forgotPassword(data.email)
      .then((_data: ForgotPasswordSuccess) => setShowForm(Form.MFA_CODE))
      .catch((err: ForgotPasswordError) => {
        forgotPasswordFormHook.setError('email', {
          type: err.code,
          message: err.message,
        })
        forgotPasswordFormHook.setValue('email', '')
      })
  }

  const handleCodeSubmission = (_data: FieldValues) => {
    setShowForm(Form.RESET_PASSWORD)
  }

  const handleResetPasswordSubmission = async (data: FieldValues) => {
    try {
      await Auth.forgotPasswordSubmit(
        forgotPasswordFormHook.getValues('email'),
        resetPasswordFormHook.getValues('mfa'),
        data.newpassword
      )
      navigate('/login')
    } catch (err) {
      const errorCode = err as { code: string }
      if (errorCode.code && errorCode.code === 'CodeMismatchException') {
        setMfaErrorsAndClearFields()
      } else {
        setResetPasswordErrors()
      }
    }
  }

  const handleResendCode = async () => {
    try {
      const response = await Auth.forgotPassword(
        forgotPasswordFormHook.getValues('email')
      )
      setIsCodeResent(!!response.CodeDeliveryDetails)
    } catch (err) {
      setIsCodeResent(false)
    }
  }

  return (
    <>
      {showForm === Form.FORGOT_PASSWORD && (
        <ForgotPasswordForm
          useFormHook={forgotPasswordFormHook}
          handleSubmission={onForgotPasswordSubmit}
        />
      )}

      {showForm === Form.MFA_CODE && (
        <TwoStepAuthentication
          email={forgotPasswordFormHook.getValues('email')}
          handleResendCode={handleResendCode}
          handleSubmission={handleCodeSubmission}
          inputName="mfa"
          useFormHook={resetPasswordFormHook}
          isCodeResent={isCodeResent}
        />
      )}

      {showForm === Form.RESET_PASSWORD && (
        <ResetPasswordForm
          handleSubmission={handleResetPasswordSubmission}
          useFormHook={resetPasswordFormHook}
        />
      )}
    </>
  )
}

export default ForgotPassword
