import { InputLabel, Typography } from '@mui/material'

export interface Props {
  label: string
}

const SelectionLabel = ({ label }: Props): JSX.Element => {
  return (
    <InputLabel
      htmlFor={label.toLowerCase().trim().replace(/\s+/g, '-')}
      shrink={false}
      sx={{
        transform: 'translate(3px, 20px) scale(1)',
      }}
    >
      <Typography variant="subtitle2">{label}</Typography>
    </InputLabel>
  )
}

export default SelectionLabel
