import { Chip as MuiChip, SxProps, Theme } from '@mui/material'
import { no_select } from 'constants/styles'
import { theme } from 'theme'

interface Props {
  label?: string
  onClick?: () => void
  sx?: SxProps<Theme>
  hoverSx?: SxProps<Theme>
}

export const Chip = ({ sx, ...props }: Props): JSX.Element => {
  return (
    <MuiChip
      label={props.label}
      onClick={props.onClick}
      sx={
        [
          {
            height: theme.spacing(3),
            maxWidth: 400,
            textTransform: 'capitalize',
            '.MuiChip-label': {
              px: 1,
            },
            ':hover': props?.hoverSx ?? sx,
            ...no_select,
            ...theme.typography.caption,
          },
          sx,
        ] as SxProps<Theme>
      }
    />
  )
}
