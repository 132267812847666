import { ActionMenuItem, ActionMenuPopper } from 'components/ActionMenuPopper'
import { KebabButton } from 'components/Buttons/KebabButton'
import React from 'react'

export interface Props {
  items?: ActionMenuItem[]
  onClick?: (e: React.MouseEvent) => void
}

export const ActionMenuKebab = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<Element>()
  const [open, setOpen] = React.useState(false)

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (props.items && !anchorEl) {
      setAnchorEl(e.currentTarget as Element)
      setOpen(true)
    }

    props.onClick?.(e)
    !!anchorEl && setOpen(!open)
  }

  return (
    <>
      <KebabButton onClick={handleClick} />
      <ActionMenuPopper
        anchorEl={anchorEl}
        isOpen={open}
        setOpen={setOpen}
        actionItems={props?.items ?? []}
      />
    </>
  )
}
