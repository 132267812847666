import { EvaluationTask } from 'generated/graphql'

import {
  TotalDiscovery,
  UpdateDiscovery,
} from '../state/useEvaluationPageState'
import { SummaryDrawerContent } from './SummaryDrawerContent'

export interface Props extends UpdateDiscovery {
  task: EvaluationTask
  discoveries: TotalDiscovery[]
}

const SummaryDrawer = ({
  task,
  discoveries,
  editDiscovery,
  deleteDiscovery,
  flagDiscovery,
}: Props): JSX.Element => {
  return (
    <SummaryDrawerContent
      task={task}
      discoveries={discoveries}
      editDiscovery={editDiscovery}
      deleteDiscovery={deleteDiscovery}
      flagDiscovery={flagDiscovery}
    />
  )
}

export { SummaryDrawer }
