import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { format } from 'date-fns'
import React, { ReactNode } from 'react'
import { theme } from 'theme'

const PREFIX = 'PageSection'

const classes = {
  lastUpdated: `${PREFIX}-lastUpdated`,
  divider: `${PREFIX}-divider`,
}

const StyledBox = styled(Box)({
  paddingBottom: theme.spacing(3),
  [`& .${classes.lastUpdated}`]: {
    color: theme.typography.h2.color,
  },
  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.info.light,
  },
})

export interface Props {
  children?: ReactNode
  header?: string
  inlineLeftChildren?: ReactNode
  inlineRightChildren?: ReactNode
  lastUpdated?: Date
  leftProportion?: number
  rightProportion?: number
}

export const PageSection = (props: Props): JSX.Element => {
  return (
    <StyledBox id="page-section">
      <Grid pb={1} id="page-section-header" container>
        <Grid
          item
          xs={props.leftProportion ? props.leftProportion : 8}
          alignItems="center"
          container
        >
          {props.header && (
            <Typography
              sx={{ float: 'left' }}
              id="page-section-header-typography"
              variant="h6"
              mr={3}
            >
              {props.header}
            </Typography>
          )}
          {props.inlineLeftChildren}
        </Grid>

        <Grid
          item
          id="page-section-header-inline-children"
          xs={props.rightProportion ? props.rightProportion : 4}
        >
          <Box sx={{ float: 'right', width: '100%' }}>
            {props.inlineRightChildren}
          </Box>
        </Grid>
      </Grid>

      {props.lastUpdated ? (
        <Box pl={3.5} pb={4} id="page-section-last-updated">
          <Typography
            id="page-section-last-updated-typography"
            variant="h6"
            className={classes.lastUpdated}
          >
            {`Last Updated ${format(
              new Date(props.lastUpdated),
              'yyyy-MM-dd'
            )}`}
          </Typography>
        </Box>
      ) : (
        <Box pb={3} id="page-section-no-last-updated" />
      )}
      {React.Children.map(props.children, (child) =>
        child ? <PageItem>{child}</PageItem> : null
      )}
    </StyledBox>
  )
}

interface ItemProps {
  children: ReactNode
}

const PageItem = (props: ItemProps): JSX.Element => {
  return (
    <Box
      display="flex"
      pb={2}
      justifyContent="flex-start"
      alignItems="center"
      id="page-section-item"
    >
      {props.children}
    </Box>
  )
}
