import { Box, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import { MessageAlert } from 'components/MessageAlert'
import MultiLineTextField from 'components/MultiLineTextField'
import React, { useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

type Props = {
  requestingChange: boolean
  setRequestingChange: (requestingChange: boolean) => void
  formHook: UseFormReturn<FieldValues>
  onSave: () => Promise<void>
}

const RequestChange = ({
  requestingChange,
  setRequestingChange,
  formHook,
  onSave,
}: Props): JSX.Element | null => {
  const [activityIndicator, setActivityIndicator] = useState(false)
  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = formHook
  const handleCancel = () => {
    setValue('RequestChange', null)
    setRequestingChange(false)
    clearErrors()
  }
  const handleRequestChange = async () => {
    setActivityIndicator(true)
    await onSave()
    setRequestingChange(false)
    setActivityIndicator(false)
  }
  const hasErrors = !(
    Object.keys(errors).length === 0 && errors.constructor === Object
  )

  return requestingChange ? (
    <Box
      id="discovery-request-change-body"
      mt={4}
      mr={2}
      onClick={(e) => e.stopPropagation()}
    >
      <Box sx={{ borderTop: `1px solid ${theme.palette.info.main}` }} pt={3}>
        <Typography
          variant="body1"
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          Request Change
        </Typography>
      </Box>
      {hasErrors && <MessageAlert variant="error" />}
      <Box pt={2} pb={3}>
        <MultiLineTextField
          rows={5}
          variant={'outlined'}
          title={'Add comment'}
          name={'RequestChange'}
          placeholder={'Enter comment'}
          useFormHook={formHook}
          rules={{ required: 'This field is required' }}
        />
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <BidscaleButton
          id={'discovery-view-edit-comment-cancel-button'}
          buttonVariant="cancel"
          sx={{ mr: 3, width: '164px', height: '40px' }}
          onClick={handleCancel}
        >
          CANCEL
        </BidscaleButton>

        <BidscaleButton
          id={'discovery-view-edit-comment-delete-button'}
          buttonVariant="execute"
          onClick={formHook.handleSubmit(handleRequestChange)}
          sx={{ width: '148px', height: '40px' }}
          activityIndicator={activityIndicator}
        >
          Request Change
        </BidscaleButton>
      </Box>
    </Box>
  ) : null
}

export default RequestChange
