import { devtoolsExchange } from '@urql/devtools'
import { authExchange } from '@urql/exchange-auth'
import { cacheExchange, Data } from '@urql/exchange-graphcache'
import awsmobile from 'bdsc-exports'
import schema, { Factor, SolicitationMember } from 'generated/graphql'
import { has } from 'lodash'
import {
  deleteFileCache,
  upsertFileCache,
} from 'pages/DocumentManager/state/useDocumentManagerState'
import {
  deleteDiscoveryCache,
  upsertDiscoveryCache,
} from 'pages/Evaluation/state/useEvaluationPageState'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import React, { ReactNode, useContext, useMemo } from 'react'
import {
  CombinedError,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  makeOperation,
  Operation,
  Provider,
  subscriptionExchange,
} from 'urql'

import {
  createAppSyncAuthorizedWebSocket,
  createAppSyncGraphQLOperationAdapter,
  UUIDOperationIdSubscriptionClient,
} from './websocket'

export interface Props {
  children: ReactNode
}

export interface AuthState {
  token: string
  refreshToken: string
}

const GraphQlClientProvider = ({ children }: Props): JSX.Element => {
  const { user } = useContext(UserContext)

  const client = useMemo(() => {
    if (!user) {
      return null
    }

    const getAuth = async ({ authState }: { authState: AuthState | null }) => {
      if (!authState) {
        const token = user?.jwt
        const refreshToken = user?.refreshToken
        if (token && refreshToken) {
          return { token, refreshToken }
        }
        return null
      }

      return null
    }

    const addAuthToOperation = ({
      authState,
      operation,
    }: {
      authState: AuthState | null
      operation: Operation
    }) => {
      if (!authState || !authState.token) {
        return operation
      }

      const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {}

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: authState.token,
          },
        },
      })
    }

    const WSAuthentication = {
      Authorization: user?.jwt,
      host: awsmobile.aws_appsync_graphqlEndpoint
        .replace('https://', '')
        .replace('/graphql', ''),
    }
    const payload = {}

    const authenticationBase64 = Buffer.from(
      JSON.stringify(WSAuthentication)
    ).toString('base64')
    const payloadBase64 = Buffer.from(JSON.stringify(payload)).toString(
      'base64'
    )

    const wsEndpoint = awsmobile.aws_appsync_graphqlEndpoint
      .replace('https://', 'wss://')
      .replace('http://', 'ws://')
      .replace('appsync-api', 'appsync-realtime-api')
      .replace('gogi-beta', 'grt-beta')

    const subscriptionClient = new UUIDOperationIdSubscriptionClient(
      `${wsEndpoint}?header=${authenticationBase64}&payload=${payloadBase64}`,
      { timeout: 5 * 60 * 1000, reconnect: true, lazy: true },
      createAppSyncAuthorizedWebSocket()
    ).use([createAppSyncGraphQLOperationAdapter(WSAuthentication)])

    return createClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      requestPolicy: 'cache-first',
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        cacheExchange({
          schema,
          keys: {
            // Fixes warnings
            SolicitationMember: (data: Data) =>
              [
                'solicitationMemberPseudo',
                (data as SolicitationMember)?.user?.id,
              ].join('-'),
            EvaluationConfiguration: (_data) => null,
            FileTransfer: (_data) => null,
            // Factor changes based on the member so best not to key on it
            Factor: (data) => factorKey(data as Factor),
            EvaluationTaskHistory: (data) =>
              ['EvaluationTaskHistoryPseudo', data.updatedAt].join('-'),
          },
          updates: {
            Mutation: {
              upsertDiscovery: upsertDiscoveryCache,
              deleteDiscovery: deleteDiscoveryCache,
              deleteFile: deleteFileCache,
              upsertFile: upsertFileCache,
            },
          },
        }),
        authExchange<AuthState>({
          getAuth: getAuth,
          addAuthToOperation: addAuthToOperation,
        }),
        fetchExchange,
        subscriptionExchange({
          forwardSubscription(operation) {
            return subscriptionClient.request(operation)
          },
          enableAllOperations: false,
        }),
        errorExchange({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (error: CombinedError, _operation: Operation<any, any>) =>
            // eslint-disable-next-line no-console
            console.error(error),
        }),
      ],
    })
  }, [user])

  if (!client) {
    return <React.Fragment></React.Fragment>
  }

  return <Provider value={client}>{children}</Provider>
}

export { GraphQlClientProvider }
function factorKey(factor: Factor): string {
  if (
    !has(factor, 'members') ||
    !factor?.members ||
    factor?.members?.length > 1
  ) {
    return factor.id
  } else {
    return ['factorPseudo', factor?.members?.[0].user.id].join('-')
  }
}
