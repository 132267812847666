import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { SortButton } from 'components/Buttons/SortButton'
import { ColumnConfiguration, SortObject } from 'components/DataTable/index'
import React, { Fragment } from 'react'

interface Props<T> {
  collapsible?: React.FC<T>
  columns: ColumnConfiguration<T>[]
  handleSortData: (sort: SortObject<T>) => void
  sortDataBy: SortObject<T>
}

const useStyles = makeStyles((_theme) => ({
  headerColumn: {
    '&:hover .MuiTableSortLabel-icon': {
      opacity: 0,
    },
  },
}))

const DataTableHeader = <T,>({
  collapsible,
  columns,
  handleSortData,
  sortDataBy,
}: Props<T>): JSX.Element => {
  const classes = useStyles()

  return (
    <TableHead id="data-table-head">
      <TableRow>
        {collapsible && <TableCell sx={{ p: 0 }} />}
        {columns.map((column, index) => (
          <Fragment key={index}>
            {column.sort ? (
              <TableCell
                className={clsx(column?.classes?.header)}
                sx={{
                  px: 1,
                }}
              >
                <TableSortLabel
                  classes={{
                    root: classes.headerColumn,
                  }}
                  onClick={() =>
                    handleSortData({
                      columnName: column.name,
                      sort: column.sort,
                      order:
                        column.name === sortDataBy.columnName
                          ? sortDataBy.order === 'desc'
                            ? 'asc'
                            : 'desc'
                          : 'asc',
                    })
                  }
                >
                  <Box display="flex" alignItems="center">
                    <SortButton
                      disabled={!(column.name === sortDataBy.columnName)}
                      order={sortDataBy.order}
                    />
                    <Typography
                      className={clsx(column?.classes?.headerText)}
                      variant="caption"
                      sx={column.headerSx}
                    >
                      {column.name}
                    </Typography>
                  </Box>
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell className={clsx(column?.classes?.header)}>
                <Typography
                  className={clsx(column?.classes?.headerText)}
                  variant="caption"
                >
                  {column.name}
                </Typography>
              </TableCell>
            )}
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default DataTableHeader
