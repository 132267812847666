import { Props as ChartProps } from 'components/analytics/Chart'
import { debounce } from 'lodash'
import React from 'react'

export const useRedrawSVG = (props: ChartProps): void => {
  const resizeListener = React.useCallback(() => {
    props.svgRef.current && props.draw()
  }, [props])

  const redraw = React.useMemo(
    () =>
      debounce(() => {
        props.svgRef.current && props.draw()
      }, 100),
    [props]
  )
  React.useLayoutEffect(() => {
    window.addEventListener('resize', resizeListener)
    redraw()
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [redraw, resizeListener])
}
