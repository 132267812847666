import HomePageSkeleton from './HomePageSkeleton'
import { Page } from './Page'
import { useHomePageState } from './state'

const Home = (): JSX.Element => {
  const state = useHomePageState()

  return !state.loading ? <Page state={state} /> : <HomePageSkeleton />
}

export { Home }
