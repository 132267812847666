import { Box, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import { RatingSet } from 'generated/graphql'

import { RatingsTable } from './RatingsTable'

interface Props {
  ratings: RatingSet[]
}
export const Ratings = ({ ratings }: Props): JSX.Element => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
        mb={4}
        mr={5}
      >
        <Typography variant="h5" fontSize="24px" lineHeight="32px">
          Rating Sets
        </Typography>
        <BidscaleButton
          buttonVariant="execute"
          children="ADD RATING SET"
          onClick={() => alert('Add Rating Set')}
        />
      </Box>
      <RatingsTable ratings={ratings} />
    </Box>
  )
}
