import {
  Box,
  LinearProgress as BaseLinearProgress,
  LinearProgressProps,
  Paper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import React from 'react'
import { theme } from 'theme'

interface Props extends LinearProgressProps {
  value: number
  displayProgress?: 'percentage' | 'fraction'
  min?: number
  max?: number
  border?: boolean
  header?: string
  fileSize?: string
}

export const LinearProgressBar = ({ sx, ...props }: Props): JSX.Element => (
  <Box>
    {!props.border ? (
      <Main
        {...props}
        sx={[...(Array.isArray(sx) ? sx : [sx])] as SxProps<Theme>}
      />
    ) : (
      <Paper variant="outlined">
        <Box px={2} pb={2.5}>
          <Main
            {...props}
            sx={[...(Array.isArray(sx) ? sx : [sx])] as SxProps<Theme>}
          />
        </Box>
      </Paper>
    )}
  </Box>
)

const Main = ({ sx, ...props }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      mt={props.header ? 0 : 2}
      sx={[...(Array.isArray(sx) ? sx : [sx])] as SxProps<Theme>}
    >
      {props.fileSize && (
        <Box display="flex" id="file-size">
          <Box mr={1.5} alignSelf={props.header ? 'flex-end' : 'center'}>
            <Typography variant="caption">
              {props.fileSize.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      )}

      <Box display="flex" flex={1} justifyContent="center">
        <Box
          flex={1}
          mr={1.5}
          mt={props.header ? 2 : 0}
          alignSelf="center"
          id="linear-progress"
        >
          {props.header && (
            <Typography variant="body2">{props.header}</Typography>
          )}

          <Box mb={props.header ? 1 : 0}>
            <BaseLinearProgress
              variant={props.variant ?? 'determinate'}
              value={props.value}
              sx={{
                height: theme.spacing(1),
                borderRadius: theme.spacing(0.5),
              }}
            />
          </Box>
        </Box>

        <Box
          id="display-progress"
          alignSelf={props.header ? 'flex-end' : 'center'}
        >
          {props.displayProgress === 'percentage' && (
            <Box id="percentage">
              <Typography variant="body2">{`${Math.round(
                props.value
              )}%`}</Typography>
            </Box>
          )}
          {props.displayProgress === 'fraction' && (
            <Box id="check">
              <Typography variant="body2">
                {`${props.min ?? '?'} of ${props.max ?? '?'}`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
