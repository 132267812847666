import {
  alpha,
  Box,
  SxProps,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Theme,
  Typography,
} from '@mui/material'
import { ActionMenuPopper } from 'components/ActionMenuPopper'
import { filter, map } from 'lodash'
import React, { useState } from 'react'
import { theme } from 'theme'

export interface TabsProps {
  handleOnChange: (tab: string) => void
  selectedTab: string
  sxTabSpacer?: SxProps<Theme>
  tabs: {
    tabTitle: string
    tabPanel?: React.ReactNode
    display: boolean
    link: string
  }[]
}

export interface ITabPanel {
  children?: React.ReactNode
  selectedTab: string
  tab: string
}

const TabPanel = ({ children, selectedTab, tab }: ITabPanel): JSX.Element => {
  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== tab}
      id={`tabpanel-${tab}`}
      aria-labelledby={`tab-${tab}`}
      style={{ height: '100%' }}
    >
      {selectedTab === tab && children}
    </div>
  )
}

export const Tabs = ({
  handleOnChange,
  selectedTab,
  ...props
}: TabsProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [popperOpen, setPopperOpen] = useState(false)
  const [popperText, setPopperText] = useState('')

  const handleChange = (_event: React.SyntheticEvent, newTab: string) => {
    handleOnChange(newTab)
  }

  const handlePopper = (event: React.MouseEvent<HTMLElement>, text: string) => {
    event.preventDefault()
    if (text.length > 28) {
      setPopperText(text)
      setAnchorEl(event.currentTarget)
      setPopperOpen(true)
    }
  }

  return (
    <>
      <Box display={'flex'} alignItems={'end'}>
        <Box
          id="tab-spacers"
          sx={{
            width: theme.spacing(5),
            borderBottom: `1px solid ${theme.palette.info.main}`,
            ...props.sxTabSpacer,
          }}
        />
        <Box id="tabs" width={1}>
          <MuiTabs
            value={selectedTab}
            onChange={handleChange}
            sx={tabStyles}
            variant="standard"
          >
            {map(
              filter(props.tabs, (tab) => tab.display),
              (tab, index) => (
                <MuiTab
                  sx={tabItemStyles}
                  label={
                    <Typography width={1} noWrap={true}>
                      {tab.tabTitle}
                    </Typography>
                  }
                  key={index}
                  onMouseEnter={(e) => handlePopper(e, tab.tabTitle)}
                  onMouseLeave={() => {
                    popperOpen && setPopperOpen(false)
                  }}
                  value={tab.link.toLowerCase()}
                />
              )
            )}
            <Box
              id="tab-spacers"
              width={1}
              borderBottom={`1px solid ${theme.palette.info.main}`}
            />
          </MuiTabs>
        </Box>
      </Box>
      <Box m={5} flex="auto">
        {map(
          filter(props.tabs, (tab) => tab.display),
          (tab, index) => (
            <TabPanel
              selectedTab={selectedTab}
              tab={tab.link.toLowerCase()}
              key={index}
            >
              {tab.tabPanel}
            </TabPanel>
          )
        )}
      </Box>
      <ActionMenuPopper
        isOpen={popperOpen}
        setOpen={setPopperOpen}
        anchorEl={anchorEl}
        actionItems={[
          {
            itemName: popperText,
          },
        ]}
        placement="top"
        fallbackPlacements={['bottom']}
      />
    </>
  )
}

const tabStyles: SxProps<Theme> = {
  '&.MuiTabs-root': { minHeight: '33px' },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}

const tabItemStyles: SxProps<Theme> = {
  '&.MuiTab-root': {
    py: 0,
    px: 1,
    mt: 1,
    maxWidth: '266px',
    width: 1,
    height: '33px',
    minHeight: '33px',
    font: theme.typography.subtitle1,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '32px',
    color: alpha(theme.palette.info.dark, 0.7),
    backgroundColor: alpha(theme.palette.info.main, 0.3),
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: '8px 8px 0 0',
    '&.Mui-selected': {
      height: '41px',
      mt: '0',
      borderBottom: 'none',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.info.light,
    },
    '&:hover': {
      '&:not(.Mui-selected)': {
        fontWeight: 500,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info.main,
      },
    },
  },
}
