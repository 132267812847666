import Chart, {
  ChartData,
  ChartOptions,
  mapColors,
} from 'components/analytics/Chart'
import Legend, { IconProps } from 'components/Legend'
import * as d3 from 'd3'
import { find, map, union } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { theme } from 'theme'

export interface BarChartData {
  xLabel: string
  yLabels: string[]
  data: ChartData[]
}

export interface LineChartData {
  xLabel: string
  yLabel: string
  data: ChartData[]
}

export interface BarChartOptions extends ChartOptions {
  verticalLine?: {
    title?: string
    xValue: string | number
    description: string
  }
  barWidth: number
  xAxis?: {
    xAxis1: string
    xAxis2: string
  }
}

const defaultChartOptions: BarChartOptions = {
  barWidth: 35,
  colorPalette: ['#6ad098', '#25c5f8', '#ee7d5c', '#8a66a6'],
  colorMap: {},
}

export interface Props {
  data: BarChartData
  lineData?: LineChartData
  chartOptions?: Partial<BarChartOptions>
}

const lineColor = theme.palette.text.primary

const BarChart = (props: Props): JSX.Element => {
  const d3svg = useRef<SVGSVGElement>(null)
  const chartOptions = { ...defaultChartOptions, ...props.chartOptions }
  const mappedColors = mapColors(
    props.data.yLabels,
    chartOptions?.colorPalette,
    props?.chartOptions?.colorMap
  )

  const drawGridlines = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      yScale: d3.ScaleLinear<number, number, never>,
      width: number
    ) => {
      const yAxisGrid = d3.axisLeft(yScale).tickSize(-width)

      svg
        .append('g')
        .call(yAxisGrid)
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick line')
            .attr('x1', width / 13.4)
            .attr('x2', width / 1.06)
            .attr('shape-rendering', 'crispEdges')
            .attr('stroke', '#EEEEEE')
            .attr('stroke-width', '1px')
        )
    },
    []
  )

  const drawBars = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      layers: d3.Series<{ [key: string]: number }, string>[],
      xScale: d3.ScaleBand<string>,
      yScale: d3.ScaleLinear<number, number, never>
    ) => {
      svg
        .selectAll('.layer')
        .data(layers)
        .join('g')
        .attr('class', (layer, index) => `Layer${index}`)
        .attr('fill', (layer) => mappedColors[layer.key])
        .selectAll('rect')
        .data((layer) => {
          return layer
        })
        .join('rect')
        .attr('x', (_sequence, index) => xScale(index.toString()) as number)
        .attr('width', chartOptions.barWidth)
        .attr('y', (sequence) => yScale(sequence[1]))
        .attr('height', (sequence) => yScale(sequence[0]) - yScale(sequence[1]))
        .attr('class', (sequence, index) => {
          return `x${index}`
        })
    },
    [chartOptions.barWidth, mappedColors]
  )

  const drawLineChart = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      data: ChartData[],
      xScale: d3.ScaleBand<string>,
      yScale: d3.ScaleLinear<number, number, never>,
      yLabel: string
    ) => {
      const lineGenerator = d3
        .line<Partial<ChartData>>()
        .x(
          (_d, index) =>
            (xScale(index.toString()) as number) + chartOptions.barWidth / 2
        )
        .y((d) => (yLabel ? yScale(d[yLabel] as number) : yScale(0)))
        .curve(d3.curveCardinal)

      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', lineColor)
        .style('stroke-dasharray', '8, 8')
        .attr('stroke-miterlimit', 1)
        .attr('stroke-width', 2)
        .attr('d', lineGenerator(data))
    },
    [chartOptions.barWidth]
  )

  const drawTodayDot = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      xPosition: number
    ) => {
      const textTheme = theme.typography.subtitle2
      const tasksPerDay = find(
        props.data.data,
        (item) => item.date === chartOptions.verticalLine?.xValue
      )
      const verticalLineValue = chartOptions.verticalLine?.title
        ? chartOptions.verticalLine?.title
        : tasksPerDay?.remainingTasks

      if (chartOptions.verticalLine?.xValue) {
        svg
          .append('circle')
          .attr('class', 'dot')
          .attr('cx', xPosition)
          .attr('cy', 25)
          .attr('fill', textTheme.color || '#003845')
          .attr('r', 21.5)
        svg
          .append('text')
          .attr('x', xPosition)
          .attr('y', 31)
          .attr('text-anchor', 'middle')
          .attr('alignmentBaseline', 'central')
          .attr('fill', '#FFFFFF')
          .attr('font-size', textTheme.fontSize || '16px')
          .attr('font-weight', textTheme.fontWeight || '700')
          .attr('font-family', textTheme.fontFamily || 'proxima-nova')
          .text((_d) => verticalLineValue || '')
      }
    },
    [
      chartOptions.verticalLine?.title,
      chartOptions.verticalLine?.xValue,
      props.data.data,
    ]
  )

  const drawYAxisLabel = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      yLabel: string,
      data: ChartData[],
      yScale: d3.ScaleLinear<number, number, never>,
      height: number
    ) => {
      const textTheme = theme.typography.caption
      const yAxis = d3.axisLeft(yScale).ticks(6).tickSizeInner(10)
      svg
        .append('g')
        .classed('y', true)
        .classed('axis', true)

        .attr('transform', 'translate(60, 0)')
        .call(yAxis)
        //removes y axis line
        .call((g) => g.select('.domain').remove())
        //removes 0 tick label and line
        .call((g) => g.selectAll('.tick:first-of-type line').remove())
        .call((g) => g.selectAll('.tick:first-of-type text').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .style('text-anchor', 'middle')
            .attr('dx', '13px')
            .attr('dy', 5)
            .attr('fill', textTheme.color || '#003845')
            .attr('font-size', textTheme.fontSize || '14px')
            .attr('font-weight', '500')
            .attr('font-family', textTheme.fontFamily || 'proxima-nova')
            .attr('font-style', textTheme.fontStyle || 'normal')
        )

      svg
        .append('text')
        .attr('class', 'y label')
        .attr('fill', theme.typography.body2.color || '#003845')
        .attr('font-size', theme.typography.body2.fontSize || '16px')
        .attr('font-weight', '500')
        .attr(
          'font-family',
          theme.typography.body2.fontFamily || 'proxima-nova'
        )
        .attr('text-anchor', 'middle')
        .attr('x', 0 - height / 2)
        .attr('dy', '11px')
        .attr('transform', 'rotate(-90)')
        .text(yLabel)
    },
    []
  )

  const drawXAxis = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      data: ChartData[],
      xScale: d3.ScaleBand<string>,
      xAxis1: string,
      xAxis2: string,
      height: number,
      width: number,
      xLabel: string
    ) => {
      const textTheme = theme.typography.caption
      const xAxisDay = d3
        .axisBottom(xScale)
        .tickFormat((_d, index) => {
          return data[index][xAxis1]?.toString() || ''
        })
        .tickSizeInner(0)
        .tickSizeOuter(0)

      const xAxisDate = d3
        .axisBottom(xScale)
        .tickFormat((_d, index) => {
          return data[index][xAxis2]?.toString() || ''
        })
        .tickSizeInner(0)
        .tickSizeOuter(0)
      svg
        .append('g')
        .classed('x', true)
        .classed('axis', true)
        .attr('transform', `translate(0, ${height})`)
        .call(xAxisDay)
        .attr('stroke-opacity', 0)
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', (chartOptions.barWidth / 2) * 0.3)
            .attr('dy', 16)
            .attr('fill', textTheme.color || '#003845')
            .attr('font-size', textTheme.fontSize || '14px')
            .attr('font-weight', '500')
            .attr('font-family', textTheme.fontFamily || 'proxima-nova')
            .attr('font-style', textTheme.fontStyle || 'normal')
        )
      svg
        .append('g')
        .classed('x', true)
        .classed('axis', true)
        .attr('transform', `translate(0, ${height})`)
        .call(xAxisDate)
        .attr('stroke-opacity', 0)
        .call((g) =>
          g
            .selectAll('.tick text')
            .style('text-anchor', 'middle')
            .attr('x', (chartOptions.barWidth / 2) * 0.3)
            .attr('dy', 30)
            .attr('fill', textTheme.color || '#003845')
            .attr('font-size', textTheme.fontSize || '14px')
            .attr('font-weight', '500')
            .attr('font-family', textTheme.fontFamily || 'proxima-nova')
            .attr('font-style', textTheme.fontStyle || 'normal')
        )

      svg
        .append('text')
        .attr('class', 'y label')
        .attr('fill', theme.typography.body2.color || '#003845')
        .attr('font-size', theme.typography.body2.fontSize || '16px')
        .attr('font-weight', '500')
        .attr(
          'font-family',
          theme.typography.body2.fontFamily || 'proxima-nova'
        )
        .attr('text-anchor', 'middle')
        .attr('x', width / 2)
        .attr('dy', height + 65)
        .text(xLabel)
    },
    [chartOptions.barWidth]
  )

  const drawClipPath = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      layers: d3.Series<{ [key: string]: number }, string>[]
    ) => {
      const flagForBottom: { [key: string]: string } = {}
      const flagForTop: { [key: string]: { [key: string]: string } } = {}

      layers.forEach((layer, layerIndex) => {
        layer.forEach((sequence, sequenceIndex) => {
          if (sequence[0] === 0 && sequence[1] !== 0) {
            flagForBottom[`x${sequenceIndex}`] = `Layer${layerIndex}`
          }
          if (
            flagForTop[`x${sequenceIndex}`] === undefined ||
            (sequence[0] !== sequence[1] &&
              sequence[1] > parseInt(flagForTop[`x${sequenceIndex}`].s1))
          ) {
            flagForTop[`x${sequenceIndex}`] = {
              layerKey: `Layer${layerIndex}`,
              s1: sequence[1].toString(),
            }
          }
        })
      })

      for (const [sequenceIndex, layerIndex] of Object.entries(flagForBottom)) {
        const group = svg.selectAll('g').filter(`.${layerIndex}`)
        const rect = group.selectAll('rect').filter(`.${sequenceIndex}`)
        const radius = 0

        const x = parseFloat(rect.attr('x'))
        const y = parseFloat(rect.attr('y'))
        const height = parseFloat(rect.attr('height'))
        const width = parseFloat(rect.attr('width'))
        group
          .data([1])
          .append('path')
          .attr('d', () => {
            const ret = d3.path()
            ret.moveTo(x, y + height - radius)
            ret.quadraticCurveTo(x, y + height, x + radius, y + height)
            ret.lineTo(x - 1, y + height)
            return ret.toString()
          })
          .attr('fill', '#FFFFFF')

        group
          .data([1])
          .append('path')
          .attr('d', () => {
            const ret = d3.path()
            ret.moveTo(x + width, y + height - radius)
            ret.quadraticCurveTo(
              x + width,
              y + height,
              x + width - radius,
              y + height
            )
            ret.lineTo(x + width + 1, y + height)
            return ret.toString()
          })
          .attr('fill', '#FFFFFF')
      }

      for (const [sequenceIndex, layerItem] of Object.entries(flagForTop)) {
        const group = svg.selectAll('g').filter(`.${layerItem.layerKey}`)
        const rect = group.selectAll('rect').filter(`.${sequenceIndex}`)
        const radius = 0

        const x = parseFloat(rect.attr('x'))
        const y = parseFloat(rect.attr('y'))
        const width = parseFloat(rect.attr('width'))
        group
          .data([1])
          .append('path')
          .attr('d', () => {
            const ret = d3.path()
            ret.moveTo(x, y + radius)
            ret.quadraticCurveTo(x, y, x + radius, y)
            ret.lineTo(x - 1, y - 1)
            return ret.toString()
          })
          .attr('fill', '#FFFFFF')

        group
          .data([1])
          .append('path')
          .attr('d', () => {
            const ret = d3.path()
            ret.moveTo(x + width, y + radius)
            ret.quadraticCurveTo(x + width, y, x + width - radius, y)
            ret.lineTo(x + width + 1, y - 1)
            return ret.toString()
          })
          .attr('fill', '#FFFFFF')
      }
    },
    []
  )

  const drawChart = useCallback(() => {
    if (d3svg.current && props.data.data.length > 0) {
      const svg = d3.select(d3svg.current)
      svg.selectAll('*').remove()

      const height = d3svg.current?.height.baseVal.value - 70
      const width = d3svg.current?.width.baseVal.value

      // stacks / layers
      const xLabel = props.data.xLabel
      const allKeys = props.data.yLabels
      const data = props.data.data
      const xAxis1 = chartOptions.xAxis?.xAxis1 as string
      const xAxis2 = chartOptions.xAxis?.xAxis2 as string
      const stackGenerator = d3.stack().keys(allKeys)
      const layers = stackGenerator(data as Iterable<{ [key: string]: number }>)

      const extent = [
        0,
        d3.max(layers, (layer) => d3.max(layer, (sequence) => sequence[1])),
      ] as number[]
      // scales
      const xScale = d3
        .scaleBand()
        .domain(data.map((_d, index) => index.toString()) as Iterable<string>)
        .range([30, width])
        .padding(0.76)
      const yScale = d3.scaleLinear().domain(extent).range([height, 10])
      // rendering
      drawGridlines(svg, yScale, width)
      drawBars(svg, layers, xScale, yScale)
      drawClipPath(svg, layers)
      // axes
      drawXAxis(
        svg,
        data,
        xScale,
        xAxis1,
        xAxis2,
        height,
        width,
        'Date Forecast'
      )
      drawYAxisLabel(svg, 'Tasks Remaining', data, yScale, height)

      //append projection line
      if (props?.lineData?.data) {
        drawLineChart(
          svg,
          props?.lineData?.data,
          xScale,
          yScale,
          props?.lineData?.yLabel
        )
      }

      //append "today" line
      if (props?.chartOptions?.verticalLine?.xValue) {
        const xValue = chartOptions.verticalLine?.xValue
        const xIndex = data
          .findIndex((row) => row[xLabel] === xValue)
          .toString()
        const xPosition = (xScale(xIndex) || 0) + chartOptions.barWidth / 2
        xIndex !== '-1' && drawTodayDot(svg, xPosition)
      }
    }
  }, [
    props.data.data,
    props.data.xLabel,
    props.data.yLabels,
    props?.lineData?.data,
    props?.lineData?.yLabel,
    props?.chartOptions?.verticalLine?.xValue,
    chartOptions.xAxis?.xAxis1,
    chartOptions.xAxis?.xAxis2,
    chartOptions.verticalLine?.xValue,
    chartOptions.barWidth,
    drawGridlines,
    drawBars,
    drawClipPath,
    drawXAxis,
    drawYAxisLabel,
    drawLineChart,
    drawTodayDot,
  ])

  useEffect(() => {
    drawChart()
  }, [drawChart])

  const legend = () => {
    const icons: IconProps[] = union(
      map(
        props.data.yLabels,
        (label) =>
          label !== 'Projected' && {
            label,
          }
      )
    ) as IconProps[]
    const lineProps = {
      label: props.lineData?.yLabel || '',
      shape: 'Line',
      color: lineColor,
    } as IconProps
    const circleProps = {
      label: props.chartOptions?.verticalLine?.description || '',
      shape: 'Circle',
      color: lineColor,
    } as IconProps
    const iconsCustom: IconProps[] =
      props.lineData?.yLabel && props.chartOptions?.verticalLine?.xValue
        ? (union([lineProps], [circleProps], icons) as IconProps[])
        : props.lineData?.yLabel
        ? (union([lineProps], icons) as IconProps[])
        : icons

    return (
      <Legend
        chartOptions={{
          colorPalette: chartOptions?.colorPalette,
          colorMap: mappedColors,
        }}
        icons={iconsCustom}
        removeLegendMarginY
      />
    )
  }

  return (
    <Chart
      svgRef={d3svg}
      draw={drawChart}
      legend={chartOptions.showLegend && legend()}
    />
  )
}

export default BarChart
