import { Box, Skeleton } from '@mui/material'
import React from 'react'

import { LEFT_PANEL_WIDTH, RIGHT_PANEL_WIDTH } from '../Page'

const EvaluationPageSkeleton = (): JSX.Element => {
  return (
    <Box display="flex" width="100%" height="100vh">
      <Box width={LEFT_PANEL_WIDTH} height="auto" my={3} mx={2}>
        <Skeleton
          variant="rectangular"
          height="100%"
          width="100%"
          animation="wave"
        />
      </Box>

      <Box width={RIGHT_PANEL_WIDTH} height="auto" my={3} mx={2}>
        <Skeleton
          variant="rectangular"
          height="100%"
          width="100%"
          animation="wave"
        />
      </Box>
    </Box>
  )
}

export default EvaluationPageSkeleton
