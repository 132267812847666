import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import { AuthContext } from 'pages/Login/User/providers/AuthProvider'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext } from 'react'

import { ProfileView } from './ProfileView'

export type ProfileVariant = 'navbar' | 'evaluation'

interface Props {
  variant: ProfileVariant
}

const Profile = ({ variant }: Props): JSX.Element => {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const user = userContext.user
  const firstName = user?.firstName
  const familyName = user?.familyName
  const email = user?.email
  const logOut = authContext?.logout

  return (
    <>
      {firstName && familyName && email && logOut ? (
        <ProfileView
          firstName={firstName}
          familyName={familyName}
          email={email}
          logOut={logOut}
          variant={variant}
        />
      ) : (
        <LoadingIndicator displayText="Loading Profile" />
      )}
    </>
  )
}

export { Profile }
