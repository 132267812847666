import { Box, Typography } from '@mui/material'
import { ReactComponent as ChangesReq } from 'assets/activityFeed/changesReq.svg'
import { ReactComponent as Complete } from 'assets/activityFeed/complete.svg'
import { ReactComponent as Document } from 'assets/activityFeed/document.svg'
import { ReactComponent as Rejected } from 'assets/activityFeed/rejected.svg'
import { ReactComponent as Review } from 'assets/activityFeed/review.svg'
import { ReactComponent as TaskStarted } from 'assets/activityFeed/taskStarted.svg'
import { multilineEllipsis } from 'constants/styles'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { Activity, ActivityType, User } from 'generated/graphql'
import React, { ReactNode } from 'react'
import { theme } from 'theme'

export type UserProp = Omit<User, 'roles' | 'title' | 'status'>

export type ActivityFeed = Omit<Activity, 'user'> & {
  user: UserProp
}
export interface ActivityFeedItem extends ActivityFeed {
  icon?: ReactNode
}
export interface Props {
  items?: ActivityFeedItem[]
}

export const ActivityFeedView = ({ items }: Props): JSX.Element => {
  const [show, setShow] = React.useState(false)
  const ref = React.useRef<HTMLElement>(null)

  return (
    <Box id={'activity-feed'} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" pr={3}>
        <Typography variant="overline">ACTIVITY FEED</Typography>
        <Typography variant="overline">{`(${items?.length ?? 0})`}</Typography>
      </Box>
      <Box mt={2} mb={1} pr={3}>
        <Typography variant="caption" color="text.secondary">
          {items?.length
            ? 'Overview'
            : 'There is currently no activity to show'}
        </Typography>
      </Box>
      <Box
        ref={ref}
        pr={3}
        sx={{
          overflow: 'auto',
          height: ref.current ? ref.current.clientHeight : 'auto',
          minHeight: '670px',
        }}
      >
        {items?.length ? (
          items.map((item, index) =>
            show ? (
              <ActivityFeedItemMemo
                key={`activity-feed-${item.id}`}
                {...item}
              />
            ) : (
              index < 6 && (
                <ActivityFeedItemMemo
                  key={`activity-feed-${item.id}`}
                  {...item}
                />
              )
            )
          )
        ) : (
          <Box
            display="flex"
            mt={3}
            pb={2}
            borderBottom={`1px solid ${theme.palette.info.main}`}
          >
            <Box mr={1.5} pt={0.5}>
              <Box
                p={1.5}
                sx={{
                  border: `4px dashed ${theme.palette.info.main}`,
                  bgcolor: 'info.light',
                }}
              />
            </Box>
            <Typography variant="subtitle2" width="284px" textAlign="left">
              Your activity will be updated here. Come back soon to see your
              progress!
            </Typography>
          </Box>
        )}
      </Box>
      {items && items?.length > 6 && show === false && (
        <Typography
          variant="subtitle1"
          fontSize="12px"
          lineHeight="15px"
          textAlign="center"
          sx={{ cursor: 'pointer', textDecorationLine: 'underline' }}
          onClick={() => setShow(true)}
        >
          Show All
        </Typography>
      )}
    </Box>
  )
}

const ActivityFeedItemMemo = React.memo(
  ({ createdAt, user, name, type, icon }: ActivityFeedItem): JSX.Element => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={1}
        mt={3}
        pb={2}
        borderBottom={`1px solid ${theme.palette.info.main}`}
      >
        <Box display="flex" alignItems="flex-top" width={'80%'}>
          <Box mr={1.5} pt={0.5}>
            {icon ?? getIcon(type)}
          </Box>
          <Box width={1}>
            <Typography sx={multilineEllipsis(2)} variant="subtitle2">
              {name}
            </Typography>
            <Typography variant="caption">
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="caption">
            {formatTime(parseISO(createdAt))} ago
          </Typography>
        </Box>
      </Box>
    )
  }
)

const getIcon = (type: ActivityType): JSX.Element => {
  switch (type) {
    case ActivityType.TaskStarted:
      return <TaskStarted />
    case ActivityType.TaskCompleted:
      return <Complete />
    case ActivityType.ReportGenerated:
      return <Complete />
    case ActivityType.TaskChangesRequested:
      return <ChangesReq height="28px" width="28px" />
    case ActivityType.TaskInReview:
      return <Review height="28px" width="28px" />
    case ActivityType.DocumentAdded:
      return <Document />
    case ActivityType.DocumentUpdated:
      return <Document />
  }
  return <Rejected height="28px" width="28px" />
}

const formatTime = (date: Date): string => {
  const timeAbbr = formatDistanceToNowStrict(date).split(' ')
  switch (!!date) {
    case timeAbbr[1] === 'minute' || timeAbbr[1] === 'minutes':
      return `${timeAbbr[0]}m`
    case timeAbbr[1] === 'hour' || timeAbbr[1] === 'hours':
      return `${timeAbbr[0]}h`
    case timeAbbr[1] === 'month' || timeAbbr[1] === 'months':
      return `${timeAbbr[0]}mo`
    case timeAbbr[1] === 'year' || timeAbbr[1] === 'years':
      return `${timeAbbr[0]}yr`
  }
  return `${timeAbbr[0]}d`
}
