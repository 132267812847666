import { Box, Slide, SlideProps } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { makeStyles } from '@mui/styles'
import { SnackbarProvider } from 'notistack'
import React, { ReactNode } from 'react'
import { theme } from 'theme'

export const Snackbar = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      hideIconVariant
      preventDuplicate
      classes={{
        root: classes.snackbarContent,
        containerRoot: classes.containerRoot,
        variantSuccess: classes.success,
        variantInfo: classes.info,
      }}
      maxSnack={3}
      autoHideDuration={5000}
      TransitionComponent={React.forwardRef<unknown, TransitionProps>(
        function Transition(props, ref) {
          return <Slide ref={ref} {...(props as SlideProps)} direction="up" />
        }
      )}
    >
      <Box>{children}</Box>
    </SnackbarProvider>
  )
}

const useStyles = makeStyles(() => ({
  snackbarContent: {
    width: '340px',
    '& #notistack-snackbar': {
      fontSize: 'typography.body2.fontSize',
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: 'typography.body2',
    },
  },
  containerRoot: {
    '& #notistack-snackbar': {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  success: { backgroundColor: `${theme.palette.text.primary} !important` },
  info: { backgroundColor: `${theme.palette.primary.main} !important` },
}))
