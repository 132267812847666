import { Box, Typography } from '@mui/material'
import React from 'react'

export interface Props {
  header: string | JSX.Element
}

export const PageHeader = (props: Props): JSX.Element => {
  return (
    <Box
      id="page-header"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      pb={4}
    >
      <Box>
        <Typography variant="h4">{props.header}</Typography>
      </Box>
    </Box>
  )
}
