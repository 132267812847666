import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Divider,
  Drawer as DrawerBase,
  DrawerProps,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'

export const DEFAULT_SKIP_WIDTH = '24vw'
export const DEFAULT_DRAWER_WIDTH = '48vw'
export const DEFAULT_RIGHT_DRAWER_WIDTH = '369px'

export interface Props extends DrawerProps {
  anchor: 'top' | 'left' | 'bottom' | 'right'
  onDrawerClose?: () => void
  heading: string
  skip?: boolean
  skipValue?: number | string
  variant?: 'permanent' | 'persistent' | 'temporary'
  innerWidth?: number | string
  customHeader?: JSX.Element
  sticky?: boolean
}

interface LeftDrawerProps {
  onDrawerClose?: () => void
  heading: string
  innerWidth?: number | string
  open: boolean
  children: ReactNode
  skipValue: number | string
}
interface RightDrawerProps {
  onDrawerClose?: () => void
  heading: string
  innerWidth?: number | string
  open: boolean
  children: ReactNode
}

interface FullScreenDrawerProps {
  onDrawerClose?: () => void
  customHeader?: JSX.Element
  heading?: string
  open: boolean
  children: ReactNode
  sticky?: boolean
}

const LeftDrawer = (props: LeftDrawerProps): JSX.Element => {
  return (
    <Drawer
      {...props}
      anchor="left"
      variant="persistent"
      skip={true}
      innerWidth={props.innerWidth ?? DEFAULT_DRAWER_WIDTH}
      id="left-drawer"
    />
  )
}

const RightDrawer = (props: RightDrawerProps): JSX.Element => {
  return (
    <Drawer
      {...props}
      anchor="right"
      variant="persistent"
      innerWidth={props.innerWidth ?? DEFAULT_RIGHT_DRAWER_WIDTH}
      id="right-drawer"
    />
  )
}

const FullScreenDrawer = (props: FullScreenDrawerProps): JSX.Element => {
  return (
    <Drawer
      {...props}
      anchor="left"
      variant="persistent"
      skip={false}
      heading={props.heading || ''}
      innerWidth={1}
      customHeader={props.customHeader}
      id="fullscreen-drawer"
    />
  )
}

const Drawer = (props: Props): JSX.Element => {
  const TopBar = ({ heading }: { heading: string }): JSX.Element => (
    <Box
      display="flex"
      bgcolor={'info.light'}
      alignItems="center"
      justifyContent="space-between"
      id="top-bar"
    >
      <Box id="top-bar-heading">
        <Typography variant="h6" textTransform="capitalize">
          {heading}
        </Typography>
      </Box>

      {props.onDrawerClose && (
        <Box id="drawer-close" sx={{ color: 'info.dark' }}>
          <IconButton
            id="drawer-close-icon-button"
            onClick={props.onDrawerClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" color="inherit" />
          </IconButton>
        </Box>
      )}
    </Box>
  )

  const DrawerHeader = ({ isSticky }: { isSticky?: boolean }): JSX.Element => {
    return (
      <Box bgcolor="info.light" sx={isSticky ? Styles.stickyHeader : {}}>
        <Box py={3}>
          {props.customHeader || <TopBar heading={props.heading} />}
        </Box>
        <Divider sx={{ marginRight: (theme) => theme.spacing(2) }} />
      </Box>
    )
  }

  return (
    <Box key={props.anchor} id="drawer" flex={1}>
      <DrawerBase
        anchor={props.anchor}
        elevation={props.elevation}
        hideBackdrop={props.hideBackdrop}
        open={props.open}
        variant={props.variant ?? 'persistent'}
        sx={Object.assign(
          {},
          Styles.placeholder,
          ...(Array.isArray(props.sx) ? props.sx : [props.sx])
        )}
      >
        <Box display="flex" flexDirection="row">
          {props.skip && (
            <Box
              flex={props.skipValue ? 0 : 1}
              minWidth={props.skipValue ?? DEFAULT_SKIP_WIDTH}
            />
          )}

          <Box
            height={'100vh'}
            width={props.innerWidth ?? DEFAULT_DRAWER_WIDTH}
          >
            <Box pl={4} pr={props.anchor === 'right' ? 4 : 2} pb={2} height={1}>
              {props.sticky && <DrawerHeader isSticky={props.sticky} />}
              <DrawerHeader />

              <Box mr={props.anchor === 'right' ? 0 : 2} height={1}>
                {props.children}
              </Box>
            </Box>
          </Box>
        </Box>
      </DrawerBase>
    </Box>
  )
}

export { FullScreenDrawer, LeftDrawer, Drawer, RightDrawer }

const Styles: Record<string, SxProps<Theme>> = {
  placeholder: {},
  stickyHeader: {
    position: 'fixed',
    width: 'calc(100% - 48px)',
    zIndex: '100',
  },
}
