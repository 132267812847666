import {
  Box,
  Pagination,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import { SortDirection } from 'components/Buttons/SortButton'
import DataTableBody from 'components/DataTable/DataTableBody'
import DataTableHeader from 'components/DataTable/DataTableHeader'
import { orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { theme } from 'theme'

export type SortObject<T> = {
  columnName: string
  sort?: (item: T) => string | number
  order: SortDirection
}

export interface Props<T> {
  columns: ColumnConfiguration<T>[]
  title?: string
  data: T[]
  rowsPerPage: number
  showFirstButton?: boolean
  showLastButton?: boolean
  defaultSort?: SortObject<T>
  collapsible?: React.FC<T>
  isCollapsible?: (data: T) => boolean
  noResultFound?: string
}

export interface ColumnConfiguration<T> {
  name: string
  classes?: {
    header?: string | string[]
    headerText?: string | string[]
    body?: string | string[]
  }
  renderer: React.FC<T | null>
  sort?: (item: T) => string | number
  key?: keyof T
  customWidth?: string
  headerSx?: SxProps<Theme>
  bodySx?: SxProps<Theme> | ((item: T) => SxProps<Theme>)
}

const DataTable = <T,>({
  collapsible,
  isCollapsible,
  columns,
  data,
  defaultSort,
  title,
  showFirstButton,
  showLastButton,
  rowsPerPage = 4,
  noResultFound = 'There are no results',
}: Props<T>): JSX.Element => {
  const [sortDataBy, setSortDataBy] = useState<SortObject<T>>(
    {} as SortObject<T>
  )
  const [page, setPage] = React.useState(1)
  const count = Math.ceil(data.length / rowsPerPage)
  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (defaultSort) {
      setSortDataBy(defaultSort)
    }
  }, [defaultSort])

  const sortData = useMemo(() => {
    if (sortDataBy.sort && sortDataBy.order) {
      return orderBy([...data], [sortDataBy.sort], [sortDataBy.order])
    }

    return data
  }, [data, sortDataBy])
  return (
    <TableContainer id="data-table">
      <Typography variant="h6" pl={2} id="data-table-title">
        {title}
      </Typography>

      <Table
        sx={{
          my: !title ? 0 : 2,
        }}
      >
        <DataTableHeader
          collapsible={collapsible}
          columns={columns}
          sortDataBy={sortDataBy}
          handleSortData={(sort) => setSortDataBy(sort)}
        />
        {data.length ? (
          <DataTableBody
            collapsible={collapsible}
            isCollapsible={isCollapsible}
            columns={columns}
            data={
              rowsPerPage > 0
                ? sortData.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                : sortData
            }
          />
        ) : (
          <TableBody>
            <TableRow sx={{ height: theme.spacing(20) }}>
              <TableCell
                align="center"
                variant="footer"
                colSpan={12}
                sx={{ borderBottom: 'none' }}
              >
                <Typography
                  variant="caption"
                  color={theme.typography.body2.color}
                  textAlign="center"
                >
                  {noResultFound}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>

      <Box display="flex" justifyContent="center" mt={1} pb={5}>
        <Pagination
          id="data-table-pagination"
          count={count}
          page={page}
          variant="text"
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          onChange={handleChange}
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            '& .MuiButtonBase-root': theme.typography.body2,
            '& .Mui-selected': {
              bgcolor: 'transparent !important',
              fontWeight: theme.typography.fontWeightBold,
              ...(count === 1 && {
                color: theme.palette.info.dark,
                fontWeight: theme.typography.fontWeightRegular,
              }),
            },
            '& .Mui-disabled': {
              color: 'info.dark',
            },
          }}
        />
      </Box>
    </TableContainer>
  )
}

export default DataTable
