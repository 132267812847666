import { Typography } from '@mui/material'
import {
  Definition,
  DefinitionView,
} from 'components/DefinitionList/DefinitionView'

export interface Props {
  definitions: Definition[]
  title?: string
}

const DefinitionList = ({ definitions, title }: Props): JSX.Element => {
  return (
    <>
      {title && (
        <Typography
          variant="body2"
          sx={{ color: 'text.primary', fontWeight: 600 }}
        >
          {title}
        </Typography>
      )}
      {definitions.map((definition, index) => (
        <DefinitionView key={index} definition={definition} index={index} />
      ))}
    </>
  )
}

export { DefinitionList }
