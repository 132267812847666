import { Box, Typography } from '@mui/material'
import TooltipPopper from 'components/TooltipPopper'
import { format } from 'date-fns'
import { Label } from 'pages/AcquisitionTracker/components/ToggleLegend'
import React, { useMemo, useState } from 'react'

interface Props {
  calendarDateObj: {
    names: string[]
    type: string
  }
  calendarView: Label
  date: Date
  handleSetCalendarColor: (obj: { names: string[]; type: string }) => string
}

const CalendarDay = ({
  calendarDateObj,
  calendarView,
  date,
  handleSetCalendarColor,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [tooltipOpen, setToolTipOpen] = useState<boolean>(false)

  const handleTooltip = (
    e: React.MouseEvent<HTMLElement> | MouseEvent | TouchEvent
  ) => {
    e.preventDefault()
    if (tooltipOpen) {
      setAnchorEl(null)
      setToolTipOpen(false)
    } else if (e.currentTarget instanceof HTMLElement) {
      setAnchorEl(anchorEl ? null : e.currentTarget)
      setToolTipOpen(true)
    }
  }

  const sortedActions = useMemo(() => {
    return calendarDateObj.names.reduce(
      (result: Record<string, string[]>, current) => {
        const actionList = current.match(/\((.*?)\)/)
        let keyName = current
        let actionItem = ''

        if (actionList) {
          const [initiativeName] = current.split(actionList[0])
          keyName = initiativeName.trim()
          actionItem = actionList[1]
        }

        return {
          ...result,
          [keyName]: result[keyName]
            ? [...result[keyName], actionItem]
            : [actionItem],
        }
      },
      {}
    )
  }, [calendarDateObj])

  const esisContent = () => (
    <Box width="232px">
      <Typography variant="body1">Initiatives</Typography>
      {calendarDateObj.names.join(', ')}
    </Box>
  )

  const actionsContent = () => {
    const titleStyles = { color: '#0E0E0E', fontWeight: 600 }
    const contentStyles = { color: '#0E0E0E', fontWeight: 500 }
    return (
      <>
        <Typography variant="body1">Contracting Actions</Typography>
        <Box display="flex" justifyContent="space-between" width="100%" my={1}>
          <Box width="50%">
            <Typography variant="caption" sx={titleStyles}>
              Initiative
            </Typography>
          </Box>
          <Box width="30%" ml={2}>
            <Typography variant="caption" sx={titleStyles}>
              Actions
            </Typography>
          </Box>
        </Box>
        {Object.keys(sortedActions).map((key, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            width="100%"
            mb={1}
          >
            <Box width="50%">
              <Typography variant="caption" sx={contentStyles}>
                {key}
              </Typography>
            </Box>
            <Box width="30%">
              <Typography variant="caption" sx={contentStyles}>
                {sortedActions[key].join(', ')}
              </Typography>
            </Box>
          </Box>
        ))}
      </>
    )
  }

  return (
    <div>
      <TooltipPopper
        open={tooltipOpen}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        handleTooltip={handleTooltip}
      >
        <Box textAlign="left">
          {calendarView === Label.ESIS && esisContent()}
          {calendarView === Label.ACTIONS && actionsContent()}
        </Box>
      </TooltipPopper>
      <Box
        onClick={handleTooltip}
        sx={{
          alignItems: 'center',
          background: handleSetCalendarColor(calendarDateObj),
          color: 'text.primary',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          height: '100%',
          left: 0,
          top: 0,
          width: '100%',
          zIndex: 2,
        }}
      >
        {format(date, 'd')}
      </Box>
    </div>
  )
}

export default CalendarDay
