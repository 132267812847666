import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  SxProps,
  Theme,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

export interface InfoButtonProps {
  children: ReactNode
  startPosition?: {
    x?: number
    y?: number
  }
  bounds?: string
  sx?: SxProps<Theme>
  popperSx?: SxProps<Theme>
  openInfoLabel: string
  setOpenInfoLabel: Dispatch<SetStateAction<string>>
  label: string
  disablePortal?: boolean
}

export const InfoButton = ({
  children,
  openInfoLabel,
  setOpenInfoLabel,
  label,
  sx,
  popperSx,
  disablePortal,
}: InfoButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handlePopper = (
    event: React.MouseEvent<HTMLElement> | MouseEvent | TouchEvent
  ) => {
    event.preventDefault()
    if (openInfoLabel === label) {
      setOpenInfoLabel('')
      setAnchorEl(null)
    } else if (event.currentTarget instanceof HTMLElement) {
      setAnchorEl(anchorEl ? null : event.currentTarget)
      setOpenInfoLabel(label)
    }
  }
  useEffect(() => {
    if (openInfoLabel !== label) {
      setAnchorEl(null)
    }
  }, [openInfoLabel, label])

  return (
    <IconButton
      size="small"
      sx={{ width: 'fit-content', ...sx }}
      id={label}
      onClick={(e) => handlePopper(e)}
    >
      <Box height="20px">
        <Box>
          <InfoOutlinedIcon
            fontSize="small"
            sx={{ color: 'text.primary', margin: 'auto' }}
          />
        </Box>

        <Popper
          id={'info-popper'}
          open={openInfoLabel === label}
          anchorEl={anchorEl}
          placement="right-start"
          disablePortal={disablePortal}
          sx={{ zIndex: 1000, ...popperSx }}
        >
          <ClickAwayListener onClickAway={(e) => handlePopper(e)}>
            <Paper
              sx={{
                minWidth: '200px',
                maxWidth: '352px',
                minHeight: '10px',
                backgroundColor: 'background.paper',
                boxShadow:
                  '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
                borderRadius: '0px',
                marginLeft: 1,
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Box p={2.5}>{children}</Box>
                <Box onClick={(e) => handlePopper(e)}>
                  <IconButton size="small">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </IconButton>
  )
}
