import { Box, Divider, Typography } from '@mui/material'
import { BasicAccordion } from 'components/Accordian'
import { ControlledFilters } from 'components/RadioGroup/ControlledFilters'
import RatingDefinitions from 'components/RatingDefinitions'
import { Criteria } from 'generated/graphql'
import { useFilteredDiscoveries } from 'hooks/useFilteredData/useFilteredDiscoveries'
import { filter, map, orderBy } from 'lodash'
import { CriteriaComment } from 'pages/Evaluation/CriteriaComment'
import { ViewAndEditDiscovery } from 'pages/Evaluation/ViewAndEditDiscovery'
import { GenericDiscoveryBox } from 'pages/Evaluation/ViewAndEditDiscovery/GenericDiscoveryBox'
import { SyntheticEvent, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../components/Accordion'
import { Props as CriteriaDrawerProps } from '..'

export const CriteriaDrawerContent = ({
  discoveries,
  task,
  criteriaId,
  editDiscovery,
  deleteDiscovery,
  upsertRating,
  setCriteriaDrawerId,
  flagDiscovery,
  discoveryPositions,
}: CriteriaDrawerProps): JSX.Element => {
  const [expandDefinitions, setExpandDefinitions] = useState(true)

  const { filtersData, setFiltersData, getFilteredDiscoveries } =
    useFilteredDiscoveries(task, discoveries)

  const handleChange = (id: string) => (_event: SyntheticEvent) => {
    setCriteriaDrawerId?.('')

    criteriaId !== id && setCriteriaDrawerId?.(id)
  }

  const getDiscoveries = (criteria: Criteria) => {
    const existingDiscoveries = orderBy(
      filter(
        getFilteredDiscoveries(),
        (discovery) => discovery.criteria.id === criteria.id
      ),
      'createdAt',
      'asc'
    )
    return existingDiscoveries
  }

  const getCriteria = () => {
    return orderBy(task?.factor.criteria ?? [], (item) => item.name, 'asc')
  }

  const getDiscoveryPosition = (discoveryId: string): string => {
    const discoveryPosition = discoveryPositions.find(
      (discoveryNum) => discoveryId === discoveryNum.id
    )
    return discoveryPosition?.order || ''
  }

  return (
    <Box>
      {filtersData.length > 0 && (
        <Box position={'relative'} pb={4} mx={-3}>
          <ControlledFilters
            filtersData={filtersData}
            setFiltersData={setFiltersData}
            actionButtonsPlacement={'bottom'}
          />
        </Box>
      )}
      <Box pt={5}>
        <BasicAccordion title={'Factor Definitions'}>
          <Box sx={{ px: 2, pt: 1 }}>
            <Typography variant="subtitle2" mb={1}>
              Factor Definition
            </Typography>
            <Typography variant="body2" my={2}>
              {task.factor.description}
            </Typography>
            <RatingDefinitions data={task.factor.overallRatingOptions} />
          </Box>
        </BasicAccordion>
      </Box>
      <Box pt={5}>
        <Divider />
      </Box>
      <Box pb={4}>
        {map(getCriteria(), (criteria, criteriaIndex) => (
          <Accordion
            key={criteriaIndex}
            expanded={criteriaId === criteria.id}
            onChange={handleChange(criteria.id)}
          >
            <AccordionSummary
              aria-controls={`panel${criteriaIndex + 1}d-content`}
              id={`panel${criteriaIndex + 1}d-header`}
            >
              <Typography variant="h6">
                {criteriaIndex + 1}.0: {criteria.name}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Box id={'discovery-editor-definitions'} mt={2} mb={4}>
                <BasicAccordion
                  title={'Definitions'}
                  expanded={expandDefinitions}
                  onChange={() => setExpandDefinitions(!expandDefinitions)}
                >
                  <Typography variant="body2" mb={4}>
                    {criteria.description}
                  </Typography>

                  <RatingDefinitions
                    data={task.factor.discoveryRatingOptions}
                    title="Discovery Rating Definitions"
                  />

                  <CriteriaComment
                    criteria={criteria}
                    user={task.assignee}
                    ratings={task.factor.criteriaRatingOptions}
                    upsertRating={upsertRating}
                  />
                </BasicAccordion>
              </Box>

              <Typography variant="h6" pb={2}>
                Discoveries
              </Typography>
              {getDiscoveries(criteria).length > 0 ? (
                map(getDiscoveries(criteria), (discovery) => (
                  <Box pb={3} key={discovery.id}>
                    <ViewAndEditDiscovery
                      titleNumbering={`${getDiscoveryPosition(discovery.id)}`}
                      discovery={discovery}
                      ratings={task.factor.discoveryRatingOptions}
                      onEditDiscovery={editDiscovery}
                      onDeleteDiscovery={deleteDiscovery}
                      taskType={task.taskType}
                      customSelectionWidth="150px"
                      onFlagDiscovery={flagDiscovery}
                    />
                  </Box>
                ))
              ) : (
                <Box pb={3}>
                  <GenericDiscoveryBox disabled editingOrDeleting={false} />
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )
}
