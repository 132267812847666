import CloseIcon from '@mui/icons-material/Close'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React, { ReactNode } from 'react'

interface GenericDialogProps {
  open: boolean
  handleClose: () => void
  title: string
  children: ReactNode
}

const GenericDialog = ({
  open,
  handleClose,
  title,
  children,
}: GenericDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{ sx: { borderRadius: '0px' } }}
    >
      <Box
        display="flex"
        width="-webkit-fill-available"
        justifyContent="space-between"
        my={3}
        px={4}
      >
        <Typography id="generic-dialog-title" variant="h5">
          {title}
        </Typography>
        <IconButton
          id="drawer-close-icon-button"
          onClick={handleClose}
          aria-label="close"
          sx={{ height: '21px', width: '21px' }}
        >
          <CloseIcon sx={{ height: '21px', width: '21px' }} color="inherit" />
        </IconButton>
      </Box>
      <Divider sx={{ mx: 4 }} />
      <DialogContent sx={{ py: 0, px: 4 }}>{children}</DialogContent>
    </Dialog>
  )
}

export default GenericDialog
