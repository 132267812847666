import {
  alpha,
  Box,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

export interface Props {
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  placeholder?: string
  rows: number
  variant: 'filled' | 'standard' | 'outlined'
  title?: string
  preview?: string
  type?: string
  name: string
  width?: string
  useFormHook: UseFormReturn
  disabled?: boolean
}

const MultiLineTextField = ({
  rules,
  placeholder,
  rows,
  variant,
  title,
  preview,
  type,
  name,
  width,
  useFormHook,
  disabled,
}: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormHook
  const styleError = Boolean(errors && errors[name])
  return (
    <Box id="multi-line-text-field" width={width ? width : 1}>
      <Typography variant="subtitle2">{title}</Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            id="multi-line-text-field-input"
            multiline
            type={type}
            rows={rows}
            defaultValue={preview}
            placeholder={placeholder ?? ''}
            variant={variant}
            fullWidth
            sx={Object.assign(
              {},
              styleError ? styles.error : styles.neutral,
              disabled && styles.disabled
            )}
            {...field}
          />
        )}
      />
    </Box>
  )
}

export default MultiLineTextField

const styles: Record<string, SxProps<Theme>> = {
  neutral: {
    bgcolor: theme.palette.info.light,
    '& .MuiOutlinedInput-root, &: hover .MuiOutlinedInput-root': {
      '& > fieldset': {
        border: `1px solid ${theme.palette.info.dark}`,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: `4px solid ${theme.palette.primary.light}`,
      },
    },
    '& .MuiInputBase-root': theme.typography.body1,
  },
  disabled: {
    bgcolor: alpha(theme.palette.info.dark, 0.2),
    borderRadius: '4px',
    pointerEvents: 'none',
    border: `1px solid ${alpha(theme.palette.info.dark, 0.45)}`,
    '& .MuiOutlinedInput-root, &: hover .MuiOutlinedInput-root': {
      '& > fieldset': {
        border: `initial`,
      },
    },
  },
  error: {
    bgcolor: alpha(theme.palette.error.light, 0.25),
    '& .MuiOutlinedInput-root,& .MuiOutlinedInput-root.Mui-focused, & .MuiOutlinedInput-root.Mui-error,  &: hover .MuiOutlinedInput-root ':
      {
        '& > fieldset': {
          border: `4px solid ${theme.palette.error.light}`,
        },
      },
    '& .MuiInputBase-root': theme.typography.body1,
  },
}
