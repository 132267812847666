import {
  Box,
  Link as MuiLink,
  outlinedInputClasses,
  Typography,
} from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import SingleLineInput from 'components/form/SingleLineInput'
import { MessageAlert } from 'components/MessageAlert'
import React, { ChangeEvent, useEffect } from 'react'
import { FieldValues, useForm, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

import BackToSignIn from '../BackToSignIn'

interface Props {
  email: string
  handleResendCode: () => void
  handleSubmission: (data: FieldValues) => void
  inputName: string
  useFormHook: UseFormReturn
  isCodeResent?: boolean
}

const TwoStepAuthentication = ({
  email,
  handleResendCode,
  handleSubmission,
  inputName,
  useFormHook,
}: Props): JSX.Element => {
  const localFormHook = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const inputLength = 6

  useEffect(() => {
    localFormHook.setFocus(`${inputName}-0`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (useFormHook.formState.errors[inputName]) {
      Array.from(Array(inputLength).keys()).forEach((value) => {
        localFormHook.setError(`${inputName}-${value}`, { type: 'custom' })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFormHook.formState])

  const handleInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name } = e.target
    const [input, index] = name.split('-')
    const code = Object.values(localFormHook.getValues()).join('')
    if (+index < inputLength - 1) {
      localFormHook.setFocus(`${input}-${+index + 1}`)
    }

    if (code.length === inputLength) {
      useFormHook.setValue(
        inputName,
        Object.values(localFormHook.getValues()).join('')
      )
    }
  }

  return (
    <>
      {!!Object.keys(localFormHook.formState.errors).length && (
        <Box mb={3}>
          <MessageAlert variant="error" />
        </Box>
      )}

      <Box textAlign="center">
        <Typography
          variant="caption"
          sx={{ fontWeight: 500, color: 'text.secondary' }}
        >
          A 6-digit code was sent to {email}. If you don’t see a notification,
          please check your spam folder.
        </Typography>
      </Box>

      <form id="2fa-form" onSubmit={useFormHook.handleSubmit(handleSubmission)}>
        <Box display="flex" justifyContent="center" alignItems="center" py={4}>
          {Array.from(Array(inputLength).keys()).map((index) => (
            <React.Fragment key={index}>
              {inputLength / 2 === index && (
                <hr
                  style={{
                    backgroundColor: '#DADCE0',
                    border: 0,
                    height: '2px',
                    margin: '0 8px 0 0',
                    width: '12px',
                  }}
                />
              )}
              <Box mr={1} height={40} width={32}>
                <SingleLineInput
                  useFormHook={localFormHook}
                  rules={{ required: true }}
                  inputType="text"
                  hideLabel
                  label="MFA"
                  name={`${inputName}-${index}`}
                  handleOnChange={handleInputChange}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      boxSizing: 'border-box',
                      height: '12px',
                      padding: '12px 8px',
                    },
                  }}
                  sx={{
                    input: {
                      [`& .${outlinedInputClasses.input}`]: {
                        padding: 0,
                        textAlign: 'center',
                      },
                      [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                        {
                          borderColor: (theme) =>
                            `${theme.palette.primary.light}!important`,
                          borderWidth: '3px!important',
                        },
                    },
                  }}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>

        <BidscaleButton
          buttonVariant="execute"
          disabled={!localFormHook.formState.isValid}
          sx={{
            marginBottom: (theme) => theme.spacing(3),
            textTransform: 'capitalize',
            width: '100%',
          }}
          type="submit"
        >
          Reset My Password
        </BidscaleButton>

        <Box textAlign="center">
          <Typography variant="caption">
            Haven't received a reset code?
          </Typography>
          <Box my={2}>
            <Typography
              variant="caption"
              fontWeight={theme.typography.fontWeightBold}
              sx={{ cursor: 'pointer' }}
              onClick={handleResendCode}
            >
              <MuiLink color="primary.main">Send me another code</MuiLink>
            </Typography>
          </Box>
          <BackToSignIn />
        </Box>
      </form>
    </>
  )
}

export default TwoStepAuthentication
