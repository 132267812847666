import { isAfter } from 'date-fns'
import { EvaluationTask, EvaluationTaskStatus, File } from 'generated/graphql'
import {
  filter,
  groupBy,
  last,
  map,
  mapValues,
  reduce,
  split,
  upperCase,
  values,
} from 'lodash'

import {
  DocumentsTableRow,
  ReportsStatusType,
  ReportsTableRow,
} from './useDocumentManagerState'

export const getReportsTableData = (
  tasks: EvaluationTask[]
): ReportsTableRow[] => {
  const groups = groupBy(tasks, (task) => task.proposal.id)
  const mappedGroups = values(
    mapValues(groups, (tasks) => ({
      organization: tasks[0].proposal.organization,
      countTasks: tasks.length,
      countCompleteTasks: filter(
        tasks,
        (task) =>
          task.status === EvaluationTaskStatus.Completed ||
          task.status === EvaluationTaskStatus.RequiresReviewNotStarted ||
          task.status === EvaluationTaskStatus.RequiresReviewStarted
      ).length,
      proposalId: tasks[0].proposal.id,
      lastUpdate: latestTaskOfTaskSet(tasks).updatedAt,
    }))
  )
  const completeGroups = map(mappedGroups, (group) => ({
    ...group,
    status:
      group.countCompleteTasks === group.countTasks
        ? ReportsStatusType.FINISHED
        : ReportsStatusType.NOT_FINISHED,
  }))
  return completeGroups
}

export const latestTaskOfTaskSet = (
  tasks: EvaluationTask[]
): EvaluationTask => {
  return reduce(
    tasks,
    (latestTask, currentTask): EvaluationTask => {
      return isAfter(
        new Date(latestTask?.updatedAt),
        new Date(currentTask?.updatedAt)
      )
        ? latestTask
        : currentTask
    },
    tasks[0]
  )
}

export const getDocumentsTableData = (files: File[]): DocumentsTableRow[] =>
  map(files, (file) => ({
    name: file.name,
    id: file.id,
    key: file.key,
    user: file.user.firstName + ' ' + file.user.lastName,
    tag: file.tag,
    date: file.updatedAt,
    type: upperCase(last(split(file.name, '.'))),
  }))
