import { KpiData } from 'components/Kpi/LargeKpi'
import { FilterData } from 'components/RadioGroup/Filters'
import { Initiative } from 'generated/graphql'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  AreaProps,
  calculateAnticipatedActions,
  calculateApproachingDue,
  calculateChartData,
  calculateFilteredInitiatives,
  calculateFundedActionsInFiscalYear,
  calculateInitiativesWithExpiringFunds,
  calculateUnfundedInitiatives,
  calculateWithoutProgress,
  generateCalendarProps,
  getInitialFiltersData,
  SortedCalendarDate,
} from './AcquisitionTrackerStateFunctions'

export interface useAcquisitionTrackerTransformationReturn {
  keyPerformanceIndicatorsData: KpiData[]
  areaChartProps: AreaProps
  calendarDates: SortedCalendarDate
  filtersData: FilterData[]
  setFiltersData: Dispatch<SetStateAction<FilterData[]>>
  getFilteredInitiatives: () => Initiative[]
}

export enum WorkflowDashboardTaskStatus {
  'AtRisk' = 'At Risk',
  'Active' = 'Active',
  'NotStarted' = 'Not Started',
  'Completed' = 'Completed',
}

export interface TaskStatusCountsObject {
  [WorkflowDashboardTaskStatus.Completed]: number
  [WorkflowDashboardTaskStatus.NotStarted]: number
  [WorkflowDashboardTaskStatus.Active]: number
  [WorkflowDashboardTaskStatus.AtRisk]: number
}

export interface StatusTableRow {
  id: string
  name: string
  role: string
  status: WorkflowDashboardTaskStatus
  tasksComplete: number
  totalTasks: number
  details: TaskDetails[]
}

export interface TaskDetails {
  taskName: string
  factorName: string
  offerer: string
  status: WorkflowDashboardTaskStatus
  dueDate: string
  countDiscoveries: number
}

const useAcquisitionTrackerTransformation = (
  initiatives: Initiative[]
): useAcquisitionTrackerTransformationReturn => {
  const [calendarDates, setCalendarDates] = useState<SortedCalendarDate>({})

  const [keyPerformanceIndicatorsData, setKeyPerformanceIndicatorsData] =
    useState<KpiData[]>([])
  const [areaChartProps, setAreaChartProps] = useState<AreaProps>({
    data: {
      xLabel: '',
      yLabels: [],
      data: [],
    },
    chartOptions: {},
  })

  const [filtersData, setFiltersData] = useState<FilterData[]>([])
  const getFilteredInitiatives = useCallback(() => {
    return calculateFilteredInitiatives(filtersData, initiatives)
  }, [filtersData, initiatives])

  useEffect(() => {
    setFiltersData(getInitialFiltersData(initiatives))
  }, [initiatives])

  useEffect(() => {
    if (initiatives.length > 0) {
      const filteredInitiatives = getFilteredInitiatives()
      const unfundedFilteredInitiatives =
        calculateUnfundedInitiatives(filteredInitiatives)
      setKeyPerformanceIndicatorsData([
        calculateApproachingDue(unfundedFilteredInitiatives),
        calculateWithoutProgress(unfundedFilteredInitiatives),
        calculateAnticipatedActions(unfundedFilteredInitiatives),
        calculateInitiativesWithExpiringFunds(unfundedFilteredInitiatives),
        calculateFundedActionsInFiscalYear(
          filteredInitiatives.length - unfundedFilteredInitiatives.length
        ),
      ])
      setAreaChartProps(calculateChartData(unfundedFilteredInitiatives))
      setCalendarDates(generateCalendarProps(filteredInitiatives))
    }
  }, [getFilteredInitiatives, initiatives])

  return {
    keyPerformanceIndicatorsData,
    areaChartProps,
    calendarDates,
    filtersData,
    setFiltersData,
    getFilteredInitiatives,
  }
}

export { useAcquisitionTrackerTransformation }
