export enum UpdatedDate {
  GREEN = '< 3 Days',
  GRAY = '3-7 Days',
  RED = '> 7 Days',
}

export enum EscalationStatus {
  CSUITE = 'C-Suite',
  KO_PO = 'KO/PM',
  PEO = 'PEO',
}

export enum AquisitionTable {
  INITIATIVE = 'Initiative',
  AWARD_DATE = 'Award Date',
  PHASE = 'Phase',
  LAST_UPDATED = 'Last Updated',
  BUSINESS = 'Business Unit',
  WIP = 'WIP Health',
  ESCALATION = 'Escalation',
}

export enum KpiVerbiage {
  Anticipated_Actions = 'Anticipated Actions in Next 60 Days',
  Expiring = 'Initiatives with Expiring Funds',
  Approaching_Due_Date = 'Initiatives Approaching Due in Next 60 Days',
  Without_Progress = 'Initiatives Without Recent Progress',
  Funded_Actions = 'Funded Actions in FY',
}

export const month = new Date().getUTCMonth()
export const year = new Date().getFullYear()
export const FYStart = month >= 9 ? new Date(year, 9) : new Date(year - 1, 9)
export const fundedActionsInFY =
  KpiVerbiage.Funded_Actions +
  (FYStart.getFullYear() + 1).toString().substring(2)
