import { Typography } from '@mui/material'
import { EvaluationTask } from 'generated/graphql'
import {
  getDaysRemaining,
  getTaskStatusCounts,
} from 'pages/WorkflowDashboard/state/WorkflowStateFunctions'
import React from 'react'

export interface Props {
  tasks?: EvaluationTask[]
}

const ActionsMessage = ({ tasks }: Props): JSX.Element => {
  const statusCounts = getTaskStatusCounts(tasks || [])
  const daysRemaining = getDaysRemaining(tasks || [])

  const getMessage = (): string => {
    if (statusCounts['At Risk'] === 0) {
      if (tasks && tasks.length > 0) {
        return `You have ${daysRemaining} days left to complete ${tasks.length} open
              proposal review actions.`
      }
      return 'Great Work! You have 0 open proposal review actions.'
    }
    return `You have ${statusCounts['At Risk']} task${
      statusCounts['At Risk'] === 1 ? '' : 's'
    } behind schedule. You have ${daysRemaining} days to complete ${
      tasks?.length
    } open proposal review action${tasks?.length === 1 ? '.' : 's.'}`
  }
  return (
    <Typography variant="h1" mb={4} sx={{ lineHeight: '38px' }}>
      {getMessage()}
    </Typography>
  )
}

export default ActionsMessage
