import '@fontsource/roboto'
import '@fontsource/inter'
import '@fontsource/roboto-slab'
import '@fontsource/lora'
import './assets/fonts/index.css'

import { createTheme, ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    chips: {
      dollar: {
        default: string
        main: string
        mid: string
        dark: string
        text: string
      }
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    chips?: {
      dollar: {
        default: string
        main: string
        mid: string
        dark: string
        text: string
      }
    }
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: '#6AE4D0',
      main: '#26AA94',
      dark: '#127A69',
    },
    secondary: {
      light: '#FFE29C',
      main: '#F8BD25',
      dark: '#E0A815',
    },
    info: {
      light: '#FFFFFF',
      main: '#EEEEEE',
      dark: '#C0C2C7',
    },
    success: {
      light: '#61CE9D',
      main: '#039855',
      dark: '#087745',
    },
    text: {
      primary: '#003845',
      secondary: '#6D758D',
    },
    background: {
      default: '#FCFEFF',
      paper: '#FFFFFF',
    },
    warning: {
      light: '#f39F55',
      main: '#F47200',
      dark: '#B95702',
    },
    error: {
      light: '#FF9292',
      main: '#D92D20',
      dark: '#9E1B1B',
    },
  },
  typography: {
    fontFamily: ['Lora', 'proxima-nova'].join(','),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      color: '#003845',
      fontFamily: 'Lora',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '38px',
    },
    h2: {
      color: '#003845',
      fontFamily: 'Lora',
      fontSize: '60px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '72px',
    },
    h3: {
      color: '#003845',
      fontFamily: 'Lora',
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '60px',
    },
    h4: {
      color: '#003845',
      fontFamily: 'Lora',
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '44px',
    },
    h5: {
      color: '#003845',
      fontFamily: 'Lora',
      fontSize: '26px',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '38px',
    },
    h6: {
      color: '#003845',
      fontFamily: 'Lora',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '26px',
    },
    body1: {
      color: '#003845',
      fontFamily: 'proxima-nova',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '28px',
    },
    body2: {
      color: '#6D758D',
      fontFamily: 'proxima-nova',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '28px',
    },
    subtitle1: {
      color: '#003845',
      fontFamily: 'proxima-nova',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '24px',
    },
    subtitle2: {
      color: '#003845',
      fontFamily: 'proxima-nova',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '24px',
    },
    button: {
      color: '#003845',
      fontFamily: 'proxima-nova',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '32px',
      textTransform: 'none',
    },
    caption: {
      color: '#003845',
      fontFamily: 'proxima-nova',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '20px',
    },
    overline: {
      color: '#003845',
      fontFamily: 'proxima-nova',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0.12em',
      lineHeight: '24px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '28px',
        },
        sizeSmall: {
          height: '22px',
        },
        sizeLarge: {
          height: '28px',
        },
        contained: {
          webkitBoxShadow: 'none',
          mozBoxShadow: 'none',
          boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '32px',
          paddingRight: '32px',
        },
      },
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: '0px',
          margin: '0px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: '0px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: '#5E5E5E',
          fontSize: '14px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#FFFFFF',
          '&:focus': {
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },
  },
  chips: {
    dollar: {
      default: '#0064FF',
      main: '#004ABC',
      mid: '#00317D',
      dark: '#001A41',
      text: '#667085',
    },
  },
}

export const theme = createTheme(themeOptions)
