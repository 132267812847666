import { Link as MuiLink, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { theme } from 'theme'

const BackToSignIn = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Typography
      variant="caption"
      display="flex"
      justifyContent="center"
      mt={1}
      fontWeight={theme.typography.fontWeightBold}
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate('/login')}
    >
      <MuiLink color="primary.main">Back to Sign In</MuiLink>
    </Typography>
  )
}

export default BackToSignIn
