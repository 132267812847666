import { Solicitation, useGetNavigationDataQuery } from 'generated/graphql'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useMemo } from 'react'

export interface NavigationPageState {
  solicitations: Solicitation[]
  isFetching: boolean
}

export function useNavigationPageState(): NavigationPageState {
  const userContext = useContext(UserContext)
  const userId = userContext.user.userId
  const tenantId = userContext.user.tenant.id

  const [query] = useGetNavigationDataQuery({
    variables: { userId, tenantId },
    pause: userId === '' || tenantId === '',
  })

  const getsolicitations = useMemo(
    () => query?.data?.listSolicitationByUser as Solicitation[],
    [query?.data?.listSolicitationByUser]
  )

  return {
    solicitations: getsolicitations ?? [],
    isFetching: query.fetching,
  }
}
