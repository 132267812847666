import { EvaluationTask, RatingInput, Scalars } from 'generated/graphql'
import { DiscoveryPositions } from 'hooks/useSortedCriteria'

import {
  TotalDiscovery,
  UpdateDiscovery,
} from '../state/useEvaluationPageState'
import { CriteriaDrawerContent } from './CriteriaDrawerContent'

export interface Props extends UpdateDiscovery {
  task: EvaluationTask
  discoveries: TotalDiscovery[]
  upsertRating: (inputs: {
    rating: RatingInput
    criteriaId?: Scalars['ID']
  }) => void
  criteriaId?: Scalars['ID']
  setCriteriaDrawerId?: (id: string) => void
  discoveryPositions: DiscoveryPositions[]
}

const CriteriaDrawer = ({
  task,
  criteriaId,
  setCriteriaDrawerId,
  editDiscovery,
  deleteDiscovery,
  upsertRating,
  flagDiscovery,
  discoveries,
  discoveryPositions,
}: Props): JSX.Element => {
  return (
    <CriteriaDrawerContent
      discoveryPositions={discoveryPositions}
      task={task}
      discoveries={discoveries}
      editDiscovery={editDiscovery}
      deleteDiscovery={deleteDiscovery}
      upsertRating={upsertRating}
      criteriaId={criteriaId}
      setCriteriaDrawerId={setCriteriaDrawerId}
      flagDiscovery={flagDiscovery}
    />
  )
}

export { CriteriaDrawer }
