import CommentSharpIcon from '@mui/icons-material/CommentSharp'
import { alpha, Box, Typography } from '@mui/material'
import { ActionMenuPopper } from 'components/ActionMenuPopper'
import { KebabButton } from 'components/Buttons/KebabButton'
import { Discovery, Scalars } from 'generated/graphql'
import LastLevelMenuItem from 'pages/Evaluation/EvaluationLeftPanel/LastLevelMenuItem'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import React, { useContext, useState } from 'react'

import { SelectionProps } from '../TopLevelMenuItem'

interface Props extends SelectionProps {
  discovery: Discovery
  openEditDiscoveryDrawer: (discoveryId: Scalars['ID']) => void
  openDeleteDiscoveryDrawer: (discoveryId: Scalars['ID']) => void
  discoveryOrder: number
  criteriaOrder: number
  jumpToPage: (jumpTo?: number | undefined) => void
  openViewDiscoveryDrawer: (discoveryId: Array<Scalars['ID']>) => void
}

const MidLevelMenuItem = ({
  discovery,
  openEditDiscoveryDrawer,
  openDeleteDiscoveryDrawer,
  discoveryOrder,
  criteriaOrder,
  annotationSelected,
  setAnnotationSelected,
  openDiscovery,
  setOpenDiscovery,
  jumpToPage,
  openViewDiscoveryDrawer,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element>()
  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false)
  const { user } = useContext(UserContext)

  const discoveryClicked = openDiscovery === discovery.id

  const handleKebabClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget as Element)
    setIsActionMenuOpen(!isActionMenuOpen)
  }
  const handleClick = () => {
    if (discoveryClicked) {
      setOpenDiscovery('')
      setAnnotationSelected('')
    } else {
      setOpenDiscovery(discovery.id)
      if (discovery.annotations.length) {
        setAnnotationSelected(discovery.annotations[0].id)
        jumpToPage(discovery.annotations[0].startPage)
      }
    }
  }

  const getContextMenuItems = () => {
    if (discovery.author.id.includes(user.email)) {
      return [
        discovery.feedback[0] && !discovery.feedback[0].acknowledged
          ? {
              itemName: 'View Feedback',
              onClick: () => {
                setIsActionMenuOpen(false)
                openViewDiscoveryDrawer([discovery.id])
              },
            }
          : {
              itemName: 'Edit',
              onClick: () => {
                setIsActionMenuOpen(false)
                openEditDiscoveryDrawer(discovery.id)
              },
            },
        {
          itemName: 'Remove',
          onClick: () => {
            setIsActionMenuOpen(false)
            openDeleteDiscoveryDrawer(discovery.id)
          },
        },
      ]
    }

    return [
      {
        itemName: 'View Discovery',
        onClick: () => {
          setIsActionMenuOpen(false)
          openViewDiscoveryDrawer([discovery.id])
        },
      },
      {
        itemName: 'Request Change',
        onClick: () => {
          setIsActionMenuOpen(false)
          openViewDiscoveryDrawer([discovery.id])
        },
      },
    ]
  }

  return (
    <>
      <Box
        p={1}
        bgcolor={discoveryClicked ? 'primary.main' : 'transparent'}
        color={discoveryClicked ? 'info.light' : 'text.primary'}
        fontWeight={discoveryClicked ? 600 : 'typography.fontWeightRegular'}
        sx={{
          '&:hover': {
            backgroundColor: discoveryClicked
              ? 'primary.main'
              : (theme) => alpha(theme.palette.primary.main, 0.2),
            color: discoveryClicked ? 'info.light' : 'primary.dark',
          },
        }}
      >
        <Box display="flex" alignItems="center" pl={4}>
          <CommentSharpIcon sx={{ cursor: 'pointer' }} onClick={handleClick} />
          <Typography
            color="inherit"
            ml={1.5}
            variant="caption"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              flex: '1 1 90%',
              fontSize: '15px',
              fontWeight: 'inherit',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {criteriaOrder}.{discoveryOrder}: {discovery.rating.comment}
          </Typography>
          <KebabButton onClick={handleKebabClick} />
          <ActionMenuPopper
            anchorEl={anchorEl}
            isOpen={isActionMenuOpen}
            setOpen={setIsActionMenuOpen}
            actionItems={getContextMenuItems()}
            fallbackPlacements={['bottom']}
          />
        </Box>
      </Box>
      {discoveryClicked && (
        <>
          {discovery.annotations.map((annotation) => (
            <LastLevelMenuItem
              key={annotation.id}
              annotation={annotation}
              setAnnotationSelected={setAnnotationSelected}
              annotationSelected={annotationSelected}
              jumpToPage={jumpToPage}
            />
          ))}
        </>
      )}
    </>
  )
}

export default MidLevelMenuItem
