// https://reactjs.org/docs/error-boundaries.html

import ErrorPage from 'pages/ErrorPage'
import React, { ReactNode } from 'react'

interface State {
  hasError: boolean
}

class ApplicationError extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render(): ReactNode {
    // TODO: Update markup when email, help, and help desk processes are established.

    if (this.state.hasError) {
      return <ErrorPage />
    }
    return this.props.children
  }
}

export default ApplicationError
