import 'react-calendar/dist/Calendar.css'

import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { format } from 'date-fns'
import { Label } from 'pages/AcquisitionTracker/components/ToggleLegend'
import CalendarDay from 'pages/AcquisitionTracker/ExecutiveCalendarContainer/CalendarDay'
import { SortedCalendarDate } from 'pages/AcquisitionTracker/state/AcquisitionTrackerStateFunctions'
import { useState } from 'react'
import Calendar, { CalendarTileProperties } from 'react-calendar'

export interface Props {
  date: Date
  calendarDates: SortedCalendarDate
  calendarView: Label
  handleSetCalendarColor: (obj: { names: string[]; type: string }) => string
}

const MonthCalendar = ({
  date,
  calendarDates,
  calendarView,
  handleSetCalendarColor,
}: Props): JSX.Element => {
  const [value, onChange] = useState(date)
  const classes = useStyles()

  const outputDaysOfTheMonth = ({ date, view }: CalendarTileProperties) => {
    const isoDate = calendarDates[date.toLocaleDateString()]
    if (view === 'month' && isoDate) {
      return (
        <CalendarDay
          calendarDateObj={isoDate}
          calendarView={calendarView}
          date={date}
          handleSetCalendarColor={handleSetCalendarColor}
        />
      )
    }

    return null
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Calendar
        allowPartialRange={true}
        className={classes.calendar}
        onChange={(d: Date) => {
          onChange(d)
        }}
        calendarType="US"
        value={value}
        navigationLabel={() => (
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" className={classes.month}>
              {Array.isArray(value) && value.length
                ? format(value[0], 'MMMM yyyy')
                : format(value, 'MMMM yyyy')}
            </Typography>
          </Box>
        )}
        nextLabel={null}
        next2Label={null}
        prevLabel={null}
        prev2Label={null}
        tileContent={outputDaysOfTheMonth}
        formatShortWeekday={(locale, date) => format(date, 'cccccc')}
        maxDetail="month"
        minDetail="month"
        tileDisabled={() => true}
        showFixedNumberOfWeeks
      />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  calendar: {
    border: `1px solid ${theme.palette.info.dark} !important`,
    borderRadius: '5px',

    padding: theme.spacing(1.75, 1.75),
    width: '298px',
    '& .react-calendar__navigation': {
      height: 'auto',
    },
    '& .react-calendar__navigation__label': {
      padding: 0,
    },
    '& .react-calendar__month-view__weekdays': {
      marginBottom: theme.spacing(2),
      textTransform: 'capitalize',
    },
    '& .react-calendar__month-view__weekdays__weekday': {
      ...theme.typography.caption,
      fontWeight: 600,
      lineHeight: '16px',
    },
    '& .react-calendar__month-view__weekdays__weekday abbr': {
      textDecoration: 'none',
    },
    '& .react-calendar__month-view__days__day--weekend': {
      ...theme.typography.caption,
    },
    '& .react-calendar__month-view__days__day--neighboringMonth, .react-calendar__tile:disabled.react-calendar__month-view__days__day--neighboringMonth':
      {
        color: theme.palette.info.dark,
        '& *': {
          background: 'transparent!important',
          color: theme.palette.info.dark,
        },
      },
    '& .react-calendar__tile': {
      height: '36px',
      padding: 0,
      width: '36px',
    },
    '& .react-calendar__tile--now': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus':
      {
        backgroundColor: '#e6e6e6',
        color: '#000',
      },
    '& .react-calendar__tile--active': {
      backgroundColor: '#fff',
      color: '#454545',
    },
    '& .react-calendar--selectRange .react-calendar__tile--hover, .react-calendar__tile--now.react-calendar__tile--hover':
      {
        backgroundColor: '#e6e6e6',
      },
    '& .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus':
      {
        backgroundColor: '#e6e6e6',
      },
    '& .react-calendar__tile.react-calendar__month-view__days__day': {
      ...theme.typography.caption,
      overflow: 'visible!important',
      position: 'relative',
      '& abbr': {
        position: 'relative',
        zIndex: 1,
      },
    },
    '& .react-calendar__tile:disabled, .react-calendar__navigation button:disabled':
      {
        backgroundColor: '#fff',
        ...theme.typography.caption,
      },
  },
  colorMarker: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  fontBold: {
    fontWeight: 700,
  },
  month: {
    fontSize: '18px',
    fontWeight: 500,
  },
  popperType: {
    color: '#454545',
    fontSize: '12px',
    textAlign: 'center',
  },
}))

export default MonthCalendar
