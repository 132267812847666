import { Box, Typography } from '@mui/material'
import {
  Criteria,
  DiscoveryInput,
  EvaluationTaskType,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { DiscoveryPositions, SortedCriteria } from 'hooks/useSortedCriteria'
import {
  TotalDiscovery,
  UpdateDiscovery,
} from 'pages/Evaluation/state/useEvaluationPageState'
import { ViewAndEditDiscovery } from 'pages/Evaluation/ViewAndEditDiscovery'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../components/Accordion'

interface Props extends UpdateDiscovery {
  discoveries: TotalDiscovery[]
  ratings: RatingOption[]
  discoveryPositions: DiscoveryPositions[]
  sortedCriteria: SortedCriteria[]
  taskType: EvaluationTaskType
  flagDiscovery: (
    discoveryInput: DiscoveryInput,
    discoveryTaskId: Scalars['ID']
  ) => Promise<boolean>
  discoveryCriteriaMap: Array<{
    criteria: Criteria
    discoveries: TotalDiscovery[]
  }>
}

const ViewDiscoveriesDrawerContent = ({
  ratings,
  discoveryPositions,
  taskType,
  editDiscovery,
  deleteDiscovery,
  flagDiscovery,

  discoveryCriteriaMap,
}: Props): JSX.Element => {
  const getDiscoveryPosition = (discoveryId: string): string => {
    const discoveryPosition = discoveryPositions.find(
      (discoveryNum) => discoveryId === discoveryNum.id
    )
    return discoveryPosition?.order || ''
  }

  return (
    <>
      {discoveryCriteriaMap.map(
        (
          discoveryCriteria: {
            criteria: Criteria
            discoveries: TotalDiscovery[]
          },
          index: number
        ) => (
          <Accordion
            defaultExpanded
            key={discoveryCriteria.criteria.id}
            sx={{ borderBottom: 'none' }}
          >
            <AccordionSummary
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}d-header`}
              sx={{ px: 0 }}
            >
              <Typography variant="h6">{`${index + 1}.0 ${
                discoveryCriteria.criteria.name
              }`}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              {discoveryCriteria.discoveries.map(
                (discovery: TotalDiscovery) => (
                  <Box my={2} key={discovery.id}>
                    <ViewAndEditDiscovery
                      key={`viewAndEdit-${discovery.id}`}
                      taskType={taskType}
                      discovery={discovery}
                      titleNumbering={getDiscoveryPosition(discovery.id)}
                      title={`Discovery`}
                      ratings={ratings}
                      onEditDiscovery={editDiscovery}
                      onDeleteDiscovery={deleteDiscovery}
                      onFlagDiscovery={flagDiscovery}
                    />
                  </Box>
                )
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
    </>
  )
}

export default ViewDiscoveriesDrawerContent
