import { Cache as UrqlCache, ResolveInfo } from '@urql/exchange-graphcache'
import {
  CommunicationType,
  DeletedEntity,
  DeleteFileMutation,
  Exact,
  FileTag,
  GetDocumentManagerPageDataDocument,
  GetDocumentManagerPageDataQuery,
  Maybe,
  MutationDeleteFileArgs,
  MutationUpsertFileArgs,
  Scalars,
  Solicitation,
  TransferType,
  UpsertFileInput,
  UpsertFileMutation,
  UpsertFileMutationVariables,
  useDeleteFileMutation,
  useGenerateReportMutation,
  useGetDocumentManagerPageDataQuery,
  useGetFileQuery,
  useUpsertFileMutation,
} from 'generated/graphql'
import { map, remove, set } from 'lodash'
import { useSnackbar } from 'notistack'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'
import { OperationResult } from 'urql'

import {
  getDocumentsTableData,
  getReportsTableData,
} from './DocumentManagerFunctions'

export interface ReportsTableRow extends Record<string, unknown> {
  organization: string
  countTasks: number
  countCompleteTasks: number
  proposalId: Scalars['ID']
  lastUpdate: Scalars['DateTime']
  status: ReportsStatusType
}

export enum ReportsStatusType {
  FINISHED = 'Finished',
  NOT_FINISHED = 'Not Finished',
}

export interface DocumentsTableRow {
  name: string
  id: string
  key: string
  user: string
  tag?: Maybe<FileTag>
  date: Scalars['DateTime']
  type: string
}

export interface DocumentManagerReturn {
  reportsTableData: ReportsTableRow[]
  documentsTableData: DocumentsTableRow[]
  solicitation?: Solicitation
}

interface DocumentStateProps {
  solicitation: Solicitation | undefined
  generateReport: (proposalId: Scalars['ID']) => Promise<unknown>
  deleteFile: (
    fileId: Scalars['ID'],
    proposalId?: Scalars['ID']
  ) => Promise<boolean>
  editFile: (
    id: Scalars['ID'],
    name: Scalars['String'],
    tag?: Maybe<FileTag>
  ) => Promise<boolean>
  downloadFile: (fileId: Scalars['ID']) => void
  upsertFile: (
    input: UpsertFileInput
  ) => Promise<
    OperationResult<UpsertFileMutation, Exact<MutationUpsertFileArgs>>
  >
}

const useDocumentManagerState = (
  solicitationId: Scalars['ID']
): DocumentStateProps => {
  const userContext = useContext(UserContext)
  const tenantId = userContext.user.tenant.id

  const { enqueueSnackbar } = useSnackbar()

  const [solicitation, setSolicitation] = useState<Solicitation | undefined>()

  const [query] = useGetDocumentManagerPageDataQuery({
    variables: { tenantId: tenantId, solicitationId: solicitationId },
    pause: tenantId === '' || solicitationId === '',
  })
  const [fileId, setFileId] = useState<string | null>(null)

  const [_generateReportResult, generateReportMutation] =
    useGenerateReportMutation()

  const [_deleteFileResult, deleteFileMutation] = useDeleteFileMutation()
  const [_upsertFileResult, upsertFileMutation] = useUpsertFileMutation()
  const [getFileResult] = useGetFileQuery({
    pause: !fileId,
    variables: {
      tenantId: tenantId,
      solicitationId: solicitationId,
      fileId: fileId || '',
      type: TransferType.Download,
    },
  })

  useEffect(() => {
    if (query?.data) {
      setSolicitation(query?.data.getSolicitation as Solicitation)
    }
  }, [query])

  const upsertFile = (input: UpsertFileInput) => {
    return upsertFileMutation({ tenantId, solicitationId, input })
  }

  const generateReport = async (proposalId: Scalars['ID']) => {
    enqueueSnackbar(`Please wait, report is generating.`)

    const generateReportResult = await generateReportMutation({
      tenantId: tenantId,
      solicitationId: solicitationId,
      proposalId: proposalId,
    })

    if (generateReportResult.error) {
      enqueueSnackbar(
        `There was a problem generating the report. Please try again.`,
        {
          variant: 'error',
        }
      )
    } else {
      enqueueSnackbar(`Report successfully generated.`)
    }

    const presignedUrl =
      generateReportResult.data?.generateReport.transfer.presignedUrl
    presignedUrl && window.location.assign(presignedUrl)

    return generateReportResult
  }

  const editFile = async (
    id: Scalars['ID'],
    name: Scalars['String'],
    tag?: Maybe<FileTag>
  ) => {
    const editFileResult = await upsertFileMutation({
      tenantId: tenantId,
      solicitationId: solicitationId,
      input: tag ? { id, name, tag } : { id, name },
    })

    if (editFileResult.error) {
      enqueueSnackbar(
        `There was a problem updating the file. Please try again.`,
        {
          variant: 'error',
        }
      )
      return false
    } else {
      enqueueSnackbar(`This file has been updated and saved.`)
      return true
    }
  }

  const deleteFile = async (
    fileId: Scalars['ID'],
    proposalId?: Scalars['ID']
  ) => {
    const deleteFileResult = await deleteFileMutation({
      id: fileId,
      tenantId: tenantId,
      proposalId: proposalId,
      solicitationId: solicitationId,
    })

    if (deleteFileResult.error) {
      enqueueSnackbar(
        `There was a problem removing the file. Please try again.`,
        {
          variant: 'error',
        }
      )
      return false
    } else {
      enqueueSnackbar(`File successfully removed.`)
      return true
    }
  }

  const downloadFile = (fileId: Scalars['ID']) => {
    setFileId(fileId)
  }

  useEffect(() => {
    if (getFileResult.error) {
      enqueueSnackbar(
        'There was a problem downloading the file, Please try again.',
        { variant: 'error' }
      )
    } else if (getFileResult.data?.getFile) {
      const presignedUrl = getFileResult.data?.getFile?.transfer.presignedUrl
      presignedUrl && window.location.assign(presignedUrl)
      setFileId(null)
    }
  }, [enqueueSnackbar, getFileResult])

  return {
    solicitation,
    generateReport,
    editFile,
    deleteFile,
    upsertFile,
    downloadFile,
  }
}

const useDocumentManagerTransform = (
  solicitation?: Solicitation
): DocumentManagerReturn => {
  return solicitation
    ? {
        reportsTableData: getReportsTableData(solicitation?.tasks),
        documentsTableData: getDocumentsTableData(solicitation?.files),
        solicitation,
      }
    : {
        reportsTableData: [],
        documentsTableData: [],
        solicitation: undefined,
      }
}

function deleteFileCache(
  result: DeleteFileMutation,
  args: MutationDeleteFileArgs,
  cache: UrqlCache,
  _info: ResolveInfo
): void {
  const query = GetDocumentManagerPageDataDocument
  cache.updateQuery(
    {
      query,
      variables: {
        tenantId: args.tenantId,
        solicitationId: args.solicitationId,
      },
    },
    (data: GetDocumentManagerPageDataQuery | null) => {
      if (args.communication === CommunicationType.Sync) {
        const deletedEntity = result?.deleteFile as DeletedEntity
        deletedEntity?.id &&
          remove(
            data?.getSolicitation?.files || [],
            (file) => file.id === deletedEntity.id
          )
      }

      return data
    }
  )
}

function upsertFileCache(
  result: UpsertFileMutation,
  args: UpsertFileMutationVariables,
  cache: UrqlCache,
  _info: ResolveInfo
): void {
  const query = GetDocumentManagerPageDataDocument
  const isCreate = !args.input?.id
  cache.updateQuery(
    {
      query,
      variables: {
        tenantId: args.tenantId,
        solicitationId: args.solicitationId,
      },
    },
    (data: GetDocumentManagerPageDataQuery | null) => {
      if (isCreate) {
        result?.upsertFile &&
          data?.getSolicitation?.files.unshift(result?.upsertFile)
        return data
      }
      const updatedFiles = map(data?.getSolicitation?.files, (file) => {
        if (file.id === result?.upsertFile.id) {
          const input = {
            ...args.input,
            tag: args.input?.tag ?? null,
          }

          return {
            ...file,
            ...input,
          }
        }
        return file
      })
      data && set(data, 'getSolicitation.files', updatedFiles)
      return data
    }
  )
}

export {
  useDocumentManagerState,
  useDocumentManagerTransform,
  deleteFileCache,
  upsertFileCache,
}
