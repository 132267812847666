import { Box, Link as MuiLink, Typography } from '@mui/material'
import React from 'react'

const ContactUs = (): JSX.Element => {
  return (
    <Box>
      <Typography variant="caption" sx={{ fontWeight: 700 }}>
        Contact Us:{' '}
        <MuiLink
          href="mailto:info@bidscale.com"
          target="_blank"
          rel="noreferrer"
          color="primary.main"
        >
          info@bidscale.com
        </MuiLink>
      </Typography>
    </Box>
  )
}

export default ContactUs
