import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ButtonProps, IconButton, SxProps, Theme } from '@mui/material'
import { theme } from 'theme'

export const KebabButton = ({
  onClick,
  sx,
  disabled,
}: ButtonProps): JSX.Element => {
  return (
    <IconButton
      id="kebab-button"
      onClick={onClick}
      sx={
        [
          {
            color: `${theme.palette.text.primary} !important`,
            '&:disabled': { color: 'info.dark' },
          },
          sx,
        ] as SxProps<Theme>
      }
      disabled={disabled}
    >
      <MoreVertIcon />
    </IconButton>
  )
}
