import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { alpha, Box, SxProps, Theme, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React from 'react'
import { theme } from 'theme'

export interface Props {
  title: string
  id?: string
  children?: React.ReactNode
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void
  expanded?: boolean
  sx?: SxProps<Theme>
}

export const BasicAccordion = ({ sx, ...props }: Props): JSX.Element => {
  return (
    <Box>
      <Accordion
        expanded={props.expanded}
        onChange={props.onChange}
        sx={{
          backgroundColor: alpha(theme.palette.info.main, 0.5),
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: alpha(theme.palette.info.main, 1),
          },
          '&.Mui-expanded:hover': {
            backgroundColor: alpha(theme.palette.info.main, 0.5),
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ArrowForwardIosSharpIcon
              fontSize="inherit"
              sx={{
                color: 'text.primary',
              }}
            />
          }
          aria-controls={`${props.id}-accordion-content` ?? 'accordion-content'}
          id={props.id ?? 'accordion-header'}
          sx={Object.assign(
            {
              height: 45,
              minHeight: 45,
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
              },
            },
            ...(Array.isArray(sx) ? sx : [sx])
          )}
        >
          <Typography variant="body2">{props.title}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box>{props.children}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
