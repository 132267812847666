import { Box, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import SingleLineInput from 'components/form/SingleLineInput'
import { MessageAlert } from 'components/MessageAlert'
import ContactUs from 'pages/Login/Authentication/ContactUs'
import React, { ChangeEvent, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

export interface Props {
  handleSubmission: (e: FieldValues) => void | Promise<void> | null
  useFormHook: UseFormReturn
  showResetError?: boolean
}

export const ResetPasswordForm = ({
  handleSubmission,
  useFormHook,
  showResetError,
}: Props): JSX.Element => {
  const {
    formState: { isSubmitting },
  } = useFormHook
  const [isCharacterLengthValid, setIsCharacterLengthValid] = useState(false)
  const [isUppercaseValid, setIsUppercaseValid] = useState(false)
  const [isLowercaseValid, setIsLowercaseValid] = useState(false)
  const [isNumberValid, setIsNumberValid] = useState(false)
  const [isSpecialCharactersValid, setIsSpecialCharactersValid] =
    useState(false)

  const canSubmit =
    useFormHook.watch('newpassword') && useFormHook.watch('verifypassword')

  const isPasswordValid =
    isCharacterLengthValid &&
    isUppercaseValid &&
    isLowercaseValid &&
    isNumberValid &&
    isSpecialCharactersValid

  const handlePasswordChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = e

    setIsCharacterLengthValid(!!value.trim() && value.trim().length >= 12)
    setIsUppercaseValid(/[A-Z]/.test(value))
    setIsLowercaseValid(/[a-z]/.test(value))
    setIsNumberValid(/\d+/.test(value))
    setIsSpecialCharactersValid(
      /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/.test(value)
    )
  }

  const validateNewPassword = ({
    newpassword,
    verifypassword,
  }: FieldValues) => {
    if (!isPasswordValid) {
      useFormHook.setError('newpassword', {
        message: '*Password does not meet requirements',
      })
      return false
    }

    if (!verifypassword.trim() || newpassword !== verifypassword) {
      useFormHook.setError('verifypassword', {
        message: '*Passwords do not match',
      })
      return false
    }

    return true
  }

  const validateFormAndHandleSubmission = (data: FieldValues) => {
    validateNewPassword(data) && handleSubmission(data)
  }

  return (
    <Box textAlign="center">
      {(showResetError ||
        !!Object.keys(useFormHook.formState.errors).length) && (
        <Box mb={3}>
          <MessageAlert
            //TODO: Fix type error in MessageAlert
            // message={
            //   errors?.verifypassword?.message ??
            //   errors?.newpassword?.message ??
            //   undefined
            // }
            variant="error"
          />
        </Box>
      )}

      <Box
        mb={!Object.keys(useFormHook.formState.errors).length ? 4 : 3}
        px={2}
      >
        <Typography
          px={4}
          variant="body2"
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
          }}
        >
          Your new password must contain at least 12 characters, 1 uppercase, 1
          lowercase, 1 numeric, and 1 special character.
        </Typography>
      </Box>

      <form
        onSubmit={useFormHook.handleSubmit(validateFormAndHandleSubmission)}
        style={{
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2.25),
          width: '100%',
        }}
      >
        <Box mb={2} width="100%">
          <SingleLineInput
            useFormHook={useFormHook}
            handleOnChange={handlePasswordChange}
            inputType="password"
            label="New Password"
            name="newpassword"
          />
        </Box>
        <Box mb={2} width="100%">
          <SingleLineInput
            useFormHook={useFormHook}
            inputType="password"
            label="Verify Password"
            name="verifypassword"
          />
        </Box>

        <Box marginTop={3}>
          <BidscaleButton
            buttonVariant="execute"
            disabled={isSubmitting || !canSubmit}
            sx={{
              marginBottom: (theme) => theme.spacing(3),
              textTransform: 'capitalize',
              width: '100%',
            }}
            type="submit"
          >
            Reset My Password
          </BidscaleButton>
        </Box>

        <Box textAlign="center">
          <ContactUs />
        </Box>
      </form>
    </Box>
  )
}

export default ResetPasswordForm
