/* eslint-disable */
/**
 *  WARNING: DO NOT EDIT. This file is autogenerated in automated pipelines.
 * This file is generated by generate-bdsc-exports.sh
 **/

const awsmobile = { "aws_user_pools_id": "us-gov-west-1_2iUknhiB9", "aws_user_pools_web_client_id": "38459gufvo6lb6ood9fm5g2jl6", "aws_appsync_graphqlEndpoint": "https://33s8u6kd3b.execute-api.us-gov-west-1.amazonaws.com/select/graphql", "bdsc_branch_id": "staging", "aws_cognito_region": "us-gov-west-1" };


export default awsmobile;
