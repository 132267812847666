import { useParams } from 'react-router-dom'

import { Page } from './Page'
import {
  useWorkflowPageState,
  useWorkflowTransformation,
} from './state/useWorkflowState'
import WorkflowDashboardPageSkeleton from './WorkflowDashboardSkeleton'

const WorkflowDashboard = (): JSX.Element => {
  const { solicitationId } = useParams<{ solicitationId: string }>()

  const { solicitation } = useWorkflowPageState(
    decodeURIComponent(solicitationId || '')
  )
  const transformedProps = useWorkflowTransformation(solicitation)

  return solicitation ? (
    <Page {...transformedProps} />
  ) : (
    <WorkflowDashboardPageSkeleton />
  )
}

export { WorkflowDashboard }
