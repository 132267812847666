import { Box, Skeleton } from '@mui/material'
import React from 'react'

const HomePageSkeleton = (): JSX.Element => {
  return (
    <Box display="flex" ml={1} width="inherit" height="100vh">
      <Box
        width="59%"
        height="100%"
        display="flex"
        flexDirection="column"
        mt={3.5}
        pr={4}
      >
        <Skeleton
          variant="rectangular"
          height="80px"
          width="100%"
          animation="wave"
        />

        <Skeleton
          variant="rectangular"
          height="50%"
          width="100%"
          animation="wave"
          sx={{ marginTop: 10 }}
        />
      </Box>

      <Box
        display="flex"
        width="41%"
        height="100vh"
        flexDirection="column"
        pr={4}
        pt={3.5}
        pb={6.5}
      >
        <Skeleton
          variant="rectangular"
          height="30%"
          width="100%"
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          animation="wave"
          sx={{ marginTop: 7.5, flexGrow: 0.8 }}
        />
      </Box>
    </Box>
  )
}

export default HomePageSkeleton
