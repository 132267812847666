import { Box, Typography } from '@mui/material'
import { theme as AnalyticsTheme } from 'analyticsTheme'
import { addMonths } from 'date-fns'
import {
  Label,
  ToggleLegend,
} from 'pages/AcquisitionTracker/components/ToggleLegend'
import { useState } from 'react'

import { SortedCalendarDate } from '../state/AcquisitionTrackerStateFunctions'
import MonthCalendar from './MonthCalendar'

export interface Props {
  date?: Date
  calendarDates: SortedCalendarDate
  maxActions?: number
}

const ExecutiveCalendarContainer = (props: Props): JSX.Element => {
  const [toggle, setToggle] = useState<Label>(Label.ESIS)
  const dateObject = props.date ?? new Date()
  const isActionsView = toggle === Label.ACTIONS

  const getActionsColor = (amount: number) => {
    const calculate = (amount / (props.maxActions ?? 10)) * 100
    if (calculate <= 25) {
      return AnalyticsTheme.calendar.light
    }
    if (calculate <= 50) {
      return AnalyticsTheme.calendar.mid
    }
    if (calculate <= 75) {
      return AnalyticsTheme.calendar.main
    }
    return AnalyticsTheme.calendar.dark
  }

  const getColor = (type: string) => {
    return calendarColors[type]
  }

  const handleSetCalendarColor = (obj: { names: string[]; type: string }) => {
    if (!isActionsView) {
      return getColor(obj.type)
    }
    return getActionsColor(obj.names.length)
  }

  const calendar_id = 'executive-calendar-container'
  const numberOfCalendars = 3

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flex={1}
      id={calendar_id}
    >
      <Box display="flex" alignItems="flex-start" flex={1} maxWidth="1380px">
        {Array.from(Array(numberOfCalendars).keys()).map((index) => (
          <MonthCalendar
            key={calendar_id + index}
            date={addMonths(dateObject, index)}
            calendarDates={props.calendarDates}
            handleSetCalendarColor={handleSetCalendarColor}
            calendarView={toggle}
          />
        ))}
        <Box ml={3}>
          <Typography variant="body2" mb={1}>
            Calendar View
          </Typography>
          <ToggleLegend selected={toggle} handleSelection={setToggle} />
        </Box>
      </Box>
    </Box>
  )
}

const calendarColors: Record<string, string> = {
  ESIS: AnalyticsTheme.palette.primary.main,
  PSP: AnalyticsTheme.palette.info.main,
  'ESIS/PSP': `linear-gradient(320deg, ${AnalyticsTheme.palette.primary.main} 50%, ${AnalyticsTheme.palette.info.main} 50%)`,
  light: AnalyticsTheme.calendar.light,
  mid: AnalyticsTheme.calendar.mid,
  main: AnalyticsTheme.calendar.main,
  dark: AnalyticsTheme.calendar.dark,
}

export default ExecutiveCalendarContainer
