import {
  Box,
  ClickAwayListener,
  Popper,
  PopperPlacementType,
  PopperProps,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { filter } from 'lodash'
import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import { theme } from 'theme'

export type ActionMenuItem = {
  itemName: string
  onClick?: (e: React.MouseEvent) => void
  icon?: ReactElement
  disabled?: boolean
}
export interface Props {
  anchorEl: HTMLElement | PopperProps['anchorEl'] | Element | null
  isOpen: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
  actionItems: ActionMenuItem[]
  placement?: PopperPlacementType
  fallbackPlacements?: PopperPlacementType[]
}

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
})

export const ActionMenuPopper = ({
  anchorEl,
  isOpen,
  setOpen,
  actionItems,
  placement = 'bottom',
  fallbackPlacements,
}: Props): JSX.Element => {
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null)

  const handleClickAway = () => {
    window.getSelection()?.removeAllRanges()
    setOpen?.(false)
  }

  const getFallbackPlacements = (): PopperPlacementType[] => {
    if (fallbackPlacements) {
      return fallbackPlacements
    }

    return filter(['top', 'bottom', 'right', 'left'], (p) => p !== placement)
  }

  const actualFallbackPlacements = getFallbackPlacements()

  const handleClick = (e: React.MouseEvent, item: ActionMenuItem) => {
    setOpen?.(false)
    if (!item.disabled) {
      item.onClick?.(e)
    }
  }

  return (
    <Popper
      id="actions-menu-popper"
      placement={placement}
      disablePortal={true}
      open={isOpen}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: actualFallbackPlacements,
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      sx={styles.popper}
    >
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
        <div>
          <Arrow id="arrow" ref={setArrowRef} className="MuiPopper-arrow" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            bgcolor="black"
            p={1.5}
            borderRadius={0.5}
            boxShadow={theme.shadows[1]}
          >
            {actionItems.map((item, index) => (
              <Typography
                key={`${index + 1}`}
                variant="caption"
                sx={{
                  color: `${theme.palette.info.light} !important`,
                  textTransform: 'capitalize',
                  fontWeight: `${theme.typography.fontWeightBold} !important`,
                  '&:hover': {
                    color: !item.disabled
                      ? `${theme.palette.primary.light} !important`
                      : `${theme.palette.text.secondary} !important`,
                    cursor: !item.disabled ? 'pointer' : 'default',
                  },
                  '&:hover svg': {
                    fill: '#26AA94 !important',
                  },
                }}
                onClick={(e) => handleClick(e, item)}
                mb={index !== actionItems.length - 1 ? 1 : 0}
              >
                <Box display="flex" alignItems="center">
                  {item?.icon}
                  {item.itemName}
                </Box>
              </Typography>
            ))}
          </Box>
        </div>
      </ClickAwayListener>
    </Popper>
  )
}

const styles: Record<string, SxProps<Theme>> = {
  popper: {
    zIndex: 10000,
    '&  > div > div': {
      position: 'relative',
    },
    '&[data-popper-placement*="bottom"]': {
      '& > div > div': {
        marginTop: 1,
      },
      '& .MuiPopper-arrow': {
        top: 0,
        left: 0,
        marginTop: '1px',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent black transparent`,
        },
      },
    },
    '&[data-popper-placement*="top"]': {
      '& > div': {
        marginBottom: 1,
      },
      '& .MuiPopper-arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '1px',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `black transparent transparent transparent`,
        },
      },
    },
    '&[data-popper-placement*="right"]': {
      '& > div': {
        marginLeft: 1,
      },
      '& .MuiPopper-arrow': {
        left: 0,
        marginLeft: '1px',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent black transparent transparent`,
        },
      },
    },
    '&[data-popper-placement*="left"]': {
      '& > div': {
        marginRight: 1,
      },
      '& .MuiPopper-arrow': {
        right: 0,
        marginRight: '1px',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent black`,
        },
      },
    },
  },
}
