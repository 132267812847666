import { Box, Divider, Typography } from '@mui/material'
import { ReactComponent as EditorIcon } from 'assets/editor.svg'
import { ReactComponent as SummaryIcon } from 'assets/summary.svg'
import { Profile } from 'components/Profile'
import { theme } from 'theme'

interface Props {
  openCriteriaDrawer: () => void
  openSummaryDrawer: () => void
  hideActionButtons?: boolean
  flaggedCount: number
}

const LeftPanelFooter = ({
  openCriteriaDrawer,
  openSummaryDrawer,
  hideActionButtons,
  flaggedCount,
}: Props): JSX.Element => {
  return (
    <Box>
      {!hideActionButtons && (
        <Box pb={1} pt={2} display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            sx={{
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
              '&:hover': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                fill: theme.palette.primary.main,
              },
            }}
            onClick={openSummaryDrawer}
            width="50%"
          >
            <Box display="flex" ml="auto" mr={5} justifyContent="center">
              <Box my="auto">
                <SummaryIcon fill={'inherit'} />
              </Box>
              <Typography ml={1} color={'inherit'} variant="body2">
                Summary
              </Typography>
            </Box>
          </Box>
          <Box height={24}>
            <Divider orientation="vertical" />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
              '&:hover': {
                cursor: 'pointer',
                color: theme.palette.primary.main,
                fill: theme.palette.primary.main,
              },
            }}
            onClick={openCriteriaDrawer}
            width="50%"
          >
            <Box display="flex" mr="auto" ml={5} justifyContent="center">
              <Box my="auto" position="relative">
                {flaggedCount > 0 && (
                  <Box
                    borderRadius="50%"
                    height="16px"
                    width="16px"
                    bgcolor={theme.palette.secondary.main}
                    textAlign="center"
                    position="absolute"
                    left="-8px"
                    top="-10px"
                  >
                    <Typography
                      variant="subtitle2"
                      fontSize="12px"
                      my="auto"
                      lineHeight={'16px'}
                    >
                      {flaggedCount}
                    </Typography>
                  </Box>
                )}
                <EditorIcon fill={'inherit'} />
              </Box>
              <Typography ml={1} color={'inherit'} variant="body2">
                Editor
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        pt={2}
        borderTop={`1px solid`}
        borderColor={'info.main'}
      >
        <Profile variant="evaluation" />
      </Box>
    </Box>
  )
}

export default LeftPanelFooter
