import { Props as BarChartProps } from 'components/analytics/BarChart'
import { ChartData } from 'components/analytics/Chart'
import {
  Scalars,
  Solicitation,
  useGetWorkflowPageDataQuery,
} from 'generated/graphql'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'

import {
  generateStatusTableRows,
  getBarChartData,
  getDaysRemaining,
  getLineChartData,
  getTaskStatusCounts,
} from './WorkflowStateFunctions'

interface useWorkflowTransformationReturn {
  daysRemaining: number
  taskStatusCounts: TaskStatusCountsObject
  statusTableData: StatusTableRow[]
  barChartProps: BarChartProps
}

export enum WorkflowDashboardTaskStatus {
  'AtRisk' = 'At Risk',
  'Active' = 'Active',
  'NotStarted' = 'Not Started',
  'Completed' = 'Completed',
}

export interface TaskStatusCountsObject {
  [WorkflowDashboardTaskStatus.Completed]: number
  [WorkflowDashboardTaskStatus.NotStarted]: number
  [WorkflowDashboardTaskStatus.Active]: number
  [WorkflowDashboardTaskStatus.AtRisk]: number
}

export interface StatusTableRow {
  id: string
  name: string
  role: string
  status: WorkflowDashboardTaskStatus
  tasksComplete: number
  totalTasks: number
  details: TaskDetails[]
}

export interface TaskDetails {
  taskName: string
  factorName: string
  offerer: string
  status: WorkflowDashboardTaskStatus
  dueDate: string
  countDiscoveries: number
}

const useWorkflowPageState = (
  solicitationId: Scalars['ID']
): { solicitation: Solicitation | undefined } => {
  const userContext = useContext(UserContext)
  const userId = userContext.user.userId
  const tenantId = userContext.user.tenant.id

  const [solicitation, setSolicitation] = useState<Solicitation | undefined>()

  const [query] = useGetWorkflowPageDataQuery({
    variables: { tenantId: tenantId, solicitationId: solicitationId },
    pause: userId === '' || tenantId === '' || solicitationId === '',
  })

  useEffect(() => {
    if (query?.data) {
      setSolicitation(query?.data.getSolicitation as Solicitation)
    }
  }, [query])

  return {
    solicitation: solicitation,
  }
}

const useWorkflowTransformation = (
  solicitation: Solicitation | undefined
): useWorkflowTransformationReturn => {
  const yLabels = [
    WorkflowDashboardTaskStatus.NotStarted,
    WorkflowDashboardTaskStatus.AtRisk,
    WorkflowDashboardTaskStatus.Active,
    'Projected',
  ]
  const undefinedReturn: useWorkflowTransformationReturn = {
    daysRemaining: 0,
    taskStatusCounts: {
      [WorkflowDashboardTaskStatus.Completed]: 0,
      [WorkflowDashboardTaskStatus.NotStarted]: 0,
      [WorkflowDashboardTaskStatus.Active]: 0,
      [WorkflowDashboardTaskStatus.AtRisk]: 0,
    },
    statusTableData: [] as StatusTableRow[],
    barChartProps: {
      data: {
        yLabels: [],
        xLabel: '',
        data: [] as ChartData[],
      },
    },
  }
  if (solicitation) {
    return {
      daysRemaining: getDaysRemaining(solicitation.tasks),
      taskStatusCounts: getTaskStatusCounts(solicitation.tasks),
      statusTableData: generateStatusTableRows(solicitation.tasks),
      barChartProps: {
        data: {
          xLabel: 'date',
          yLabels: yLabels,
          data: getBarChartData(solicitation.tasks),
        },
        lineData: {
          xLabel: 'date',
          yLabel: 'SS Timeline',
          data: getLineChartData(solicitation.tasks),
        },
        chartOptions: {
          showLegend: true,
        },
      },
    }
  } else {
    return undefinedReturn
  }
}

export { useWorkflowPageState, useWorkflowTransformation }
