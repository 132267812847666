import useResizeObserver from '@react-hook/resize-observer'
import { useLayoutEffect, useState } from 'react'

const useElementSize = (
  target: HTMLDivElement | null,
  onResize?: () => void
): DOMRect | undefined => {
  const [size, setSize] = useState<DOMRect | undefined>(undefined)
  useLayoutEffect(() => {
    setSize(target?.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => {
    setSize(entry.contentRect)
    onResize?.()
  })
  return size
}

export { useElementSize }
