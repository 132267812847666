import { Box, Skeleton } from '@mui/material'

export const NavigationPaneSkeleton = (): JSX.Element => {
  return (
    <Box display="flex" width="188px" height="300px" mx={2} my={0.75}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ width: 1, height: 1, borderRadius: '4px' }}
      />
    </Box>
  )
}
