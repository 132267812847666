import { Box, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import SingleLineInput from 'components/form/SingleLineInput'
import { MessageAlert } from 'components/MessageAlert'
import React from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import BackToSignIn from '../../BackToSignIn'

export interface Props {
  useFormHook: UseFormReturn
  handleSubmission: (e: FieldValues) => void
}

const ForgotPaswordForm = ({
  useFormHook,
  handleSubmission,
}: Props): JSX.Element => {
  const {
    formState: { errors, isValid, isSubmitting },
  } = useFormHook

  return (
    <Box>
      {!!Object.keys(errors).length && (
        <Box mb={3}>
          <MessageAlert variant="error" />
        </Box>
      )}

      <Box>
        <Box marginBottom={1} textAlign="center">
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            Forgot Your Password?
          </Typography>
        </Box>
        <Box px={!Object.keys(errors).length ? 6 : 9.5}>
          <Typography
            variant="body2"
            sx={{ fontSize: '14px', lineHeight: '20px', textAlign: 'center' }}
          >
            {!Object.keys(errors).length
              ? 'Enter your email address and we will send you a 6-digit code to reset your password'
              : 'You either entered an incorrect email or it is not in our system'}
          </Typography>
        </Box>

        <form onSubmit={useFormHook.handleSubmit(handleSubmission)}>
          <Box pt={4}>
            <SingleLineInput
              useFormHook={useFormHook}
              rules={{ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }}
              inputType="text"
              label="Your Email"
              name="email"
            />
          </Box>

          <Box marginTop={3}>
            <BidscaleButton
              disabled={isSubmitting || !isValid}
              buttonVariant="execute"
              sx={{
                marginBottom: (theme) => theme.spacing(3),
                textTransform: 'capitalize',
                width: '100%',
              }}
              type="submit"
            >
              Send 6-digit Code
            </BidscaleButton>
          </Box>
        </form>

        <Box textAlign="center">
          <Typography variant="caption">
            Not a member? Bidscale is invite only right now.
          </Typography>
          <BackToSignIn />
        </Box>
      </Box>
    </Box>
  )
}

export default ForgotPaswordForm
