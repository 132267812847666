import { alpha, Box, SvgIcon, SvgIconProps, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { theme } from 'theme'

export interface Props {
  title: string
  linkTo: string
  pattern?: string
  svgIconProps: Partial<SvgIconProps>
}

const NavigationButton = ({
  title,
  linkTo,
  pattern,
  svgIconProps,
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const active = pattern
    ? location.pathname.startsWith(pattern)
    : location.pathname === linkTo
  return (
    <Box
      width="188px"
      height="36px"
      borderRadius="4px"
      display="flex"
      alignItems="center"
      mx={2}
      my={0.75}
      bgcolor={active ? alpha(theme.palette.primary.main, 0.2) : undefined}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          '& .buttonText': { color: theme.palette.primary.main },
          '& .svgIcon': { color: theme.palette.primary.main },
        },
      }}
      onClick={() => navigate(linkTo)}
    >
      <Box ml={2.25} display="flex" alignItems="center">
        <SvgIcon
          {...svgIconProps}
          className="svgIcon"
          sx={{
            color: active
              ? theme.palette.primary.dark
              : theme.palette.text.primary,
          }}
        />
        <Typography
          variant="subtitle2"
          ml={2}
          className="buttonText"
          sx={{
            maxWidth: '120px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: active
              ? theme.palette.primary.dark
              : theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export { NavigationButton }
