import { Box } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import DataTable, {
  ColumnConfiguration,
  Props as DataTableProps,
} from 'components/DataTable'
import {
  DateRenderer,
  TextRenderer,
} from 'components/DataTable/GenericRenderers'
import { LinearProgressBar } from 'components/ProgressBar/LinearProgressBar'
import React from 'react'
import { useForm } from 'react-hook-form'

import { ReportsTableRow } from '../state/useDocumentManagerState'

interface Props {
  reportsTableData: ReportsTableRow[]
  generateReport: (id: string) => Promise<unknown>
}

const ReportsTable = ({
  reportsTableData,
  generateReport,
}: Props): JSX.Element => {
  const GenerateRenderer = (data: ReportsTableRow): JSX.Element => {
    const formHook = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

    const handleSubmit = async () => {
      await generateReport(data.proposalId)
    }

    return (
      <BidscaleButton
        buttonVariant="secondary"
        disabled={data.countCompleteTasks !== data.countTasks}
        activityIndicator={formHook.formState.isSubmitting}
        onClick={formHook.handleSubmit(handleSubmit, handleSubmit)}
      >
        GENERATE
      </BidscaleButton>
    )
  }

  const optionalDateRenderer = (
    data: ReportsTableRow,
    column: ColumnConfiguration<ReportsTableRow>
  ): JSX.Element => {
    if (data.countCompleteTasks !== data.countTasks) {
      return (
        <Box ml={8} width="fit-content">
          -
        </Box>
      )
    }
    return DateRenderer(data, column)
  }

  const progressBarRenderer = (data: ReportsTableRow): JSX.Element => {
    return (
      <LinearProgressBar
        value={(100 * data.countCompleteTasks) / data.countTasks}
        displayProgress="fraction"
        min={data.countCompleteTasks}
        max={data.countTasks}
        border={false}
      />
    )
  }

  const tableConfig: DataTableProps<ReportsTableRow> = {
    columns: [
      {
        name: 'Offeror',
        renderer: TextRenderer,
        sort: (item) => item.organization,
        key: 'organization',
      },
      {
        name: 'Tasks Completed',
        renderer: progressBarRenderer,
        sort: (item) => item.countCompleteTasks,
      },
      {
        name: 'Status',
        renderer: TextRenderer,
        sort: (item) => item.status,
        key: 'status',
      },
      {
        name: 'Date Completed',
        renderer: optionalDateRenderer,
        sort: (item) => item.lastUpdate,
        key: 'lastUpdate',
      },
      {
        name: 'More',
        renderer: GenerateRenderer,
      },
    ],
    data: reportsTableData,
    rowsPerPage: 5,
  }

  return <DataTable {...tableConfig} />
}

export { ReportsTable }
