import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import BidscaleLogo from 'assets/Bidscale_Logomark_WhiteTealBackground_2022_V1.png'
import { EvaluationTask, EvaluationTaskType, Scalars } from 'generated/graphql'
import { SortedCriteria } from 'hooks/useSortedCriteria'
import LeftPanelFooter from 'pages/Evaluation/EvaluationLeftPanel/LeftPanelFooter'
import TopLevelMenuItem from 'pages/Evaluation/EvaluationLeftPanel/TopLevelMenuItem'
import { useLayoutEffect, useRef, useState } from 'react'

interface Props {
  evaluationTask?: EvaluationTask
  setAnnotationSelected: (activeAnnotation: string) => void
  annotationSelected: string
  sortedCriteria: SortedCriteria[]
  openEditDiscoveryDrawer: (discoveryId: Scalars['ID']) => void
  openDeleteDiscoveryDrawer: (discoveryId: Scalars['ID']) => void
  openCriteriaDrawer: () => void
  openSummaryDrawer: () => void
  width?: string | number
  criteriaOpened: string
  setCriteriaOpened: (criteriaOpened: string) => void
  openDiscovery: string
  setOpenDiscovery: (openDiscovery: string) => void
  jumpToPage: (jumpTo?: number | undefined) => void
  hideEvaluationCriteria?: boolean
  showOnTop?: boolean
  taskType: EvaluationTaskType
  openViewDiscoveryDrawer: (discoveryId: Array<Scalars['ID']>) => void
  flaggedCount: number
}

const EvaluationLeftPanel = ({
  evaluationTask,
  openEditDiscoveryDrawer,
  openDeleteDiscoveryDrawer,
  openCriteriaDrawer,
  openSummaryDrawer,
  width = 1,
  sortedCriteria,
  setAnnotationSelected,
  annotationSelected,
  criteriaOpened,
  setCriteriaOpened,
  openDiscovery,
  setOpenDiscovery,
  jumpToPage,
  hideEvaluationCriteria,
  showOnTop = true,
  taskType,
  openViewDiscoveryDrawer,
  flaggedCount,
}: Props): JSX.Element => {
  const evaluationPanelRef = useRef<HTMLDivElement>(null)
  const [isOverflowY, setIsOverflowY] = useState(false)
  const { current } = evaluationPanelRef

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const boxElementHeight = Math.round(entries[0].contentRect.height)
      if (current) {
        const hasOverflowY = boxElementHeight < current.scrollHeight
        setIsOverflowY(hasOverflowY)
      }
    })

    current && observer.observe(current)
    return () => observer.disconnect()
  }, [current])

  return (
    <Box
      id="evaluation-left-panel"
      boxSizing="border-box"
      bgcolor="info.light"
      borderRight={(theme) => `1px solid ${theme.palette.info.main}`}
      display="flex"
      flexDirection="column"
      height="100vh"
      minWidth={344}
      py={4}
      width={width}
      zIndex={showOnTop ? 1201 : 1199}
    >
      <Box px={4}>
        <Box display="flex" alignItems="center" width={48} height={48} pb={3}>
          <img
            src={BidscaleLogo}
            alt="Bidscale Logo"
            style={{ marginRight: '16px', width: '100%' }}
          />
          <Typography color="#313948" variant="overline">
            {taskType === EvaluationTaskType.Consensus
              ? 'Consensus'
              : 'Evaluation'}
          </Typography>
        </Box>
        <Box pt={3} pb={3}>
          <Typography variant="h5">{evaluationTask?.factor.name}</Typography>
        </Box>
      </Box>

      <Box
        px={4}
        ref={evaluationPanelRef}
        sx={{
          flex: '1 1 auto',
          overflowY: 'auto',
        }}
      >
        {!hideEvaluationCriteria && (
          <Box
            pt={2}
            borderTop={(theme) => `1px solid ${theme.palette.info.main}`}
          >
            <Typography variant="caption" color="info.dark">
              Evaluation Criteria
            </Typography>
            {(sortedCriteria ?? []).map((criteria, index) => (
              <TopLevelMenuItem
                key={criteria.id}
                criteria={criteria}
                criteriaOrder={index + 1}
                discoveries={criteria.discoveries ?? []}
                openEditDiscoveryDrawer={openEditDiscoveryDrawer}
                openDeleteDiscoveryDrawer={openDeleteDiscoveryDrawer}
                openDiscovery={openDiscovery}
                setOpenDiscovery={setOpenDiscovery}
                annotationSelected={annotationSelected}
                setAnnotationSelected={setAnnotationSelected}
                criteriaOpened={criteriaOpened}
                setCriteriaOpened={setCriteriaOpened}
                jumpToPage={jumpToPage}
                openViewDiscoveryDrawer={openViewDiscoveryDrawer}
              />
            ))}
          </Box>
        )}
      </Box>
      <Box
        px={4}
        sx={{
          boxShadow: isOverflowY ? '-1px -4px 4px rgba(0, 0, 0, 0.05)' : 'none',
        }}
      >
        <LeftPanelFooter
          openCriteriaDrawer={openCriteriaDrawer}
          openSummaryDrawer={openSummaryDrawer}
          hideActionButtons={hideEvaluationCriteria}
          flaggedCount={flaggedCount}
        />
      </Box>
    </Box>
  )
}

export default EvaluationLeftPanel
