import { alpha, Box, SxProps, Theme, Typography } from '@mui/material'
import { ReactComponent as Select } from 'assets/taskStatus/select.svg'
import { ReactComponent as Active } from 'assets/taskStatus/task-active.svg'
import { ReactComponent as Completed } from 'assets/taskStatus/task-completed.svg'
import { ReactComponent as Inactive } from 'assets/taskStatus/task-inactive.svg'
import { ReactComponent as AtRisk } from 'assets/taskStatus/task-risk.svg'
import { Tag } from 'components/Tag'
import { box_shadow, multilineEllipsis } from 'constants/styles'
import cuid from 'cuid'
import { format } from 'date-fns'
import { EvaluationTaskType } from 'generated/graphql'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'theme'

export enum TaskStatus {
  NOT_STARTED = 'not started',
  AT_RISK = 'at risk',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  SOLICITATION = 'solicitation',
}
export interface Props {
  id?: string
  variant: TaskStatus
  title: string
  subtitle?: string
  tasksCounter?: number
  vendor: string
  dueDate: string
  taskTypes?: EvaluationTaskType | EvaluationTaskType[]
  icon?: ReactElement
  linkTo?: string
  newTab?: boolean
  sx?: SxProps<Theme>
}

export const TaskCard = ({
  sx,
  tasksCounter,
  taskTypes,
  linkTo,
  newTab,
  variant,
  ...props
}: Props): JSX.Element => {
  const SHARED_SPACING = 2
  const TASK_TYPES = 'task-types'

  const TaskCardView = (): JSX.Element => (
    <Box
      p={3}
      borderRadius="4px"
      sx={
        [
          {
            opacity: variant === TaskStatus.COMPLETED && 0.4,
            '&:hover': {
              backgroundColor:
                variant !== TaskStatus.COMPLETED
                  ? alpha(theme.palette.primary.main, 0.05)
                  : 'none',
              cursor: variant === TaskStatus.COMPLETED ? 'default' : 'pointer',
            },
          },
          box_shadow,
          ...(Array.isArray(sx) ? sx : [sx]),
        ] as SxProps<Theme>
      }
      id={props.id}
    >
      <Box display="flex">
        <Box pt={1}>{props.icon ?? VariantIcon(variant)}</Box>

        <Box display="flex" flex={1} flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Box flexGrow={1} mx={SHARED_SPACING}>
              <Typography variant="body1" textTransform="capitalize">
                {props.title}
              </Typography>
              {props.subtitle && (
                <Typography variant="body2" sx={multilineEllipsis(2)}>
                  {props.subtitle}
                </Typography>
              )}
            </Box>

            {tasksCounter && tasksCounter > 0 ? (
              <Tag label={String(tasksCounter)} sx={{ minWidth: '25px' }} />
            ) : null}
          </Box>

          <Box
            display="flex"
            mt={3}
            ml={SHARED_SPACING}
            justifyContent="space-between"
          >
            {variant !== TaskStatus.SOLICITATION ? (
              <Box display="flex" flexWrap="wrap">
                <Box mr={2}>
                  <KeyAndValueUtility
                    label="Vendor"
                    value={truncateText(props.vendor)}
                  />
                </Box>
                <Box mr={2}>
                  <KeyAndValueUtility
                    label="Due"
                    value={format(new Date(props.dueDate), 'yyyy-MM-dd')}
                  />
                </Box>

                <KeyAndValueUtility label="Status" value={variant} />
              </Box>
            ) : (
              <KeyAndValueUtility
                label="Next Due Date"
                value={format(new Date(props.dueDate), 'yyyy-MM-dd')}
              />
            )}

            <Box display="flex" alignItems="center" id={TASK_TYPES}>
              {taskTypes instanceof Array
                ? taskTypes.map((taskType, index) => (
                    <Box
                      mr={taskTypes && index !== taskTypes?.length - 1 ? 1 : 0}
                    >
                      <Tag
                        label={truncateText(taskType.toLowerCase())}
                        key={`${TASK_TYPES}-${cuid()}`}
                      />
                    </Box>
                  ))
                : taskTypes && (
                    <Tag
                      label={truncateText(taskTypes.toLowerCase())}
                      key={`${TASK_TYPES}-${cuid()}`}
                    />
                  )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )

  return linkTo ? (
    <Link
      to={linkTo}
      style={{
        textDecoration: 'none',
        display: 'block',
      }}
      target={newTab ? '_blank' : '_self'}
    >
      <TaskCardView />
    </Link>
  ) : (
    <TaskCardView />
  )
}

const KeyAndValueUtility = (props: {
  label: string
  value: string
}): JSX.Element => (
  <Box display="flex" alignItems="center" textTransform="capitalize">
    <Typography pr={0.5} variant="subtitle2">
      {props.label}:
    </Typography>
    <Typography variant="body2">{props.value}</Typography>
  </Box>
)

const VariantIcon = (status: TaskStatus): ReactElement => {
  const HEIGHT = 40
  const WIDTH = 40
  if (status === TaskStatus.ACTIVE) {
    return <Active height={HEIGHT} width={WIDTH} />
  } else if (status === TaskStatus.AT_RISK) {
    return <AtRisk height={HEIGHT} width={WIDTH} />
  } else if (status === TaskStatus.COMPLETED) {
    return <Completed height={HEIGHT} width={WIDTH} />
  } else if (status === TaskStatus.SOLICITATION) {
    return <Select height={HEIGHT} width={WIDTH} />
  }
  return <Inactive height={HEIGHT} width={WIDTH} />
}

const truncateText = (label: string): string => {
  const LIMIT = 24
  const limitOffset = LIMIT - 2
  return label.length > limitOffset
    ? label.substring(0, limitOffset) + '...'
    : label
}
