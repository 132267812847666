import { Box } from '@mui/material'
import Color from 'color'
import { useRedrawSVG } from 'hooks/useRedrawSVG'
import { ReactNode, RefObject } from 'react'

export type ChartData = Record<string, number | string | boolean>

export interface ChartOptions {
  colorPalette: string[]
  colorMap?: Record<string, string>
  title?: string
  showLegend?: boolean
  iconPalette?: string[]
}

export interface Props {
  svgRef: RefObject<SVGSVGElement>
  legend?: ReactNode
  draw: () => void
}
const Chart = (props: Props): JSX.Element => {
  useRedrawSVG(props)

  return (
    <Box
      display="flex"
      flexDirection="row"
      height="100%"
      aria-describedby="legend"
    >
      <svg
        className="bar-chart-container"
        width="100%"
        height="100%"
        role="img"
        ref={props.svgRef}
      ></svg>

      {props.legend && (
        <Box sx={{ height: '100%', width: '20%', float: 'right' }}>
          {props.legend}
        </Box>
      )}
    </Box>
  )
}
export default Chart

export const mapColors = (
  labels: string[],
  palette: string[],
  staticMappedColors?: { [key: string]: string }
): { [key: string]: string } => {
  const mappedColorsArray = labels.map((label, index) => {
    const color = Color(palette[index % palette.length]).hsv()
    const adjustedColor = color.value(
      color.value() - 15 * Math.floor(index / palette.length)
    )
    return { [label]: adjustedColor.hex() }
  })
  const mappedColors: { [key: string]: string } = Object.assign(
    {},
    ...mappedColorsArray
  )
  return {
    ...mappedColors,
    ...staticMappedColors,
  }
}
