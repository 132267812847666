import { Box, Skeleton } from '@mui/material'
import React from 'react'

const WorkflowDashboardPageSkeleton = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      ml={1}
      width="inherit"
      height="100%"
      pr={5}
      pt={8}
    >
      <Box
        display="flex"
        width="100%"
        height="290px"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          height="290px"
          sx={{ flex: 1, mr: 5 }}
        />
        <Skeleton
          variant="circular"
          height="290px"
          width="290px"
          animation="wave"
        />
      </Box>
      <Box flex="auto" py={5}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height="100%"
          width="100%"
        />
      </Box>
    </Box>
  )
}

export default WorkflowDashboardPageSkeleton
