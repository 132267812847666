import {
  Annotation,
  AnnotationInput,
  Criteria,
  Discovery,
  DiscoveryInput,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { filter, indexOf } from 'lodash'
import { useEffect, useState } from 'react'
import { FieldValues, useForm, useWatch } from 'react-hook-form'

import { EvaluationError } from '../state/useEvaluationPageState'
import { DiscoveryDrawerContent } from './DiscoveryDrawerContent'

export interface Props {
  annotation?: AnnotationInput
  criteria: Criteria[]
  discoveries: Discovery[]
  ratingOptions: RatingOption[]
  addDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  editDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  deleteDiscovery: (discoveryId: Scalars['ID']) => Promise<boolean>
  onCancel: () => void
  editingDiscoveryId?: Scalars['ID']
  deletingDiscoveryId?: Scalars['ID']
  error?: EvaluationError
}

const DiscoveryDrawer = ({
  annotation,
  criteria,
  discoveries,
  ratingOptions,
  addDiscovery,
  editDiscovery,
  deleteDiscovery,
  onCancel,
  editingDiscoveryId,
  deletingDiscoveryId,
  error,
}: Props): JSX.Element => {
  const defaultState = {
    'Discovery Comment': '',
    'Discovery Type': 'Add New Discovery',
    'Evaluation Criteria': '',
    'Rate Discovery': '',
  }
  const formHook = useForm({
    defaultValues: defaultState as FieldValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const [removedAnnotations, setRemovedAnnotations] =
    useState<Scalars['ID'][]>()
  const {
    clearErrors,
    setValue,
    control,
    reset,
    formState: { isDirty, errors },
  } = formHook
  const selectedCriteria = useWatch({ control, name: 'Evaluation Criteria' })
  const selectedDiscovery = useWatch({ control, name: 'Discovery Type' })
  const comment = useWatch({ control, name: 'Discovery Comment' })
  const rating = useWatch({ control, name: 'Rate Discovery' })

  useEffect(() => {
    const id = editingDiscoveryId || deletingDiscoveryId
    if (!!editingDiscoveryId || !!deletingDiscoveryId) {
      setValue(
        'Evaluation Criteria',
        discoveries.find((discovery) => discovery.id === id)?.criteria.id
      )
    }
  }, [discoveries, editingDiscoveryId, deletingDiscoveryId, setValue])

  useEffect(() => {
    setValue(
      'Discovery Type',
      editingDiscoveryId ?? deletingDiscoveryId ?? 'Add New Discovery'
    )
  }, [deletingDiscoveryId, editingDiscoveryId, selectedCriteria, setValue])

  useEffect(() => {
    clearErrors()
    if (selectedDiscovery !== 'Add New Discovery') {
      const discovery = discoveries.find(
        (discovery) => discovery.id === selectedDiscovery
      )
      setValue('Discovery Comment', discovery?.rating.comment)
      setValue('Rate Discovery', discovery?.rating.option)
    }
  }, [discoveries, selectedDiscovery, setValue, clearErrors])

  const onSubmit = async (_data: { [x: string]: string }) => {
    const discovery = discoveries.find(
      (discovery) => discovery.id === selectedDiscovery
    )
    const annotations: AnnotationInput[] = [
      ...(discovery?.annotations || []),
    ] as AnnotationInput[]
    const withNewAnnotations: AnnotationInput[] = annotation
      ? [...annotations, annotation]
      : annotations
    const withoutDeletedAnnotations = removedAnnotations
      ? filter(
          withNewAnnotations,
          (annotation) => indexOf(removedAnnotations, annotation.id) < 0
        )
      : withNewAnnotations

    const data = {
      id: undefined,
      criteriaId: selectedCriteria,
      annotations: withoutDeletedAnnotations,
      rating: {
        option: rating,
        comment: comment,
      },
    }

    if (selectedDiscovery === 'Add New Discovery') {
      if (await addDiscovery(data)) {
        reset()
      }
    } else {
      data.id = selectedDiscovery
      if (await editDiscovery(data)) {
        reset()
      }
    }
  }

  const onDelete = async () => {
    if (deletingDiscoveryId && (await deleteDiscovery(deletingDiscoveryId))) {
      reset()
    }
  }

  const getAnnotations = () => {
    const existingAnnotations =
      discoveries.find((discovery) => discovery.id === selectedDiscovery)
        ?.annotations || []
    const withNewAnnotations = annotation
      ? [...existingAnnotations, { ...(annotation as Annotation) }]
      : existingAnnotations
    const withoutDeletedAnnotations = removedAnnotations
      ? filter(
          withNewAnnotations,
          (annotation) => indexOf(removedAnnotations, annotation.id) < 0
        )
      : withNewAnnotations
    return withoutDeletedAnnotations
  }

  const handleRemoveAnnotation = (id: Scalars['ID']) => {
    if (!removedAnnotations) {
      setRemovedAnnotations([id])
    } else if (indexOf(removedAnnotations, id) < 0) {
      setRemovedAnnotations([...removedAnnotations, id])
    }
  }

  return (
    <DiscoveryDrawerContent
      formHook={formHook}
      editingExistingDiscovery={!!editingDiscoveryId}
      deletingExistingDiscovery={!!deletingDiscoveryId}
      annotationsList={getAnnotations()}
      criteria={criteria}
      criteriaDisplayIndex={
        criteria.findIndex((c) => c.id === selectedCriteria) + 1
      }
      disableDiscoveryTypeSelect={selectedCriteria === ''}
      discoveriesList={filter(
        discoveries,
        (discovery) => discovery.criteria.id === selectedCriteria
      )}
      ratings={ratingOptions}
      onSave={formHook.handleSubmit(onSubmit)}
      onCancel={() => {
        onCancel()
        isDirty && reset()
      }}
      onDelete={onDelete}
      onRemoveAnnotation={handleRemoveAnnotation}
      error={error}
      hasErrors={Object.keys(errors).length > 0}
    />
  )
}

export { DiscoveryDrawer }
