import { capitalize } from 'lodash'

export const lowerTrim = (string: string): string => string.toLowerCase().trim()

export const dateTime2DateString = (
  dateTime: string,
  includeTime?: boolean
): string => {
  if (includeTime) {
    return `${new Date(dateTime).toLocaleDateString('en-US')} at ${new Date(
      dateTime
    ).toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })}`
  }
  return new Date(dateTime).toLocaleDateString('en-US')
}

export const parseStringToBoolean = (str: string): string | boolean => {
  if (str.toUpperCase() === 'TRUE' || str.toUpperCase() === 'FALSE') {
    return str.toUpperCase() === 'TRUE'
  }
  return str
}

export const capitalizeString = (sentence: string): string => {
  return sentence
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ')
}
