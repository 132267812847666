import {
  alpha,
  Box,
  Chip,
  ClickAwayListener,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { Drawer } from 'components/Drawer'
import { format } from 'date-fns'
import { ExpirationStatus, PopType } from 'generated/graphql'
import { sortBy } from 'lodash'
import { ContractPanelInterface } from 'pages/ContractsOverview/ContractsOverviewTable/ActiveInstrumentTable'
import { theme } from 'theme'

interface Props {
  data: ContractPanelInterface
  open: boolean
  handleOnClose: () => void
}

const ContractPanel = ({ open, handleOnClose, data }: Props): JSX.Element => {
  const { activeInstrument, contractOptions, closeOutDate } = data
  const getExpirationChip = (status: ExpirationStatus) => {
    let bgColor = 'info.main'
    let color = 'text.secondary'
    let text = 'Unknown'

    switch (status) {
      case ExpirationStatus.Expiring:
        bgColor = 'error.light'
        color = 'error.dark'
        text = 'Expiring'
        break
      case ExpirationStatus.Yearone:
        bgColor = 'secondary.light'
        color = '#705000'
        text = '1 Year'
        break
      case ExpirationStatus.Yeartwo:
        bgColor = alpha(theme.palette.primary.main, 0.25)
        color = 'success.main'
        text = '2 Years'
        break
    }

    return (
      <Chip
        label={
          <Typography variant="body2" color={color}>
            {text}
          </Typography>
        }
        sx={{ backgroundColor: bgColor }}
      />
    )
  }

  const getYearEndDates = () => {
    const baseOptions = contractOptions.filter((option) =>
      [PopType.Base].includes(option.popType)
    )

    const oyOptions = sortBy(
      contractOptions.filter((option) =>
        [PopType.Option].includes(option.popType)
      ),
      [(option) => new Date(option.endDate)]
    )

    return (
      <>
        {!!baseOptions.length && (
          <Typography variant="body2" sx={styles.content}>
            Base Year End Date:{' '}
            {format(new Date(baseOptions[0].endDate), 'yyyy-MM-dd')}
          </Typography>
        )}
        {oyOptions.map((option, index) => (
          <Typography variant="body2" sx={styles.content} key={index}>
            OY{index + 1}: {format(new Date(option.endDate), 'yyyy-MM-dd')}
          </Typography>
        ))}
      </>
    )
  }

  const isValidNumber = (amount: number) =>
    Number.isInteger(amount) || isFinite(amount)

  const getDollarAmount = (amount: number) => {
    if (isValidNumber(amount)) {
      return `$${new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
      }).format(amount)}`
    }
    return '--'
  }

  const getPercentage = (amount: number) => {
    if (isValidNumber(amount)) {
      return `${Math.round(amount)}%`
    }
    return '--'
  }

  const getNumberAndSetDecimal = (amount: number) => {
    if (isValidNumber(amount)) {
      return amount.toFixed(4)
    }
    return '--'
  }

  return (
    <Drawer
      anchor="right"
      elevation={12}
      hideBackdrop
      heading={activeInstrument.name}
      innerWidth={433}
      open={open}
      onDrawerClose={handleOnClose}
      variant="temporary"
    >
      <ClickAwayListener onClickAway={handleOnClose}>
        <Box py={2}>
          <Typography variant="subtitle2" sx={styles.title}>
            Active Instrument
          </Typography>
          <Typography variant="body2" sx={styles.content}>
            {activeInstrument.description ?? '--'}
          </Typography>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                PMO POC
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument?.pmoPoc ?? '--'}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Contracting POC
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument?.contractingPoc ?? '--'}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Contractor
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument?.contractor ?? '--'}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Status
              </Typography>
              <Chip
                label={
                  <Typography
                    variant="body2"
                    color={activeInstrument.active ? 'success.main' : '#705000'}
                  >
                    {activeInstrument.active ? 'Active' : 'Inactive'}
                  </Typography>
                }
                sx={activeInstrument.active ? styles.green : styles.yellow}
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Follow-on Req.
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument?.followOnReq ? 'Yes' : 'No'}
              </Typography>
            </Box>
          </Box>

          <Box mt={3}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                End Date:
              </Typography>
              <Typography variant="body2" sx={styles.content} ml={1}>
                {activeInstrument.endDate
                  ? format(new Date(activeInstrument.endDate), 'yyyy-MM-dd')
                  : '--'}
              </Typography>
            </Box>
            {getYearEndDates()}
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                CPARS Due
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument.cparsDueDate
                  ? format(
                      new Date(activeInstrument.cparsDueDate),
                      'yyyy-MM-dd'
                    )
                  : '--'}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Closeout Date
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {closeOutDate
                  ? format(new Date(closeOutDate), 'yyyy-MM-dd')
                  : '--'}
              </Typography>
            </Box>
          </Box>

          <Box mt={3}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Disbursed Target:
              </Typography>
              <Typography variant="body2" sx={styles.content} ml={1}>
                {getPercentage(activeInstrument?.displayData?.disbursedTarget)}
              </Typography>
            </Box>
            <Typography variant="body2" sx={styles.content}>
              Funded Amount: {getDollarAmount(activeInstrument.fundedAmount)}
            </Typography>
            <Typography variant="body2" sx={styles.content}>
              Unfunded Amount:{' '}
              {getDollarAmount(activeInstrument?.displayData?.unfundedAmount)}
            </Typography>
            <Typography variant="body2" sx={styles.content}>
              Disbursed Amount:{' '}
              {getDollarAmount(activeInstrument.dispersedAmount)}
            </Typography>
            <Typography variant="body2" sx={styles.content}>
              ULO Available for Re-use: {activeInstrument.uloAvailable ?? '--'}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Percent Funded
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument?.displayData?.percentFunded ?? '--'}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Liquid Coefficient
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {getNumberAndSetDecimal(
                  activeInstrument?.displayData?.liquidCoefficient
                )}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                FTEs
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument.ftes ?? '--'} FTEs
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument.aasFtes ?? '--'} A&AS FTEs
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Expiration
              </Typography>
              {getExpirationChip(
                activeInstrument.expirationStatus as ExpirationStatus
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Next FY Funding Req.
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument.nextFyFundingReq ?? '--'}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle2" sx={styles.title}>
                Funding Type
              </Typography>
              <Typography variant="body2" sx={styles.content}>
                {activeInstrument.fundingType ?? '--'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ClickAwayListener>
    </Drawer>
  )
}

const styles: Record<string, SxProps<Theme>> = {
  content: {
    lineHeight: '24px',
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  green: {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
    color: `${theme.palette.success.main}!important`,
  },
  yellow: {
    backgroundColor: 'secondary.light',
    color: '#705000',
  },
}

export default ContractPanel
