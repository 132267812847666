import { Box, Typography } from '@mui/material'
import { ReactComponent as ErrorPageImage } from 'assets/errorPage.svg'
import { Link } from 'react-router-dom'
import { theme } from 'theme'

function ErrorPage(): JSX.Element {
  const reloadPage = () => {
    window.location.reload()
  }
  return (
    <Box height="100vh" width="100%">
      <Box mx="auto" width="fit-content" height="fit-content" mt={12}>
        <ErrorPageImage />
        <Typography
          variant="body1"
          sx={{
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
          }}
        >
          Sorry, this page isn't available.
        </Typography>

        <Box
          mx="auto"
          width="100%"
          mt={2}
          sx={{ '& a': { textDecoration: 'none' } }}
          display="flex"
          justifyContent="center"
        >
          <Box width="50%" display="flex" justifyContent="flex-end">
            <Link to="/">
              <Typography variant="body1" color="#27A993" mr={1.5}>
                Go to homepage
              </Typography>
            </Link>
          </Box>

          <Box sx={{ borderLeft: `1px solid ${theme.palette.info.main}` }} />
          <Box width="50%">
            <Typography
              onClick={reloadPage}
              ml={1.5}
              variant="body1"
              color="#27A993"
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            >
              Try to reload this page
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorPage
