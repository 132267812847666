import BarChartIcon from '@mui/icons-material/BarChart'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import { Box, Typography } from '@mui/material'
import { IconButton } from 'components/Buttons/IconButton'
import React from 'react'

import { IconVariant } from '.'

export const PageAnalyticsIconButton = (props: {
  handleClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => void
  index: number
}): JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2">View</Typography>
      <IconButton
        arrIcons={[
          {
            value: IconVariant.donut,
            avatarIcon: <DonutLargeIcon fontSize="small" />,
          },
          {
            value: IconVariant.bar,
            avatarIcon: <BarChartIcon />,
          },
        ]}
        handleClick={props.handleClick}
        select={props.index === 0 ? IconVariant.donut : IconVariant.bar}
      />
    </Box>
  )
}
