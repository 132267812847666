import { Box, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import SingleLineInput from 'components/form/SingleLineInput'
import { MessageAlert } from 'components/MessageAlert'
import ContactUs from 'pages/Login/Authentication/ContactUs'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { Link } from 'react-router-dom'

interface Props {
  handleSubmission: (e: FieldValues) => Promise<void> | null
  useFormHook: UseFormReturn
}

const LoginForm = ({ useFormHook, ...props }: Props): JSX.Element => {
  const {
    formState: { errors, isValid, isSubmitting },
  } = useFormHook

  const handleSubmit = async (e: FieldValues) => {
    await props.handleSubmission(e)
  }

  return (
    <Box display="flex" flexDirection="column">
      {!!Object.keys(errors).length && (
        <Box mb={3}>
          <MessageAlert
            message="You have entered incorrect credentials"
            variant="error"
          />
        </Box>
      )}
      <Box id="login-form">
        <Box mb={2} width="100%">
          <SingleLineInput
            useFormHook={useFormHook}
            rules={{ required: true }}
            inputType="text"
            label="Your Email"
            name="email"
          />
        </Box>

        <Box mb={2} width="100%">
          <SingleLineInput
            useFormHook={useFormHook}
            rules={{ required: true }}
            inputType="password"
            label="Your Password"
            name="password"
          />
        </Box>

        <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
          <Typography
            color="primary.main"
            variant="subtitle2"
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            Forgot Your Password?
          </Typography>
        </Link>

        <BidscaleButton
          buttonVariant="execute"
          disabled={!isValid}
          sx={{ margin: (theme) => theme.spacing(3, 0), width: '100%' }}
          onClick={useFormHook.handleSubmit(handleSubmit, handleSubmit)}
          activityIndicator={isSubmitting}
        >
          Sign In
        </BidscaleButton>

        <Box textAlign="center">
          {!Object.keys(errors).length && (
            <Typography variant="caption">
              Not a member? Bidscale is invite only right now.
            </Typography>
          )}

          <ContactUs />
        </Box>
      </Box>
    </Box>
  )
}

export default LoginForm
