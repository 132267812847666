import { Box, SxProps, Theme, Typography } from '@mui/material'

export type KpiData = {
  label: number
  subtitle?: string
}

export interface Props extends KpiData {
  sx?: SxProps<Theme>
  labelSx?: SxProps<Theme>
  subtitleSx?: SxProps<Theme>
}

export const KpiLarge = ({
  label,
  subtitle,
  sx,
  ...props
}: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      id="kpi-large"
      sx={[...(Array.isArray(sx) ? sx : [sx])] as SxProps<Theme>}
      width={223}
    >
      <Typography
        variant="h1"
        display="flex"
        justifyContent="center"
        alignItems="center"
        id="kpi-large-label"
        sx={[{ fontSize: 112, height: 123 }, props.labelSx] as SxProps<Theme>}
      >
        {label}
      </Typography>

      <Typography
        variant="subtitle1"
        id="kpi-large-subtitle"
        sx={
          [
            {
              lineHeight: '38px',
              textAlign: 'center',
              fontWeight: 700,
              fontSize: 32,
            },
            props.subtitleSx,
          ] as SxProps<Theme>
        }
      >
        {subtitle}
      </Typography>
    </Box>
  )
}
