import { inRange, last, max } from 'lodash'

import { SelectedTextItem } from './PDFMetadata'
import { WrappedSelectedTextItem } from './WrappedPage'

interface OverlappedItem {
  parent: WrappedSelectedTextItem
  children: WrappedSelectedTextItem[]
}

export const reduceOverlappedItems = (
  acc: {
    parent: WrappedSelectedTextItem
    children: WrappedSelectedTextItem[]
  }[],
  matchedItem: WrappedSelectedTextItem
): {
  parent: WrappedSelectedTextItem
  children: WrappedSelectedTextItem[]
}[] => {
  const lastElement = last(acc)
  if (!lastElement) {
    return [
      ...acc.slice(0, acc.length - 1),
      { parent: matchedItem, children: [matchedItem] },
    ]
  }
  if (
    inRange(
      matchedItem.item.startIndex,
      lastElement.parent.item.startIndex,
      lastElement.parent.item.stopIndex
    )
  ) {
    const newStop = max([
      matchedItem.item.stopIndex,
      lastElement.parent.item.stopIndex,
    ]) as number
    return [
      ...acc.slice(0, acc.length - 1),
      {
        parent: {
          ...lastElement.parent,
          item: {
            ...lastElement.parent.item,
            stopIndex: newStop,
          },
        },
        children: [...lastElement.children, matchedItem],
      },
    ]
  } else {
    return [...acc, { parent: matchedItem, children: [matchedItem] }]
  }
}

export const generateTextItems = (
  overlappedItems: OverlappedItem[]
): { upto: number; elements: JSX.Element[] } => {
  return [
    ...overlappedItems,
    last(overlappedItems) as {
      parent: WrappedSelectedTextItem
      children: WrappedSelectedTextItem[]
    },
  ]
    .filter(Boolean)
    .map(({ parent }) => parent.item)
    .reduce(
      (
        acc: { upto: number; elements: JSX.Element[] },
        item: SelectedTextItem
      ): { upto: number; elements: JSX.Element[] } => {
        if (acc.upto < item.startIndex) {
          return {
            upto: item.stopIndex,
            elements: [
              ...acc.elements,
              item.item.str.substring(
                acc.upto,
                item.startIndex
              ) as unknown as JSX.Element,
            ],
          }
        } else if (acc.upto !== item.startIndex) {
          return {
            upto: item.stopIndex,
            elements: [
              ...acc.elements,
              item.item.str.substring(
                item.stopIndex,
                item.item.str.length
              ) as unknown as JSX.Element,
            ],
          }
        }
        return {
          upto: acc.upto + item.stopIndex,
          elements: [...acc.elements, '' as unknown as JSX.Element],
        }
      },
      { upto: 0, elements: [] }
    )
}

export const parseOutActiveAnnotations = (
  overlappedItems: OverlappedItem[]
): OverlappedItem[] => {
  return overlappedItems
}
