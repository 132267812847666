import { Box, Typography } from '@mui/material'
import { ActionMenuKebab } from 'components/ActionMenuKebab'
import { ActionMenuItem } from 'components/ActionMenuPopper'
import { AnnotationCardList } from 'components/Annotations/AnnotationCardList'
import RatingSelection from 'components/form/RatingSelection'
import { format } from 'date-fns'
import {
  Discovery,
  DiscoveryFeedbackInput,
  DiscoveryInput,
  EvaluationTaskType,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { filter, indexOf } from 'lodash'
import { TotalDiscovery } from 'pages/Evaluation/state/useEvaluationPageState'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import React, { useContext, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import FlagAndFeedback from '../components/FlagAndFeedback'
import RequestChange from '../components/RequestChange'
import {
  noOverFlowStyle,
  ViewAndEditComment,
} from '../components/ViewAndEditComment'
import { GenericDiscoveryBox } from './GenericDiscoveryBox'

export interface Props {
  titleNumbering: string
  title?: string
  discovery: TotalDiscovery
  ratings: Array<RatingOption>
  disabled?: boolean
  forceActive?: boolean
  onEditDiscovery: (
    discoveryInput: DiscoveryInput
  ) => Promise<Discovery | undefined>
  onDeleteDiscovery: (id: Scalars['ID']) => Promise<boolean>
  onFlagDiscovery: (
    discoveryInput: DiscoveryInput,
    discoveryTaskId: Scalars['ID']
  ) => Promise<boolean>
  customSelectionWidth?: string
  taskType: EvaluationTaskType
}

export const ViewAndEditDiscovery = ({
  titleNumbering,
  title,
  discovery,
  ratings,
  disabled = false,
  forceActive = false,
  onEditDiscovery,
  onDeleteDiscovery,
  onFlagDiscovery,
  customSelectionWidth,
  taskType,
}: Props): JSX.Element => {
  const formHook = useForm({
    defaultValues: {
      Rating: discovery.rating.option,
      Comment: discovery.rating.comment,
      RequestChange: '',
    } as FieldValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { annotations, rating, feedback } = discovery

  const [active, setActive] = useState(forceActive || false)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [requestingChange, setRequestingChange] = useState(false)
  const user = useContext(UserContext)
  const [removedAnnotations, setRemovedAnnotations] =
    useState<Scalars['ID'][]>()
  const [progressBarActive, setProgressBarActive] = useState<boolean>(false)

  const handleSave = async (data: Record<string, string>) => {
    const updatedAnnotations = getAnnotations()
    const updatedDiscovery: DiscoveryInput = {
      id: discovery.id,
      criteriaId: discovery.criteria.id,
      annotations: updatedAnnotations,
      rating: {
        option: data['Rating'],
        comment: data['Comment'],
      },
    }
    if (discovery.feedback[0] && !discovery.feedback[0]?.acknowledged) {
      updatedDiscovery.feedback = {
        id: feedback[0]?.id,
        acknowledged: true,
        comment: feedback[0]?.comment,
        sentBy: {
          id: discovery.feedback[0].sentBy?.id,
          firstName: discovery.feedback[0].sentBy?.firstName,
          lastName: discovery.feedback[0].sentBy?.lastName,
        },
      }
    }
    await onEditDiscovery(updatedDiscovery)
  }

  const handleFlagForReview = async (discoveryId: string) => {
    const updatedDiscovery: DiscoveryInput = {
      id: discoveryId,
      criteriaId: discovery.criteria.id,
      review: !discovery.review,
    }
    setProgressBarActive(true)
    await onFlagDiscovery(updatedDiscovery, discovery.taskId ?? '')

    setProgressBarActive(false)
  }

  const handleRequestChange = async (data: Record<string, string>) => {
    const updatedDiscovery: DiscoveryInput = {
      id: discovery.id,
      feedback: {
        comment: data['RequestChange'],
        sentBy: {
          id: user.user.userId,
          firstName: user.user.firstName,
          lastName: user.user.familyName,
        },
      } as DiscoveryFeedbackInput,
    }
    const totalDiscovery = discovery as TotalDiscovery
    await onFlagDiscovery?.(updatedDiscovery, totalDiscovery.taskId ?? '')
  }

  const handleRemoveAnnotation = (id: Scalars['ID']) => {
    if (!removedAnnotations) {
      setRemovedAnnotations([id])
    } else if (indexOf(removedAnnotations, id) < 0) {
      setRemovedAnnotations([...removedAnnotations, id])
    }
  }

  const getAnnotations = () => {
    const withoutDeletedAnnotations = removedAnnotations
      ? filter(
          annotations,
          (annotation) => indexOf(removedAnnotations, annotation.id) < 0
        )
      : annotations
    return withoutDeletedAnnotations
  }

  const actionMenuItems: ActionMenuItem[] =
    taskType === EvaluationTaskType.Consensus &&
    discovery.author.id !== user.user.userId
      ? [
          {
            itemName: 'Request Change',
            onClick: (e: React.MouseEvent) => {
              e.stopPropagation()
              setActive(true)
              setRequestingChange(true)
            },
            disabled:
              discovery.feedback[0] && !discovery.feedback[0].acknowledged,
          },
        ]
      : [
          {
            itemName: 'Edit',
            onClick: (e: React.MouseEvent) => {
              e.stopPropagation()
              if (!editing) {
                setEditing(true)
                setActive(true)
              }
            },
          },
          {
            itemName: 'Remove',
            onClick: (e: React.MouseEvent) => {
              e.stopPropagation()
              if (!deleting) {
                setActive(true)
                setDeleting(true)
              }
            },
          },
        ]

  const handleDelete = async () => {
    await onDeleteDiscovery(discovery.id)
  }

  const flaggedState =
    (taskType === EvaluationTaskType.Consensus && discovery.review) ||
    (!feedback[0]?.acknowledged && feedback[0])

  return (
    <GenericDiscoveryBox
      forceActive={forceActive}
      keepActive={editing || deleting}
      setActive={setActive}
      secondaryChildren={
        <Box pt={3}>
          <AnnotationCardList
            annotations={getAnnotations()}
            onRemove={editing ? handleRemoveAnnotation : undefined}
            flagged={flaggedState}
          />
        </Box>
      }
      disabled={disabled}
      editingOrDeleting={editing || deleting}
      flagged={flaggedState}
      progressBar={progressBarActive}
    >
      <Box
        id="discovery-view-edit-header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box id="discovery-view-edit-header-left-section">
          <Typography
            variant="h6"
            sx={Object.assign({}, noOverFlowStyle, {
              WebkitLineClamp: 1,
            })}
          >
            {`${titleNumbering}: ${title || rating.comment}`}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {discovery.author && (
              <Typography variant="body2">
                {`${discovery.author.firstName} ${discovery.author.lastName} -`}
                &nbsp;
              </Typography>
            )}
            <Typography variant="overline">
              {format(new Date(discovery.createdAt), 'yyyy-MM-dd')}
            </Typography>
          </Box>
        </Box>
        <Box
          id="discovery-view-edit-header-right-section"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <RatingSelection
            useFormHook={formHook}
            label="Rating"
            hideLabel={true}
            hideDefaultOption={true}
            items={ratings}
            itemColor={'backgroundColor'}
            hideErrorMessage={true}
            rules={{ required: 'This field is required' }}
            readonly={!editing}
            customSelectionWidth={customSelectionWidth}
          />
          <Box ml={2}>
            <ActionMenuKebab items={actionMenuItems} />
          </Box>
        </Box>
      </Box>
      <ViewAndEditComment
        active={active}
        editing={editing}
        setEditing={setEditing}
        formHook={formHook}
        onSave={formHook.handleSubmit(handleSave)}
        onDelete={handleDelete}
        deleting={deleting}
        setDeleting={setDeleting}
        isFlagged={flaggedState}
      />
      <RequestChange
        requestingChange={requestingChange}
        setRequestingChange={setRequestingChange}
        formHook={formHook}
        onSave={formHook.handleSubmit(handleRequestChange)}
      />
      <FlagAndFeedback
        flaggedForReview={discovery.review}
        handleFlagForReview={() => handleFlagForReview(discovery.id)}
        taskType={taskType}
        active={active}
        feedback={feedback[0]}
        acknowledged={feedback[0]?.acknowledged}
      />
    </GenericDiscoveryBox>
  )
}
