import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { alpha, Box, Typography } from '@mui/material'
import { Criteria, Discovery, Scalars } from 'generated/graphql'
import MidLevelMenuItem from 'pages/Evaluation/EvaluationLeftPanel/MidLevelMenuItem'
import { useEffect, useState } from 'react'

export interface SelectionProps {
  openDiscovery: string
  setOpenDiscovery: (openDiscovery: string) => void
  annotationSelected: string
  setAnnotationSelected: (annotationSelected: string) => void
}
interface Props extends SelectionProps {
  criteria: Criteria
  discoveries: Discovery[]
  openEditDiscoveryDrawer: (discoveryId: Scalars['ID']) => void
  openDeleteDiscoveryDrawer: (discoveryId: Scalars['ID']) => void
  criteriaOrder: number
  criteriaOpened: string
  setCriteriaOpened: (criteriaOpened: string) => void
  jumpToPage: (jumpTo?: number | undefined) => void
  openViewDiscoveryDrawer: (discoveryId: Array<Scalars['ID']>) => void
}

const TopLevelMenuItem = ({
  criteria,
  discoveries,
  openEditDiscoveryDrawer,
  openDeleteDiscoveryDrawer,
  criteriaOrder,
  annotationSelected,
  setAnnotationSelected,
  openDiscovery,
  setOpenDiscovery,
  criteriaOpened,
  setCriteriaOpened,
  jumpToPage,
  openViewDiscoveryDrawer,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (criteriaOpened === criteria.id) {
      setIsOpen(true)
      setCriteriaOpened('')
    } else if (discoveries.length === 0) {
      setIsOpen(false)
    }
  }, [criteriaOpened, criteria, discoveries, setCriteriaOpened])

  return (
    <>
      <Box
        borderBottom={(theme) => `1px solid ${theme.palette.info.main}`}
        pb={2}
        pt={2}
        onClick={() => discoveries.length && setIsOpen(!isOpen)}
        sx={{ cursor: discoveries.length ? 'pointer' : 'text' }}
      >
        <Box display="flex" alignItems="center" pr={1}>
          <Box width="fit-content" height="24px" ml={'-5px'}>
            {!isOpen && (
              <KeyboardArrowDownIcon
                sx={{
                  color: discoveries.length ? 'text.primary' : 'info.main',
                  margin: 'auto 0 auto 0',
                }}
              />
            )}
            {isOpen && <KeyboardArrowUpIcon />}
          </Box>
          <Typography
            variant="h6"
            sx={{
              flex: '1 1 90%',
              overflow: 'hidden',
              paddingLeft: (theme) => theme.spacing(2),
              paddingRight: (theme) => theme.spacing(1),
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {criteriaOrder}.0: {criteria.name}
          </Typography>
        </Box>
      </Box>
      {isOpen && discoveries.length > 0 && (
        <Box bgcolor={(theme) => alpha(theme.palette.info.main, 0.3)}>
          {discoveries.map((discovery, index) => (
            <MidLevelMenuItem
              key={discovery.id}
              discovery={discovery}
              openEditDiscoveryDrawer={openEditDiscoveryDrawer}
              openDeleteDiscoveryDrawer={openDeleteDiscoveryDrawer}
              discoveryOrder={index + 1}
              criteriaOrder={criteriaOrder}
              openDiscovery={openDiscovery}
              setOpenDiscovery={setOpenDiscovery}
              setAnnotationSelected={setAnnotationSelected}
              annotationSelected={annotationSelected}
              jumpToPage={jumpToPage}
              openViewDiscoveryDrawer={openViewDiscoveryDrawer}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default TopLevelMenuItem
