import { Box, Typography } from '@mui/material'
import Acknowledgement from 'components/Acknowledgement'
import { ExecuteButton } from 'components/Buttons/BidscaleButton'
import { Forms } from 'pages/Login'
import React, { Dispatch, SetStateAction } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { theme } from 'theme'

interface Props {
  setFormType: Dispatch<SetStateAction<Forms>>
}

const LoginAcknowledgement = ({ setFormType }: Props): JSX.Element => {
  const { control, watch } = useForm<FieldValues>({
    defaultValues: {
      checkbox: false,
    },
  })
  const isChecked = watch('checkbox')
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1">Acknowledgement</Typography>
      <Typography
        variant="caption"
        fontWeight={theme.typography.fontWeightMedium}
        lineHeight="24px"
        mt={1.5}
      >
        This is a U.S. Government system. System usage may be monitored,
        recorded, and subject to audit. Unauthorized use of this system is
        prohibited and subject to criminal and civil penalties. Use of this
        system indicates consent to monitoring and recording.
      </Typography>
      <Box my={3}>
        <Acknowledgement
          control={control}
          name="checkbox"
          message="By checking this box, you signify that you have read and acknowledge the above message."
        />
      </Box>
      <ExecuteButton
        disabled={!isChecked}
        onClick={() => setFormType(Forms.LOGIN)}
        sx={{ width: '100%' }}
      >
        Continue
      </ExecuteButton>
    </Box>
  )
}

export default LoginAcknowledgement
