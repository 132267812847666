import { Page } from './Page'
import { useAdminPanelPageState } from './state/useAdminPanelPageState'

const Admin = (): JSX.Element => {
  const state = useAdminPanelPageState()

  return <Page state={state} />
}

export { Admin }
