export enum PriorityOrder {
  LOW,
  MEDIUM,
  HIGH,
}

export enum DollarRange {
  LESS_THAN_10K = '< 10K',
  BETWEEN_10K_250K = '10K - 250K',
  BETWEEN_250K_2M = '250K - 2M',
  BETWEEN_2M_7_5M = '2M - 7.5M',
  BETWEEN_7_5M_25M = '7.5M - 25M',
  BETWEEN_25M_100M = '25M - 100M',
  BETWEEN_100M_500M = '100M - 500M',
  MORE_THAN_500M = '500M+',
}

export enum DateRange {
  MORE_THAN_24_MONTHS = '> 24 Months',
  BETWEEN_18_TO_24_MONTHS = '18 - 24 Months',
  BETWEEN_12_TO_18_MONTHS = '12 - 18 Months',
  LESS_THAN_12_MONTHS = '< 12 Months',
  MORE_THAN_6_MONTHS = '> 6 Months',
  BETWEEN_3_TO_6_MONTHS = '3 - 6 Months',
  BETWEEN_60_TO_90_DAYS = '60 - 90 Days',
  LESS_THAN_60_DAYS = '< 60 Days',
}

export enum ContractsTable {
  CONTRACT_NAME = 'Contract Name',
  CONTRACT_NUMBER = 'Contract Number',
  OPTS_REMAINING = 'Opts. Remaining',
  BUSINESS_UNIT = 'Business Unit',
  VALUE = 'Value',
  CONTR_OFFICE = 'Contr. Office',
  FINAL_COMPLETION = 'Final Completion',
  CURRENT_OPT_END = 'Current Opt. End',
  PRIORITY = 'Priority',
}

export enum ActiveInstTable {
  ACTIVE_INSTRUMENT_NUMBER = 'Active Instrument Number',
  ACTIVE_INSTRUMENT_NAME = 'Active Instrument Name',
  VALUE = 'Value',
  BUSINESS_UNIT = 'Business Unit',
  CONTRACTOR = 'Contractor',
}
