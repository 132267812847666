import {
  alpha,
  Box,
  SxProps,
  Theme,
  ToggleButton as GenericToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React from 'react'
import { theme } from 'theme'

//T likely expecting an enum
export interface ToggleButtonInfo<T> {
  value: T
  disabled?: boolean
  buttonChild?: React.ReactNode
}

export interface Props<T> {
  select: T
  buttons: ToggleButtonInfo<T>[]
  handleClick?: (event: React.MouseEvent<HTMLElement>, value: T) => void
}

export const ToggleButton = <T,>({
  select,
  handleClick,
  buttons,
}: Props<T>): JSX.Element => (
  <Box display="flex" flexDirection="column">
    <Box sx={styles.toggleContainer}>
      <ToggleButtonGroup
        sx={
          [
            styles.toggleGroup,
            {
              transform: `translateX(${
                select === buttons?.[0].value ? '3px' : '89px'
              })`,
            },
          ] as SxProps<Theme>
        }
      />

      {buttons?.map((button, index) => (
        <Box id="toggle-buttons" key={index}>
          <GenericToggleButton
            key={index}
            value={button?.value}
            sx={
              [
                styles.toggleButton,
                select === button?.value
                  ? styles.toggleHoverInactive
                  : styles.toggleHoverActive,
              ] as SxProps<Theme>
            }
            disabled={button.disabled}
            onClick={handleClick}
            disableRipple
          >
            {button?.value}
          </GenericToggleButton>
        </Box>
      ))}
    </Box>
  </Box>
)

const styles: Record<string, SxProps<Theme>> = {
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    px: '1px',
    height: '36px',
    width: '172px',
    borderRadius: '4px',
    position: 'relative',
    background: theme.palette.info.main,
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
  },
  toggleGroup: {
    position: 'absolute',
    height: '30px',
    width: '80px',
    background: theme.palette.info.light,
    borderRadius: '4px',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.5s ease',
  },
  toggleButton: {
    height: '30px',
    width: '80px',
    color: theme.palette.text.primary,
    borderRadius: '4px',
    border: 'none !important',
    m: '3px !important',
    ...theme.typography.caption,
  },
  toggleHoverActive: {
    '&:hover': {
      bgcolor: alpha(theme.palette.info.dark, 0.5),
      boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.1)',
    },
  },
  toggleHoverInactive: {
    '&:hover': {
      bgcolor: 'transparent !important',
      cursor: 'default',
    },
  },
}
