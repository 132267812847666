import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { theme } from 'theme'

export const TopBar = ({
  heading,
  children,
  p,
}: {
  heading?: string
  children?: ReactNode
  p?: number
}): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor={theme.palette.info.light}
      justifyContent="space-between"
      p={p ?? 3}
      id="top-bar"
      borderBottom={(theme) => `1px solid ${theme.palette.info.main}`}
    >
      <Box id="top-bar-heading">
        {heading && <Typography variant="h5">{heading}</Typography>}
      </Box>

      <Box id="top-bar-rest">{children}</Box>
    </Box>
  )
}
