import CircleIcon from '@mui/icons-material/Circle'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { Annotation } from 'generated/graphql'

interface Props {
  annotation: Annotation
  annotationSelected: string
  setAnnotationSelected: (annotationSelected: string) => void
  jumpToPage: (jumpTo?: number | undefined) => void
}

const LastLevelMenuItem = ({
  annotation,
  setAnnotationSelected,
  annotationSelected,
  jumpToPage,
}: Props): JSX.Element => {
  const handleClick = () => {
    if (annotationSelected === annotation.id) {
      setAnnotationSelected('')
    } else {
      setAnnotationSelected(annotation.id)
      jumpToPage(annotation.startPage)
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      pl={9.125}
      pr={1.5}
      py={1.5}
      sx={Object.assign(
        {
          cursor: 'pointer',
        },
        annotationSelected === annotation.id ? styles.clicked : styles.regular
      )}
      onClick={handleClick}
    >
      <LabelOutlinedIcon />
      <CircleIcon
        sx={{
          height: '6px',
          margin: (theme) => theme.spacing(0, 1),
          width: '6px',
        }}
      />
      <Typography
        variant="caption"
        color="inherit"
        fontWeight="inherit"
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {annotation.text}
      </Typography>
    </Box>
  )
}

export default LastLevelMenuItem

const styles: Record<string, SxProps<Theme>> = {
  clicked: {
    color: 'primary.dark',
    fontWeight: 600,
  },
  regular: {
    color: 'text.primary',
    fontWeight: 'typography.fontWeightMedium',
    '&:hover': {
      fontWeight: 'typography.fontWeightBold',
    },
  },
}
