import 'App.css'

import { Authenticator } from '@aws-amplify/ui-react'
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import Amplify from 'aws-amplify'
import { Snackbar } from 'components/snackbar'
import AuthenticatedLanding from 'pages/AuthenticatedLanding'
import ApplicationError from 'pages/ErrorPage/ApplicationError/ApplicationError'
import { AuthProvider } from 'pages/Login/User/providers/AuthProvider'
import { UserProvider } from 'pages/Login/User/providers/UserProvider'
import { BrowserRouter } from 'react-router-dom'
import { theme } from 'theme'
import { GraphQlClientProvider } from 'utils/graphql/client/GraphQlClient'

import config from './bdsc-exports'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

Amplify.configure(config)

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Snackbar>
            <ApplicationError>
              <AuthProvider>
                <UserProvider>
                  <GraphQlClientProvider>
                    <Authenticator.Provider>
                      <AuthenticatedLanding />
                    </Authenticator.Provider>
                  </GraphQlClientProvider>
                </UserProvider>
              </AuthProvider>
            </ApplicationError>
          </Snackbar>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  )
}

export default App
