import { Box, Typography } from '@mui/material'
import { Filter } from 'components/RadioGroup/Filter'
import { map } from 'lodash'

export interface FilterData {
  category: string
  options: { title: string; selected: boolean }[]
}

export interface FilterClickInput {
  category: string
  option: { title: string; selected: boolean }
}

export interface Props {
  filtersData: FilterData[]
  onFilterClick: (data: FilterClickInput) => void
}

export const Filters = ({ filtersData, onFilterClick }: Props): JSX.Element => {
  return (
    <Box px={1}>
      {map(filtersData, (filterData) => (
        <Box key={filterData.category} id={`${filterData.category}-filters`}>
          <Typography
            variant="subtitle2"
            pt={1}
          >{`${filterData.category}:`}</Typography>
          <Box display={'flex'} flexWrap="wrap">
            {map(filterData.options, (option) => (
              <Box
                key={option.title}
                id={`${filterData.category}-filters-${option}`}
                my={1}
                mr={1}
              >
                <Filter
                  title={option.title}
                  isSelected={option.selected}
                  setIsSelected={(selected: boolean) => {
                    onFilterClick({
                      category: filterData.category,
                      option: {
                        title: option.title,
                        selected: selected,
                      },
                    })
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
