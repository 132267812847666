import { Box, CircularProgress, CircularProgressProps } from '@mui/material'

interface Props {
  displayText?: string
  indicatorProps?: CircularProgressProps
}

const LoadingIndicator = ({
  displayText,
  indicatorProps,
}: Props): JSX.Element => (
  <Box>
    {indicatorProps ? (
      <CircularProgress {...indicatorProps} />
    ) : (
      <CircularProgress />
    )}
    {displayText && <p>{displayText}</p>}
  </Box>
)

export default LoadingIndicator
