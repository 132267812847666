import { ChildTable, ChildTableProps } from 'components/DataTable/ChildTable'
import {
  TagRenderer,
  TextRenderer,
} from 'components/DataTable/GenericRenderers'

import { TaskDetails } from '../../state/useWorkflowState'

interface Props {
  evaluatorStatusTableData: TaskDetails[]
}

const EvaluatorStatusTable = ({
  evaluatorStatusTableData,
}: Props): JSX.Element => {
  const tableConfig: ChildTableProps<TaskDetails> = {
    columns: [
      {
        name: 'Task Name',
        renderer: TextRenderer,
        key: 'taskName',
      },
      {
        name: 'Section',
        renderer: TextRenderer,
        key: 'factorName',
      },
      {
        name: 'Offeror',
        renderer: TextRenderer,
        key: 'offerer',
      },
      {
        name: 'Status',
        renderer: TagRenderer,
        key: 'status',
      },
      {
        name: 'Discoveries',
        renderer: TextRenderer,
        key: 'countDiscoveries',
      },
      {
        name: 'Due Date',
        renderer: TextRenderer,
        key: 'dueDate',
      },
    ],
    data: evaluatorStatusTableData,
  }

  return <ChildTable {...tableConfig} />
}

export { EvaluatorStatusTable }
