import {
  Initiative,
  useGetAcquisitionTrackerPageDataQuery,
} from 'generated/graphql'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'

export interface AcquisitionTrackerPageState {
  initiatives: Initiative[]
  isFetching: boolean
}

export function useAcquisitionTrackerPageState(): AcquisitionTrackerPageState {
  const userContext = useContext(UserContext)
  const tenantId = userContext.user.tenant.id

  const [initiatives, setInitiatives] = useState<Initiative[]>([])

  const [query] = useGetAcquisitionTrackerPageDataQuery({
    variables: {
      tenantId: tenantId,
    },
    pause: tenantId === '',
  })

  useEffect(() => {
    if (query?.data) {
      const initiatives: Initiative[] = query?.data.listInitiatives
      if (initiatives.length > 0) {
        setInitiatives(initiatives)
      }
    }
  }, [query])

  const [isFetching, setIsFetching] = useState<boolean>(query.fetching)
  useEffect(
    () => setIsFetching(query.fetching),
    [setIsFetching, query.fetching]
  )

  return {
    initiatives,
    isFetching,
  }
}
