import {
  RatingSet,
  useListUsersQuery,
  User,
  UserRole,
  useUpsertUserMutation,
} from 'generated/graphql'
import { useSnackbar } from 'notistack'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'

export interface AdminPanelPageState {
  users: User[]
  ratings: RatingSet[]
  isFetching: boolean
  upsertUser: (input: UpsertUserInput) => Promise<void>
}

export interface UpsertUserInput {
  email: string
  firstName: string
  lastName: string
  title: string
  roles: UserRole | UserRole[]
}

export function useAdminPanelPageState(): AdminPanelPageState {
  const userContext = useContext(UserContext)
  const tenantId = userContext.user.tenant.id

  const [users, setUsers] = useState<User[]>([])
  const [ratings, setRatings] = useState<RatingSet[]>([])
  const [upsertUserMutationResult, upsertUserMutation] = useUpsertUserMutation()
  const { enqueueSnackbar } = useSnackbar()

  const [query] = useListUsersQuery({
    variables: {
      tenantId: tenantId,
    },
    pause: tenantId === '',
  })

  useEffect(() => {
    if (query?.data) {
      setUsers(query?.data.listUsers as User[])
      setRatings(query.data.listRatingSets as RatingSet[])
    }
  }, [query])

  const [isFetching, setIsFetching] = useState<boolean>(query.fetching)
  useEffect(
    () => setIsFetching(query.fetching),
    [setIsFetching, query.fetching]
  )

  const upsertUser = async (input: UpsertUserInput) => {
    await upsertUserMutation({
      tenantId: tenantId,
      ...input,
    })
    if (upsertUserMutationResult.error) {
      enqueueSnackbar(`Failed to add/edit user`, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar(`User added/edited`)
    }
  }

  return {
    users,
    ratings,
    isFetching,
    upsertUser,
  }
}
