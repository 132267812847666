import { Auth } from 'aws-amplify'
import { AcquisitionTracker } from 'pages/AcquisitionTracker'
import { Admin } from 'pages/Admin'
import { ContractsOverview } from 'pages/ContractsOverview'
import ErrorPage from 'pages/ErrorPage'
import { Evaluation } from 'pages/Evaluation'
import { Home } from 'pages/Home'
import Login from 'pages/Login'
import Authentication from 'pages/Login/Authentication'
import ForgotPassword from 'pages/Login/Authentication/ForgotPassword'
import { UserContext, UserRoles } from 'pages/Login/User/providers/UserProvider'
import { NavigationPane } from 'pages/NavigationPane'
import { SolicitationHome } from 'pages/SolicitationHome'
import { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

const AuthenticatedLanding = (): JSX.Element => {
  const { hasRole } = useContext(UserContext)

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <NavigationPane />
          </RequireAuth>
        }
      >
        <Route index element={<Home />} />
        {hasRole(UserRoles.ACQUISITION_TRACKER) ? (
          <Route path="initiatives" element={<AcquisitionTracker />} />
        ) : null}
        {hasRole(UserRoles.CONTRACT_VIEWER) ? (
          <Route path="contracts" element={<ContractsOverview />} />
        ) : null}
        {hasRole(UserRoles.ADMIN) ? (
          <Route path="admin/*" element={<Admin />} />
        ) : null}
        <Route
          path="solicitations/:solicitationId/*"
          element={<SolicitationHome />}
        />
      </Route>
      <Route
        path="solicitations/:solicitationId/evaluations/:evaluationTaskId"
        element={
          <RequireAuth>
            <Evaluation />
          </RequireAuth>
        }
      />
      <Route
        path="/login"
        element={
          <Authentication>
            <Login />
          </Authentication>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Authentication>
            <ForgotPassword />
          </Authentication>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  )
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [navigate, setNavigate] = useState<boolean>(false)
  const location = useLocation()
  async function checkAuthState() {
    try {
      await Auth.currentAuthenticatedUser()
    } catch (err) {
      setNavigate(true)
    }
  }
  useEffect(() => {
    checkAuthState()
  })
  return navigate ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    children
  )
}

export default AuthenticatedLanding
