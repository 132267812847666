import { Box } from '@mui/material'
import { MessageAlert } from 'components/MessageAlert'
import { LinearProgressBar } from 'components/ProgressBar/LinearProgressBar'
import {
  UploadFile,
  UploadProgress,
} from 'pages/DocumentManager/providers/UploadDocumentProvider'

interface Props {
  uploadProgress: UploadProgress | null
  fileDetails: UploadFile | null
  uploadCompleted: number
  showUploadError: boolean
  resetUploadError: () => void
}

const UploadDocument = ({
  uploadProgress,
  fileDetails,
  uploadCompleted,
  showUploadError,
  resetUploadError,
}: Props): JSX.Element => {
  return (
    <>
      {uploadProgress && fileDetails && !showUploadError && (
        <Box id="file-progress-bar" pt={1.5} pb={2} width="100%">
          <LinearProgressBar
            border
            displayProgress="percentage"
            fileSize={fileDetails.size}
            header={`Uploading File ${uploadCompleted} of ${fileDetails.files.length}`}
            max={100}
            min={0}
            value={uploadProgress.progress * 100}
          />
        </Box>
      )}
      {showUploadError && (
        <Box id="file-upload-error" pt={1.5} pb={2} width="100%">
          <MessageAlert
            handleOnClose={resetUploadError}
            message="Upload incomplete. Failed files, please try again."
            variant="error"
          />
        </Box>
      )}
    </>
  )
}

export default UploadDocument
