import {
  Criteria,
  Discovery,
  DiscoveryInput,
  EvaluationTaskType,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { DiscoveryPositions, SortedCriteria } from 'hooks/useSortedCriteria'
import { filter, map, uniqBy } from 'lodash'

import { TotalDiscovery } from '../state/useEvaluationPageState'
import ViewDiscoveriesDrawerContent from './ViewDiscoveriesDrawerContent'

interface Props {
  discoveries: TotalDiscovery[]
  ratings: RatingOption[]
  discoveryPositions: DiscoveryPositions[]
  taskType: EvaluationTaskType
  sortedCriteria: SortedCriteria[]
  editDiscovery: (
    discoveryInput: DiscoveryInput
  ) => Promise<Discovery | undefined>
  flagDiscovery: (
    discoveryInput: DiscoveryInput,
    discoveryTaskId: Scalars['ID']
  ) => Promise<boolean>
  deleteDiscovery: (discoveryId: Scalars['ID']) => Promise<boolean>
  discoveriesToShow: string[]
}

export const ViewDiscoveriesDrawer = ({
  discoveries,
  ratings,
  discoveryPositions,
  taskType,
  sortedCriteria,
  discoveriesToShow,
  editDiscovery,
  deleteDiscovery,
  flagDiscovery,
}: Props): JSX.Element => {
  const filteredDiscoveries = discoveries.filter((discovery) =>
    discoveriesToShow.includes(discovery.id)
  )

  const getCriteria = (discoveries: TotalDiscovery[]) => {
    return uniqBy(
      map(discoveries, (discovery) => discovery.criteria),
      'id'
    )
  }

  const getDiscoveriesByCriteria = (
    criteria: Criteria,
    discoveries: TotalDiscovery[]
  ) => {
    return filter(
      discoveries,
      (discovery) => discovery.criteria.id === criteria.id
    ) as TotalDiscovery[]
  }

  const discoveryCiteriaMap: Array<{
    criteria: Criteria
    discoveries: TotalDiscovery[]
  }> = getCriteria(filteredDiscoveries).reduce(
    (
      acc: Array<{
        criteria: Criteria
        discoveries: TotalDiscovery[]
      }>,
      curr
    ) => {
      const criteriaWithId = {
        criteria: curr,
        discoveries: getDiscoveriesByCriteria(curr, filteredDiscoveries),
      }
      acc.push(criteriaWithId)
      return acc
    },
    []
  )
  return (
    <ViewDiscoveriesDrawerContent
      discoveryCriteriaMap={discoveryCiteriaMap}
      taskType={taskType}
      discoveries={filteredDiscoveries}
      ratings={ratings}
      discoveryPositions={discoveryPositions}
      flagDiscovery={flagDiscovery}
      sortedCriteria={sortedCriteria}
      editDiscovery={editDiscovery}
      deleteDiscovery={deleteDiscovery}
    />
  )
}
