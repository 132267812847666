import { Box, Typography } from '@mui/material'

export interface Definition {
  name: string
  description: string
}

interface Props {
  definition: Definition
  index: number
}

const DefinitionView = ({ definition, index }: Props): JSX.Element => {
  return (
    <Box
      sx={{
        paddingBottom: (theme) => theme.spacing(2),
        paddingTop: (theme) => theme.spacing(!index ? 1 : 2),
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: 'primary.main',
          fontSize: '18px',
          fontWeight: 700,
          marginBottom: (theme) => theme.spacing(1),
        }}
      >
        {definition.name}
      </Typography>
      <Typography variant="body2">{definition.description}</Typography>
    </Box>
  )
}

export { DefinitionView }
