import { Box, Grid, Typography } from '@mui/material'
import { RatingOption } from 'generated/graphql'

import SingleDefinition from './SingleDefinition'

export interface Props {
  title?: string
  data?: RatingOption[]
}

const RatingDefinitions = ({
  data,
  title = 'Rating Definitions',
}: Props): JSX.Element => {
  return (
    <Box id="rating-definitions-main" width="100%" my={2}>
      <Typography id="rating-definitions-main-title" variant="subtitle2" mb={1}>
        {title}
      </Typography>
      <Grid container columnSpacing={3}>
        {data &&
          data.map(
            (definition: RatingOption): JSX.Element => (
              <Grid item lg={6} md={6} sm={6} key={definition.id}>
                <SingleDefinition {...definition} />
              </Grid>
            )
          )}
      </Grid>
    </Box>
  )
}

export default RatingDefinitions
