import { alpha, Box } from '@mui/material'
import { theme as analyticsTheme } from 'analyticsTheme'
import { ToggleButton, ToggleButtonInfo } from 'components/Buttons/ToggleButton'
import Legend from 'components/Legend'
import React from 'react'
import { theme as selectTheme } from 'theme'

export enum Label {
  ESIS = 'ESIS/PSP',
  ACTIONS = 'Actions',
}

const buttons: ToggleButtonInfo<Label>[] = [
  { value: Label.ESIS },
  { value: Label.ACTIONS },
]

interface Props {
  selected: Label
  handleSelection: (val: Label) => void
}

export const ToggleLegend = ({
  handleSelection,
  selected,
}: Props): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLElement>, value: Label) =>
    handleSelection(value)

  return (
    <Box>
      <Box mb={4}>
        <ToggleButton
          select={selected}
          buttons={buttons}
          handleClick={handleClick}
        />
      </Box>

      {selected === Label.ESIS && (
        <Legend
          icons={[
            { label: 'ESIS', color: analyticsTheme.palette.primary.main },
            { label: 'PSP', color: analyticsTheme.palette.info.main },
          ]}
        />
      )}

      {selected === Label.ACTIONS && (
        <Legend
          gradient={{
            low: alpha(selectTheme.palette.secondary.dark, 0.1),
            high: selectTheme.palette.secondary.dark,
          }}
        />
      )}
    </Box>
  )
}
