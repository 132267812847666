import {
  Criteria,
  Discovery,
  RatingInput,
  RatingOption,
} from 'generated/graphql'
import { CriteriaPositions, DiscoveryPositions } from 'hooks/useSortedCriteria'
import { ReactNode } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { RecommendationDrawerContent } from './RecommendationDrawerContent'

export interface Recommendation {
  withRating: boolean
  ratingOptions: Array<RatingOption>
  proposalName?: ReactNode
  onCancel?: () => void

  openCriteriaDrawer?: (criteriaId?: string) => void
  missingCriteriaRatings?: Criteria[]
  criteriaPositions?: CriteriaPositions[]

  openViewDiscoveriesDrawer?: (discoveryIds: string[]) => void
  reviewDiscoveries?: Discovery[]
  discoveryPositions?: DiscoveryPositions[]
}

export interface Props extends Recommendation {
  upsertRating?: (inputs: { rating: RatingInput }) => void
  completeTask?: () => void
}

export const defaultProps: Props = {
  withRating: false,
  ratingOptions: [],
}

const RecommendationDrawer = ({
  upsertRating,
  completeTask,
  onCancel,
  openCriteriaDrawer,
  openViewDiscoveriesDrawer,
  ...props
}: Props): JSX.Element => {
  const formHook = useForm({
    defaultValues: {
      'Overall Recommendation': '',
      'Rate This Proposal': '',
      checkbox: false,
    } as FieldValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: { [x: string]: string }) => {
    if (props.withRating) {
      await upsertRating?.({
        rating: {
          comment: data['Overall Recommendation'],
          option: data['Rate This Proposal'],
        },
      })
    }
    await completeTask?.()
  }

  return (
    <RecommendationDrawerContent
      formHook={formHook}
      onSave={formHook.handleSubmit(onSubmit)}
      onCancel={onCancel}
      openCriteriaDrawer={openCriteriaDrawer}
      openViewDiscoveriesDrawer={openViewDiscoveriesDrawer}
      {...props}
    />
  )
}

export { RecommendationDrawer }
