import { alpha, Box } from '@mui/material'
import { FullScreenDrawer, LeftDrawer } from 'components/Drawer'
import { PdfPageNavigation } from 'components/PdfPageNavigation'
import { TopBar } from 'components/TopBar'
import { UserAgreement } from 'components/UserAgreement'
import {
  AnnotationInput,
  DiscoveryInput,
  EvaluationTaskType,
  Scalars,
} from 'generated/graphql'
import { useSortedCriteria } from 'hooks/useSortedCriteria'
import EvaluationLeftPanel from 'pages/Evaluation/EvaluationLeftPanel'
import { useState } from 'react'
import { theme } from 'theme'

import { CriteriaDrawer } from '../CriteriaDrawer'
import {
  DiscoveryDrawer,
  Props as DiscoveryDrawerProps,
} from '../DiscoveryDrawer'
import PDFViewer from '../PDFViewer'
import {
  defaultProps as defaultRecommendationDrawerProps,
  Props as RecommendationDrawerProps,
  RecommendationDrawer,
} from '../RecommendationDrawer'
import { DrawerType, useDrawerState } from '../state/useDrawerState'
import { EvaluationPageState } from '../state/useEvaluationPageState'
import { usePdfNavigation } from '../state/usePdfNavigation'
import { useZoomState } from '../state/useZoomState'
import { SummaryDrawer, SummaryDrawerHeader } from '../SummaryDrawer'
import { ViewDiscoveriesDrawer } from '../ViewDiscoveriesDrawer'

export interface DocumentState {
  numPages: number
  pageWidth: number
  pageHeight: number
}
export interface Props extends Omit<EvaluationPageState, 'isFetching'> {
  file?: string
}

export const LEFT_PANEL_WIDTH = '28vw'
export const RIGHT_PANEL_WIDTH = '72vw'

export const Page = ({
  evaluationTask,
  file,
  flagDiscovery,
  addDiscovery,
  editDiscovery,
  deleteDiscovery,
  error,
  clearError,
  upsertRating,
  completeTask,
  discoveries,
  startTask,
}: Props): JSX.Element => {
  const [confirmation, setConfirmation] = useState(false)

  const flaggedCount =
    evaluationTask?.taskType === EvaluationTaskType.Evaluation
      ? discoveries.filter(
          (discovery) =>
            discovery.feedback[0] && !discovery.feedback[0]?.acknowledged
        ).length
      : discoveries.filter((discovery) => discovery.feedback.length).length
  const { drawerState, closeDrawer, openDrawer } = useDrawerState()
  const { zoom, scale, zoomIn, zoomOut, zoomOptions, setZoom } = useZoomState({
    zoom: 1,
    ratio: 1,
  })
  const [criteriaOpened, setCriteriaOpened] = useState('')
  const [openDiscovery, setOpenDiscovery] = useState('')
  const [annotationSelected, setAnnotationSelected] = useState('')
  const { sortedCriteria, discoveryPositions, criteriaPositions } =
    useSortedCriteria(evaluationTask, discoveries)

  const [criteriaDrawerId, setCriteriaDrawerId] = useState('')

  const [discoveryDrawerState, setDiscoveryDrawerState] =
    useState<DiscoveryDrawerProps>({
      discoveries: [],
      criteria: [],
      ratingOptions: [],
      onCancel: () => {
        closeDrawer(DrawerType.DISCOVERY_DRAWER)
        clearError()
      },
      addDiscovery: async (discoveryInput: DiscoveryInput) => {
        clearError()
        const newDiscovery = await addDiscovery(discoveryInput)
        if (newDiscovery) {
          setCriteriaOpened(discoveryInput?.criteriaId || '')
          setOpenDiscovery(newDiscovery?.id || '')
          setAnnotationSelected(newDiscovery?.annotations[0].id || '')
          jumpToPage(newDiscovery?.annotations[0].startPage)
          closeDrawer(DrawerType.DISCOVERY_DRAWER)
        }
        return !!newDiscovery
      },
      editDiscovery: async (discoveryInput: DiscoveryInput) => {
        clearError()
        const updatedDiscovery = await editDiscovery(discoveryInput)
        if (updatedDiscovery) {
          setCriteriaOpened(discoveryInput?.criteriaId || '')
          setOpenDiscovery(updatedDiscovery?.id || '')
          setAnnotationSelected(
            updatedDiscovery?.annotations[
              updatedDiscovery.annotations.length - 1
            ].id || ''
          )
          closeDrawer(DrawerType.DISCOVERY_DRAWER)
        }
        return !!updatedDiscovery
      },
      deleteDiscovery: async (discoveryId: Scalars['ID']) => {
        clearError()
        const deletedSuccessfully = await deleteDiscovery(discoveryId)
        setCriteriaOpened('')
        if (deletedSuccessfully) {
          closeDrawer(DrawerType.DISCOVERY_DRAWER)
        }
        return deletedSuccessfully
      },
    })
  const [viewDiscoveriesDrawerState, setViewDiscoveriesDrawerState] = useState<
    string[]
  >([])

  const openAddDiscoveryDrawer = (annotationInput: AnnotationInput) => {
    clearError()
    setCriteriaOpened('')
    setDiscoveryDrawerState({
      ...discoveryDrawerState,
      discoveries: evaluationTask?.discoveries || [],
      criteria: sortedCriteria,
      ratingOptions: evaluationTask?.factor.discoveryRatingOptions || [],
      annotation: annotationInput,
      editingDiscoveryId: undefined,
      deletingDiscoveryId: undefined,
    })
    openDrawer(DrawerType.DISCOVERY_DRAWER)
  }

  const openEditDiscoveryDrawer = (discoveryId: Scalars['ID']) => {
    clearError()
    setCriteriaOpened('')
    setDiscoveryDrawerState({
      ...discoveryDrawerState,
      discoveries: evaluationTask?.discoveries || [],
      criteria: sortedCriteria,
      ratingOptions: evaluationTask?.factor.discoveryRatingOptions || [],
      annotation: undefined,
      editingDiscoveryId: discoveryId,
      deletingDiscoveryId: undefined,
    })
    openDrawer(DrawerType.DISCOVERY_DRAWER)
  }

  const openDeleteDiscoveryDrawer = (discoveryId: Scalars['ID']) => {
    clearError()
    setCriteriaOpened('')
    setDiscoveryDrawerState({
      ...discoveryDrawerState,
      discoveries: evaluationTask?.discoveries || [],
      criteria: sortedCriteria,
      ratingOptions: evaluationTask?.factor.discoveryRatingOptions || [],
      deletingDiscoveryId: discoveryId,
      editingDiscoveryId: undefined,
    })
    openDrawer(DrawerType.DISCOVERY_DRAWER)
  }

  const openViewDiscoveriesDrawer = (discoveryIds: string[]) => {
    clearError()
    setViewDiscoveriesDrawerState(discoveryIds)
    openDrawer(DrawerType.VIEW_DISCOVERIES_DRAWER)
  }

  const [recommendationDrawerState, setRecommendationDrawerState] =
    useState<RecommendationDrawerProps>({
      ...defaultRecommendationDrawerProps,
    })

  const openRecommendationDrawer = () => {
    setRecommendationDrawerState({
      ratingOptions: evaluationTask?.factor.overallRatingOptions || [],
      onCancel: () => closeDrawer(DrawerType.RECOMMENDATION_DRAWER),
      withRating: evaluationTask?.configuration.overallRatings || false,
      upsertRating: upsertRating,
      completeTask: completeTask,

      openCriteriaDrawer: openCriteriaDrawer,
      proposalName: evaluationTask?.proposal.name,
      missingCriteriaRatings: sortedCriteria.filter(
        (criteria) => !criteria.rating
      ),
      criteriaPositions: criteriaPositions,

      openViewDiscoveriesDrawer: openViewDiscoveriesDrawer,
      reviewDiscoveries: discoveries.filter(
        (discovery) =>
          discovery.feedback[0] && !discovery.feedback[0].acknowledged
      ),
      discoveryPositions: discoveryPositions,
    })

    openDrawer(DrawerType.RECOMMENDATION_DRAWER)
  }

  const openCriteriaDrawer = (criteriaId?: string) => {
    if (criteriaId) {
      clearError()
      setCriteriaDrawerId(criteriaId)
    }
    openDrawer(DrawerType.CRITERIA_DRAWER)
  }

  const openSummaryDrawer = () => {
    openDrawer(DrawerType.SUMMARY_DRAWER)
  }

  const {
    documentState,
    setDocumentState,
    pageInView,
    setPageInView,
    nextPage,
    prevPage,
    jumpToPage,
  } = usePdfNavigation({
    numPages: 0,
    pageWidth: 0,
    pageHeight: 0,
  })

  return (
    <>
      {evaluationTask && (
        <Box
          id="page-view"
          width="100%"
          height="100%"
          display="flex"
          flexDirection="row"
        >
          <EvaluationLeftPanel
            evaluationTask={confirmation ? evaluationTask : undefined}
            taskType={evaluationTask.taskType}
            sortedCriteria={sortedCriteria}
            setAnnotationSelected={setAnnotationSelected}
            annotationSelected={annotationSelected}
            openEditDiscoveryDrawer={openEditDiscoveryDrawer}
            openDeleteDiscoveryDrawer={openDeleteDiscoveryDrawer}
            openCriteriaDrawer={openCriteriaDrawer}
            openSummaryDrawer={openSummaryDrawer}
            width={LEFT_PANEL_WIDTH}
            setCriteriaOpened={setCriteriaOpened}
            criteriaOpened={criteriaOpened}
            openDiscovery={openDiscovery}
            setOpenDiscovery={setOpenDiscovery}
            jumpToPage={jumpToPage}
            hideEvaluationCriteria={
              drawerState.currentOpenDrawer === DrawerType.USER_AGREEMENT
            }
            showOnTop={
              drawerState.currentOpenDrawer !== DrawerType.SUMMARY_DRAWER
            }
            openViewDiscoveryDrawer={openViewDiscoveriesDrawer}
            flaggedCount={flaggedCount}
          />
          <Box
            id="evaluation-right-panel"
            flexBasis="auto"
            flexGrow={1}
            height="100vh"
            width={RIGHT_PANEL_WIDTH}
            display="flex"
            flexDirection="column"
            sx={{ bgcolor: alpha(theme.palette.info.main, 0.3) }}
          >
            <Box id="evaluation-top-bar">
              <TopBar heading={''}>
                <PdfPageNavigation
                  current={pageInView}
                  max={documentState.numPages}
                  zoom={zoom}
                  setParentZoom={setZoom}
                  zoomOptions={zoomOptions}
                  zoomInCallback={zoomIn}
                  zoomOutCallback={zoomOut}
                  arrowDownwardCallback={nextPage}
                  arrowUpwardCallback={prevPage}
                  jumpToPage={jumpToPage}
                  openRecommendationDrawer={openRecommendationDrawer}
                />
              </TopBar>
            </Box>
            <Box
              margin="auto"
              overflow="auto"
              id="evaluation-content"
              width="100%"
              height="100%"
            >
              <LeftDrawer
                open={
                  drawerState.currentOpenDrawer === DrawerType.DISCOVERY_DRAWER
                }
                heading={drawerState.currentOpenDrawer as string}
                onDrawerClose={() => closeDrawer(DrawerType.DISCOVERY_DRAWER)}
                skipValue={LEFT_PANEL_WIDTH}
              >
                {drawerState.currentOpenDrawer ===
                  DrawerType.DISCOVERY_DRAWER && (
                  <DiscoveryDrawer error={error} {...discoveryDrawerState} />
                )}
              </LeftDrawer>
              <LeftDrawer
                open={
                  drawerState.currentOpenDrawer ===
                  DrawerType.VIEW_DISCOVERIES_DRAWER
                }
                heading={evaluationTask.factor.name}
                onDrawerClose={() =>
                  closeDrawer(DrawerType.VIEW_DISCOVERIES_DRAWER)
                }
                skipValue={LEFT_PANEL_WIDTH}
              >
                {drawerState.currentOpenDrawer ===
                  DrawerType.VIEW_DISCOVERIES_DRAWER && (
                  <ViewDiscoveriesDrawer
                    discoveries={discoveries}
                    discoveriesToShow={viewDiscoveriesDrawerState}
                    ratings={evaluationTask.factor.discoveryRatingOptions}
                    discoveryPositions={discoveryPositions}
                    editDiscovery={editDiscovery}
                    sortedCriteria={sortedCriteria}
                    deleteDiscovery={deleteDiscovery}
                    taskType={evaluationTask.taskType}
                    flagDiscovery={flagDiscovery}
                  />
                )}
              </LeftDrawer>
              <LeftDrawer
                open={
                  drawerState.currentOpenDrawer === DrawerType.CRITERIA_DRAWER
                }
                heading={evaluationTask.factor.name}
                onDrawerClose={() => {
                  drawerState.currentOpenDrawer &&
                    closeDrawer(drawerState.currentOpenDrawer)
                }}
                skipValue={LEFT_PANEL_WIDTH}
              >
                {drawerState.currentOpenDrawer ===
                  DrawerType.CRITERIA_DRAWER && (
                  <CriteriaDrawer
                    discoveryPositions={discoveryPositions}
                    task={evaluationTask}
                    discoveries={discoveries}
                    editDiscovery={editDiscovery}
                    deleteDiscovery={deleteDiscovery}
                    flagDiscovery={flagDiscovery}
                    upsertRating={upsertRating}
                    criteriaId={criteriaDrawerId}
                    setCriteriaDrawerId={setCriteriaDrawerId}
                  />
                )}
              </LeftDrawer>

              <LeftDrawer
                open={
                  drawerState.currentOpenDrawer ===
                  DrawerType.RECOMMENDATION_DRAWER
                }
                heading={drawerState.currentOpenDrawer as string}
                onDrawerClose={() => {
                  drawerState.currentOpenDrawer &&
                    closeDrawer(drawerState.currentOpenDrawer)
                }}
                skipValue={LEFT_PANEL_WIDTH}
              >
                {drawerState.currentOpenDrawer ===
                  DrawerType.RECOMMENDATION_DRAWER && (
                  <RecommendationDrawer {...recommendationDrawerState} />
                )}
              </LeftDrawer>

              <LeftDrawer
                open={
                  drawerState.currentOpenDrawer === DrawerType.USER_AGREEMENT
                }
                heading={drawerState.currentOpenDrawer as string}
                innerWidth={RIGHT_PANEL_WIDTH}
                skipValue={LEFT_PANEL_WIDTH}
              >
                {drawerState.currentOpenDrawer ===
                  DrawerType.USER_AGREEMENT && (
                  <UserAgreement
                    title={evaluationTask?.proposal.name || ''}
                    onConfirmClick={() => {
                      setConfirmation(true)
                      startTask()
                      drawerState.currentOpenDrawer &&
                        closeDrawer(drawerState.currentOpenDrawer)
                    }}
                  />
                )}
              </LeftDrawer>
              <FullScreenDrawer
                open={
                  drawerState.currentOpenDrawer === DrawerType.SUMMARY_DRAWER
                }
                customHeader={
                  <SummaryDrawerHeader
                    closeDrawer={() => closeDrawer(DrawerType.SUMMARY_DRAWER)}
                    task={evaluationTask}
                    completeTask={completeTask}
                    upsertRating={upsertRating}
                  />
                }
                sticky
              >
                {drawerState.currentOpenDrawer ===
                  DrawerType.SUMMARY_DRAWER && (
                  <SummaryDrawer
                    discoveries={discoveries}
                    task={evaluationTask}
                    editDiscovery={editDiscovery}
                    deleteDiscovery={deleteDiscovery}
                    flagDiscovery={flagDiscovery}
                  />
                )}
              </FullScreenDrawer>
              <PDFViewer
                file={file}
                scale={scale}
                openAddDiscoveryDrawer={openAddDiscoveryDrawer}
                openViewDiscoveriesDrawer={openViewDiscoveriesDrawer}
                pageInView={pageInView}
                setPageInView={setPageInView}
                documentState={documentState}
                setDocumentState={setDocumentState}
                passiveDiscoveries={discoveries}
                activeAnnotation={annotationSelected}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
