import { alpha, Box } from '@mui/material'
import BarChart, { Props as BarchartProps } from 'components/analytics/BarChart'
import { ChartData } from 'components/analytics/Chart'
import PieChart from 'components/analytics/PieChart'
import { KpiLarge } from 'components/Kpi/LargeKpi'
import { KpiMedium } from 'components/Kpi/MediumKpi'
import { PageSection } from 'components/PageSection'
import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { theme } from 'theme'

import { EvaluatorTable } from '../EvaluatorTable'
import {
  StatusTableRow,
  TaskStatusCountsObject,
  WorkflowDashboardTaskStatus as TaskStatus,
} from '../state/useWorkflowState'
import { PageAnalyticsIconButton } from './PageAnalyticsIconButton'

export enum IconVariant {
  donut = 'donut',
  bar = 'bar',
}
interface Props {
  daysRemaining: number
  taskStatusCounts: TaskStatusCountsObject
  statusTableData: StatusTableRow[]
  barChartProps: BarchartProps
}

const Page = ({
  daysRemaining,
  taskStatusCounts,
  statusTableData,
  barChartProps,
}: Props): JSX.Element => {
  const [index, setIndex] = useState<number>(0)
  const handleClick = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setIndex(value === IconVariant.donut ? 0 : 1)
  }

  const baseId = 'workflow-dashboard'

  const KpiColumn = ({
    labelTop,
    labelBottom,
  }: {
    labelTop: TaskStatus
    labelBottom: TaskStatus
  }): JSX.Element => {
    return (
      <Box display="flex" flexDirection="column">
        <KpiMedium
          value={taskStatusCounts[labelTop]}
          subtitle={labelTop}
          barColor={styles.statusColorMap[labelTop]}
        />
        <Box pb={6} />
        <KpiMedium
          value={taskStatusCounts[labelBottom]}
          subtitle={labelBottom}
          barColor={styles.statusColorMap[labelBottom]}
        />
      </Box>
    )
  }
  return (
    <>
      <PageSection
        header="Overview"
        inlineRightChildren={
          <Box display="flex" justifyContent="flex-end">
            <PageAnalyticsIconButton handleClick={handleClick} index={index} />
          </Box>
        }
      >
        <Box id="evaluation-dashboard-carousel" width="100%">
          <Carousel
            animation="slide"
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysInvisible={true}
            index={index}
            swipe={false}
          >
            <Box id={`${baseId}`} display="flex" justifyContent="space-around">
              <Box
                id={`${baseId}-kpis`}
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                flex={1}
              >
                <KpiLarge label={daysRemaining} subtitle="Days Remaining" />

                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  flex={1}
                >
                  <KpiColumn
                    labelTop={TaskStatus.Completed}
                    labelBottom={TaskStatus.Active}
                  />
                  <KpiColumn
                    labelTop={TaskStatus.NotStarted}
                    labelBottom={TaskStatus.AtRisk}
                  />
                </Box>
              </Box>

              <Box
                id={`${baseId}-pie-chart`}
                display="flex"
                alignItems="center"
                height="290px"
                width="290px"
                mr={5}
              >
                <PieChart
                  data={taskStatusCounts as unknown as Partial<ChartData>}
                  chartOptions={{
                    centerValue: `${Object.values(taskStatusCounts).reduce(
                      (a, b) => a + b,
                      0
                    )}`,
                    centerLabel: 'Total Tasks',
                    colorMap: styles.statusColorMap,
                    heightWidth: 290,
                  }}
                />
              </Box>
            </Box>

            <Box id={`${baseId}-bar-chart`} width="100%" height="290px">
              <BarChart
                data={barChartProps.data}
                lineData={barChartProps.lineData}
                chartOptions={{
                  colorMap: {
                    Projected: theme.palette.info.main,
                    ...styles.statusColorMap,
                  },
                  xAxis: { xAxis1: 'day', xAxis2: 'date' },
                  ...barChartProps.chartOptions,
                }}
              />
            </Box>
          </Carousel>
        </Box>
      </PageSection>
      <PageSection header="Evaluator Status">
        <EvaluatorTable evaluatorTableData={statusTableData} />
      </PageSection>
    </>
  )
}
const styles = {
  statusColorMap: {
    [TaskStatus.AtRisk]: theme.palette.error.light,
    [TaskStatus.NotStarted]: theme.palette.info.dark,
    [TaskStatus.Active]: alpha(theme.palette.primary.main, 0.25),
    [TaskStatus.Completed]: theme.palette.success.light,
  },
}

export { Page }
