import { ActivityFeed } from 'components/ActivityFeed'
import {
  EvaluationTask,
  Solicitation,
  useGetSolicitationPageDataQuery,
} from 'generated/graphql'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'

export interface SolicitationPageState {
  solicitation?: Solicitation
  tasks?: EvaluationTask[]
  activityFeed?: ActivityFeed[]
}

export function useSolicitationPageState(
  solicitationId: string
): SolicitationPageState {
  const userContext = useContext(UserContext)
  const tenantId = userContext.user.tenant.id
  const userId = userContext.user.userId
  const [tasks, setTasks] = useState<EvaluationTask[]>()
  const [solicitation, setSolicitation] = useState<Solicitation>()
  const [activityFeed, setActivityFeed] = useState<ActivityFeed[]>()

  const [query] = useGetSolicitationPageDataQuery({
    variables: {
      tenantId: tenantId,
      solicitationId: solicitationId,
      userId: userId,
    },
    pause: solicitationId === '' || tenantId === '' || userId === '',
  })

  useEffect(() => {
    if (query?.data) {
      const tasksPerSolicitation = query?.data.listEvaluationTasksByUser.filter(
        (task) => task.proposal.solicitationId === solicitationId
      )
      setTasks(tasksPerSolicitation as EvaluationTask[])
      setSolicitation(query?.data.getSolicitation as Solicitation)
      setActivityFeed(query?.data.listActivities as ActivityFeed[])
    }
  }, [query, solicitationId])

  return {
    solicitation,
    tasks,
    activityFeed,
  }
}
