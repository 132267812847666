import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  Collapse,
  IconButton,
  SxProps,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ColumnConfiguration } from 'components/DataTable/index'
import React, { Fragment, useState } from 'react'
import { theme } from 'theme'

interface Props<T> {
  collapsible?: React.FC<T>
  isCollapsible?: (data: T) => boolean
  columns: ColumnConfiguration<T>[]
  data: T[]
}

interface RowProps<T> {
  collapsible?: React.FC<T>
  isCollapsible?: boolean
  isCollapsibleOpen?: boolean
  setCollapsibleState?: (state: boolean) => void
  columns: ColumnConfiguration<T>[]
  item: T
}

const Row = <T,>({
  collapsible,
  isCollapsible,
  isCollapsibleOpen,
  setCollapsibleState,
  columns,
  item,
}: RowProps<T>): JSX.Element => {
  const classes = useStyles()

  const getStyles = (data: ColumnConfiguration<T>) => {
    return {
      px: 1,
      ...(typeof data.bodySx === 'function'
        ? (data.bodySx as (item: T) => SxProps<Theme>)(item)
        : data.bodySx),
    }
  }

  return (
    <>
      <TableRow
        sx={{
          height: theme.spacing(10),
          '& .MuiTypography-root': theme.typography.body2,
        }}
        id="data-table-body"
      >
        {collapsible && (
          <TableCell sx={{ p: 0, width: theme.spacing(3) }}>
            <IconButton
              aria-label="expand row"
              sx={{ p: 0 }}
              size="small"
              disabled={!isCollapsible}
              onClick={() => setCollapsibleState?.(!isCollapsibleOpen)}
            >
              {isCollapsibleOpen ? (
                <KeyboardArrowUp id="table-collapse-button" />
              ) : (
                <KeyboardArrowDown id="table-expand-button" />
              )}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column, i) => (
          <TableCell
            key={i}
            className={clsx(column?.classes?.body, classes.column)}
            sx={getStyles(column)}
          >
            {column.sort && (
              <Box
                sx={{
                  width: theme.spacing(2),
                  height: theme.spacing(1),
                  float: 'left',
                }}
              />
            )}
            {column.renderer(item, column)}
          </TableCell>
        ))}
      </TableRow>
      {collapsible && (
        <TableRow sx={{ '& .MuiTableRow-root': theme.typography.body2 }}>
          <TableCell sx={{ border: 0, p: 0 }} colSpan={columns.length + 1}>
            <Collapse in={isCollapsibleOpen} timeout="auto" unmountOnExit>
              {collapsible(item)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

//eslint-disable-next-line
const DataTableBody = <T extends Record<string, any>>({
  collapsible,
  isCollapsible,
  columns,
  data,
}: Props<T>): JSX.Element => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>())

  return (
    <TableBody sx={{ '& .MuiTableBody-root': theme.typography.body2 }}>
      {data.map((item, i) => (
        <Fragment key={i}>
          <Row
            collapsible={collapsible}
            isCollapsible={isCollapsible?.(item)}
            isCollapsibleOpen={isCollapsibleOpen.get(item.id) || false}
            setCollapsibleState={(state: boolean) =>
              setIsCollapsibleOpen(
                (previous) =>
                  new Map<string, boolean>(previous.set(item.id, state))
              )
            }
            columns={columns}
            item={item}
          />
          {data.length < 2 && <TableRow sx={{ height: theme.spacing(10) }} />}
        </Fragment>
      ))}
    </TableBody>
  )
}

const useStyles = makeStyles((theme) => ({
  column: {
    marginLeft: theme.spacing(2),
  },
}))
export default DataTableBody
