import { useParams } from 'react-router-dom'

import { Page } from './Page'
import SolicitationPageSkeleton from './SolicitationPageSkeleton'
import { useSolicitationPageState } from './state/useSolicitationPageState'

const SolicitationHome = (): JSX.Element => {
  const { solicitationId } = useParams<{ solicitationId: string }>()
  const decodedSolicitationId = decodeURIComponent(solicitationId || '')
  const state = useSolicitationPageState(decodedSolicitationId)

  return state.solicitation ? <Page {...state} /> : <SolicitationPageSkeleton />
}

export { SolicitationHome }
