import DataTable, { Props as DataTableProps } from 'components/DataTable'
import {
  TagRenderer,
  TextRenderer,
} from 'components/DataTable/GenericRenderers'
import { LinearProgressBar } from 'components/ProgressBar/LinearProgressBar'

import { EvaluatorStatusTable } from '../EvaluatorTable/EvaulatorStatusTable'
import { StatusTableRow } from '../state/useWorkflowState'

interface Props {
  evaluatorTableData: StatusTableRow[]
}

function isCollapsible(data: StatusTableRow): boolean {
  return !!data.details?.length
}

const EvaluatorTable = ({ evaluatorTableData }: Props): JSX.Element => {
  const LinearBarRenderer = (data: StatusTableRow): JSX.Element => {
    return (
      <LinearProgressBar
        displayProgress="fraction"
        max={data.totalTasks}
        min={data.tasksComplete}
        value={(data.tasksComplete / data.totalTasks) * 100}
        variant="determinate"
      />
    )
  }

  const collapseRenderer = (data: StatusTableRow): JSX.Element => {
    return <EvaluatorStatusTable evaluatorStatusTableData={data.details} />
  }

  const tableConfig: DataTableProps<StatusTableRow> = {
    columns: [
      {
        name: 'Name',
        renderer: TextRenderer,
        sort: (item) => item.name.toLowerCase(),
        key: 'name',
      },
      {
        name: 'Role',
        renderer: TextRenderer,
        sort: (item) => item.role.toLowerCase(),
        key: 'role',
      },
      {
        name: 'Overall State',
        renderer: TagRenderer,
        sort: (item) => item.status.toLowerCase(),
        key: 'status',
      },
      {
        name: 'Tasks Completed',
        renderer: LinearBarRenderer,
        sort: (item) => item.tasksComplete / item.totalTasks,
        key: 'totalTasks',
      },
    ],
    data: evaluatorTableData,
    rowsPerPage: 5,
    isCollapsible: isCollapsible,
    collapsible: collapseRenderer,
  }

  return <DataTable {...tableConfig} />
}

export { EvaluatorTable }
