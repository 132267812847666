import { CSSProperties } from '@mui/styled-engine'
import Chart, {
  ChartData,
  ChartOptions,
  mapColors,
} from 'components/analytics/Chart'
import * as d3 from 'd3'
import { useCallback, useEffect, useRef } from 'react'
import { theme } from 'theme'

export interface Props {
  data: Partial<ChartData>
  chartOptions?: Partial<PieChartOptions>
}

export interface PieChartOptions extends ChartOptions {
  centerLabel?: string
  centerValue?: string
  heightWidth?: number
}

const defaultChartOptions: ChartOptions = {
  colorPalette: [],
  colorMap: {},
}

const PieChart = (props: Props): JSX.Element => {
  const d3svg = useRef<SVGSVGElement>(null)

  const chartOptions = { ...defaultChartOptions, ...props.chartOptions }
  const mappedColors = mapColors(
    Object.keys(props.data),
    chartOptions?.colorPalette,
    props?.chartOptions?.colorMap
  )

  const renderCenterLabel = useCallback(
    (group: d3.Selection<SVGGElement, unknown, null, undefined>) => {
      if (props?.chartOptions?.centerValue) {
        group
          .selectAll('g')
          .data([1])
          .enter()
          .append('text')
          .attr('fill', styles.textValueTheme.color as string)
          .attr('font-size', styles.textValueTheme.fontSize as string)
          .attr('font-weight', styles.textValueTheme.fontWeight as string)
          .attr('font-family', styles.textValueTheme.fontFamily as string)
          .attr('text-anchor', 'middle')
          .attr('y', '3%')
          .text((_d) => props?.chartOptions?.centerValue || '')
      }
      if (props?.chartOptions?.centerLabel) {
        group
          .selectAll('g')
          .data([1])
          .enter()
          .append('text')
          .attr('fill', styles.textLabelTheme.color as string)
          .attr('font-size', styles.textLabelTheme.fontSize as string)
          .attr('font-weight', styles.textLabelTheme.fontWeight as string)
          .attr('font-family', styles.textLabelTheme.fontFamily as string)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'text-before-edge')
          .attr('y', '7%')
          .text((_d) => props?.chartOptions?.centerLabel || '')
      }
    },
    [props?.chartOptions?.centerLabel, props?.chartOptions?.centerValue]
  )

  const drawArchs = useCallback(
    (
      group: d3.Selection<SVGGElement, unknown, null, undefined>,
      radius: number
    ) => {
      const dataMapKeys = Object.keys(props.data)
      const dataMapValues = Object.values(props.data)

      const arc = d3
        .arc<d3.PieArcDatum<Partial<ChartData>>>()
        .innerRadius(radius * 0.6)
        .outerRadius(radius - 1)

      // Compute the position of each group on the pie:
      const pie = d3
        .pie<Partial<ChartData>>()
        .sort(null)
        .value((d) => d as unknown as number)

      const arcs = pie(dataMapValues as unknown as ChartData[])

      group
        .selectAll('path')
        .data(arcs)
        .join('path')
        .attr('fill', (_d, i) => mappedColors[dataMapKeys[i]])
        .attr('d', arc)
    },
    [mappedColors, props.data]
  )

  const drawChart = useCallback(() => {
    if (d3svg.current && props.data) {
      const svg = d3.select(d3svg.current)
      svg.selectAll('*').remove()
      const height = props?.chartOptions?.heightWidth
        ? props?.chartOptions?.heightWidth
        : Math.min(
            d3svg.current?.height.baseVal.value,
            d3svg.current?.width.baseVal.value
          )
      const width = height
      const translatedGroup = svg
        .append('g')
        .attr(
          'transform',
          'translate(' +
            d3svg.current?.width.baseVal.value / 2 +
            ',' +
            d3svg.current?.height.baseVal.value / 2 +
            ')'
        )

      renderCenterLabel(translatedGroup)
      drawArchs(translatedGroup, Math.min(width, height) / 2)
    }
  }, [
    drawArchs,
    props?.chartOptions?.heightWidth,
    props.data,
    renderCenterLabel,
  ])

  useEffect(() => {
    drawChart()
  }, [drawChart])

  return <Chart svgRef={d3svg} draw={drawChart} />
}

const styles: Record<string, CSSProperties> = {
  textValueTheme: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h1.fontFamily,
  },
  textLabelTheme: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.subtitle1.fontSize,
    fontFamily: theme.typography.subtitle1.fontFamily,
  },
}

export default PieChart
