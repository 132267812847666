import { Box, Skeleton } from '@mui/material'

const ContractsOverviewSkeleton = (): JSX.Element => {
  return (
    <Box ml={0.75} mt={3.5} mr={4} width={1}>
      <Box mb={4}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height="80px"
          width="50%"
        />
      </Box>

      <Skeleton
        variant="rectangular"
        animation="wave"
        height={'80%'}
        width="100%"
      />
    </Box>
  )
}

export default ContractsOverviewSkeleton
