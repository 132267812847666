import Selection from 'components/form/SelectionBox/Selection'
import { Props as SelectionLabelProps } from 'components/form/SelectionBox/SelectionLabel'
import {
  Controller,
  FieldValues,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form'

export interface SharedProps<T> {
  defaultOption?: string
  disabled?: boolean
  hideDefaultOption?: boolean
  hideLabel?: boolean
  hideErrorMessage?: boolean
  items: T[]
  itemLabel?: keyof T
  itemValue?: keyof T
  readonly?: boolean
  renderValue?: (obj: { item: T; field: FieldValues }) => JSX.Element
  useFormHook: UseFormReturn
}

export interface Props<T> extends SharedProps<T>, SelectionLabelProps {
  classes?: Record<string, string>
  defaultValue?: string
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  customSelectionWidth?: string
}

const SelectionBox = <T extends Record<string, unknown>>({
  defaultOption = 'Select One',
  defaultValue = '',
  disabled,
  label,
  hideDefaultOption = false,
  hideLabel = false,
  hideErrorMessage = false,
  items,
  itemLabel = 'name',
  itemValue = 'id',
  readonly,
  renderValue,
  rules,
  useFormHook,
  customSelectionWidth,
}: Props<T>): JSX.Element => {
  const { control } = useFormHook
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={label}
      render={({ field }) => (
        <Selection
          defaultOption={defaultOption}
          defaultValue={defaultValue}
          disabled={disabled}
          field={field}
          hideDefaultOption={hideDefaultOption}
          hideLabel={hideLabel}
          hideErrorMessage={hideErrorMessage}
          items={items}
          itemLabel={itemLabel}
          itemValue={itemValue}
          label={label}
          readonly={readonly}
          renderValue={renderValue}
          useFormHook={useFormHook}
          customSelectionWidth={customSelectionWidth}
        />
      )}
      rules={rules}
    />
  )
}

export default SelectionBox
