import { Box, Paper, SxProps, Theme, Typography } from '@mui/material'
import { ReactComponent as BidscaleLogo } from 'assets/bidscaleLogoAndText.svg'
import { ReactComponent as Memorial } from 'assets/memorialBackground.svg'
import React from 'react'

interface Props {
  children: React.ReactNode
}

const Authentication = ({ children }: Props): JSX.Element => {
  return (
    <>
      <Memorial
        style={{
          position: 'absolute',
          top: 0,
          minWidth: '110%',
          left: '-10%',
          minHeight: '100%',
        }}
      />
      <Box sx={styles.mainContainer}>
        <Paper sx={styles.paper} elevation={16}>
          <Box display="flex" alignItems="stretch">
            <Box
              bgcolor="primary.main"
              borderRadius="4px 0 0 4px"
              boxSizing="border-box"
              minHeight={617}
              px={7}
              pt={15}
              width={273}
            >
              <Typography
                color="info.light"
                textAlign="center"
                variant="h4"
                width={164}
              >
                Let's get started
              </Typography>
            </Box>
            <Box flex="1 1 auto">
              <Box pb={5} pt={9} textAlign="center" width="100%">
                <BidscaleLogo style={{ width: '20em' }} />
              </Box>
              <Box pb={4} px={7}>
                {children}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

const styles: Record<string, SxProps<Theme>> = {
  icon: {
    position: 'absolute',
    left: '30px',
    top: '-150px',
    zIndex: 10,
    width: '300px',
  },
  mainContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    boxSizing: 'border-box',
    margin: 'auto',
    width: '785px',
  },
}

export default Authentication
